import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    formWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },

    timeFields: {
        flexDirection: 'row',
        flex: 1,
    },

    timeFieldInputContainer: {
        width: '50%',
        paddingLeft: 10,
        paddingRight: 10,
    },

    timeFieldInput: {
        width: '100%',
    },
}));

export const leftCalIcon = {
    type: 'ionicon',
    name: 'calendar',
    size: 18,
};

export const leftTimeIcon = {
    type: 'ionicon',
    name: 'time-outline',
    size: 18,
};

export default useStyles;
