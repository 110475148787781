import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Pressable, ScrollView, View} from 'react-native';
import {Button, ButtonGroup, Icon, Input, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    BANK,
    BANK_ADDRESS,
    BANK_INFO,
    CONFIRM,
    EDIT_USER_DATA,
    IBAN,
    OIB,
    SWIFT,
    USER_DATA_ADDRESS,
    USER_DATA_CITY_POST,
    USER_DATA_CONTACT,
    USER_DATA_COUNTRY,
    USER_DATA_EMAIL,
    USER_DATA_FORM_ADDRESS,
    USER_DATA_FORM_BANK,
    USER_DATA_FORM_BANK_ADDRESS,
    USER_DATA_FORM_BANK_IBAN,
    USER_DATA_FORM_BANK_SWIFT,
    USER_DATA_FORM_CITY,
    USER_DATA_FORM_COUNTRY,
    USER_DATA_FORM_EMAIL,
    USER_DATA_FORM_ENTITY,
    USER_DATA_FORM_ENTITY_FLAG,
    USER_DATA_FORM_HUMAN_ENTITY,
    USER_DATA_FORM_IN_PDV_SYSTEM,
    USER_DATA_FORM_LEGAL_ENTITY,
    USER_DATA_FORM_LEGAL_NAME,
    USER_DATA_FORM_OIB,
    USER_DATA_FORM_PERSONAL_NAME,
    USER_DATA_FORM_TEL,
    USER_DATA_INV_FOOTER_DATA,
    USER_DATA_INV_OPERATOR,
    USER_DATA_INV_OPERATOR_ID,
    USER_DATA_LEGAL_ENTITY,
    USER_DATA_LEGAL_NAME,
    USER_DATA_NAME_AND_SURNAME,
    USER_DATA_PERSON,
    USER_DATA_SOJOURN_TAX,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import schema from './EditUserDataDialog.schema';
import useStyles from './EditUserDataDialog.styles';
import {isCompanySelected, isGuestSelected} from '../../utils/helpers';

export const invoiceFormFields = [
    USER_DATA_FORM_ENTITY,
    USER_DATA_FORM_ENTITY_FLAG,
    USER_DATA_FORM_OIB,
    USER_DATA_FORM_LEGAL_NAME,
    USER_DATA_FORM_PERSONAL_NAME,
    USER_DATA_FORM_ADDRESS,
    USER_DATA_FORM_CITY,
    USER_DATA_FORM_COUNTRY,
    USER_DATA_FORM_TEL,
    USER_DATA_FORM_EMAIL,
    USER_DATA_FORM_BANK,
    USER_DATA_FORM_BANK_IBAN,
    USER_DATA_FORM_BANK_SWIFT,
    USER_DATA_FORM_BANK_ADDRESS,
    USER_DATA_FORM_IN_PDV_SYSTEM,
    USER_DATA_SOJOURN_TAX,
    USER_DATA_INV_OPERATOR,
    USER_DATA_INV_OPERATOR_ID,
    USER_DATA_INV_FOOTER_DATA,
];

const integerFlags = [USER_DATA_FORM_ENTITY_FLAG];
const booleanDefaultFlag = [USER_DATA_SOJOURN_TAX];

const EditUserDataDialog = ({open, setOpen, onConfirm, userInfo}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);

    const [isLegalEntity, setIsLegalEntity] = useState(false);
    const [selectedEntityIndex, setSelectedEntityIndex] = useState(0);

    const handleClose = () => {
        setOpen(false);
    };

    const entityButton1 = () => <Text style={global.modalContentText}>{t(USER_DATA_PERSON)}</Text>;
    const entityButton2 = () => <Text style={global.modalContentText}>{t(USER_DATA_LEGAL_ENTITY)}</Text>;
    const entityButtons = [{element: entityButton1}, {element: entityButton2}];
    const updateEntityIndex = idx => {
        setSelectedEntityIndex(idx);
    };
    useEffect(() => {
        // Person
        if (isGuestSelected(selectedEntityIndex)) {
            setIsLegalEntity(false);
            setValue(USER_DATA_FORM_ENTITY, USER_DATA_FORM_HUMAN_ENTITY);
            setValue(USER_DATA_FORM_ENTITY_FLAG, 0);
            setValue(USER_DATA_FORM_OIB, '');
            setValue(USER_DATA_FORM_LEGAL_NAME, '');
            // Company
        } else if (isCompanySelected(selectedEntityIndex)) {
            setIsLegalEntity(true);
            setValue(USER_DATA_FORM_ENTITY, USER_DATA_FORM_LEGAL_ENTITY);
            setValue(USER_DATA_FORM_ENTITY_FLAG, 1);
            setValue(USER_DATA_FORM_PERSONAL_NAME, '');
        }
    }, [selectedEntityIndex]);

    useEffect(() => {
        if (open && userInfo) {
            initForm();
            setSelectedEntityIndex(parseInt(userInfo[USER_DATA_FORM_ENTITY_FLAG] || 0));
        }
    }, [open]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema(selectedEntityIndex, t)),
    });

    const onClickConfirm = _ => {
        handleSubmit(onSubmit, onError)();
    };

    const onSubmit = async (data, e) => {
        await onConfirm(data);
    };
    const onError = (errors, e) => {
        console.log(errors, e);
    };

    const initForm = () => {
        for (let formKey of invoiceFormFields) {
            register(formKey);
            const existingData = userInfo?.[formKey];
            if (integerFlags.includes(formKey)) {
                setValue(formKey, Number(Boolean(existingData)));
            } else if (existingData) {
                setValue(formKey, existingData);
            } else if (booleanDefaultFlag.includes(formKey)) {
                setValue(formKey, 'false');
            } else {
                setValue(formKey, '');
            }
        }
        const entity = getValues(USER_DATA_FORM_ENTITY);
        if (!entity) {
            // default
            setValue(USER_DATA_FORM_ENTITY, USER_DATA_FORM_HUMAN_ENTITY);
            setValue(USER_DATA_FORM_ENTITY_FLAG, 0);
        }
    };

    const onChangeField = (data, field) => {
        setValue(field, data);
    };

    // focust methods
    const [bankAddressFocus, setBankAddressFocus] = useState(false);
    const turnOnBankAddressFocus = () => {
        setBankAddressFocus(true);
    };
    const turnOffBankAddressFocus = () => {
        setBankAddressFocus(false);
    };

    const [bankSwiftFocus, setBankSwiftFocus] = useState(false);
    const turnOnBankSwiftFocus = () => {
        setBankSwiftFocus(true);
    };
    const turnOffBankSwiftFocus = () => {
        setBankSwiftFocus(false);
    };

    const [bankIbanFocus, setBankIbanFocus] = useState(false);
    const turnOnBankIbanFocus = () => {
        setBankIbanFocus(true);
    };
    const turnOffBankIbanFocus = () => {
        setBankIbanFocus(false);
    };

    const [bankFocus, setBankFocus] = useState(false);
    const turnOnBankFocus = () => {
        setBankFocus(true);
    };
    const turnOffBankFocus = () => {
        setBankFocus(false);
    };

    const [emailFocus, setEmailFocus] = useState(false);
    const turnOnEmailFocus = () => {
        setEmailFocus(true);
    };
    const turnOffEmailFocus = () => {
        setEmailFocus(false);
    };

    const [telFocus, setTelFocus] = useState(false);
    const turnOnTelFocus = () => {
        setTelFocus(true);
    };
    const turnOffTelFocus = () => {
        setTelFocus(false);
    };

    const [countryFocus, setCountryFocus] = useState(false);
    const turnOnCountryFocus = () => {
        setCountryFocus(true);
    };
    const turnOffCountryFocus = () => {
        setCountryFocus(false);
    };

    const [cityFocus, setCityFocus] = useState(false);
    const turnOnCityFocus = () => {
        setCityFocus(true);
    };
    const turnOffCityFocus = () => {
        setCityFocus(false);
    };

    const [addressFocus, setAddressFocus] = useState(false);
    const turnOnAddressFocus = () => {
        setAddressFocus(true);
    };
    const turnOffAddressFocus = () => {
        setAddressFocus(false);
    };

    const [personalNameFocus, setPersonalNameFocus] = useState(false);
    const turnOnPersonalNameFocus = () => {
        setPersonalNameFocus(true);
    };
    const turnOffPersonalNameFocus = () => {
        setPersonalNameFocus(false);
    };

    const [oibFocus, setOibFocus] = useState(false);
    const turnOnOibFocus = () => {
        setOibFocus(true);
    };
    const turnOffOibFocus = () => {
        setOibFocus(false);
    };

    const [nameFocus, setNameFocus] = useState(false);
    const turnOnNameFocus = () => {
        setNameFocus(true);
    };
    const turnOffNameFocus = () => {
        setNameFocus(false);
    };

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(EDIT_USER_DATA)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <ScrollView contentContainerStyle={global.modalContent}>
                    <ButtonGroup
                        onPress={updateEntityIndex}
                        selectedIndex={selectedEntityIndex}
                        buttons={entityButtons}
                        containerStyle={{
                            marginHorizontal: 0,
                            marginTop: 0,
                            marginBottom: 20,
                            borderColor: theme.colors.greyExtraLight,
                            backgroundColor: theme.colors.greyExtraLight,
                            borderRadius: 30,
                            padding: 2,
                            height: 45,
                        }}
                        buttonContainerStyle={{borderRadius: 30, overflow: 'hidden'}}
                        buttonStyle={{borderRadius: 30}}
                        selectedButtonStyle={{backgroundColor: theme.colors.white}}
                        innerBorderStyle={{width: 0}}
                    />
                    {!isLegalEntity && (
                        <Controller
                            name={USER_DATA_FORM_PERSONAL_NAME}
                            render={({field}) => (
                                <Input
                                    label={t(USER_DATA_NAME_AND_SURNAME)}
                                    value={field.value}
                                    onChangeText={text => onChangeField(text, USER_DATA_FORM_PERSONAL_NAME)}
                                    errorMessage={
                                        errors[USER_DATA_FORM_PERSONAL_NAME]
                                            ? errors[USER_DATA_FORM_PERSONAL_NAME].message
                                            : null
                                    }
                                    containerStyle={[styles.inputContainer]}
                                    onFocus={turnOnPersonalNameFocus}
                                    onBlur={turnOffPersonalNameFocus}
                                    style={[personalNameFocus && global.inputFocus]}
                                />
                            )}
                            control={control}
                            defaultValue=""
                        />
                    )}
                    {isLegalEntity && (
                        <>
                            <Controller
                                name={USER_DATA_FORM_LEGAL_NAME}
                                render={({field}) => (
                                    <Input
                                        label={t(USER_DATA_LEGAL_NAME)}
                                        value={field.value}
                                        onChangeText={text => onChangeField(text, USER_DATA_FORM_LEGAL_NAME)}
                                        errorMessage={
                                            errors[USER_DATA_FORM_LEGAL_NAME]
                                                ? errors[USER_DATA_FORM_LEGAL_NAME].message
                                                : null
                                        }
                                        containerStyle={[styles.inputContainer]}
                                        onFocus={turnOnNameFocus}
                                        onBlur={turnOffNameFocus}
                                        style={[nameFocus && global.inputFocus]}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                            <Controller
                                name={USER_DATA_FORM_OIB}
                                render={({field}) => (
                                    <Input
                                        label={t(OIB)}
                                        value={field.value}
                                        onChangeText={text => onChangeField(text, USER_DATA_FORM_OIB)}
                                        errorMessage={
                                            errors[USER_DATA_FORM_OIB] ? errors[USER_DATA_FORM_OIB].message : null
                                        }
                                        containerStyle={[styles.inputContainer]}
                                        onFocus={turnOnOibFocus}
                                        onBlur={turnOffOibFocus}
                                        style={[oibFocus && global.inputFocus]}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </>
                    )}

                    <Controller
                        name={USER_DATA_FORM_ADDRESS}
                        render={({field}) => (
                            <Input
                                label={t(USER_DATA_ADDRESS)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_ADDRESS)}
                                errorMessage={
                                    errors[USER_DATA_FORM_ADDRESS] ? errors[USER_DATA_FORM_ADDRESS].message : null
                                }
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnAddressFocus}
                                onBlur={turnOffAddressFocus}
                                style={[addressFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={USER_DATA_FORM_CITY}
                        render={({field}) => (
                            <Input
                                label={t(USER_DATA_CITY_POST)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_CITY)}
                                errorMessage={errors[USER_DATA_FORM_CITY] ? errors[USER_DATA_FORM_CITY].message : null}
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnCityFocus}
                                onBlur={turnOffCityFocus}
                                style={[cityFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={USER_DATA_FORM_COUNTRY}
                        render={({field}) => (
                            <Input
                                label={t(USER_DATA_COUNTRY)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_COUNTRY)}
                                errorMessage={
                                    errors[USER_DATA_FORM_COUNTRY] ? errors[USER_DATA_FORM_COUNTRY].message : null
                                }
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnCountryFocus}
                                onBlur={turnOffCountryFocus}
                                style={[countryFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={USER_DATA_FORM_TEL}
                        render={({field}) => (
                            <Input
                                label={t(USER_DATA_CONTACT)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_TEL)}
                                errorMessage={errors[USER_DATA_FORM_TEL] ? errors[USER_DATA_FORM_TEL].message : null}
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnTelFocus}
                                onBlur={turnOffTelFocus}
                                style={[telFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />

                    <Controller
                        name={USER_DATA_FORM_EMAIL}
                        render={({field}) => (
                            <Input
                                label={t(USER_DATA_EMAIL)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_EMAIL)}
                                errorMessage={
                                    errors[USER_DATA_FORM_EMAIL] ? errors[USER_DATA_FORM_EMAIL].message : null
                                }
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnEmailFocus}
                                onBlur={turnOffEmailFocus}
                                style={[emailFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />

                    <Text style={global.modalContentText}>{t(BANK_INFO)}</Text>
                    <Controller
                        name={USER_DATA_FORM_BANK_IBAN}
                        render={({field}) => (
                            <Input
                                label={t(IBAN)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_BANK_IBAN)}
                                errorMessage={
                                    errors[USER_DATA_FORM_BANK_IBAN] ? errors[USER_DATA_FORM_BANK_IBAN].message : null
                                }
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnBankIbanFocus}
                                onBlur={turnOffBankIbanFocus}
                                style={[bankIbanFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={USER_DATA_FORM_BANK_SWIFT}
                        render={({field}) => (
                            <Input
                                label={t(SWIFT)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_BANK_SWIFT)}
                                errorMessage={
                                    errors[USER_DATA_FORM_BANK_SWIFT] ? errors[USER_DATA_FORM_BANK_SWIFT].message : null
                                }
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnBankSwiftFocus}
                                onBlur={turnOffBankSwiftFocus}
                                style={[bankSwiftFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={USER_DATA_FORM_BANK}
                        render={({field}) => (
                            <Input
                                label={t(BANK)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_BANK)}
                                errorMessage={errors[USER_DATA_FORM_BANK] ? errors[USER_DATA_FORM_BANK].message : null}
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnBankFocus}
                                onBlur={turnOffBankFocus}
                                style={[bankFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={USER_DATA_FORM_BANK_ADDRESS}
                        render={({field}) => (
                            <Input
                                label={t(BANK_ADDRESS)}
                                value={field.value}
                                onChangeText={text => onChangeField(text, USER_DATA_FORM_BANK_ADDRESS)}
                                errorMessage={
                                    errors[USER_DATA_FORM_BANK_ADDRESS]
                                        ? errors[USER_DATA_FORM_BANK_ADDRESS].message
                                        : null
                                }
                                containerStyle={[styles.inputContainer]}
                                onFocus={turnOnBankAddressFocus}
                                onBlur={turnOffBankAddressFocus}
                                style={[bankAddressFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                </ScrollView>
                <View style={global.modalFooter}>
                    <Button
                        onPress={onClickConfirm}
                        title={t(CONFIRM)}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default EditUserDataDialog;
