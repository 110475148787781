import React, {useState, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, Pressable, View} from 'react-native';
import {ListItem, SearchBar, Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import Modal from 'react-native-modal';
import {ID, NAME, SEARCH} from '../../constants/stringsAndFields';
import {isNotEmpty} from '../../utils/arrayHelpers';

const ModalChooser = ({visibleModal, setVisibleModal, chooserData}) => {
    const [search, setSearch] = useState('');
    const [focusSearch, setFocusSearch] = useState(false);
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);

    const {
        title = '',
        filterProperty = NAME,
        filterItemId = ID,
        listData = [],
        onItemSelected = _ => {},
    } = chooserData ?? {};

    const updateSearch = useCallback(text => {
        setSearch(text);
    }, []);

    const toggleOverlay = useCallback(() => {
        setSearch('');
        setVisibleModal(false);
    }, []);

    const turnOnSearchFocus = useCallback(() => {
        setFocusSearch(true);
    }, []);

    const turnOffSearchFocus = useCallback(() => {
        setFocusSearch(false);
    }, []);

    const filteredList =
        (isNotEmpty(listData) &&
            listData.filter(item => item[filterProperty]?.toLowerCase().includes(search.toLowerCase()))) ??
        [];

    const render = ({item}) => (
        <ListItem
            key={item?.[filterItemId]}
            bottomDivider
            onPress={() => {
                setSearch('');
                onItemSelected(item);
            }}>
            <ListItem.Content>
                <ListItem.Title>
                    <Text style={global.textMain}>{item?.[filterProperty]}</Text>
                </ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    const searchContainerStyle = useMemo(() => {
        return {backgroundColor: theme.colors.lightBg, borderTopWidth: 0, borderBottomColor: theme.colors.greyLight};
    }, [theme]);

    const searchInputStyle = useMemo(() => {
        return [
            {
                backgroundColor: theme.colors.white,
                borderWidth: 1,
                borderRadius: 40,
                minHeight: 40,
                paddingLeft: 35,
                paddingRight: 35,
                color: theme.colors.textColor,
                marginLeft: 0,
            },
            focusSearch && global.inputFocus,
        ];
    }, [theme, focusSearch]);

    return (
        <Modal
            propagateSwipe
            isVisible={visibleModal}
            onBackdropPress={toggleOverlay}
            onBackButtonPress={toggleOverlay}
            backdropTransitionOutTiming={0}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{title}</Text>
                    <Pressable onPress={toggleOverlay} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <SearchBar
                    placeholder={t(SEARCH)}
                    onChangeText={updateSearch}
                    value={search}
                    onFocus={turnOnSearchFocus}
                    onBlur={turnOffSearchFocus}
                    searchIcon={searchIconStyle}
                    containerStyle={searchContainerStyle}
                    inputContainerStyle={inputContainerStyle}
                    inputStyle={searchInputStyle}
                    leftIconContainerStyle={leftIconContainer}
                    rightIconContainerStyle={righIconContainer}
                />
                <FlatList data={filteredList} renderItem={render} keyExtractor={item => item?.[filterItemId]} />
            </View>
        </Modal>
    );
};

export default ModalChooser;

// styles
const inputContainerStyle = {borderBottomWidth: 0, backgroundColor: 'transparent', marginBottom: 0};

const searchIconStyle = {
    name: 'search-outline',
    type: 'ionicon',
};

const leftIconContainer = {
    position: 'absolute',
    left: 12,
    marginVertical: 0,
    paddingRight: 0,
    marginLeft: 0,
    zIndex: 1,
};

const righIconContainer = {
    position: 'absolute',
    right: 12,
    marginVertical: 0,
    paddingRight: 0,
    marginRight: 0,
    zIndex: 1,
};
