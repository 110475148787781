import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, SafeAreaView, View, useWindowDimensions} from 'react-native';
import {Icon, LinearProgress, useTheme} from 'react-native-elements';
import {cancelGuest, fetchTourist} from '../api/inCheckinRestService';
import Confirm2For1Dialog from '../components/Confirm2For1Dialog';
import ConfirmDialog from '../components/ConfirmDialog';
import CreditsDepletedDialog from '../components/CreditsDepletedDialog';
import ErrorMessageDialog from '../components/ErrorMessageDialog';
import SelfCheckinQRDialog from '../components/SelfCheckinQRDialog';
import TouristDetailsDialog from '../components/TouristDetailsDialog';
import CheckinActions from '../components/checkin/CheckinActions';
import CheckinActionsDialog from '../components/checkin/CheckinActionsDialog';
import CheckinDetailsHeader from '../components/checkin/CheckinDetailsHeader';
import FabButton from '../components/checkin/FabButton';
import FacilityErrorMessageDialog from '../components/checkin/FacilityErrorMessageDialog';
import FooterActionButtons from '../components/checkin/FooterActionButtons';
import GuestList from '../components/checkin/GuestList';
import InvoicesDialog from '../components/checkin/InvoicesDialog';
import NoteEditDialog from '../components/checkin/NoteEditDialog';
import ShareLinkDialog from '../components/checkin/ShareLinkDialog';
import {useSharedCheckinActions} from '../components/checkin/useCheckinActions';
import {useSharedDialogs} from '../components/checkin/useDialogs';
import {useSharedErrors} from '../components/checkin/useErrors';
import {
    ADDITIONAL_FIELDS,
    CANCELED_STATUS,
    CHECKIN_ID,
    CHECKIN_NOTE_FIELD,
    CHECKOUT_OUT,
    CONFIRM_CHECKIN_DELETE_DIALOG,
    CONFIRM_CHECKIN_DELETE_TITLE,
    CONFIRM_CHECKIN_EMPTY_DELETE_DIALOG,
    CONFIRM_CHECKIN_WITH_NO_EV_GUEST_DELETE_DIALOG,
    CONFIRM_GUEST_CANCEL_DIALOG,
    CONFIRM_GUEST_CANCEL_TITLE,
    CONFIRM_GUEST_DELETE_DIALOG,
    CONFIRM_GUEST_DELETE_TITLE,
    COUNTRY_DATA,
    CREDITS_DEPLETED_MSG,
    CREDITS_DEPLETED_TITLE,
    CREDITS_EXPIRED_MSG,
    CREDITS_EXPIRED_TITLE,
    DOCUMENT_TYPE_DATA,
    EVISITOR,
    FACILITY,
    FACILITY_CODE,
    FORESEEN_STAY_UNTIL_FIELD,
    GUEST_NOTE_FIELD,
    MAX_GUEST_NUMBER,
    NOTE_EDIT_MSG,
    NOTE_EDIT_TITLE,
    PIN_ID,
    RECORDS,
    REST_ERRORS,
    SETTLEMENTS_DATA,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TOURIST_NAME,
    TOURIST_SURNAME,
    UPDATED_STATUS,
    content,
    guests,
    id,
    status,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {isEmpty, sortDateTimes} from '../utils/arrayHelpers';
import {showCreditsDialog, useCreditsAfterExpireConfirm} from '../utils/credits';
import {
    checkArrivalDisabled,
    getForCancel,
    getForCheckin,
    getForCheckout,
    getGuestsStatusesCounts,
    initCheckin,
    reCreateGuest,
    syncTouristsCheckoutStatus,
    updateGuestCheckedInStatus,
} from '../utils/guestUtils';
import {isFacilityError, isGuestEmpty, mapTouristToGuest} from '../utils/helpers';
import {sleep} from '../utils/promiseHelpers';

const SLEEP_DURATION = 1000;
const HIDE_UPDATE_CHECKIN_MESSAGES = true;

const IS_WEB = Platform.OS === 'web';
const IS_ANDROID = Platform.OS === 'android';
const IS_IOS = Platform.OS === 'ios';

const CheckinScreen = ({route, navigation}) => {
    const {
        eVisitorStaticData,
        clearErrors,
        getCheckin,
        refreshCheckin,
        updateGuest,
        deleteGuest,
        apiErrors,
        apiMessages,
        onAddCheckin,
        onUpdateCheckin,
        onDeleteCheckin,
        onReCreateGuest,
        setApiErrors,
        getEvisitorByPin,
        getInvoicesByCheckin,
        updateLocalCheckin,
    } = useContext(DataContext);
    const [checkin, setCheckin] = useState(null);
    const isFocused = useIsFocused();
    const {checkinId = null, isNewCheckin = false, tourist = null, guestManualDate = false} = route.params ?? {};
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const [openProgress, setOpenProgress] = useState(false);
    const [runImport, setRunImport] = useState(true);
    const [confirmDialogData, setConfirmDialogData] = useState();
    const [openApiErrorMessage, setOpenApiErrorMessage] = useState(false);
    const [disableArrivalForCheckedIn, setDisableArrivalForCheckedIn] = useState(false);

    const {closeFab, openCreditsDepleted, openCreditsAfterExpire, openInvoices, prepareInvoices} = useSharedDialogs();

    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;
    const isMobileSmall = width < 500;

    const countries = useMemo(() => eVisitorStaticData && eVisitorStaticData?.[COUNTRY_DATA], [eVisitorStaticData]);
    const citiesSettlements = useMemo(
        () => eVisitorStaticData && eVisitorStaticData?.[SETTLEMENTS_DATA],
        [eVisitorStaticData]
    );
    const documentTypes = useMemo(
        () => eVisitorStaticData && eVisitorStaticData?.[DOCUMENT_TYPE_DATA],
        [eVisitorStaticData]
    );

    const {closeAllErrors, setCheckinErrors, openCheckinErrorMsg, openFacilityErrorMsg} = useSharedErrors();

    const showErrors = async (error, guest) => {
        const showedCreditsDialog = await showCreditsDialog(error, openCreditsDepleted, openCreditsAfterExpire);
        if (showedCreditsDialog) return;
        if (isFacilityError(error)) {
            const {type, description} = error?.[REST_ERRORS];
            setCheckinErrors({
                type: type,
                description: description,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openFacilityErrorMsg();
        } else if (error?.[REST_ERRORS]) {
            const {type, description} = error?.[REST_ERRORS];
            setCheckinErrors({
                type: type,
                description: description,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        } else if (error?.response) {
            const {type, description} = error.response?.data;
            setCheckinErrors({
                type: type,
                description: description,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        } else if (error?.message) {
            setCheckinErrors({
                type: 'Guest',
                description: error.message,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        }
    };

    const refreshLocalCheckin = useCallback(async checkinId => {
        const dbCheckin = await getCheckin(checkinId);
        dbCheckin?.guests?.sort(sortDateTimes)?.reverse();
        setCheckin(dbCheckin);
    }, []);

    const onRefresh = useCallback(async checkinId => {
        await refreshCheckin(checkinId);
        await refreshLocalCheckin(checkinId);
    }, []);

    const openNewInvoice = _ => {
        const cId = checkin?.[id] ?? checkinId;
        navigation.navigate('Invoice', {
            checkinId: cId,
        });
    };

    const onClickInvoice = async () => {
        if (checkin) {
            try {
                const invoices = await getInvoicesByCheckin(checkin);
                if (isEmpty(invoices)) {
                    openNewInvoice();
                } else {
                    prepareInvoices(invoices);
                    openInvoices();
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const updateGuestStatus = async (guest, evisitorStatus, editedStatus = false) => {
        guest[status] = evisitorStatus;
        guest[UPDATED_STATUS] = editedStatus;
        await updateGuest(guest, true);
    };

    const updateGuestNumber = async (checkin, guestNumber) => {
        const filteredGuests = checkin?.[guests] ?? [];
        const currentGuestNumber = filteredGuests.length;
        if (currentGuestNumber !== 0 && currentGuestNumber > guestNumber) {
            filteredGuests.sort(sortDateTimes);
            const diff = currentGuestNumber - guestNumber;
            try {
                setOpenProgress(true);
                for (let i = 0; i < diff; i++) {
                    await deleteGuest(filteredGuests.pop());
                }
            } catch (e) {
                console.log(e);
            } finally {
                setOpenProgress(false);
            }
        }
        checkin[ADDITIONAL_FIELDS][MAX_GUEST_NUMBER] = guestNumber;
        await onUpdateCheckin(checkin, HIDE_UPDATE_CHECKIN_MESSAGES);
    };

    const onUpdateAccomodation = async accomodation => {
        const filteredGuests = checkin?.[guests] ?? [];
        for (let filteredGuest of filteredGuests) {
            if (getForCheckin(filteredGuest[status], filteredGuest[UPDATED_STATUS])) {
                if (filteredGuest[content][FACILITY] !== accomodation[FACILITY_CODE]) {
                    filteredGuest[content][FACILITY] = accomodation[FACILITY_CODE];
                    await updateGuest(filteredGuest, false);
                }
            }
        }
        await refreshLocalCheckin(checkin?.[id]);
    };

    const onDeleteCheckinGuest = useCallback(
        async guest => {
            if (getForCancel(guest[status])) {
                setConfirmDialogData({
                    title: t(CONFIRM_GUEST_CANCEL_TITLE),
                    open: true,
                    isDelete: true,
                    setOpen: setConfirmDialogData,
                    onConfirm: continueDeleteCheckinGuest,
                    content: t(CONFIRM_GUEST_CANCEL_DIALOG),
                    data: guest,
                });
            } else {
                setConfirmDialogData({
                    title: t(CONFIRM_GUEST_DELETE_TITLE),
                    open: true,
                    isDelete: true,
                    setOpen: setConfirmDialogData,
                    onConfirm: continueDeleteCheckinGuest,
                    content: t(CONFIRM_GUEST_DELETE_DIALOG),
                    data: guest,
                });
            }
        },
        [checkin]
    );

    const continueCancel = useCallback(
        async guest => {
            const pin_id = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
            let dataUpdated = false;
            try {
                dataUpdated = true;
                await cancelGuest(pin_id, guest[content]);
                await updateGuestStatus(guest, CANCELED_STATUS, false);
            } catch (error) {
                await showErrors(error, guest);
            }
            dataUpdated && (await refreshLocalCheckin(checkin?.[id]));
        },
        [checkin]
    );

    const continueDeleteCheckinGuest = useCallback(
        async deleteGuestData => {
            if (deleteGuestData) {
                setOpenProgress(true);
                try {
                    if (getForCancel(deleteGuestData[status])) {
                        await continueCancel(deleteGuestData);
                    }
                    await deleteGuest(deleteGuestData);
                    await refreshLocalCheckin(deleteGuestData[CHECKIN_ID]);
                } catch (error) {
                    await showErrors(error, deleteGuestData);
                } finally {
                    setOpenProgress(false);
                }
            }
        },
        [checkin]
    );

    const onDelete = useCallback(() => {
        onDeleteCheckin(checkin);
        if (IS_IOS) {
            navigation.pop();
        } else {
            navigation.replace('Root');
        }
    }, [checkin]);

    const onDeleteCheckinClick = () => {
        const {checkedInCount, checkoutedCount, guestsForCheckinCount, guestCount} = getGuestsStatusesCounts(
            checkin?.[guests]
        );
        if (guestCount === 0) {
            setConfirmDialogData({
                title: t(CONFIRM_CHECKIN_DELETE_TITLE),
                open: true,
                isDelete: true,
                setOpen: setConfirmDialogData,
                onConfirm: onDelete,
                content: t(CONFIRM_CHECKIN_EMPTY_DELETE_DIALOG),
            });
        } else {
            if (checkedInCount > 0 || checkoutedCount > 0) {
                setConfirmDialogData({
                    title: t(CONFIRM_CHECKIN_DELETE_TITLE),
                    open: true,
                    isDelete: true,
                    setOpen: setConfirmDialogData,
                    onConfirm: onDelete,
                    content: t(CONFIRM_CHECKIN_DELETE_DIALOG),
                });
            } else if (guestsForCheckinCount > 0) {
                setConfirmDialogData({
                    title: t(CONFIRM_CHECKIN_DELETE_TITLE),
                    open: true,
                    isDelete: true,
                    setOpen: setConfirmDialogData,
                    onConfirm: onDelete,
                    content: t(CONFIRM_CHECKIN_WITH_NO_EV_GUEST_DELETE_DIALOG),
                });
            }
        }
    };

    const goBack = useCallback(() => {
        navigation.replace('Root');
    }, []);

    const syncEmptyTourists = async (forceStatusCheck = false) => {
        const eVisitorPinId = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        const checkinGuests = checkin?.[guests];
        if (eVisitorPinId && checkinGuests && countries && citiesSettlements && documentTypes) {
            for (let guest of checkinGuests) {
                if ((isGuestEmpty(guest[content]) && guest[status] !== CANCELED_STATUS) || forceStatusCheck) {
                    try {
                        setOpenProgress(true);
                        const touristRes = await fetchTourist(eVisitorPinId, 1, 1, guest[id]);
                        if (touristRes) {
                            const touristData = touristRes[RECORDS]?.[0];
                            if (touristData) {
                                if (forceStatusCheck) {
                                    await updateGuestCheckedInStatus(guest, touristData, updateGuest);
                                } else {
                                    const mappedGuest = mapTouristToGuest(
                                        touristData,
                                        countries,
                                        citiesSettlements,
                                        documentTypes
                                    );
                                    await reCreateGuest(
                                        {
                                            ...mappedGuest,
                                            id: guest[id],
                                            [CHECKOUT_OUT]: touristData[CHECKOUT_OUT],
                                            [GUEST_NOTE_FIELD]:
                                                guest[content]?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ?? '',
                                        },
                                        checkin,
                                        getEvisitorByPin,
                                        onReCreateGuest
                                    );
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    } finally {
                        setOpenProgress(false);
                    }
                }
            }
            await refreshLocalCheckin(checkin?.[id]);
        }
    };

    const sync = async (checkin, updateGuestStatus, refreshLocalCheckin) => {
        const pin_id = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        try {
            await syncEmptyTourists(false);
            const isAnyGuestCheckoutStatusUpdated = await syncTouristsCheckoutStatus(
                pin_id,
                checkin[guests],
                updateGuestStatus,
                setOpenProgress
            );
            if (isAnyGuestCheckoutStatusUpdated) {
                await refreshLocalCheckin(checkin?.[id]);
            }
        } catch (e) {
            console.log(e);
        } finally {
            checkArrivalDisabled(checkin?.[guests], setDisableArrivalForCheckedIn);
        }
    };

    const createNewCheckin = async () => {
        let newCheckinId = null;
        try {
            const newCheckin = await initCheckin(route.params);
            if (newCheckin) {
                try {
                    setOpenProgress(true);
                    await onAddCheckin(newCheckin);
                } catch (e) {
                    console.log(e);
                } finally {
                    setOpenProgress(false);
                    newCheckinId = newCheckin[id];
                    await refreshLocalCheckin(newCheckinId);
                }
            }
        } catch (e) {
            console.log('Could not initialize new checkin');
            console.log(e);
            setOpenProgress(false);
        }
        return newCheckinId;
    };

    const deleteButton = (
        <Pressable
            onPress={onDeleteCheckinClick}
            style={({pressed}) => ({
                opacity: pressed ? 0.5 : 1,
            })}>
            <Icon
                name="trash-outline"
                type="ionicon"
                size={22}
                style={{marginRight: 15}}
                color={theme.colors.primary}
            />
        </Pressable>
    );

    const memoizedHeaderGoBackButton = useMemo(() => {
        return (
            <Pressable onPress={goBack}>
                <Icon
                    name="arrow-back"
                    size={24}
                    color={theme.colors.textColor}
                    containerStyle={{marginLeft: 14, marginRight: 14}}
                />
            </Pressable>
        );
    }, [theme]);

    const onBuyCreditsPress = async () => {
        navigation.navigate('Packages');
    };

    const onEditNoteConfirm = async updatedNote => {
        try {
            setOpenProgress(true);
            checkin[ADDITIONAL_FIELDS][CHECKIN_NOTE_FIELD] = updatedNote;
            await onUpdateCheckin(checkin, HIDE_UPDATE_CHECKIN_MESSAGES);
            setCheckin(checkin);
        } catch (e) {
            console.log(e);
        } finally {
            setOpenProgress(false);
        }
    };

    const onDeleteNote = async _ => {
        try {
            setOpenProgress(true);
            checkin[ADDITIONAL_FIELDS][CHECKIN_NOTE_FIELD] = '';
            await onUpdateCheckin(checkin, HIDE_UPDATE_CHECKIN_MESSAGES);
            setCheckin(checkin);
        } catch (e) {
            console.log(e);
        } finally {
            setOpenProgress(false);
        }
    };

    // on each focus refresh checkins
    useEffect(() => {
        isFocused && clearErrors();
        const cId = checkinId ?? checkin?.[id];
        if (isFocused && cId) {
            refreshLocalCheckin(cId).catch(console.error);
        }
    }, [isFocused, checkinId]);

    // on unfocus close fab
    useFocusEffect(
        useCallback(() => {
            return () => {
                closeFab();
            };
        }, [])
    );

    useEffect(() => {
        if (isNewCheckin && !checkinId) {
            createNewCheckin()
                .then(newCheckinId => {
                    if (newCheckinId && IS_WEB) {
                        navigation.replace('Checkin', {
                            screen: 'CheckinScreen',
                            params: {checkinId: newCheckinId},
                        });
                    }
                })
                .catch(console.error);
        }
    }, []);

    useEffect(() => {
        if (checkin && runImport) {
            setRunImport(false);
            sync(checkin, updateGuestStatus, refreshLocalCheckin, setOpenProgress).catch(console.error);
        }
    }, [checkin?.[id]]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!runImport) syncEmptyTourists(false).catch(console.error);
        checkArrivalDisabled(checkin?.[guests], setDisableArrivalForCheckedIn);
    }, [JSON.stringify(checkin?.[guests])]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (apiErrors) {
            console.log(apiErrors);
            setOpenApiErrorMessage(true);
            sleep(SLEEP_DURATION).then(_ => {
                const cId = checkin?.[id] ?? checkinId;
                refreshLocalCheckin(cId).catch(console.error);
            });
        }
        apiMessages && setOpenApiErrorMessage(true);
    }, [apiErrors, apiMessages]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (checkin?.[id]) {
            const refreshCheckin = async _ => {
                await onRefresh(checkin[id]);
                await syncEmptyTourists(true);
            };

            const refreshButton = (
                <Pressable
                    onPress={refreshCheckin}
                    style={({pressed}) => ({
                        opacity: pressed ? 0.5 : 1,
                    })}>
                    <Icon
                        type="ionicon"
                        name="refresh-outline"
                        size={22}
                        color={theme.colors.primary}
                        style={{marginRight: 17}}
                    />
                </Pressable>
            );

            const headerRightButtons = (
                <View style={[global.flexCenter, Platform.OS === 'android' && {marginRight: -20}]}>
                    {deleteButton}
                    {refreshButton}
                </View>
            );

            if (IS_ANDROID) {
                navigation.setOptions({
                    headerRight: () => headerRightButtons,
                });
            } else {
                navigation.getParent()?.setOptions({
                    headerRight: () => headerRightButtons,
                });
            }
        }
    }, [checkin?.[id], checkin?.[guests]]);

    // web
    if (IS_WEB) {
        useEffect(() => {
            const parent = navigation.getParent();
            if (parent) {
                parent.setOptions({
                    headerLeft: () => memoizedHeaderGoBackButton,
                });
            }
        }, [navigation.getParent()]);
    }

    if (!checkin) {
        return (
            <View style={global.containerBg}>
                {openProgress && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
            </View>
        );
    }

    return (
        <SafeAreaView style={global.containerBg}>
            {openProgress && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
            <GuestList
                checkin={checkin}
                onDeleteCheckinGuest={onDeleteCheckinGuest}
                checkinDetailHeader={
                    <CheckinDetailsHeader
                        checkin={checkin}
                        setCheckin={setCheckin}
                        disableArrivalForCheckedIn={disableArrivalForCheckedIn}
                        onClickInvoice={onClickInvoice}
                        onUpdateCheckin={onUpdateCheckin}
                        onUpdateAccomodation={onUpdateAccomodation}
                        useSharedCheckinActions={useSharedCheckinActions}
                        useSharedDialogs={useSharedDialogs}
                    />
                }
            />
            <TouristDetailsDialog setApiErrors={setApiErrors} useSharedDialogs={useSharedDialogs} />
            <SelfCheckinQRDialog useSharedDialogs={useSharedDialogs} />
            <ShareLinkDialog
                checkin={checkin}
                navigation={navigation}
                useSharedCheckinActions={useSharedCheckinActions}
                useSharedDialogs={useSharedDialogs}
                updateGuestNumber={updateGuestNumber}
                updateLocalCheckin={updateLocalCheckin}
                refreshLocalCheckin={refreshLocalCheckin}
            />
            <ConfirmDialog {...confirmDialogData} />
            <CheckinActions
                checkin={checkin}
                refreshLocalCheckin={refreshLocalCheckin}
                updateGuestStatus={updateGuestStatus}
                updateGuest={updateGuest}
                setOpenProgress={setOpenProgress}
                showErrors={showErrors}
                useSharedCheckinActions={useSharedCheckinActions}
            />
            <CheckinActionsDialog useSharedCheckinActions={useSharedCheckinActions} checkin={checkin} />
            <FooterActionButtons
                t={t}
                theme={theme}
                global={global}
                isMobile={isMobile}
                isMobileSmall={isMobileSmall}
                checkin={checkin}
                useSharedCheckinActions={useSharedCheckinActions}
                setOpenProgress={setOpenProgress}
                showErrors={showErrors}
            />
            <FabButton
                t={t}
                theme={theme}
                global={global}
                isMobile={isMobile}
                useSharedDialogs={useSharedDialogs}
                navigation={navigation}
                checkin={checkin}
            />
            <Confirm2For1Dialog
                title={t(CREDITS_EXPIRED_TITLE)}
                content={t(CREDITS_EXPIRED_MSG)}
                onConfirm={useCreditsAfterExpireConfirm}
                onBuy={onBuyCreditsPress}
                useSharedDialogs={useSharedDialogs}
            />
            <CreditsDepletedDialog
                title={t(CREDITS_DEPLETED_TITLE)}
                content={t(CREDITS_DEPLETED_MSG)}
                onBuy={onBuyCreditsPress}
                useSharedDialogs={useSharedDialogs}
            />
            <NoteEditDialog
                title={t(NOTE_EDIT_TITLE)}
                content={t(NOTE_EDIT_MSG)}
                onConfirm={onEditNoteConfirm}
                checkin={checkin}
                useSharedDialogs={useSharedDialogs}
                onDeleteNote={onDeleteNote}
            />
            <InvoicesDialog
                useSharedDialogs={useSharedDialogs}
                openNewInvoice={openNewInvoice}
                navigation={navigation}
            />
            <ErrorMessageDialog
                isScreenFocused={isFocused}
                useSharedErrors={useSharedErrors}
                apiErrors={apiErrors}
                apiMessages={apiMessages}
                openApiErrorMessage={openApiErrorMessage}
                closeErrorDialogs={closeAllErrors}
            />
            <FacilityErrorMessageDialog
                checkin={checkin}
                onUpdateCheckin={onUpdateCheckin}
                setCheckin={setCheckin}
                onUpdateAccomodation={onUpdateAccomodation}
                isScreenFocused={isFocused}
                useSharedErrors={useSharedErrors}
                closeErrorDialogs={closeAllErrors}
            />
        </SafeAreaView>
    );
};

export default CheckinScreen;
