const globalStyle = theme => {
    return {
        /* Typography */

        textMain: {
            color: theme.colors.textColor,
            fontSize: 14,
            lineHeight: 20,
            fontFamily: theme.fontFamily,
        },

        textTitle: {
            color: theme.colors.textColor,
            fontSize: 18,
            lineHeight: 30,
            fontFamily: theme.fontFamily,
        },

        textHeader: {
            color: theme.colors.textColor,
            fontSize: 22,
            lineHeight: 38,
            fontFamily: theme.fontFamilyMedium,
        },

        textHeaderMobile: {
            color: theme.colors.textColor,
            fontSize: 18,
            lineHeight: 28,
            fontFamily: theme.fontFamilyMedium,
        },

        textCaps: {
            color: theme.colors.textColorMedium,
            fontSize: 12,
            textTransform: 'uppercase',
            fontFamily: theme.fontFamilySemibold,
        },

        textCapsDark: {
            color: theme.colors.textColor,
            fontSize: 12,
            textTransform: 'uppercase',
            fontFamily: theme.fontFamilySemibold,
        },

        textIntro: {
            color: theme.colors.greyDark,
            fontSize: 16,
            lineHeight: 26,
            fontFamily: theme.fontFamily,
            textAlign: 'center',
        },

        textNote: {
            color: theme.colors.textColor,
            fontSize: 12,
            lineHeight: 18,
            fontFamily: theme.fontFamily,
            textAlign: 'center',
        },

        link: {
            color: theme.colors.primary,
            fontFamily: theme.fontFamilyMedium,
            fontSize: 14,
            lineHeight: 20,
        },

        listEmptyMsg: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            paddingHorizontal: 20,
            paddingVertical: 100,
        },

        listEmptyMsgText: {
            color: theme.colors.greyMedium,
            fontSize: 18,
            lineHeight: 30,
            fontFamily: theme.fontFamily,
        },

        listEmptyMsgWithBackground: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            paddingHorizontal: 20,
            paddingVertical: 100,
            backgroundColor: theme.colors.lightBg,
        },

        /* Layout */
        container: {
            flex: 1,
            backgroundColor: theme.colors.white,
            width: '100%',
            height: '100%',
        },

        containerBg: {
            flex: 1,
            backgroundColor: theme.colors.lightBg,
        },

        containerBgDarker: {
            flex: 1,
            backgroundColor: theme.colors.greyExtraLight,
        },

        containerInner: {
            width: '100%',
            maxWidth: 1100,
            paddingHorizontal: 10,
            marginHorizontal: 'auto',
            alignSelf: 'center',
        },

        hasFAB: {
            paddingBottom: 80,
        },

        card: {
            backgroundColor: theme.colors.white,
            borderRadius: 10,
            overflow: 'hidden',
        },

        cardHeader: {
            minHeight: 40,
            paddingHorizontal: 15,
            backgroundColor: theme.colors.lightBg,
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.borderLight,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        cardHeaderText: {
            color: theme.colors.textColor,
            fontSize: 12,
            textTransform: 'uppercase',
            fontFamily: theme.fontFamilySemibold,
        },

        cardContent: {
            padding: 15,
        },

        cardFooter: {
            minHeight: 40,
            paddingHorizontal: 15,
            backgroundColor: theme.colors.lightBg,
            borderTopWidth: 1,
            borderTopColor: theme.colors.borderLight,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        boxShadow: {
            shadowColor: theme.colors.primary,
            shadowOpacity: 0.15,
            shadowOffset: {width: 3, height: 3},
            shadowRadius: 10,
            elevation: 3,
        },

        filterNav: {
            backgroundColor: theme.colors.primaryExtraLight,
            zIndex: 1,
            paddingHorizontal: 10,
            paddingVertical: 6,
            minHeight: 36,
            justifyContent: 'center',
            shadowColor: theme.colors.black,
            shadowOpacity: 0.1,
            shadowOffset: {width: 0, height: 3},
            shadowRadius: 5,
            elevation: 3,
        },

        filterNavYear: {
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
            paddingVertical: 3,
            paddingLeft: 9,
            paddingRight: 4,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: theme.colors.greyLight,
            backgroundColor: theme.colors.white,
            textAlign: 'center',
        },

        filterNavYearText: {
            fontSize: 11,
            fontFamily: theme.fontFamilySemibold,
            color: theme.colors.textColorLight,
        },

        contentWrapper: {
            backgroundColor: theme.colors.white,
            boxShadow: 'rgb(54 41 122 / 15%) 0 0 10px',
            borderRadius: 10,
            margin: 20,
            paddingHorizontal: 10,
            paddingVertical: 20,
        },

        flexCenter: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },

        flexRow: {
            flexDirection: 'row',
        },

        connectionStatus: {
            fontFamily: 'Montserrat-Medium',
            fontSize: 12,
            backgroundColor: '#f94747',
            color: theme.colors.white,
            paddingVertical: 2,
            paddingHorizontal: 10,
            textAlign: 'center',
        },

        guestInvalidForm: {
            fontFamily: 'Montserrat-Medium',
            fontSize: 14,
            backgroundColor: '#f94747',
            color: theme.colors.white,
            textAlign: 'center',
        },

        /* Button */

        Button: {
            buttonStyle: {
                backgroundColor: theme.colors.primary,
                borderRadius: theme.buttonBorderRadius,
                borderColor: theme.colors.primary,
                borderWidth: 1,
                paddingVertical: 10,
                paddingHorizontal: 30,
                textAlign: 'center',
                minWidth: 100,
            },

            containerStyle: {
                borderRadius: theme.buttonBorderRadius,
            },

            titleStyle: {
                color: theme.colors.white,
                fontSize: 13,
                fontFamily: theme.fontFamilySemibold,
                letterSpacing: 0.2,
                textTransform: 'uppercase',
                paddingTop: 0,
                paddingBottom: 0,
            },

            disabledStyle: {
                backgroundColor: theme.colors.greyExtraLight,
                borderColor: theme.colors.greyExtraLight,
                opacity: 0.5,
            },

            disabledTitleStyle: {
                color: theme.colors.greyDark,
            },
        },

        /* Outline Button
      
      USE: <Button
            title={'test'}
            buttonStyle={global.outlineButton}
            titleStyle={global.outlineButtonTitle}
          />
      */

        outlineButton: {
            backgroundColor: theme.colors.primaryExtraLight,
            borderColor: 'rgba(93, 77, 157,  0.4)',
            borderWidth: 1,
            borderRadius: theme.buttonBorderRadius,
            paddingVertical: 10,
            paddingHorizontal: 30,
            textAlign: 'center',
            minWidth: 100,
        },

        outlineButtonTitle: {
            color: theme.colors.primary,
            fontSize: 13,
            fontFamily: theme.fontFamilySemibold,
            letterSpacing: 0.2,
            textTransform: 'uppercase',
            paddingTop: 0,
            paddingBottom: 0,
        },

        outlineButtonContainer: {
            borderRadius: theme.buttonBorderRadius,
        },

        ModalButton: {
            Positive: {
                buttonStyle: {
                    backgroundColor: theme.colors.primary,
                    borderRadius: theme.buttonBorderRadius,
                    borderColor: theme.colors.primary,
                    borderWidth: 1,
                    paddingVertical: 8,
                    paddingHorizontal: 20,
                    textAlign: 'center',
                    minWidth: 90,
                },

                containerStyle: {
                    borderRadius: theme.buttonBorderRadius,
                },

                titleStyle: {
                    color: theme.colors.white,
                    fontSize: 12,
                    fontFamily: theme.fontFamilySemibold,
                    letterSpacing: 0.2,
                    textTransform: 'uppercase',
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },

            Negative: {
                buttonStyle: {
                    backgroundColor: theme.colors.white,
                    borderColor: 'rgba(93, 77, 157,  0.2)',
                    borderRadius: theme.buttonBorderRadius,
                    borderWidth: 1,
                    paddingVertical: 8,
                    paddingHorizontal: 20,
                    textAlign: 'center',
                    minWidth: 90,
                },

                containerStyle: {
                    borderRadius: theme.buttonBorderRadius,
                },

                titleStyle: {
                    color: theme.colors.primary,
                    fontSize: 13,
                    fontFamily: theme.fontFamilySemibold,
                    letterSpacing: 0.2,
                    textTransform: 'uppercase',
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },

            disabledStyle: {
                backgroundColor: theme.colors.greyLight,
                borderColor: theme.colors.greyExtraLight,
                opacity: 0.5,
            },

            disabledTitleStyle: {
                color: theme.colors.greyDark,
            },
        },

        circleButtonS: {
            width: 25,
            height: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 50,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.colors.greyLight,
        },

        circleButtonM: {
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 50,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.colors.greyLight,
        },

        numberButton: {
            width: 50,
            height: 40,
            paddingVertical: 0,
            paddingRight: 10,
            paddingLeft: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius: 0,
            borderWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: theme.colors.greyLight,
            backgroundColor: theme.colors.white,
            position: 'relative',
        },

        numberButtonTitle: {
            color: theme.colors.greyMedium,
        },

        numberButtonIcon: {
            position: 'absolute',
            right: 5,
        },

        FAB: {
            buttonStyle: {
                backgroundColor: theme.colors.primary,
                borderRadius: 40,
                paddingRight: 10,
                paddingLeft: 8,
                textAlign: 'center',
                height: 45,
                minWidth: 150,
            },

            titleStyle: {
                color: theme.colors.white,
                fontSize: 13,
                fontFamily: theme.fontFamilySemibold,
                marginLeft: 8,
                marginRight: 0,
                paddingVertical: 0,
            },
        },

        /* BottomButtonGroup */
        bottomButtonGroupWrapper: {
            backgroundColor: theme.colors.white,
            shadowColor: theme.colors.primaryDark,
            shadowOpacity: 0.05,
            shadowOffset: {width: 0, height: -2},
            shadowRadius: 3,
            elevation: 5,
        },

        /* SwipeButton */
        swipeButtonRoot: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.colors.white,
            shadowOpacity: 0.07,
            shadowOffset: {width: 0, height: -2},
            shadowRadius: 3,
            elevation: 5,
        },

        swipeCont: {
            height: BUTTON_HEIGHT,
            width: BUTTON_WIDTH,
            backgroundColor: theme.colors.primaryExtraLight,
            borderColor: 'rgba(93, 77, 157,  0.4)',
            borderWidth: 1,
            borderRadius: BUTTON_HEIGHT,
            padding: BUTTON_PADDING,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        colorWave: {
            position: 'absolute',
            left: 0,
            height: BUTTON_HEIGHT,
            borderRadius: BUTTON_HEIGHT,
        },
        swipeable: {
            position: 'absolute',
            left: BUTTON_PADDING,
            height: SWIPEABLE_DIMENSIONS,
            width: SWIPEABLE_DIMENSIONS,
            borderRadius: SWIPEABLE_DIMENSIONS,
            zIndex: 3,
            justifyContent: 'center',
        },
        swipeText: {
            fontFamily: theme.fontFamilyMedium,
            alignSelf: 'center',
            fontSize: 14,
            zIndex: 2,
            color: theme.colors.primary,
            marginLeft: 25,
        },

        /* List */

        flatList: {
            paddingVertical: 15,
            paddingHorizontal: 10,
        },

        listItem: {
            backgroundColor: theme.colors.white,
            borderRadius: 4,
            padding: 10,
            borderLeftWidth: 4,
            borderColor: theme.colors.greyLight,
        },

        listItemIconTextWrapper: {
            fontSize: 16,
            paddingHorizontal: 5,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: theme.colors.greyExtraLight,
            backgroundColor: theme.colors.lightBg,
            alignSelf: 'flex-start',
            minHeight: 26,
        },

        dateLabel: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 12,
            paddingHorizontal: 5,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: theme.colors.greyExtraLight,
            backgroundColor: theme.colors.lightBg,
            minHeight: 26,
            minWidth: 140,
        },

        checkinLabel: {
            textTransform: 'uppercase',
            fontSize: 11,
            fontFamily: theme.fontFamilySemibold,
            color: theme.colors.textColor,
            paddingHorizontal: 5,
            paddingVertical: 5,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: theme.colors.greyLight,
            minWidth: 120,
            height: 24,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

        labelRed: {
            color: theme.colors.error,
            borderColor: 'rgba(232, 106, 106, 0.2)',
            backgroundColor: 'rgba(232, 106, 106, 0.1)',
        },

        labelGreen: {
            color: theme.colors.success,
            borderColor: 'rgba(70, 159, 157, 0.2)',
            backgroundColor: 'rgba(70, 159, 157, 0.1)',
        },

        labelBlue: {
            color: theme.colors.default,
            borderColor: 'rgba(117, 154, 223, 0.2)',
            backgroundColor: 'rgba(117, 154, 223, 0.1)',
        },

        listItemChevronContainer: {
            position: 'absolute',
            right: 7,
        },

        ListItemChevron: {
            color: theme.colors.greyLight,
            size: 18,
        },

        listNote: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            fontFamily: theme.fontFamily,
            color: theme.colors.textColor,
            fontSize: 12,
            marginTop: 15,
            paddingTop: 10,
            borderTopWidth: 1,
            borderTopColor: theme.colors.borderLight,
        },

        listNoteText: {
            fontFamily: theme.fontFamily,
            color: theme.colors.textColor,
            fontSize: 12,
            flex: 1,
        },

        /* Modal */
        modalDialog: {
            width: '100%',
            maxWidth: 500,
            maxHeight: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: 10,
            overflow: 'hidden',
        },

        modalDialogL: {
            backgroundColor: theme.colors.white,
            width: '100%',
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: 10,
            overflow: 'hidden',
            maxHeight: '100%',
        },

        modalTitle: {
            backgroundColor: theme.colors.primaryDark,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },

        modalTitleText: {
            paddingRight: 10,
            paddingLeft: 60,
            paddingVertical: 10,
            color: theme.colors.white,
            fontSize: 14,
            fontFamily: theme.fontFamilySemibold,
            textAlign: 'center',
            flex: 1,
        },

        modalCloseButton: {
            padding: 10,
        },

        modalCloseIcon: {
            padding: 5,
            borderRadius: 20,
            backgroundColor: theme.colors.primary,
        },

        modalContent: {
            padding: 15,
            backgroundColor: theme.colors.white,
            minHeight: 100,
            justifyContent: 'center',
        },

        modalContentText: {
            color: theme.colors.textColor,
            fontSize: 14,
            textAlign: 'center',
            fontFamily: theme.fontFamily,
        },

        modalFooter: {
            backgroundColor: theme.colors.primaryExtraLight,
            padding: 8,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: 'row',
            minHeight: 50,
            //borderTopWidth: 1,
            //borderTopColor: 'rgba(93, 77, 157,  0.2)',
        },

        /* Input */

        inputFocus: {
            borderColor: theme.colors.primary,
        },

        inputWithIcon: {
            paddingLeft: 25,
        },

        inputIconContainer: {
            position: 'absolute',
            left: 0,
            marginVertical: 0,
            paddingRight: 0,
            height: 40,
            zIndex: 1,
        },

        inputWithIconRight: {
            paddingRight: 40,
        },

        inputIconRightContainer: {
            position: 'absolute',
            right: 12,
            marginVertical: 0,
            paddingRight: 0,
            zIndex: 1,
        },

        inputRounded: {
            borderWidth: 1,
            borderRadius: 40,
            height: 40,
            minHeight: 40,
            paddingRight: 10,
        },

        inputRoundedWithIcon: {
            paddingLeft: 40,
        },

        inputRoundedIconContainer: {
            position: 'absolute',
            left: 12,
            marginVertical: 0,
            paddingRight: 0,
            zIndex: 1,
        },

        inputIcon: {
            color: theme.colors.greyLight,
        },

        inputIconFocus: {
            color: theme.colors.primary,
            opacity: 0.5,
        },

        /* Time fields */

        timeBox: {
            borderWidth: 1,
            backgroundColor: theme.colors.lightBg,
            borderColor: theme.colors.greyExtraLight,
            borderRadius: 10,
            paddingRight: 10,
            paddingBottom: 2,
            paddingTop: 22,
            paddingLeft: 20,
            position: 'relative',
            width: '49%',
            maxWidth: 180,
        },

        timeBoxTitle: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: theme.colors.white,
            borderWidth: 1,
            borderColor: theme.colors.greyExtraLight,
            borderRadius: 5,
            paddingLeft: 5,
            paddingRight: 10,
            position: 'absolute',
            top: -10,
            left: 15,
        },

        timeInputContainer: {
            marginBottom: 0,
        },

        timeInputWrapper: {
            height: 35,
            width: 110,
            overflow: 'hidden',
            justifyContent: 'center',
        },

        timeInput: {
            fontSize: 13,
            height: 40,
            minHeight: 40,
            fontFamily: 'Montserrat-Medium',
            paddingLeft: 25,
            paddingRight: 0,
            borderBottomWidth: 0,
            color: theme.colors.primary,
        },

        /* eVisitor chooser - navigation */

        evChooserWrapper: {
            flex: 1,
        },

        evChooser: {
            textAlign: 'right',
        },

        /* LangSelect */

        langSelectWrapper: {
            position: 'absolute',
            top: 20,
            right: 20,
            borderRadius: 20,
            zIndex: 1,
        },

        langSelectButton: {
            backgroundColor: theme.colors.lightBg,
            borderColor: theme.colors.borderPrimaryLight,
            borderWidth: 1,
            borderRadius: 20,
            paddingRight: 21,
            paddingLeft: 10,
            paddingVertical: 5,
        },

        langSelectButtonTitle: {
            color: theme.colors.primary,
            fontFamily: theme.fontFamilySemibold,
            textTransform: 'uppercase',
            fontSize: 12,
        },

        langSelectContainer: {
            borderBottomWidth: 0,
        },

        langSelectInput: {
            width: 60,
            borderWidth: 1,
            borderColor: theme.colors.greyLight,
            borderRadius: 10,
            fontSize: 14,
            minHeight: 35,
            fontFamily: theme.fontFamily,
            textAlign: 'center',
            textTransform: 'uppercase',
            color: theme.colors.primary,
            cursor: 'pointer',
        },

        CheckBox: {
            paddingVertical: 5,
            paddingHorizontal: 0,
            margin: 0,
            borderWidth: 0,
            borderRadius: 0,
            backgroundColor: 'transparent',
        },

        CheckBoxText: {
            fontFamily: theme.fontFamilyMedium,
            fontWeight: 'normal',
            color: theme.colors.textColor,
            flex: 1,
        },
    };
};

// SwipeButton dimensions
const BUTTON_WIDTH = 250;
const BUTTON_HEIGHT = 50;
const BUTTON_PADDING = 5;
const SWIPEABLE_DIMENSIONS = BUTTON_HEIGHT - 2 * BUTTON_PADDING;

export default globalStyle;
