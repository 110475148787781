import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ScrollView, Pressable} from 'react-native';
import {Button, Icon, Input, Text, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import Modal from 'react-native-modal';
import {
    CONFIRM,
    EMAIL,
    RESET_PASSWORD_CONTENT_1,
    RESET_PASSWORD_CONTENT_2,
    RESET_PASSWORD_CONTENT_3,
    RESET_PASSWORD_LABEL,
} from '../../constants/stringsAndFields';
import {postResetPassword} from '../../api/inCheckinRestService';
import {validateEmail} from '../../utils/helpers';
import {parseResetPassContent} from '../../utils/htmlParser';
import {sleep} from '../../utils/promiseHelpers';

const ResetPasswordDialog = ({open, setOpen, onConfirm}) => {
    const {i18n, t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [message, setMessage] = useState(null);

    const [focusEmail, setEmailFocus] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setMessage(null);
    };

    const setEmailAddress = text => {
        setDisabled(!validateEmail(text));
        setEmail(text);
    };

    const onConfirmClick = async () => {
        try {
            const res = await postResetPassword(i18n.language, email);
            if (res) {
                const {isError, resetResponse} = parseResetPassContent(res);
                resetResponse && setMessage(resetResponse);
                if (!isError) {
                    await sleep(3000);
                    setOpen(false);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}
            avoidKeyboard={true}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(RESET_PASSWORD_LABEL)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <ScrollView contentContainerStyle={global.modalContent}>
                    <Text style={[global.textMain, {marginBottom: 10}]}>{t(RESET_PASSWORD_CONTENT_1)}</Text>
                    <Text style={[global.textMain, {marginBottom: 10}]}>{t(RESET_PASSWORD_CONTENT_2)}</Text>
                    <Text style={[global.textMain, {marginBottom: 10}]}>{t(RESET_PASSWORD_CONTENT_3)}</Text>
                    <Input
                        placeholder={t(EMAIL)}
                        leftIcon={
                            <Icon
                                name="mail"
                                size={22}
                                iconStyle={focusEmail ? global.inputIconFocus : global.inputIcon}
                            />
                        }
                        leftIconContainerStyle={global.inputRoundedIconContainer}
                        onChangeText={setEmailAddress}
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        style={[focusEmail && global.inputFocus, global.inputRoundedWithIcon, global.inputRounded]}
                        containerStyle={{marginTop: 15, maxWidth: '80%', alignSelf: 'center'}}
                        inputContainerStyle={{marginBottom: 10}}
                    />
                    {message && (
                        <Text style={{fontFamily: 'Montserrat-Medium', color: theme.colors.error, textAlign: 'center'}}>
                            {message}
                        </Text>
                    )}
                </ScrollView>
                <View style={global.modalFooter}>
                    <Button
                        onPress={onConfirmClick}
                        title={t(CONFIRM)}
                        disabled={disabled}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default ResetPasswordDialog;
