import React, {createContext, useEffect, useState, useCallback} from 'react';
import {
    addEVisitor,
    deleteEVisitorAcc,
    fetchStaticData,
    loginUser,
    deleteAccount as deleteAccountRest,
    REST_COMMUNICATION,
    signUpUser,
    updateUser as updateUserRest,
    getUser as getUserRest,
} from '../api/inCheckinRestService';
import {
    createOrUpdateGuest as createOrUpdateGuestDb,
    deleteAllData,
    deleteCheckin,
    deleteGuest as deleteGuestDb,
    fetchCheckin,
    fetchCheckins,
    fetchEvisitorAccount,
    fetchEvisitorAccounts,
    fetchInvoices,
    removeInvoice,
    saveCheckin,
    saveGuest,
    saveInvoice,
    updateCheckin,
    updateGuest as updateGuestDb,
    updateInvoice as updateInvoiceDb,
    updateLocalCheckinDb,
} from '../api/inCheckinService';
import {
    STATUS_DELETE_CHECKIN,
    STATUS_EDIT_CHECKIN,
    STATUS_GET_CHECKINS,
    STATUS_GET_EVISITOR,
    STATUS_GET_STATIC,
    STATUS_SAVE_CHECKIN,
    STATUS_UPDATE_CHECKIN,
    STATUS_UPDATE_CHECKIN_GUESTS,
    id,
} from '../constants/stringsAndFields';
import {getCheckinDb, getCheckins, getCheckinsWithoutGuestData} from '../database/checkin';
import {insertGuest} from '../database/guest';
import {parseStaticData} from '../utils/arrayHelpers';
import {sleep} from '../utils/promiseHelpers';
import {checkStaticData, getStaticData, storeStaticData} from '../utils/helpers';
import {clearUserData, isLoggedIn} from '../utils/userUtils';
import {
    getEvisitors,
    getEvisitor as getEvisitorDb,
    isEvisitorDataEmpty,
    deleteEvisitor as deleteEvisitorDb,
    getEvisitorByPinDb,
} from '../database/evisitor';
import {useTranslation} from 'react-i18next';
import {
    getInvoices as getInvoicesDb,
    getInvoice as getInvoiceDb,
    getInvoiceByInvoiceNumber as getInvoiceByInvoiceNumberDb,
    getInvoicesByEvAccount as getInvoicesByEvAccountDb,
    getLastInvoiceByEvAccount,
    getInvoiceByCheckin as getInvoiceByCheckinDb,
    getInvoicesByCheckin as getInvoicesByCheckinDb,
} from '../database/invoice';
import {checkIsCreditsProblemError} from '../utils/credits';
import {INVOICE_FIRST_PAGE} from '../screens/InvoicesScreen';
import {CHECKINS_FIRST_PAGE} from '../screens/CheckinsScreen';

const AUTO_CLEAN_ERRORS_SLEEP_DURATION = 5000;
const AUTO_CLEAN_MESSAGES_SLEEP_DURATION = 10000;

const DataContext = createContext({
    onThemeChange: () => {},
});

export function DataProvider(props) {
    const {db} = props;
    const {t} = useTranslation();
    const [evaccounts, setEvaccounts] = useState([]);
    const [checkins, setCheckins] = useState([]);
    const [guests, setGuests] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [eVisitorStaticData, setEVisitorStaticData] = useState();
    const [appStatus, setAppStatus] = useState({user: null, loggedIn: false, apiDataFetched: false});
    const [apiErrors, setApiErrors] = useState(null);
    const [apiMessages, setApiMessages] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [reauthOngoing, setReauthOngoing] = useState(false);
    const [startupCompleted, setStartupCompleted] = useState(false);

    const emitMessage = _ => {
        return {
            t,
            setApiMessages,
        };
    };

    useEffect(() => {
        if (db) {
            appStartupInitData().then(_ => {});
        }
    }, [db]);

    const appStartupInitData = useCallback(async () => {
        const userLoggedIn = await isLoggedIn(true, true, setLoggedIn, setReauthOngoing);
        if (userLoggedIn) {
            try {
                await initCheckins(CHECKINS_FIRST_PAGE);
                await initStaticData();
                await initEvAccounts(true, false);
            } catch (e) {
                console.log(e);
            }
        } else {
            // handling when not logged in - shared guest view (static data)
            try {
                await initStaticData();
            } catch (e) {
                console.log(e);
            }
        }
        setStartupCompleted(true);
    }, []);

    const fetchInitData = useCallback(async () => {
        try {
            try {
                await initEvAccounts(false, false);
            } catch (e) {
                console.log('Caught startup error, could not fetch evisitor accouns');
            }
            //await initCheckins();
            await initStaticData();
            //await refreshInvoices();
            setAppStatus({...appStatus, apiDataFetched: true});
        } catch (e) {
            console.log('Caught startup error');
            console.log(e);
            throw e;
        }
    }, [appStatus]);

    const initEvAccounts = useCallback(
        async (checkCache = false, fullEvisitorDownload = false) => {
            const signal = 'InitEvAccounts';
            const evisitorDataEmpty = await isEvisitorDataEmpty(db);
            if (checkCache && !evisitorDataEmpty) {
                const evisitorData = await getEvisitors(db);
                fetchAndSetEvAccounts(evisitorData);
            } else {
                setApiMessages({signal: signal, message: STATUS_GET_EVISITOR});
                try {
                    const forceRefresh = !checkCache;
                    const fetchedEvAccounts = await fetchEvisitorAccounts(
                        db,
                        forceRefresh,
                        emitMessage,
                        fullEvisitorDownload
                    );
                    fetchAndSetEvAccounts(fetchedEvAccounts);
                    setApiMessages(null);
                } catch (error) {
                    errorHandler(signal, error);
                }
            }
        },
        [db]
    );

    const initCheckins = useCallback(
        async (page = CHECKINS_FIRST_PAGE, forceRefresh = false) => {
            const signal = 'InitCheckins';
            setApiMessages({signal: signal, message: STATUS_GET_CHECKINS});
            setTimeout(function () {
                setApiMessages(null);
            }, 2000);
            try {
                const fetchedCheckins = await fetchCheckins(db, page, forceRefresh);
                await fetchAndSetCheckins(fetchedCheckins);
            } catch (error) {
                errorHandler(signal, error);
            }
        },
        [db]
    );

    const getCheckinsLocal = async () => {
        const fetchedCheckins = await getCheckinsWithoutGuestData(db);
        await fetchAndSetCheckins(fetchedCheckins);
    };

    const getCheckinsLocalFull = async (evAccount = null) => {
        const fetchedCheckins = await getCheckins(db, evAccount);
        return fetchedCheckins;
    };

    const getCheckin = async dataId => {
        const fetchedCheckin = await getCheckinDb(db, dataId);
        return fetchedCheckin;
    };

    const refreshCheckin = useCallback(
        async checkinId => {
            const signal = 'RefreshCheckin';
            setApiMessages({signal: signal, message: STATUS_GET_CHECKINS});
            try {
                await fetchCheckin(db, checkinId, false);
            } catch (error) {
                console.log(error);
                //errorHandler(signal, error);
            } finally {
                await sleep(500);
                setApiMessages(null);
            }
        },
        [db]
    );

    const initStaticData = useCallback(async () => {
        const signal = 'InitStaticData';
        const staticDataExist = await checkStaticData();
        if (staticDataExist) {
            const data = await getStaticData();
            fetchAndSetStaticData(data);
        } else {
            setApiMessages({signal: signal, message: STATUS_GET_STATIC});
            try {
                const fetchedStaticData = await fetchStaticData();
                const data = parseStaticData(fetchedStaticData);
                if (data) {
                    fetchAndSetStaticData(data);
                    await storeStaticData(data);
                }
                setApiMessages(null);
            } catch (error) {
                errorHandler(signal, error);
            }
        }
    }, []);

    const createOrUpdateGuest = useCallback(
        async (guest, isNewGuest, omitGuestCheck) => {
            const signal = 'createOrUpdateGuest';
            try {
                await createOrUpdateGuestDb(db, guest, isNewGuest, omitGuestCheck);
            } catch (error) {
                errorHandler(signal, error);
            }
        },
        [db]
    );

    const updateGuest = async (guest, removeContent = false) => {
        await updateGuestDb(db, guest, removeContent);
    };

    const deleteGuest = async guest => {
        await deleteGuestDb(db, guest);
    };

    const fetchAndSetStaticData = data => {
        if (data) setEVisitorStaticData(data);
    };

    const fetchAndSetCheckins = async data => {
        if (data) setCheckins(data);
    };

    const fetchAndSetGuests = data => {
        if (data) setGuests(data);
    };

    const fetchAndSetEvAccounts = data => {
        if (data) setEvaccounts(data);
    };

    const fetchAndSetInvoices = data => {
        if (data) setInvoices(data);
    };

    const fetchAndSetData = {
        fetchAndSetStaticData,
        fetchAndSetCheckins,
        fetchAndSetGuests,
        fetchAndSetEvAccounts,
        fetchAndSetInvoices,
    };

    const onAddCheckin = useCallback(
        async checkin => {
            setApiMessages({signal: 'OnAddCheckin', message: STATUS_SAVE_CHECKIN});
            try {
                await saveCheckin(db, checkin);
                setApiMessages(null);
            } catch (e) {
                console.log(e?.message);
                setApiErrors({signal: 'onAddCheckin', message: e?.message});
            }
        },
        [db]
    );

    const onUpdateCheckin = useCallback(
        async (checkin, hideMessages = false) => {
            !hideMessages && setApiMessages({signal: 'OnUpdateCheckin', message: STATUS_UPDATE_CHECKIN});
            try {
                await updateCheckin(db, checkin);
                setApiMessages(null);
            } catch (e) {
                console.log(e?.message);
                setApiErrors({signal: 'onUpdateCheckin', message: e?.message});
            }
        },
        [db]
    );

    const updateLocalCheckin = useCallback(
        async (checkin, hideMessages = false) => {
            !hideMessages && setApiMessages({signal: 'updateLocalCheckin', message: STATUS_UPDATE_CHECKIN});
            try {
                await updateLocalCheckinDb(db, checkin);
                setApiMessages(null);
            } catch (e) {
                console.log(e?.message);
                setApiErrors({signal: 'updateLocalCheckin', message: e?.message});
            }
        },
        [db]
    );

    const onUpdateGuests = useCallback(
        async (guests, hideMessages = true) => {
            !hideMessages && setApiMessages({signal: 'onUpdateGuests', message: STATUS_UPDATE_CHECKIN_GUESTS});
            try {
                for (let guest of guests) {
                    await saveGuest(db, guest);
                }
                setApiMessages(null);
            } catch (e) {
                console.log(e?.message);
                setApiErrors({signal: 'onAddCheckin', message: e?.message});
            }
        },
        [db]
    );

    const onEditCheckin = useCallback(
        async editCheckin => {
            try {
                setApiMessages({signal: 'OnEditCheckin', message: STATUS_EDIT_CHECKIN});
                await updateCheckin(db, editCheckin);
                setApiMessages(null);
            } catch (e) {
                console.log(e?.message);
                setApiErrors({signal: 'OnEditCheckin', message: e?.message});
            }
        },
        [db]
    );

    const onDeleteCheckin = useCallback(
        async removeCheckin => {
            try {
                setApiMessages({signal: 'OnDeleteCheckin', message: STATUS_DELETE_CHECKIN});
                await deleteCheckin(db, removeCheckin);
                setApiMessages(null);
            } catch (e) {
                console.log(e?.message);
                //setApiErrors({signal: 'OnDeleteCheckin', message: e?.message});
            }
        },
        [db]
    );

    const onCreateIfNotExistCheckin = useCallback(
        async checkin => {
            try {
                const foundCheckin = await fetchCheckin(db, checkin[id], true);
                if (!foundCheckin) {
                    await saveCheckin(db, checkin);
                }
                setApiMessages(null);
            } catch (e) {
                console.log(e?.message);
                setApiErrors({signal: 'onCreateIfNotExistCheckin', message: e?.message});
            }
        },
        [db]
    );

    const signUp = async user => {
        try {
            return await signUpUser(user);
        } catch (e) {
            setApiErrors({signal: 'SignUp', message: e?.message});
            throw e;
        }
    };

    const login = async user => {
        try {
            return await loginUser(user);
        } catch (e) {
            setApiErrors({signal: 'Login', message: e?.message});
            throw e;
        }
    };

    const deleteAccount = async accountAndMessage => {
        try {
            return await deleteAccountRest(accountAndMessage);
        } catch (e) {
            setApiErrors({signal: 'DeleteAccount', message: e?.message});
            throw e;
        }
    };

    const updateUser = async user => {
        try {
            return await updateUserRest(user);
        } catch (e) {
            setApiErrors({signal: 'updateUser', message: e?.message});
            throw e;
        }
    };

    const getUser = async _ => {
        try {
            return await getUserRest(user);
        } catch (e) {
            setApiErrors({signal: 'getUser', message: e?.message});
            throw e;
        }
    };

    const associateEvisitor = useCallback(
        async user => {
            try {
                const eVisitorAccount = await addEVisitor(user);
                await fetchEvisitorAccount(db, eVisitorAccount, emitMessage);
                await initEvAccounts(true, false);
            } catch (e) {
                setApiErrors({signal: 'AddEVisitor', message: e?.message});
                throw e;
            }
        },
        [db]
    );

    const deleteEVisitor = useCallback(
        async eVisitorAccount => {
            try {
                await deleteEVisitorAcc(eVisitorAccount);
                await deleteEvisitorDb(db, eVisitorAccount);
                await initEvAccounts(true, false);
            } catch (e) {
                setApiErrors({signal: 'DeleteEVisitor', message: e?.message});
                throw e;
            }
        },
        [db]
    );

    const getEvisitor = async evisitorId => {
        const evisitor = await getEvisitorDb(db, evisitorId);
        return evisitor;
    };

    const getEvisitorByPin = async pin => {
        const evisitor = await getEvisitorByPinDb(db, pin);
        return evisitor;
    };

    const refetchEvisitor = useCallback(
        async eVisitorAccount => {
            try {
                if (eVisitorAccount) {
                    await fetchEvisitorAccount(db, eVisitorAccount, emitMessage);
                    await initEvAccounts(true, false);
                }
            } catch (e) {
                setApiErrors({signal: 'RefetchEvisitor', message: e?.message});
                throw e;
            }
        },
        [db]
    );

    const onReCreateGuest = async guest => {
        //setApiMessages({ signal: "OnRecreateGuest", message: STATUS_RECREATE_CHECKIN_GUESTS });
        try {
            await insertGuest(db, guest);
        } catch (e) {
            console.log(e?.message);
            setApiErrors({signal: 'OnRecreateGuest', message: e?.message});
        }
    };

    const createInvoice = async invoice => {
        const signal = 'createInvoice';
        try {
            await saveInvoice(db, invoice);
        } catch (error) {
            if (checkIsCreditsProblemError(error)) {
                throw error;
            } else {
                errorHandler(signal, error);
            }
        }
    };

    const updateInvoice = async invoice => {
        const signal = 'updateInvoice';
        try {
            await updateInvoiceDb(db, invoice);
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const deleteInvoice = async invoice => {
        const signal = 'deleteInvoice';
        try {
            await removeInvoice(db, invoice);
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const getInvoices = async () => {
        const signal = 'getInvoices';
        try {
            const fetchedInvoices = await getInvoicesDb(db);
            fetchAndSetInvoices(fetchedInvoices);
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const refreshInvoices = async (evAccount, evaccounts, page = INVOICE_FIRST_PAGE, forceRefresh = false) => {
        const signal = 'refreshInvoices';
        try {
            await fetchInvoices(db, evAccount, evaccounts, page, forceRefresh);
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const getInvoicesByEvAccount = async evAccount => {
        const signal = 'getInvoicesByEvAccount';
        try {
            const fetchedInvoices = await getInvoicesByEvAccountDb(db, evAccount, evaccounts, false);
            fetchAndSetInvoices(fetchedInvoices);
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const getInvoiceByCheckin = async checkin => {
        const signal = 'getInvoiceByCheckin';
        try {
            const fetchedInvoice = await getInvoiceByCheckinDb(db, checkin);
            return fetchedInvoice;
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const getInvoicesByCheckin = async checkin => {
        const signal = 'getInvoicesByCheckin';
        try {
            const fetchedInvoice = await getInvoicesByCheckinDb(db, checkin);
            return fetchedInvoice;
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const getInvoice = async dataId => {
        const signal = 'getInvoice';
        try {
            const fetchedInvoice = await getInvoiceDb(db, dataId);
            return fetchedInvoice;
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const getInvoiceByInvoiceNumber = async dataId => {
        const signal = 'getInvoiceByInvoiceNumber';
        try {
            const fetchedInvoice = await getInvoiceByInvoiceNumberDb(db, dataId);
            return fetchedInvoice;
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const getLastInvoice = async evAccount => {
        const signal = 'getLastInvoice';
        try {
            const fetchedInvoice = await getLastInvoiceByEvAccount(db, evAccount);
            return fetchedInvoice;
        } catch (error) {
            errorHandler(signal, error);
        }
    };

    const deleteAllUserData = useCallback(async () => {
        try {
            await clearUserData();
            await deleteAllData(db);
            setLoggedIn(false);
            setCheckins([]);
            setGuests([]);
            setEvaccounts([]);
        } catch (e) {
            console.log(e);
        }
    }, [db]);

    // clear errors
    const clearErrors = () => {
        setApiMessages(null);
        setApiErrors(null);
    };

    const errorHandler = (signal, error) => {
        console.log(error);
        const err = new RestApiException(signal, error?.message);
        setApiErrors({...err});
        throw err;
    };

    useEffect(() => {
        let timer = null;
        if (apiErrors) {
            timer = setTimeout(() => {
                setApiErrors(null);
            }, AUTO_CLEAN_ERRORS_SLEEP_DURATION);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [apiErrors]);

    useEffect(() => {
        let timer = null;
        if (apiMessages && apiMessages?.signal !== REST_COMMUNICATION) {
            // errors cleanup
            timer = setTimeout(() => {
                setApiMessages(null);
            }, AUTO_CLEAN_MESSAGES_SLEEP_DURATION);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [apiMessages]);

    return (
        <DataContext.Provider
            value={{
                fetchAndSetData,
                fetchInitData,
                initCheckins,
                getCheckinsLocal,
                getCheckin,
                initEvAccounts,
                createOrUpdateGuest,
                clearErrors,
                initStaticData,
                evaccounts,
                checkins,
                guests,
                updateGuest,
                deleteGuest,
                eVisitorStaticData,
                signUp,
                login,
                deleteAccount,
                deleteEVisitor,
                associateEvisitor,
                appStatus,
                setApiErrors,
                deleteAllUserData,
                apiErrors,
                apiMessages,
                onAddCheckin,
                onUpdateCheckin,
                refreshCheckin,
                onUpdateGuests,
                onEditCheckin,
                onDeleteCheckin,
                onReCreateGuest,
                loggedIn,
                refetchEvisitor,
                getEvisitor,
                getEvisitorByPin,
                getCheckinsLocalFull,
                onCreateIfNotExistCheckin,
                updateUser,
                getUser,
                createInvoice,
                updateInvoice,
                deleteInvoice,
                getInvoice,
                getInvoices,
                getInvoicesByEvAccount,
                getInvoiceByInvoiceNumber,
                getLastInvoice,
                invoices,
                refreshInvoices,
                getInvoiceByCheckin,
                reauthOngoing,
                startupCompleted,
                setLoggedIn,
                getInvoicesByCheckin,
                updateLocalCheckin,
            }}>
            {props.children}
        </DataContext.Provider>
    );
}

function RestApiException(signal, message) {
    this.signal = signal;
    this.message = message;
}

export const DataConsumer = DataContext.Consumer;
export default DataContext;
