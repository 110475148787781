import React, {memo, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    ActivityIndicator,
    FlatList,
    RefreshControl,
    Text,
    TouchableOpacity,
    View,
    useWindowDimensions,
} from 'react-native';
import {Button, useTheme} from 'react-native-elements';
import {ADD_EVISITOR, LOAD_MORE, NO_CHECKINS, NO_EVACCOUNT_ADD_ACCOUNT} from '../../constants/stringsAndFields';
import {CHECKINS_FIRST_PAGE, CHECKINS_PAGE_COUNT} from '../../screens/CheckinsScreen';
import globalStyle from '../../theme/globalStyle';
import {filterByPinAndSort} from '../../utils/arrayHelpers';
import CheckinItem from './CheckinItem';

const DELAY_EMPTY_LIST_WARNING = 3000;

const CheckinsList = ({
    checkins,
    evAccount,
    refreshing,
    onRefresh,
    evaccountsNumber,
    openEvisitorModal,
    startupCompleted,
    sortCheckinsBy,
    getNextPageCheckins,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const width = useWindowDimensions().width;
    const [page, setPage] = useState(CHECKINS_FIRST_PAGE);
    const [loading, setLoading] = useState(false);

    const getData = async _ => {
        setLoading(true);
        const incrementedPage = page + 1;
        const results = await getNextPageCheckins(incrementedPage);
        if (results === CHECKINS_PAGE_COUNT) {
            setPage(incrementedPage);
        }
        setLoading(false);
    };

    const filteredCheckins = filterByPinAndSort(checkins, evAccount, sortCheckinsBy);

    const memoizedContainerStyle = useMemo(() => {
        const isDesktop = width > 1500;
        const global = globalStyle(theme);
        return [
            global.flatList,
            global.hasFAB,
            {flexGrow: 1, width: '100%', maxWidth: 1100, alignSelf: 'center'},
            isDesktop && {paddingTop: 25},
        ];
    }, [width, theme]);

    const emptyList = (
        <MemoizedEmptyList
            startupCompleted={startupCompleted}
            evaccountsNumber={evaccountsNumber}
            global={global}
            t={t}
            openEvisitorModal={openEvisitorModal}
        />
    );

    const refreshComp = <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />;

    const renderFooter = () => {
        return checkins?.length === (page + 1) * CHECKINS_PAGE_COUNT ? (
            <View style={styles.footer}>
                <TouchableOpacity
                    activeOpacity={0.9}
                    onPress={getData}
                    style={[global.outlineButton, styles.loadMoreBtn]}>
                    {loading ? (
                        <ActivityIndicator color={theme.colors.primary} style={[{marginLeft: 8}, {maxHeight: 8}]} />
                    ) : (
                        <Text style={[global.outlineButtonTitle, {textTransform: 'none', fontSize: 12}]}>
                            {t(LOAD_MORE)}
                        </Text>
                    )}
                </TouchableOpacity>
            </View>
        ) : null;
    };

    useEffect(() => {
        if (checkins?.length === CHECKINS_PAGE_COUNT) {
            setPage(CHECKINS_FIRST_PAGE);
        }
    }, [checkins?.length]);

    return (
        <FlatList
            data={filteredCheckins}
            renderItem={renderCheckinItem}
            refreshControl={refreshComp}
            contentContainerStyle={memoizedContainerStyle}
            ListEmptyComponent={emptyList}
            ListFooterComponent={renderFooter}
        />
    );
};

export default CheckinsList;

export function EmptyList({startupCompleted, evaccountsNumber, global, t, openEvisitorModal}) {
    // delay empty list before data settles
    const [show, setShow] = useState(false);
    useEffect(() => {
        let timer = setTimeout(() => setShow(true), DELAY_EMPTY_LIST_WARNING);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    if (!show) return null;
    if (!startupCompleted) return null;

    return evaccountsNumber === 0 ? (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_EVACCOUNT_ADD_ACCOUNT)}</Text>
            <Button
                title={t(ADD_EVISITOR)}
                onPress={openEvisitorModal}
                containerStyle={global.outlineButtonContainer}
                buttonStyle={global.outlineButton}
                titleStyle={global.outlineButtonTitle}
            />
        </View>
    ) : (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_CHECKINS)}</Text>
        </View>
    );
}
export const MemoizedEmptyList = memo(EmptyList);

export const renderCheckinItem = ({item}) => <CheckinItem checkin={item} />;

const styles = {
    footer: {
        padding: 7,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    loadMoreBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 160,
        minHeight: 38,
        paddingHorizontal: 0,
    },
};
