import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, View} from 'react-native';
import {Button, Icon, Input, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    CONFIRM,
    EDIT_OPERATOR_DATA,
    INVOICE_FORM_OPERATOR_NAME,
    INVOICE_FORM_OPERATOR_REF,
    PROFILE_INVOICE_OPERATOR_NAME,
    PROFILE_INVOICE_OPERATOR_REF,
    STORAGE_USER_DATA,
    USER_DATA_INV_OPERATOR,
    USER_DATA_INV_OPERATOR_ID,
    USER_INFO,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {getUserData} from '../../utils/userUtils';

const EditOperatorDataDialog = ({open, setOpen, onConfirm}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [operatorRef, setOperatorRef] = useState('');
    const [operatorName, setOperatorName] = useState('');

    useEffect(() => {
        if (open) {
            getUserData().then(data => {
                const userInfo = data?.[STORAGE_USER_DATA]?.[USER_INFO];
                if (userInfo) {
                    setOperatorRef(userInfo?.[USER_DATA_INV_OPERATOR_ID] ?? '');
                    setOperatorName(userInfo?.[USER_DATA_INV_OPERATOR] ?? '');
                }
            });
        }
    }, [open]);

    const onConfirmSaveData = () => {
        onConfirm({[INVOICE_FORM_OPERATOR_NAME]: operatorName, [INVOICE_FORM_OPERATOR_REF]: operatorRef});
        setOpen(false);
    };

    // focust methods
    const [operatorFocus, setOperatorFocus] = useState(false);
    const turnOnOperatorFocus = () => {
        setOperatorFocus(true);
    };
    const turnOffOperatorFocus = () => {
        setOperatorFocus(false);
    };

    const [issuedFocus, setIssuedFocus] = useState(false);
    const turnOnIssuedFocus = () => {
        setIssuedFocus(true);
    };
    const turnOffIssuedFocus = () => {
        setIssuedFocus(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(EDIT_OPERATOR_DATA)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={[global.modalContent, {paddingTop: 20, paddingBottom: 5}]}>
                    <Input
                        label={t(PROFILE_INVOICE_OPERATOR_REF)}
                        onChangeText={text => setOperatorRef(text)}
                        onFocus={turnOnOperatorFocus}
                        value={operatorRef}
                        onBlur={turnOffOperatorFocus}
                        style={[operatorFocus && global.inputFocus]}
                    />
                    <Input
                        label={t(PROFILE_INVOICE_OPERATOR_NAME)}
                        onChangeText={text => setOperatorName(text)}
                        value={operatorName}
                        onFocus={turnOnIssuedFocus}
                        onBlur={turnOffIssuedFocus}
                        style={[issuedFocus && global.inputFocus]}
                    />
                </View>
                <View style={global.modalFooter}>
                    <Button
                        title={t(CONFIRM)}
                        onPress={onConfirmSaveData}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default EditOperatorDataDialog;
