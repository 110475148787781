import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator, Platform, Pressable, View, useWindowDimensions} from 'react-native';
import {Button, Icon, Input, LinearProgress, Text, useTheme} from 'react-native-elements';
import {v4 as uuidv4} from 'uuid';
import {
    ARRIVAL_DATE_LABEL,
    ARRIVAL_ORGANIZATION,
    ARRIVAL_ORGANIZATION_DATA,
    ARRIVAL_TIME_LABEL,
    CHECKIN,
    CHECKIN_ID,
    CITIZENSHIP,
    CITIZENSHIP_LABEL,
    CITY_OF_RESIDENCE,
    CODE,
    CODE_MI,
    CODE_NAMES,
    CONFIRM_GUEST_TITLE,
    COUNTRY_CODE,
    COUNTRY_DATA,
    COUNTRY_NAME,
    COUNTRY_OF_BIRTH,
    COUNTRY_OF_BIRTH_LABEL,
    COUNTRY_OF_RESIDENCE,
    CROATIA_COUNTRY_CODE,
    DATE_OF_BIRTH_FIELD,
    DATE_OF_BIRTH_LABEL,
    DEPARTURE_DATE_LABEL,
    DEPARTURE_TIME_LABEL,
    DOCUMENT_CODE_LABEL,
    DOCUMENT_NUMBER,
    DOCUMENT_TYPE,
    DOCUMENT_TYPE_DATA,
    DOCUMENT_TYPE_LABEL,
    FIRST_NAME_LABEL,
    FORESEEN_STAY_UNTIL_FIELD,
    GENDERS,
    GENDER_FIELD,
    GENDER_LABEL,
    GUEST_EDIT_WARNING,
    GUEST_NOTE_FIELD,
    ID,
    INITIAL_GUEST_FIELD,
    LAST_NAME_LABEL,
    NAME,
    NAME_NATIONAL_SHORT,
    NEPOZNATO,
    NOTE,
    OFFERED_SERVICE_TYPE,
    PAYMENT_CATEGORY,
    PAYMENT_CATEGORY_DATA,
    PAYMENT_CATEGORY_LABEL,
    RESIDENCE_CITY_LABEL,
    RESIDENCE_COUNTRY_LABEL,
    SAVE_DATA,
    SELF_CHECKIN_FIELD,
    SERVICE_TYPE_DATA,
    SERVICE_TYPE_LABEL,
    SETTLEMENTS_DATA,
    STATUS,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
    TOURIST_NAME,
    TOURIST_SURNAME,
    TRAVEL_ARRANGEMENTS_LABEL,
    UPDATED_STATUS,
    content,
    id,
    name,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {
    findGuestInCheckin,
    mapCountryCodeToCountry,
    mapNameToObject,
    mapTypeCodeMIToObject,
    mapTypeCodeToObject,
    sortCities,
    sortCountries,
    sortDocTypes,
    sortPaymentCategories,
} from '../../utils/arrayHelpers';
import {
    calculateAge,
    checkBirthDayNotification,
    getDateFromDateString,
    getDateFromTime,
    getFormattedDateFromDateString,
    getStringDate,
    getStringTime,
} from '../../utils/dateHelper';
import {
    checkIfShouldDisableArrival,
    checkIfShouldDisableFields,
    compareInitialAndEditedGuest,
    extendGuestPaymentCategory,
    generateUpdatedStatus,
    getContentFromExistingGuest,
    initNewGuest,
    mapGender,
    registerFormFields,
    setCountryCity,
    setGuestMeta,
} from '../../utils/guestUtils';
import {
    cleanFormData,
    deepCopy,
    isCroatiaCountry,
    isCroatianLang,
    isManualGuestDate,
    isManualGuestTime,
    updateMaxGuestsCount,
} from '../../utils/helpers';
import {sleep} from '../../utils/promiseHelpers';
import {translateDocTypes} from '../../utils/translation';
import ConfirmDialog from '../ConfirmDialog';
import MessageDialog from '../MessageDialog';
import DateTimePicker from '../datetime/DateTimePicker';
import ConsentDialog from '../self-checkin/ConsentDialog';
import GenderChooser from './GenderChooser';
import schema from './Guest.schema';
import useStyles from './GuestForm.styles';
import ModalChooser from './ModalChooser';

const SLEEP_DURATION = 200;
const API_MESSAGE_TIMEOUT = 5000;

const IS_WEB = Platform.OS === 'web';

const GuestForm = props => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t, i18n} = useTranslation();
    const countryName = isCroatianLang(i18n.language) ? NAME_NATIONAL_SHORT : COUNTRY_NAME;

    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;

    const {
        existingGuest,
        isNewGuest,
        guestData,
        checkin,
        navigation,
        eVisitorStaticData,
        createOrUpdateGuest,
        apiErrors,
        apiMessages,
        isSelfCheckin,
        getCheckin,
        onUpdateCheckin,
        lang,
        isConsentAccepted,
        calendarInput,
        onAddCheckin,
        onCreateIfNotExistCheckin,
        isFocused,
        accomodationPaymentCategories,
        selfCheckinSaveButton,
        manualInput,
        setShowFormErrors,
        useSharedCheckinActions,
    } = props;

    const {
        prepareGuestForCheckin,
        toggleCheckinSwipeState,
        resetSwipeButton,
        prepareSwipeButtonType,
        fastCheckinInProgress,
    } = useSharedCheckinActions();

    const fromDayRef = useRef(null);
    const toDayRef = useRef(null);
    const fromTimeRef = useRef(null);
    const toTimeRef = useRef(null);
    const birthRef = useRef(null);

    const selectGenderRef = useRef(null);
    const selectCountryRef = useRef(null);
    const selectCitizenshipRef = useRef(null);
    const selectCountryResidenceRef = useRef(null);
    const selectCityRef = useRef(null);
    const selectDocumentTypeRef = useRef(null);
    const selectPaymentCategoryRef = useRef(null);
    const selectServiceTypeRef = useRef(null);
    const selectArrivalOrganizationRef = useRef(null);
    const selectBirthRef = useRef(null);

    const [saveButton, setSaveButton] = useState(null);
    const [closeDateTimePicker, setCloseDateTimePicker] = useState(true);
    const [datePickerData, setDatePickerData] = useState(null);
    const [genderChooserVisible, setGenderChooserVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [modalChooserData, setModalChooserData] = useState(null);
    const [disableArrivalForCheckedIn, setDisableArrivalForCheckedIn] = useState(false);
    const [disableEditFields, setDisableEditFields] = useState(false);
    const [disableArrivaleDepartureDateFields, setDisableArrivaleDepartureDateFields] = useState(false);
    const [disableArrivaleDepartureTimeFields, setDisableArrivaleDepartureTimeFields] = useState(false);

    // validation
    const [openMessage, setOpenMessage] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [confirmGuestOpen, setConfirmGuestOpen] = useState(false);
    const [isCroatianCity, setIsCroatianCity] = useState(false);
    const [guest, setGuest] = useState(existingGuest ?? {});

    // focus
    const [focusName, setNameFocus] = useState(false);
    const [focusSurname, setSurnameFocus] = useState(false);
    const [focusDocumentNumber, setDocumentNumberFocus] = useState(false);
    const [focusRoomNumber, setRoomNumberFocus] = useState(false);
    const [focusNote, setNoteFocus] = useState(false);

    // dialogs
    const [showConsentDialog, setShowConsentDialog] = useState(false);
    const [consentCallbackData, setConsentCallbackData] = useState(null);

    // custom form error
    const [formError, setFormError] = useState(null);

    // select options
    const countries = useMemo(
        () => eVisitorStaticData && sortCountries(eVisitorStaticData?.[COUNTRY_DATA]),
        [eVisitorStaticData]
    );
    const cities = useMemo(
        () => eVisitorStaticData && sortCities(eVisitorStaticData?.[SETTLEMENTS_DATA]),
        [eVisitorStaticData]
    );
    const serviceTypes = useMemo(
        () => eVisitorStaticData && eVisitorStaticData?.[SERVICE_TYPE_DATA],
        [eVisitorStaticData]
    );
    const paymentCategories = useMemo(
        () =>
            accomodationPaymentCategories
                ? accomodationPaymentCategories
                : eVisitorStaticData && sortPaymentCategories(eVisitorStaticData?.[PAYMENT_CATEGORY_DATA]),
        [accomodationPaymentCategories, eVisitorStaticData]
    );
    const arrivalOrganisations = useMemo(
        () => eVisitorStaticData && eVisitorStaticData?.[ARRIVAL_ORGANIZATION_DATA],
        [eVisitorStaticData]
    );
    const documentTypesDefault = useMemo(
        () => eVisitorStaticData && eVisitorStaticData?.[DOCUMENT_TYPE_DATA],
        [eVisitorStaticData]
    );
    const documentTypes = useMemo(
        () => documentTypesDefault && sortDocTypes(translateDocTypes(documentTypesDefault, lang)),
        [documentTypesDefault, lang]
    );

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        trigger,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema(t)),
    });

    const consentAccepted = async (isAccepted, data) => {
        setShowConsentDialog(false);
        await continueSubmit(data);
    };

    const onSubmit = async (data, e) => {
        setShowFormErrors(false);
        if (isSelfCheckin && !isConsentAccepted) {
            setConsentCallbackData(data);
            setShowConsentDialog(true);
        } else {
            await continueSubmit(data);
        }
    };

    const continueSubmit = async data => {
        setOpenProgress(true);
        const checkinId = data[CHECKIN_ID];
        const guestId = data[id];
        const guestCheckedInStatus = data[STATUS];
        const checkinData = deepCopy(data[CHECKIN]);
        const initialGuest = deepCopy(data[INITIAL_GUEST_FIELD]);
        const guestPreviouslyEditedStatus = initialGuest?.[UPDATED_STATUS];
        const editedGuest = cleanFormData(data, checkinData);
        const areEqual = compareInitialAndEditedGuest(initialGuest, editedGuest);
        //editedGuest[FACILITY] = 'not-existing-facility'; // uncommented this line to test facility refetch
        const guestSubmit = {
            [id]: guestId,
            [CHECKIN_ID]: checkinId,
            [STATUS]: guestCheckedInStatus,
            [UPDATED_STATUS]: generateUpdatedStatus(
                guestCheckedInStatus,
                guestPreviouslyEditedStatus,
                isNewGuest,
                areEqual
            ),
            [content]: editedGuest,
            [SELF_CHECKIN_FIELD]: isSelfCheckin ? true : false,
        };
        try {
            if (!isSelfCheckin && isNewGuest && !calendarInput) {
                await updateMaxGuestsCount(checkinId, getCheckin, onUpdateCheckin);
            }
            if (calendarInput && checkinData) {
                if (isNewGuest) {
                    await onAddCheckin(checkinData);
                } else {
                    await onCreateIfNotExistCheckin(checkinData);
                }
            }
            try {
                const omitGuestCheck = isSelfCheckin ? true : false;
                await createOrUpdateGuest(guestSubmit, isNewGuest, omitGuestCheck);
                isNewGuest && (await checkBirthDayNotification(guestSubmit[content], checkinData, t));
            } catch (e) {
                console.log(e);
            }

            await sleep(SLEEP_DURATION);
            setOpenProgress(false);

            // fast checkin functionality
            if (toggleCheckinSwipeState) {
                prepareGuestForCheckin(guestSubmit);
            } else if (isSelfCheckin) {
                navigation.replace('SelfCheckin', {
                    checkinId: checkinId,
                    saved: true,
                    consent: true,
                    lang: lang,
                });
            } else {
                navigation.pop();
            }
        } catch (e) {
            console.log(e);
            setOpenProgress(false);
        }
    };

    const onError = (errors, e) => {
        console.log(errors, e);
        setShowFormErrors(true);
        resetSwipeButton(true);
    };

    // Data mutation
    const onChangeField = (data, field) => {
        setValue(field, data);
    };

    const isCatSupported = catCode => paymentCategories?.find(p => p[CODE] === catCode);

    const onChangeDate = async (date, field) => {
        setValue(field, getStringDate(date));
        if (field === DATE_OF_BIRTH_FIELD || field === CITY_OF_RESIDENCE) {
            determinePaymentCategory(date, null, field);
        }
        await trigger(field);
    };

    const determinePaymentCategory = (date = null, cityOfResidence = null, field) => {
        //console.log('determinePaymentCategory date: ', date);
        //console.log('determinePaymentCategory cityOfResidence: ', cityOfResidence);
        //console.log('determinePaymentCategory field: ', field);
        if (field === DATE_OF_BIRTH_FIELD && date && cityOfResidence === null) {
            cityOfResidence = getValues(CITY_OF_RESIDENCE);
        }
        if (field === CITY_OF_RESIDENCE && cityOfResidence && date === null) {
            const dateString = getValues(DATE_OF_BIRTH_FIELD);
            //console.log('determinePaymentCategory dateString: ', dateString);
            if (dateString) {
                const formattedDateString = dateString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                date = new Date(formattedDateString);
            }
        }
        if (date) {
            const age = calculateAge(date);
            //console.log('determinePaymentCategory age: ', age);
            const facilityCityName = getFacilityCityName(checkin);
            //console.log('determinePaymentCategory facilityCityName: ', facilityCityName);
            if (age <= 12) {
                isCatSupported('1') && setValue(PAYMENT_CATEGORY, '1');
            } else {
                if (
                    facilityCityName &&
                    cityOfResidence &&
                    cityOfResidence.toLocaleLowerCase().includes(facilityCityName.toLowerCase())
                ) {
                    isCatSupported('6') && setValue(PAYMENT_CATEGORY, '6');
                } else {
                    if (age <= 18) {
                        isCatSupported('2') && setValue(PAYMENT_CATEGORY, '2');
                    } else {
                        isCatSupported('14') && setValue(PAYMENT_CATEGORY, '14');
                    }
                }
            }
        }
        //console.log('determinePaymentCategory PAYMENT_CATEGORY: ', getValues(PAYMENT_CATEGORY));
    };

    const onChangeTime = (date, field) => {
        setValue(field, getStringTime(date));
    };

    // Male -> Muško
    const handleGenderChange = value => {
        setValue(GENDER_FIELD, GENDERS[value]);
    };

    const onChangeCountry = (country, field) => {
        if (country) {
            if (field === COUNTRY_OF_RESIDENCE) {
                if (isCroatiaCountry(country)) {
                    setIsCroatianCity(true);
                } else {
                    setIsCroatianCity(false);
                }
            }
            setValue(field, country[COUNTRY_CODE]);

            const citizenship = getValues(CITIZENSHIP);
            if (citizenship === '') {
                setValue(CITIZENSHIP, country[COUNTRY_CODE]);
            }

            const countryOfResidence = getValues(COUNTRY_OF_RESIDENCE);
            if (countryOfResidence === '') {
                setValue(COUNTRY_OF_RESIDENCE, country[COUNTRY_CODE]);
                if (isCroatiaCountry(country)) setIsCroatianCity(true);
            }
        }
    };

    const openDateTimePicker = (mode, field, value, openTo = 'day') => {
        setDatePickerData({
            datePickerMode: mode,
            datePickerDate: mode === 'date' ? getDateFromDateString(value) : getDateFromTime(value),
            currentPickerField: field,
            openTo: openTo,
            rawDateValue: value,
        });
        setCloseDateTimePicker(false);
    };

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message} `;
        } else if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message} `;
        }
    };

    const preCommitHook = async _ => {
        if (formError) {
            resetSwipeButton(true);
            setShowFormErrors(true);
            return;
        }
        const cityOfResidence = getValues(CITY_OF_RESIDENCE);
        if (!isCroatianCity && (cityOfResidence === '' || cityOfResidence === null)) {
            setValue(CITY_OF_RESIDENCE, NEPOZNATO);
        }
        await continueGuest();
    };

    const continueGuest = async () => {
        handleSubmit(onSubmit, onError)();
    };

    const continueGuestAccepted = async () => {
        await continueGuest();
    };

    const turnOnNameFocus = () => {
        setNameFocus(true);
    };

    const turnOffNameFocus = () => {
        setNameFocus(false);
    };

    const turnOnSurnameFocus = () => {
        setSurnameFocus(true);
    };

    const turnOffSurnameFocus = () => {
        setSurnameFocus(false);
    };

    const turnOnRoomNumberFocus = () => {
        setRoomNumberFocus(true);
    };

    const turnOffRoomNumberFocus = () => {
        setRoomNumberFocus(false);
    };

    const turnOnNoteFocus = () => {
        setNoteFocus(true);
    };

    const turnOffNoteFocus = () => {
        setNoteFocus(false);
    };

    const selectCountry = (field, title) => {
        setModalChooserData({
            title: title,
            filterProperty: countryName,
            filterItemId: ID,
            listData: countries,
            onItemSelected: country => {
                onChangeCountry(country, field[name], countries);
                setVisibleModal(false);
            },
        });
        setVisibleModal(true);
    };

    const selectCity = (field, title) => {
        setModalChooserData({
            title: title,
            filterProperty: NAME,
            filterItemId: ID,
            listData: cities,
            onItemSelected: city => {
                setValue(field[name], city[NAME]);
                setVisibleModal(false);
                determinePaymentCategory(null, city[NAME], CITY_OF_RESIDENCE);
            },
        });
        setVisibleModal(true);
    };

    const selectDocumentType = (field, title) => {
        setModalChooserData({
            title: title,
            filterProperty: NAME,
            filterItemId: ID,
            listData: documentTypes,
            onItemSelected: docType => {
                setValue(field[name], docType[CODE]);
                setVisibleModal(false);
            },
        });
        setVisibleModal(true);
    };

    const selectPaymentCategory = (field, title) => {
        setModalChooserData({
            title: title,
            filterProperty: CODE_NAMES,
            filterItemId: ID,
            listData: paymentCategories,
            onItemSelected: payCat => {
                setValue(field[name], payCat[CODE]);
                setVisibleModal(false);
            },
        });
        setVisibleModal(true);
    };

    const selectServiceType = (field, title) => {
        setModalChooserData({
            title: title,
            filterProperty: NAME,
            filterItemId: ID,
            listData: serviceTypes,
            onItemSelected: svcType => {
                setValue(field[name], svcType[NAME]);
                setVisibleModal(false);
            },
        });
        setVisibleModal(true);
    };

    const selectArrivalOrganization = (field, title) => {
        setModalChooserData({
            title: title,
            filterProperty: NAME,
            filterItemId: ID,
            listData: arrivalOrganisations,
            onItemSelected: arrOrg => {
                setValue(field[name], arrOrg[CODE_MI]);
                setVisibleModal(false);
            },
        });
        setVisibleModal(true);
    };

    const setDisableButton = _ => {};

    const onBirthDatePress = field => {
        birthRef.current.blur();
        if (!disableEditFields) {
            openDateTimePicker('date', DATE_OF_BIRTH_FIELD, field.value);
        }
    };

    const onStayFromDatePress = field => {
        fromDayRef.current.blur();
        if (!disableArrivalForCheckedIn && !disableEditFields && !disableArrivaleDepartureDateFields) {
            openDateTimePicker('date', STAY_FROM_FIELD, field.value);
        }
    };

    const onStayFromTimePress = field => {
        fromTimeRef.current.blur();
        if (!disableArrivalForCheckedIn && !disableEditFields && !disableArrivaleDepartureTimeFields) {
            openDateTimePicker('time', TIME_STAY_FROM_FIELD, field.value);
        }
    };

    const onStayUntilDatePress = field => {
        toDayRef.current.blur();
        if (!disableEditFields && !disableArrivaleDepartureDateFields) {
            openDateTimePicker('date', FORESEEN_STAY_UNTIL_FIELD, field.value);
        }
    };

    const onStayUntilTimePress = field => {
        toTimeRef.current.blur();
        if (!disableEditFields && !disableArrivaleDepartureTimeFields) {
            openDateTimePicker('time', TIME_ESTIMATED_STAY_UNTIL_FIELD, field.value);
        }
    };

    const onSelectBirthPress = field => {
        selectBirthRef.current.blur();
        if (!disableEditFields) {
            openDateTimePicker('date', DATE_OF_BIRTH_FIELD, field.value, 'year');
        }
    };

    const onSelectGenderPress = field => {
        selectGenderRef.current.blur();
        if (!disableEditFields) {
            setGenderChooserVisible(true);
        }
    };

    const onSelectCountryBirthPress = field => {
        selectCountryRef.current.blur();
        if (!disableEditFields) {
            selectCountry(field, t(COUNTRY_OF_BIRTH_LABEL));
        }
    };

    const onSelectCitenshipPress = field => {
        selectCitizenshipRef.current.blur();
        if (!disableEditFields) {
            selectCountry(field, t(CITIZENSHIP_LABEL));
        }
    };

    const onSelectCountryResidencePress = field => {
        selectCountryResidenceRef.current.blur();
        if (!disableEditFields) {
            selectCountry(field, t(RESIDENCE_COUNTRY_LABEL));
        }
    };

    const onSelectCityPress = field => {
        selectCityRef.current.blur();
        if (!disableEditFields) {
            selectCity(field, t(RESIDENCE_CITY_LABEL));
        }
    };

    const onSelectDocumentPress = field => {
        selectDocumentTypeRef.current.blur();
        if (!disableEditFields) {
            selectDocumentType(field, t(DOCUMENT_TYPE_LABEL));
        }
    };

    const onSelectPaymentPress = field => {
        selectPaymentCategoryRef.current.blur();
        if (!disableEditFields) {
            selectPaymentCategory(field, t(PAYMENT_CATEGORY_LABEL));
        }
    };

    const onSelectServicePress = field => {
        selectServiceTypeRef.current.blur();
        if (!disableEditFields) {
            selectServiceType(field, t(SERVICE_TYPE_LABEL));
        }
    };

    const onSelectArrivalOrgPress = field => {
        selectArrivalOrganizationRef.current.blur();
        if (!disableEditFields) {
            selectArrivalOrganization(field, t(SERVICE_TYPE_LABEL));
        }
    };

    const disabledArrivalDeparture =
        disableArrivalForCheckedIn || disableEditFields || disableArrivaleDepartureDateFields;

    const disabledDeparture = disableEditFields || disableArrivaleDepartureDateFields;

    const styleNotes = [
        focusNote && global.inputFocus,
        isSelfCheckin && focusNote && {backgroundColor: theme.colors.white},
        {
            minHeight: 60,
            borderWidth: 1,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 10,
            borderRadius: 10,
        },
    ];

    const getFacilityCityName = checkin => {
        let facilityCityName = null;
        if (
            checkin &&
            checkin.additional_info &&
            checkin.additional_info.facility &&
            checkin.additional_info.facility.Name
        ) {
            const facilityName = checkin.additional_info.facility.Name;
            const colonIndex = facilityName.indexOf(':');
            facilityCityName = colonIndex !== -1 ? facilityName.substring(0, colonIndex).trim() : facilityName.trim();
        }
        return facilityCityName;
    };

    useEffect(() => {
        existingGuest && setGuest(existingGuest);
    }, [existingGuest]);

    useEffect(() => {
        const initGuest = async () => {
            let preparedGuest = isNewGuest
                ? await initNewGuest(uuidv4(), checkin, guestData)
                : findGuestInCheckin(checkin, existingGuest?.[id]);
            //console.log('GuestForm checkin: ', checkin);
            preparedGuest = getContentFromExistingGuest(preparedGuest, existingGuest);
            preparedGuest = extendGuestPaymentCategory(preparedGuest, paymentCategories);
            if (preparedGuest) {
                setGuest(preparedGuest);
                registerFormFields(preparedGuest, register, setValue);
                setGuestMeta(checkin, preparedGuest, register, setValue);

                if (checkIfShouldDisableFields(preparedGuest[STATUS])) {
                    setDisableEditFields(true);
                }
                if (checkIfShouldDisableArrival(preparedGuest[STATUS])) {
                    setDisableArrivalForCheckedIn(true);
                }

                if (isSelfCheckin) {
                    const disableArrivaleDepartureDate = isManualGuestDate(checkin);
                    if (!disableArrivaleDepartureDate) {
                        setDisableArrivaleDepartureDateFields(true);
                    } else {
                        setValue(STAY_FROM_FIELD, null);
                        setValue(FORESEEN_STAY_UNTIL_FIELD, null);
                    }
                    const disableArrivaleTime = isManualGuestTime(checkin);
                    if (!disableArrivaleTime) {
                        setDisableArrivaleDepartureTimeFields(true);
                    } else {
                        setValue(TIME_STAY_FROM_FIELD, '');
                        setValue(TIME_ESTIMATED_STAY_UNTIL_FIELD, '');
                    }
                }

                if (manualInput) {
                    setCountryCity(checkin, countries, setValue);
                }
                const countryOfResidence = getValues(COUNTRY_OF_RESIDENCE);
                if (countryOfResidence === CROATIA_COUNTRY_CODE) {
                    setIsCroatianCity(true);
                }
                setValue(INITIAL_GUEST_FIELD, getValues());
                prepareSwipeButtonType(preparedGuest);
            }
        };
        if (checkin && paymentCategories) {
            initGuest().catch(console.error);
        }
    }, [checkin, existingGuest, paymentCategories, manualInput]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (toggleCheckinSwipeState) {
            preCommitHook().catch(console.error);
        }
    }, [toggleCheckinSwipeState]);

    useEffect(() => {
        if (saveButton) {
            navigation.setOptions({
                headerRight: () => saveButton,
            });
        } else {
            navigation.setOptions({
                headerRight: () => null,
            });
        }
    }, [saveButton]);

    // saveButton
    useEffect(() => {
        if (disableEditFields) {
            setSaveButton(null);
        } else if (isSelfCheckin) {
            selfCheckinSaveButton(
                <Button
                    title={t(SAVE_DATA)}
                    onPress={preCommitHook}
                    containerStyle={global.Button.containerStyle}
                    buttonStyle={global.Button.buttonStyle}
                    titleStyle={global.Button.titleStyle}
                />
            );
        } else {
            // header save button
            setSaveButton(
                <Pressable
                    onPress={preCommitHook}
                    style={({pressed}) => ({
                        opacity: pressed ? 0.5 : 1,
                        padding: 15,
                    })}>
                    {openProgress || fastCheckinInProgress ? (
                        <ActivityIndicator color={theme.colors.primary} style={[{marginRight: 8}, {maxHeight: 8}]} />
                    ) : (
                        <Text
                            style={{
                                fontFamily: theme.fontFamilyMedium,
                                color: theme.colors.primary,
                                textTransform: 'uppercase',
                            }}>
                            {t(SAVE_DATA)}
                        </Text>
                    )}
                </Pressable>
            );
        }
    }, [t(SAVE_DATA), isCroatianCity, disableEditFields, openProgress, fastCheckinInProgress, formError]);

    if (guest && eVisitorStaticData) {
        return (
            <View>
                {openProgress && (
                    <LinearProgress
                        color={theme.colors.primary}
                        variant="indeterminate"
                        style={{position: 'absolute', left: 0, top: -15}}
                    />
                )}
                <View style={[styles.timeFieldsWrapper, !isMobile && {flexDirection: 'row', paddingBottom: 15}]}>
                    <View style={styles.timeFields}>
                        <Controller
                            name={STAY_FROM_FIELD}
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label={t(ARRIVAL_DATE_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disabledArrivalDeparture}
                                    leftIcon={
                                        <Pressable
                                            onPress={_ => onStayFromDatePress(field)}
                                            style={({pressed}) => ({
                                                opacity: pressed ? 0.5 : 1,
                                            })}>
                                            <Icon type="ionicon" name={'calendar-outline'} size={18} />
                                        </Pressable>
                                    }
                                    leftIconContainerStyle={global.inputIconContainer}
                                    ref={fromDayRef}
                                    onFocus={_ => onStayFromDatePress(field)}
                                    value={getFormattedDateFromDateString(field.value ?? '')}
                                    errorMessage={errors[STAY_FROM_FIELD] ? errors[STAY_FROM_FIELD].message : null}
                                    containerStyle={[styles.timeFieldInputContainer]}
                                    style={[global.inputWithIcon, styles.timeFieldInput, IS_WEB && {cursor: 'pointer'}]}
                                />
                            )}
                        />

                        <Controller
                            name={TIME_STAY_FROM_FIELD}
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label={t(ARRIVAL_TIME_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disabledArrivalDeparture}
                                    leftIcon={
                                        <Pressable
                                            onPress={_ => onStayFromTimePress(field)}
                                            style={({pressed}) => ({
                                                opacity: pressed ? 0.5 : 1,
                                            })}>
                                            <Icon type="ionicon" name={'time-outline'} size={18} />
                                        </Pressable>
                                    }
                                    leftIconContainerStyle={global.inputIconContainer}
                                    value={field.value ?? ''}
                                    ref={fromTimeRef}
                                    onFocus={_ => onStayFromTimePress(field)}
                                    errorMessage={
                                        errors[TIME_STAY_FROM_FIELD] ? errors[TIME_STAY_FROM_FIELD].message : null
                                    }
                                    containerStyle={[styles.timeFieldInputContainer]}
                                    style={[global.inputWithIcon, styles.timeFieldInput, IS_WEB && {cursor: 'pointer'}]}
                                />
                            )}
                        />
                    </View>
                    <View style={styles.timeFields}>
                        <Controller
                            name={FORESEEN_STAY_UNTIL_FIELD}
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label={t(DEPARTURE_DATE_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disabledDeparture}
                                    leftIcon={
                                        <Pressable
                                            onPress={_ => onStayUntilDatePress(field)}
                                            style={({pressed}) => ({
                                                opacity: pressed ? 0.5 : 1,
                                            })}>
                                            <Icon type="ionicon" name={'calendar-outline'} size={18} />
                                        </Pressable>
                                    }
                                    leftIconContainerStyle={global.inputIconContainer}
                                    ref={toDayRef}
                                    onFocus={_ => onStayUntilDatePress(field)}
                                    value={getFormattedDateFromDateString(field.value ?? '')}
                                    errorMessage={
                                        errors[FORESEEN_STAY_UNTIL_FIELD]
                                            ? errors[FORESEEN_STAY_UNTIL_FIELD].message
                                            : null
                                    }
                                    containerStyle={[styles.timeFieldInputContainer]}
                                    style={[global.inputWithIcon, styles.timeFieldInput, IS_WEB && {cursor: 'pointer'}]}
                                />
                            )}
                        />

                        <Controller
                            name={TIME_ESTIMATED_STAY_UNTIL_FIELD}
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label={t(DEPARTURE_TIME_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disabledDeparture}
                                    leftIcon={
                                        <Pressable
                                            onPress={_ => onStayUntilTimePress(field)}
                                            style={({pressed}) => ({
                                                opacity: pressed ? 0.5 : 1,
                                            })}>
                                            <Icon type="ionicon" name={'time-outline'} size={18} />
                                        </Pressable>
                                    }
                                    leftIconContainerStyle={global.inputIconContainer}
                                    ref={toTimeRef}
                                    onFocus={_ => onStayUntilTimePress(field)}
                                    value={field.value ?? ''}
                                    errorMessage={
                                        errors[TIME_ESTIMATED_STAY_UNTIL_FIELD]
                                            ? errors[TIME_ESTIMATED_STAY_UNTIL_FIELD].message
                                            : null
                                    }
                                    containerStyle={[styles.timeFieldInputContainer]}
                                    style={[global.inputWithIcon, styles.timeFieldInput, IS_WEB && {cursor: 'pointer'}]}
                                />
                            )}
                        />
                    </View>
                </View>
                <View style={styles.formWrapper}>
                    <Controller
                        name={TOURIST_NAME}
                        render={({field}) => (
                            <Input
                                label={t(FIRST_NAME_LABEL)}
                                value={field.value}
                                disabled={disableEditFields}
                                onChangeText={text => onChangeField(text, TOURIST_NAME)}
                                errorMessage={errors[TOURIST_NAME] ? errors[TOURIST_NAME].message : null}
                                containerStyle={styles.inputContainer}
                                onFocus={turnOnNameFocus}
                                onBlur={turnOffNameFocus}
                                style={[focusName && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={TOURIST_SURNAME}
                        render={({field}) => (
                            <Input
                                label={t(LAST_NAME_LABEL)}
                                value={field.value}
                                disabled={disableEditFields}
                                onChangeText={text => onChangeField(text, TOURIST_SURNAME)}
                                errorMessage={errors[TOURIST_SURNAME] ? errors[TOURIST_SURNAME].message : null}
                                containerStyle={styles.inputContainer}
                                onFocus={turnOnSurnameFocus}
                                onBlur={turnOffSurnameFocus}
                                style={[focusSurname && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={GENDER_FIELD}
                        control={control}
                        defaultValue={false}
                        render={({field}) => (
                            <Input
                                label={t(GENDER_LABEL)}
                                showSoftInputOnFocus={false}
                                caretHidden={true}
                                disabled={disableEditFields}
                                value={mapGender(field.value, t)}
                                ref={selectGenderRef}
                                onFocus={_ => onSelectGenderPress(field)}
                                errorMessage={
                                    errors[GENDER_FIELD] ? errors[GENDER_FIELD]?.message?.substring(0, 30) : null
                                }
                                containerStyle={styles.inputContainer}
                            />
                        )}
                    />
                    {IS_WEB && (
                        <View style={{flex: 1, paddingHorizontal: 10, marginBottom: 12}}>
                            <Text style={[global.textCaps, {fontSize: 11}]}>{t(DATE_OF_BIRTH_LABEL)}</Text>
                            <Controller
                                name={DATE_OF_BIRTH_FIELD}
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <Input
                                        showSoftInputOnFocus={false}
                                        caretHidden={true}
                                        disabled={disableEditFields}
                                        ref={selectBirthRef}
                                        value={getFormattedDateFromDateString(field.value) ?? ''}
                                        onFocus={_ => onSelectBirthPress(field)}
                                        errorMessage={
                                            errors[DATE_OF_BIRTH_FIELD] ? errors[DATE_OF_BIRTH_FIELD].message : null
                                        }
                                        containerStyle={{padding: 0}}
                                    />
                                )}
                            />
                        </View>
                    )}

                    {!IS_WEB && (
                        <Controller
                            name={DATE_OF_BIRTH_FIELD}
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label={t(DATE_OF_BIRTH_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disableEditFields}
                                    leftIcon={
                                        <Pressable
                                            onPress={_ => onBirthDatePress(field)}
                                            style={({pressed}) => ({
                                                opacity: pressed ? 0.5 : 1,
                                            })}>
                                            <Icon type="ionicon" name={'calendar'} size={18} />
                                        </Pressable>
                                    }
                                    leftIconContainerStyle={global.inputIconContainer}
                                    value={getFormattedDateFromDateString(field.value ?? '')}
                                    ref={birthRef}
                                    onFocus={_ => onBirthDatePress(field)}
                                    errorMessage={
                                        errors[DATE_OF_BIRTH_FIELD]
                                            ? errors[DATE_OF_BIRTH_FIELD]?.message?.substring(0, 30)
                                            : null
                                    }
                                    containerStyle={styles.inputContainer}
                                    style={[global.inputWithIcon, styles.timeFieldInput]}
                                />
                            )}
                        />
                    )}

                    <Controller
                        name={COUNTRY_OF_BIRTH}
                        render={({field}) => (
                            <Input
                                label={t(COUNTRY_OF_BIRTH_LABEL)}
                                showSoftInputOnFocus={false}
                                caretHidden={true}
                                disabled={disableEditFields}
                                ref={selectCountryRef}
                                value={mapCountryCodeToCountry(field.value, countries)?.[countryName] ?? ''}
                                onFocus={_ => onSelectCountryBirthPress(field)}
                                errorMessage={errors[COUNTRY_OF_BIRTH] ? errors[COUNTRY_OF_BIRTH].message : null}
                                containerStyle={styles.inputContainer}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={CITIZENSHIP}
                        render={({field}) => (
                            <Input
                                label={t(CITIZENSHIP_LABEL)}
                                showSoftInputOnFocus={false}
                                caretHidden={true}
                                disabled={disableEditFields}
                                ref={selectCitizenshipRef}
                                value={mapCountryCodeToCountry(field.value, countries)?.[countryName] ?? ''}
                                onFocus={_ => onSelectCitenshipPress(field)}
                                errorMessage={errors[CITIZENSHIP] ? errors[CITIZENSHIP].message : null}
                                containerStyle={styles.inputContainer}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={COUNTRY_OF_RESIDENCE}
                        render={({field}) => (
                            <Input
                                label={t(RESIDENCE_COUNTRY_LABEL)}
                                showSoftInputOnFocus={false}
                                caretHidden={true}
                                disabled={disableEditFields}
                                ref={selectCountryResidenceRef}
                                value={mapCountryCodeToCountry(field.value, countries)?.[countryName] ?? ''}
                                onFocus={_ => onSelectCountryResidencePress(field)}
                                errorMessage={
                                    errors[COUNTRY_OF_RESIDENCE] ? errors[COUNTRY_OF_RESIDENCE].message : null
                                }
                                containerStyle={styles.inputContainer}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={CITY_OF_RESIDENCE}
                        render={({field}) =>
                            isCroatianCity ? (
                                <Input
                                    label={t(RESIDENCE_CITY_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disableEditFields}
                                    value={cities.find(city => city[NAME] === field.value)?.[NAME] ?? ''}
                                    ref={selectCityRef}
                                    onFocus={_ => onSelectCityPress(field)}
                                    errorMessage={errors[CITY_OF_RESIDENCE] ? errors[CITY_OF_RESIDENCE].message : null}
                                    containerStyle={[
                                        styles.inputContainer,
                                        !isSelfCheckin & !isMobile && {width: '100%'},
                                    ]}
                                />
                            ) : (
                                <Input
                                    label={t(RESIDENCE_CITY_LABEL)}
                                    value={field.value}
                                    disabled={disableEditFields}
                                    onChangeText={text => setValue(CITY_OF_RESIDENCE, text)}
                                    errorMessage={errors[CITY_OF_RESIDENCE] ? errors[CITY_OF_RESIDENCE].message : null}
                                    containerStyle={[
                                        styles.inputContainer,
                                        !isSelfCheckin & !isMobile && {width: '100%'},
                                    ]}
                                />
                            )
                        }
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={DOCUMENT_TYPE}
                        render={({field}) => (
                            <Input
                                label={t(DOCUMENT_TYPE_LABEL)}
                                showSoftInputOnFocus={false}
                                caretHidden={true}
                                disabled={disableEditFields}
                                ref={selectDocumentTypeRef}
                                value={mapTypeCodeToObject(field.value, documentTypes)?.[NAME] ?? ''}
                                onFocus={_ => onSelectDocumentPress(field)}
                                errorMessage={errors[DOCUMENT_TYPE] ? errors[DOCUMENT_TYPE].message : null}
                                containerStyle={styles.inputContainer}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={DOCUMENT_NUMBER}
                        render={({field}) => (
                            <Input
                                label={t(DOCUMENT_CODE_LABEL)}
                                value={field.value}
                                disabled={disableEditFields}
                                onChangeText={text => onChangeField(text, DOCUMENT_NUMBER)}
                                errorMessage={errors[DOCUMENT_NUMBER] ? errors[DOCUMENT_NUMBER].message : null}
                                containerStyle={styles.inputContainer}
                                onFocus={_ => setDocumentNumberFocus(true)}
                                onBlur={_ => setDocumentNumberFocus(false)}
                                style={[focusDocumentNumber && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    {!isSelfCheckin && (
                        <Controller
                            name={PAYMENT_CATEGORY}
                            render={({field}) => (
                                <Input
                                    label={t(PAYMENT_CATEGORY_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disableEditFields}
                                    ref={selectPaymentCategoryRef}
                                    value={mapTypeCodeToObject(field.value, paymentCategories)?.[CODE_NAMES] ?? ''}
                                    onFocus={_ => onSelectPaymentPress(field)}
                                    errorMessage={errors[PAYMENT_CATEGORY] ? errors[PAYMENT_CATEGORY].message : null}
                                    containerStyle={[
                                        styles.inputContainer,
                                        !isSelfCheckin & !isMobile && {width: '100%'},
                                    ]}
                                />
                            )}
                            control={control}
                            defaultValue=""
                        />
                    )}
                    {!isSelfCheckin && (
                        <Controller
                            name={OFFERED_SERVICE_TYPE}
                            render={({field}) => (
                                <Input
                                    label={t(SERVICE_TYPE_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disableEditFields}
                                    ref={selectServiceTypeRef}
                                    value={mapNameToObject(field.value, serviceTypes)?.[NAME] ?? ''}
                                    onFocus={_ => onSelectServicePress(field)}
                                    errorMessage={
                                        errors[OFFERED_SERVICE_TYPE] ? errors[OFFERED_SERVICE_TYPE].message : null
                                    }
                                    containerStyle={[
                                        styles.inputContainer,
                                        !isSelfCheckin & !isMobile && {width: '100%', marginRight: 40},
                                    ]}
                                />
                            )}
                            control={control}
                            defaultValue=""
                        />
                    )}
                    {!isSelfCheckin && (
                        <Controller
                            name={ARRIVAL_ORGANIZATION}
                            render={({field}) => (
                                <Input
                                    label={t(TRAVEL_ARRANGEMENTS_LABEL)}
                                    showSoftInputOnFocus={false}
                                    caretHidden={true}
                                    disabled={disableEditFields}
                                    ref={selectArrivalOrganizationRef}
                                    value={mapTypeCodeMIToObject(field.value, arrivalOrganisations)?.[NAME] ?? ''}
                                    onFocus={_ => onSelectArrivalOrgPress(field)}
                                    errorMessage={
                                        errors[ARRIVAL_ORGANIZATION] ? errors[ARRIVAL_ORGANIZATION].message : null
                                    }
                                    containerStyle={[
                                        styles.inputContainer,
                                        !isSelfCheckin & !isMobile && {width: '100%'},
                                    ]}
                                />
                            )}
                            control={control}
                            defaultValue=""
                        />
                    )}

                    {/* <Controller
                        name={ROOM_NUMBER}
                        render={({field}) => (
                            <Input
                                label={t(ROOM_NUMBER_LABEL)}
                                value={field.value}
                                disabled={disableEditFields}
                                onChangeText={text => onChangeField(text, ROOM_NUMBER)}
                                errorMessage={errors[ROOM_NUMBER] ? errors[ROOM_NUMBER].message : null}
                                containerStyle={styles.inputContainer}
                                onFocus={turnOnRoomNumberFocus}
                                onBlur={turnOffRoomNumberFocus}
                                style={[focusRoomNumber && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    /> */}

                    <Controller
                        name={GUEST_NOTE_FIELD}
                        render={({field}) => (
                            <Input
                                label={t(NOTE)}
                                multiline={true}
                                numberOfLines={2}
                                disabled={disableEditFields}
                                textAlignVertical="top"
                                onFocus={turnOnNoteFocus}
                                onBlur={turnOffNoteFocus}
                                value={field.value}
                                onChangeText={text => onChangeField(text, GUEST_NOTE_FIELD)}
                                style={styleNotes}
                                containerStyle={[
                                    styles.inputContainer,
                                    !isSelfCheckin && !isMobile && {width: '100%'},
                                    {marginTop: 10},
                                ]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                </View>
                <DateTimePicker
                    closed={closeDateTimePicker}
                    setClosed={setCloseDateTimePicker}
                    onChangeDate={onChangeDate}
                    onChangeTime={onChangeTime}
                    datePickerData={datePickerData}
                />
                {genderChooserVisible && (
                    <GenderChooser
                        genderChooserVisible={genderChooserVisible}
                        setGenderChooserVisible={setGenderChooserVisible}
                        setGender={handleGenderChange}
                    />
                )}
                <ModalChooser
                    visibleModal={visibleModal}
                    setVisibleModal={setVisibleModal}
                    chooserData={modalChooserData}
                />
                {isFocused && (
                    <MessageDialog
                        message={generateMessage()}
                        isError={apiErrors}
                        open={openMessage}
                        handleOpen={setOpenMessage}
                    />
                )}
                <ConfirmDialog
                    title={t(CONFIRM_GUEST_TITLE)}
                    open={confirmGuestOpen}
                    setOpen={setConfirmGuestOpen}
                    onConfirm={continueGuestAccepted}
                    content={t(GUEST_EDIT_WARNING)}
                />
                {showConsentDialog && (
                    <ConsentDialog
                        open={showConsentDialog}
                        setOpen={setShowConsentDialog}
                        consentAccepted={consentAccepted}
                        disableButton={setDisableButton}
                        isGuest={true}
                        callBackData={consentCallbackData}
                    />
                )}
            </View>
        );
    } else
        return (
            <View>
                <LinearProgress color={theme.colors.primary} variant="indeterminate" />
            </View>
        );
};

export default GuestForm;
