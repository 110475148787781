import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, View} from 'react-native';
import {CheckBox, Icon, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import HtmlDialog, {PRIVACY_VIEW, TERMS_VIEW} from '../../components/htmlviews/HtmlDialog';
import {
    AGREE,
    AND,
    PRIVACY_TITLE,
    PRIVOLA_2,
    PRIVOLA_3,
    PRIVOLA_4,
    PRIVOLA_5,
    PRIVOLA_6,
    PRIVOLA_7,
    PRIVOLA_8,
    PRIVOLA_9,
    PRIVOLA_CONTENT_TITLE,
    TERMS_TITLE,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import useStyles from './ConsentDialog.styles';

const isWeb = Platform.OS === 'web';

const ConsentDialog = ({
    open,
    setOpen,
    consentAccepted,
    disableButton = null,
    isGuest = false,
    callBackData = null,
}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [mainPrivola, setMainPrivola] = useState(true);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [state, setState] = useState({
        privolaTwo: false,
        privolaThree: false,
        privolaFour: false,
        privolaFive: false,
    });

    const {privolaTwo, privolaThree, privolaFour, privolaFive} = state;

    const handleChange = privola => {
        setState({...state, [privola]: !state[privola]});
    };

    const onRejectPrivola = () => {
        setState({
            privolaTwo: false,
            privolaThree: false,
            privolaFour: false,
            privolaFive: false,
        });
        setMainPrivola(false);
    };

    const closeAndReject = () => {
        setOpen(false);
        onRejectPrivola();
    };

    const closeAndAccept = async () => {
        setOpen(false);
        setMainPrivola(true);
        await consentAccepted(true, callBackData);
    };

    const onPressTermsOfUseCheckBox = () => {
        setOpen(true);
    };

    const termsPreview = () => {
        setShowTerms(true);
    };

    const privacyPreview = () => {
        setShowPrivacy(true);
    };

    const termsOfUseComponent = (
        <Text style={[global.textMain, {marginBottom: 25, textAlign: 'center', fontFamily: theme.fontFamilyMedium}]}>
            <Text>{t(AGREE)}</Text>
            <Text style={[global.link, isWeb && {cursor: 'pointer'}]} onPress={termsPreview}>
                {' '}
                {t(TERMS_TITLE)}{' '}
            </Text>
            {t(AND)}
            <Text style={[global.link, isWeb && {cursor: 'pointer'}]} onPress={privacyPreview}>
                {' '}
                {t(PRIVACY_TITLE)}
            </Text>

            {showTerms && <HtmlDialog isOpen={showTerms} setOpen={setShowTerms} view={TERMS_VIEW} />}
            {showPrivacy && <HtmlDialog isOpen={showPrivacy} setOpen={setShowPrivacy} view={PRIVACY_VIEW} />}
        </Text>
    );

    useEffect(() => {
        const {privolaTwo, privolaThree, privolaFour, privolaFive} = state;
        const consentCurrentlyAccepted =
            [privolaTwo, privolaThree, privolaFour, privolaFive].filter(v => v).length === 4;
        if (consentCurrentlyAccepted) {
            closeAndAccept().catch(console.error);
        } else {
            disableButton && disableButton(true);
        }
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!mainPrivola) {
            disableButton && disableButton(true);
        }
    }, [mainPrivola]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            isVisible={open}
            onBackdropPress={closeAndReject}
            onBackButtonPress={closeAndReject}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(PRIVOLA_CONTENT_TITLE)}</Text>
                    <Pressable onPress={closeAndReject} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={global.modalContent}>
                    <Text onPress={onPressTermsOfUseCheckBox} />
                    {termsOfUseComponent}
                    <Text />
                    <CheckBox
                        title={isGuest ? t(PRIVOLA_2) : t(PRIVOLA_6)}
                        checked={privolaTwo}
                        onPress={_ => handleChange('privolaTwo')}
                        containerStyle={styles.modalConsentCheckbox}
                        fontFamily={'Montserrat-Medium'}
                        textStyle={styles.modalConsentCheckboxText}
                        size={21}
                    />
                    <CheckBox
                        title={isGuest ? t(PRIVOLA_3) : t(PRIVOLA_7)}
                        checked={privolaThree}
                        onPress={_ => handleChange('privolaThree')}
                        containerStyle={styles.modalConsentCheckbox}
                        fontFamily={'Montserrat-Medium'}
                        textStyle={styles.modalConsentCheckboxText}
                        size={21}
                    />
                    <CheckBox
                        title={isGuest ? t(PRIVOLA_4) : t(PRIVOLA_8)}
                        checked={privolaFour}
                        onPress={_ => handleChange('privolaFour')}
                        containerStyle={styles.modalConsentCheckbox}
                        fontFamily={'Montserrat-Medium'}
                        textStyle={styles.modalConsentCheckboxText}
                        size={21}
                    />
                    <CheckBox
                        title={isGuest ? t(PRIVOLA_5) : t(PRIVOLA_9)}
                        checked={privolaFive}
                        onPress={_ => handleChange('privolaFive')}
                        containerStyle={[styles.modalConsentCheckbox, {marginBottom: 0}]}
                        fontFamily={'Montserrat-Medium'}
                        textStyle={styles.modalConsentCheckboxText}
                        size={21}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default ConsentDialog;
