import {yupResolver} from '@hookform/resolvers/yup';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Pressable, ScrollView, View, useWindowDimensions} from 'react-native';
import {Button, ButtonGroup, CheckBox, Icon, Input, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    ADDRESS,
    APP_LANGS_SETTINGS,
    BACK,
    CONFIRM,
    COUNTRY_DATA,
    CREATE,
    EDIT,
    INVOICE_ACC_UNIT_LABEL,
    INVOICE_CURRENCY_LABEL,
    INVOICE_CUSTOMER_TYPE_COMPANY_ADDR_LABEL,
    INVOICE_CUSTOMER_TYPE_COMPANY_LABEL,
    INVOICE_CUSTOMER_TYPE_GUEST_ADDR_LABEL,
    INVOICE_CUSTOMER_TYPE_GUEST_LABEL,
    INVOICE_PRICE_COUNT_LABEL,
    INVOICE_PRICE_TYPE_PER_NIGHT_LABEL,
    INVOICE_PRICE_TYPE_PRICE_TOTAL_LABEL,
    INVOICE_DATE_LABEL,
    INVOICE_FINAL_NOTES_LABEL,
    INVOICE_FORM_ACC_UNIT,
    INVOICE_FORM_CLIENT,
    INVOICE_FORM_COMPANY_OIB,
    INVOICE_FORM_COMPANY_TYPE,
    INVOICE_FORM_CURRENCY,
    INVOICE_FORM_CUSTOMER_TYPE,
    INVOICE_FORM_DATE,
    INVOICE_FORM_DISCOUNT_TOTAL_SUM,
    INVOICE_FORM_ELECTRONIC_INVOICE,
    INVOICE_FORM_FOOTER_TEXT,
    INVOICE_FORM_GUEST,
    INVOICE_FORM_GUEST_ADDRESS,
    INVOICE_FORM_GUEST_STAY_RANGE,
    INVOICE_FORM_GUEST_TYPE,
    INVOICE_FORM_IN_NAME,
    INVOICE_FORM_IN_NAME_ADDRESS,
    INVOICE_FORM_IN_NAME_OIB,
    INVOICE_FORM_LANG,
    INVOICE_FORM_NUMBER,
    INVOICE_FORM_OPERATOR_NAME,
    INVOICE_FORM_OPERATOR_REF,
    INVOICE_FORM_OVERNIGHT_COUNT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE,
    INVOICE_FORM_OVERNIGHT_PDV_AMOUNT,
    INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE,
    INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV,
    INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV,
    INVOICE_FORM_OWNER,
    INVOICE_FORM_OWNER_ADDRESS,
    INVOICE_FORM_OWNER_OIB,
    INVOICE_FORM_PAYMENT_TYPE,
    INVOICE_FORM_PDV_TOTAL_SUM,
    INVOICE_FORM_PRICE_FINAL,
    INVOICE_FORM_PRICE_PER_NIGHT,
    INVOICE_FORM_PRICE_TOTAL_LABEL,
    INVOICE_FORM_SERVICE_TYPE,
    INVOICE_FORM_SHOW_FOOTER,
    INVOICE_FORM_SHOW_OPERATER,
    INVOICE_FORM_STAY_FROM,
    INVOICE_FORM_STAY_TO,
    INVOICE_FORM_TIME,
    INVOICE_FORM_TOURIST_TAX_ADULT_COUNT,
    INVOICE_FORM_TOURIST_TAX_ADULT_COUNT_LABEL,
    INVOICE_FORM_TOURIST_TAX_ADULT_RATE,
    INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL,
    INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL_LABEL,
    INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED,
    INVOICE_FORM_TOURIST_TAX_CHILD_COUNT,
    INVOICE_FORM_TOURIST_TAX_CHILD_COUNT_LABEL,
    INVOICE_FORM_TOURIST_TAX_CHILD_RATE,
    INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL,
    INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL_LABEL,
    INVOICE_FORM_TOURIST_TAX_RATE_LABEL,
    INVOICE_FORM_TOURIST_TAX_TOTAL,
    INVOICE_FORM_USER_IN_PDV_SYSTEM,
    CALCULATION_PRICE_TYPE,
    INVOICE_FORM_VER,
    INVOICE_IN_NAME,
    INVOICE_OIB,
    INVOICE_OWNER_ADDR_LABEL,
    INVOICE_OWNER_LABEL,
    INVOICE_PAYMENT_TYPE_LABEL,
    INVOICE_PRICE_DISCOUNT_LABEL,
    INVOICE_PRICE_FINAL_LABEL,
    INVOICE_PRICE_PER_NIGHT_LABEL,
    INVOICE_QUANTITY,
    INVOICE_SERVICE_TYPE_LABEL,
    INVOICE_SHOW_FOOTER_LABEL,
    INVOICE_SHOW_OPERATER_LABEL,
    INVOICE_SOJOURN_TAX_TOTAL_LABEL,
    INVOICE_TIME_LABEL,
    NAME,
    NEXT,
    OIB,
    OVERNIGHT,
    OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV,
    PDV,
    PDV_PERCENTAGE,
    STORAGE_USER_DATA,
    TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM,
    USER_DATA_ELECTRONIC_INVOICE_OPT,
    USER_DATA_INV_OPERATOR,
    USER_DATA_INV_OPERATOR_ID,
    USER_DATA_IN_PDV_SYSTEM,
    USER_DATA_SOJOURN_TAX,
    USER_INFO,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import globalStyle from '../../theme/globalStyle';
import {
    getDateFromDateString,
    getDateString,
    getHigherDateBoundary,
    getShortFormattedDateFromDateString,
    getStringDate,
    getStringFormattedDate,
    getStringTime,
} from '../../utils/dateHelper';
import {getListOfGuests} from '../../utils/guestUtils';
import {
    getBooleanTrueDefault,
    isCompanySelected,
    isGuestSelected,
    isPricePerNightSelected,
    isFinalPriceSelected,
    isStringEmptyOrNull,
} from '../../utils/helpers';
import {ADULT_TAX_PRICE, DEFAULT_CURRENCY} from '../../utils/invoice/invoice';
import {
    convertAndCalculateLegacyData,
    getCustomerTypeIndex,
    getPriceTypeIndex,
    getOwnersInfo,
    getTaxRates,
    getTouristTaxCategories,
    updateStayDates,
} from '../../utils/invoice/invoice.functions';
import {
    NUMERIC_WITH_ZERO,
    OVERNIGHT_PDV_PERCENTAGE,
    fixNaNValues,
    handleOvernightPriceChanges,
    handleTouristTaxChanges,
    updateFinalPrice,
    handleFinalPriceChanges,
} from '../../utils/prices';
import {getInvoiceUserData, getUserData, setInvoiceLastSettings} from '../../utils/userUtils';
import LangSelect from '../LangSelect';
import FormDateTimePicker from '../datetime/FormDateTimePicker';
import ClientListSelect from './ClientListSelect';
import CurrencySelect from './CurrencySelect';
import schema from './EditInvoiceDialog.schema';
import useStyles from './EditInvoiceDialog.styles';
import InvoiceNumberEditor from './InvoiceNumberEditor';
import OwnerListSelect from './OwnerListSelect';
import PaymentTypeSelect from './PaymentTypeSelect';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export const legacyFieldMapping = {
    INVOICE_FORM_TOURIST_TAX: INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED,
    INVOICE_FORM_PRICE_DISCOUNT: INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE,
    INVOICE_FORM_PRICE_TOTAL: INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV,
    INVOICE_FORM_PRICE_OVERNIGHT_TOTAL: INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV, // ??? how come same field
    INVOICE_FORM_PRICE_PDV: INVOICE_FORM_OVERNIGHT_PDV_AMOUNT,
    INVOICE_FORM_PRICE_OVERNIGHT_TOTAL_WITH_PDV: INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV,
    INVOICE_FORM_GUEST_DAYS: INVOICE_FORM_OVERNIGHT_COUNT,
    INVOICE_FORM_SOJOURN_TAX_ADULT_COUNT: INVOICE_FORM_TOURIST_TAX_ADULT_COUNT,
    INVOICE_FORM_SOJOURN_TAX_CHILD_COUNT: INVOICE_FORM_TOURIST_TAX_CHILD_COUNT,
    INVOICE_FORM_SOJOURN_TAX_ADULT: INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL,
    INVOICE_FORM_SOJOURN_TAX_CHILD: INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL,
    INVOICE_FORM_ADULT_TAX_RATE: INVOICE_FORM_TOURIST_TAX_ADULT_RATE,
    INVOICE_FORM_CHILD_TAX_RATE: INVOICE_FORM_TOURIST_TAX_CHILD_RATE,
    INVOICE_FORM_PRICE_PDV_PERCENTAGE: INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE,
    INVOICE_FORM_SOJOURN_TAX: INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED,
    INVOICE_FORM_PRICE_DISCOUNT_TOTAL_SUM: INVOICE_FORM_DISCOUNT_TOTAL_SUM,
};

const formFields = [
    INVOICE_FORM_NUMBER,
    INVOICE_FORM_LANG,
    INVOICE_FORM_OWNER,
    INVOICE_FORM_OWNER_OIB,
    INVOICE_FORM_OWNER_ADDRESS,
    INVOICE_FORM_DATE,
    INVOICE_FORM_TIME,
    INVOICE_FORM_GUEST,
    INVOICE_FORM_GUEST_ADDRESS,
    INVOICE_FORM_SERVICE_TYPE,
    INVOICE_FORM_ACC_UNIT,
    INVOICE_FORM_PAYMENT_TYPE,
    INVOICE_FORM_CURRENCY,
    INVOICE_FORM_PRICE_PER_NIGHT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE,
    INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV,
    INVOICE_FORM_OVERNIGHT_PDV_AMOUNT,
    INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV,
    INVOICE_FORM_PRICE_FINAL,
    INVOICE_FORM_STAY_FROM,
    INVOICE_FORM_STAY_TO,
    INVOICE_FORM_OVERNIGHT_COUNT,
    INVOICE_FORM_GUEST_STAY_RANGE,
    INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED,
    INVOICE_FORM_TOURIST_TAX_ADULT_COUNT,
    INVOICE_FORM_TOURIST_TAX_CHILD_COUNT,
    INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL,
    INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL,
    INVOICE_FORM_CLIENT,
    INVOICE_FORM_TOURIST_TAX_ADULT_RATE,
    INVOICE_FORM_TOURIST_TAX_CHILD_RATE,
    INVOICE_FORM_COMPANY_OIB,
    INVOICE_FORM_FOOTER_TEXT,
    INVOICE_FORM_IN_NAME,
    INVOICE_FORM_IN_NAME_OIB,
    INVOICE_FORM_IN_NAME_ADDRESS,
    INVOICE_FORM_USER_IN_PDV_SYSTEM,
    INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE,
    INVOICE_FORM_TOURIST_TAX_TOTAL,
    CALCULATION_PRICE_TYPE,
    INVOICE_FORM_ELECTRONIC_INVOICE,
    INVOICE_FORM_SHOW_OPERATER,
    INVOICE_FORM_SHOW_FOOTER,
    INVOICE_FORM_VER,
    INVOICE_FORM_PDV_TOTAL_SUM,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT,
    INVOICE_FORM_DISCOUNT_TOTAL_SUM,
    TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM,
    OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV,
];

const EditInvoiceDialog = ({open, setOpen, onConfirm, invoice, evaccounts, isExistingInvoice, checkin}) => {
    const {eVisitorStaticData} = useContext(DataContext);
    const {i18n, t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [selectedCustomerTypeIndex, setSelectedCustomerTypeIndex] = useState(0);
    const [selectedPriceTypeIndex, setSelectedPriceTypeIndex] = useState(0);
    const [initialInvoiceFormNumber, setInitialInvoiceFormNumber] = useState(null);
    const [clientList, setClientList] = useState([]);
    const [ownerCallbackField, setOwnerCallbackField] = useState(INVOICE_FORM_OWNER);
    const [userDataInPdvSystem, setUserDataInPdvSystem] = useState(true);
    const [userDataSojournTax, setUserDataSojournTax] = useState(true);
    const [userDataElectronicInvoice, setUserDataElectronicInvoice] = useState(true);
    const [userDataShowOperator, setUserDataShowOperator] = useState(true);
    const [userDataShowFooter, setUserDataShowFooter] = useState(true);
    const [page, setPage] = useState(0);
    const [ownerInfo, setOwnerInfo] = useState(null);

    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;

    const countries = useMemo(() => eVisitorStaticData && eVisitorStaticData?.[COUNTRY_DATA], [eVisitorStaticData]);

    const dateRef = useRef(null);
    const timeRef = useRef(null);
    const paymentTypeRef = useRef(null);

    const handleClose = () => {
        setPage(0);
        setOpen(false);
    };

    const incrementPage = () => {
        setPage(page + 1);
    };

    const decrementPage = () => {
        setPage(page - 1);
    };

    const customerTypeButton1 = () => (
        <Text style={{fontSize: 14, fontFamily: theme.fontFamilyMedium}}>{t(INVOICE_CUSTOMER_TYPE_GUEST_LABEL)}</Text>
    );
    const customerTypeButton2 = () => (
        <Text style={{fontSize: 14, fontFamily: theme.fontFamilyMedium}}>{t(INVOICE_CUSTOMER_TYPE_COMPANY_LABEL)}</Text>
    );
    const customerTypeButtons = [{element: customerTypeButton1}, {element: customerTypeButton2}];
    const updateCustomerTypeIndex = idx => {
        setSelectedCustomerTypeIndex(idx);
    };
    useEffect(() => {
        if (isCompanySelected(selectedCustomerTypeIndex)) {
            setValue(INVOICE_FORM_CUSTOMER_TYPE, INVOICE_FORM_COMPANY_TYPE);
            setClientList([]);
        } else if (isGuestSelected(selectedCustomerTypeIndex)) {
            setValue(INVOICE_FORM_CUSTOMER_TYPE, INVOICE_FORM_GUEST_TYPE);
            setClientList(getListOfGuests(checkin, countries, i18n.language));
        }
    }, [selectedCustomerTypeIndex, checkin]);

    useEffect(() => {
        if (invoice && evaccounts) {
            getUserData().then(data => {
                const ownerInfo = data?.[STORAGE_USER_DATA]?.[USER_INFO];
                ownerInfo && setOwnerInfo(ownerInfo);
                initForm(ownerInfo)
                    .catch(console.error)
                    .then(_ => {
                        setSelectedCustomerTypeIndex(getCustomerTypeIndex(invoice?.[INVOICE_FORM_CUSTOMER_TYPE]));
                        setSelectedPriceTypeIndex(getPriceTypeIndex(invoice?.[CALCULATION_PRICE_TYPE]));
                    });
            });
        }
    }, [invoice, evaccounts, checkin]);

    const priceTypeButton1 = () => (
        <Text style={{fontSize: 14, fontFamily: theme.fontFamilyMedium}}>{t(INVOICE_PRICE_TYPE_PER_NIGHT_LABEL)}</Text>
    );
    const priceTypeButton2 = () => (
        <Text style={{fontSize: 14, fontFamily: theme.fontFamilyMedium}}>
            {t(INVOICE_PRICE_TYPE_PRICE_TOTAL_LABEL)}
        </Text>
    );
    const priceTypeButtons = [{element: priceTypeButton1}, {element: priceTypeButton2}];

    const updatePriceTypeIndex = idx => {
        setSelectedPriceTypeIndex(idx);
        setValue(CALCULATION_PRICE_TYPE, String(idx));
    };

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onClickConfirm = _ => {
        handleSubmit(onSubmit, onError)();
    };

    const onSubmit = async (data, e) => {
        await onConfirm(data);
        await setInvoiceLastSettings({
            [INVOICE_FORM_USER_IN_PDV_SYSTEM]: String(userDataInPdvSystem),
            [INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED]: String(userDataSojournTax),
            [INVOICE_FORM_ELECTRONIC_INVOICE]: String(userDataElectronicInvoice),
            [INVOICE_FORM_SHOW_OPERATER]: String(userDataShowOperator),
            [INVOICE_FORM_SHOW_FOOTER]: String(userDataShowFooter),
            [INVOICE_FORM_TOURIST_TAX_ADULT_RATE]: String(
                data?.[INVOICE_FORM_TOURIST_TAX_ADULT_RATE] ?? ADULT_TAX_PRICE
            ),
            [CALCULATION_PRICE_TYPE]: String(data?.[CALCULATION_PRICE_TYPE]),
        });
        handleClose();
    };

    const onError = (errors, e) => {
        console.log(errors, e);
    };

    const initForm = async ownerInfo => {
        convertAndCalculateLegacyData(invoice);

        for (let formKey of formFields) {
            register(formKey);
            const existingData = invoice?.[formKey];
            if (existingData) {
                setValue(formKey, existingData);
            } else {
                setValue(formKey, '');
            }
        }

        // set default values
        const invoiceDate = getValues(INVOICE_FORM_DATE);
        if (isStringEmptyOrNull(invoiceDate)) {
            setValue(INVOICE_FORM_DATE, getStringFormattedDate(new Date()));
        }
        const invoiceTime = getValues(INVOICE_FORM_TIME);
        if (isStringEmptyOrNull(invoiceTime)) {
            setValue(INVOICE_FORM_TIME, getStringTime(new Date()));
        }
        const invoiceCurrency = getValues(INVOICE_FORM_CURRENCY);
        if (isStringEmptyOrNull(invoiceCurrency)) {
            setValue(INVOICE_FORM_CURRENCY, DEFAULT_CURRENCY);
        }
        const invoiceOwner = getValues(INVOICE_FORM_OWNER);
        if (isStringEmptyOrNull(invoiceOwner) && evaccounts) {
            const owners = getOwnersInfo(evaccounts, ownerInfo);
            if (owners && owners[0]) {
                onOwnerSelected(owners[0]);
            }
        }
        if (invoice?.[INVOICE_FORM_NUMBER]) {
            setInitialInvoiceFormNumber(invoice?.[INVOICE_FORM_NUMBER].split('-'));
        }
        const touristTaxRate = getValues(INVOICE_FORM_TOURIST_TAX_ADULT_RATE);
        if (!touristTaxRate) {
            setValue(INVOICE_FORM_TOURIST_TAX_ADULT_RATE, ADULT_TAX_PRICE);
        }

        // fix old values if NaN or pdv switch has not been set
        [
            'INVOICE_FORM_TOURIST_TAX_ADULT_SUM',
            'INVOICE_FORM_TOURIST_TAX_ADULT_COUNT',
            'INVOICE_FORM_TOURIST_TAX_CHILD_SUM',
            'INVOICE_FORM_TOURIST_TAX_CHILD_COUNT',
        ].map(item => {
            fixNaNValues(item, invoice, getValues, setValue);
        });

        const invoiceVersion = getValues(INVOICE_FORM_VER);
        if (isStringEmptyOrNull(invoiceVersion)) {
            setValue(INVOICE_FORM_VER, 2);
        }

        const operatorName = getValues(INVOICE_FORM_OPERATOR_NAME);
        const operatorRef = getValues(INVOICE_FORM_OPERATOR_REF);
        if (isStringEmptyOrNull(operatorName) && isStringEmptyOrNull(operatorRef)) {
            const {operatorData} = await getInvoiceUserData();
            if (operatorData) {
                setValue(INVOICE_FORM_OPERATOR_NAME, operatorData?.[USER_DATA_INV_OPERATOR] ?? '');
                setValue(INVOICE_FORM_OPERATOR_REF, operatorData?.[USER_DATA_INV_OPERATOR_ID] ?? '');
            }
        }

        const userInPdvSystem = getBooleanTrueDefault(getValues(INVOICE_FORM_USER_IN_PDV_SYSTEM));
        setUserDataInPdvSystem(userInPdvSystem);
        const soujournTax = getBooleanTrueDefault(getValues(INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED));
        setUserDataSojournTax(soujournTax);
        const electronicInvoice = getBooleanTrueDefault(getValues(INVOICE_FORM_ELECTRONIC_INVOICE));
        setUserDataElectronicInvoice(electronicInvoice);
        const showOperator = getBooleanTrueDefault(getValues(INVOICE_FORM_SHOW_OPERATER));
        setUserDataShowOperator(showOperator);
        const showFooter = getBooleanTrueDefault(getValues(INVOICE_FORM_SHOW_FOOTER));
        setUserDataShowFooter(showFooter);

        //console.log(getValues());
    };

    const onChangeField = (data, field) => {
        // reset in name field if manual input
        if (field === INVOICE_FORM_IN_NAME) {
            setValue(INVOICE_FORM_IN_NAME_ADDRESS, '');
            setValue(INVOICE_FORM_IN_NAME_OIB, '');
        }
        setValue(field, data);
    };

    const onOvernightPriceChangeField = (text, field) => {
        let newText = '';
        const allowedNumbers = NUMERIC_WITH_ZERO;
        // break if string contains multiple .
        if (text.replace('.', '').includes('.')) return;
        // check for numeric pattern
        for (let i = 0; i < text.length; i++) {
            if (allowedNumbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        /* console.log('**** onPriceChangeField field: ' + field);
         *Possible values:
         *INVOICE_FORM_OVERNIGHT_COUNT
         *INVOICE_FORM_PRICE_PER_NIGHT
         *INVOICE_FORM_PRICE_OVERNIGHT_DISCOUNT
         */
        setValue(field, newText);
        handleOvernightPriceChanges(getValues, setValue);
        updateFinalPrice(getValues, setValue);
    };

    const onFinalPriceChangeField = (text, field) => {
        let newText = '';
        const allowedNumbers = NUMERIC_WITH_ZERO;
        // break if string contains multiple .
        if (text.replace('.', '').includes('.')) return;
        // check for numeric pattern
        for (let i = 0; i < text.length; i++) {
            if (allowedNumbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        /*INVOICE_FORM_PRICE_FINAL */
        setValue(field, newText);
        handleFinalPriceChanges(getValues, setValue);
    };

    const onPressUserDataInPdvSystem = async _ => {
        const updatedValue = !userDataInPdvSystem;
        setUserDataInPdvSystem(updatedValue);
        setValue(INVOICE_FORM_USER_IN_PDV_SYSTEM, String(updatedValue));
        // if false then reset all pdv calculations
        if (!updatedValue) {
            setValue(INVOICE_FORM_OVERNIGHT_PDV_AMOUNT, String(0));
            setValue(INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE, String(0));
        } else {
            // if true, then reset pdv% to default 13%
            const pdvPercentage = getValues(INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE);
            if (!pdvPercentage || pdvPercentage === '0') {
                setValue(INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE, String(OVERNIGHT_PDV_PERCENTAGE));
            }
        }
        handleOvernightPriceChanges(getValues, setValue);
        if (isFinalPriceSelected(selectedPriceTypeIndex)) {
            handleFinalPriceChanges(getValues, setValue);
        } else {
            // recalculate prices
            updateFinalPrice(getValues, setValue);
        }
    };

    const onTouristTaxChangeField = (text, field) => {
        let newText = '';
        const allowedNumbers = NUMERIC_WITH_ZERO;
        // break if string contains multiple .
        if (text.replace('.', '').includes('.')) return;
        // check for numeric pattern
        for (let i = 0; i < text.length; i++) {
            if (allowedNumbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        /* console.log('**** onTouristTaxChangeField field: ' + field);
         *Possible values:
         *INVOICE_FORM_TOURIST_TAX_ADULT_RATE
         *INVOICE_FORM_TOURIST_TAX_ADULT_COUNT
         *INVOICE_FORM_TOURIST_TAX_CHILD_COUNT
         */
        setValue(field, newText);
        handleTouristTaxChanges(getValues, setValue);
        if (isFinalPriceSelected(selectedPriceTypeIndex)) {
            handleFinalPriceChanges(getValues, setValue);
        } else {
            // recalculate prices
            updateFinalPrice(getValues, setValue);
        }
    };

    const onPressTouristTax = async _ => {
        //console.log('**** onPressTouristTax');
        const updatedValue = !userDataSojournTax;
        setUserDataSojournTax(updatedValue);
        setValue(INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED, String(updatedValue));
        if (!updatedValue && checkin) {
            // recalculate taxes
            const taxRates = getTaxRates(ADULT_TAX_PRICE);
            const taxes = getTouristTaxCategories(checkin, taxRates);
            setValue(INVOICE_FORM_TOURIST_TAX_ADULT_COUNT, String(taxes.adultsCount));
            setValue(INVOICE_FORM_TOURIST_TAX_CHILD_COUNT, String(taxes.childrenCount));
            handleTouristTaxChanges(getValues, setValue);
        } else if (updatedValue == true) {
            setValue(INVOICE_FORM_TOURIST_TAX_ADULT_COUNT, String(0));
            setValue(INVOICE_FORM_TOURIST_TAX_CHILD_COUNT, String(0));
            setValue(INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL, String(0));
            setValue(INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL, String(0));
            setValue(INVOICE_FORM_TOURIST_TAX_TOTAL, String(0));
        }
        if (isFinalPriceSelected(selectedPriceTypeIndex)) {
            handleFinalPriceChanges(getValues, setValue);
        } else {
            // recalculate prices
            updateFinalPrice(getValues, setValue);
        }
    };

    const [clientFocus, setClientFocus] = useState(false);
    const turnOnClientFocus = () => {
        setClientFocus(true);
    };
    const turnOffClientFocus = () => {
        setClientFocus(false);
    };

    const [ownerInfoFocus, setOwnerInfoFocus] = useState(false);
    const turnOnOwnerInfoFocus = () => {
        setOwnerInfoFocus(true);
    };
    const turnOffOwnerInfoFocus = () => {
        setOwnerInfoFocus(false);
    };

    const [ownerAddressFocus, setOwnerAddressFocus] = useState(false);
    const turnOnOwnerAddressFocus = () => {
        setOwnerAddressFocus(true);
    };
    const turnOffOwnerAddressFocus = () => {
        setOwnerAddressFocus(false);
    };

    const [ownerOibFocus, setOwnerOibFocus] = useState(false);
    const turnOnOwnerOibFocus = () => {
        setOwnerOibFocus(true);
    };
    const turnOffOwnerOibFocus = () => {
        setOwnerOibFocus(false);
    };

    const [guestAddressFocus, setGuestAddressFocus] = useState(false);
    const turnOnGuestAddressFocus = () => {
        setGuestAddressFocus(true);
    };
    const turnOffGuestAddressFocus = () => {
        setGuestAddressFocus(false);
    };

    const [companyOibFocus, setCompanyOibFocus] = useState(false);
    const turnOnCompanyOibFocus = () => {
        setCompanyOibFocus(true);
    };
    const turnOffCompanyOibFocus = () => {
        setCompanyOibFocus(false);
    };

    const [inNameFocus, setInNameFocus] = useState(false);
    const turnOnInNameFocus = () => {
        setInNameFocus(true);
    };
    const turnOffInNameFocus = () => {
        setInNameFocus(false);
    };

    const [serviceTypeFocus, setServiceTypeFocus] = useState(false);
    const turnOnServiceTypeFocus = () => {
        setServiceTypeFocus(true);
    };
    const turnOffServiceTypeFocus = () => {
        setServiceTypeFocus(false);
    };

    const [adultTaxRateFocus, setAdultTaxRateFocus] = useState(false);
    const turnOnAdultTaxRateFocus = () => {
        setAdultTaxRateFocus(true);
    };
    const turnOffAdultTaxRateFocus = () => {
        setAdultTaxRateFocus(false);
    };

    const [childTaxRateFocus, setChildTaxRateFocus] = useState(false);
    const turnOnChildTaxRateFocus = () => {
        setChildTaxRateFocus(true);
    };
    const turnOffChildTaxRateFocus = () => {
        setChildTaxRateFocus(false);
    };

    const [ammountTaxRateFocus, setAmmountTaxRateFocus] = useState(false);
    const turnOnAmmountTaxRateFocus = () => {
        setAmmountTaxRateFocus(true);
    };
    const turnOffAmmountTaxRateFocus = () => {
        setAmmountTaxRateFocus(false);
    };

    const [accomodationUnitFocus, setAccomodationUnitFocus] = useState(false);
    const turnOnAccomodationUnitFocus = () => {
        setAccomodationUnitFocus(true);
    };
    const turnOffAccomodationUnitFocus = () => {
        setAccomodationUnitFocus(false);
    };

    const [priceQuantityFocus, setPriceQuantityFocus] = useState(false);
    const turnOnPriceQuantityFocus = () => {
        setPriceQuantityFocus(true);
    };
    const turnOffPriceQuantityFocus = () => {
        setPriceQuantityFocus(false);
    };

    const [pricePerNightFocus, setPricePerNightFocus] = useState(false);
    const turnOnPricePerNightFocus = () => {
        setPricePerNightFocus(true);
    };
    const turnOffPricePerNightFocus = () => {
        setPricePerNightFocus(false);
    };

    const [finalPriceFocus, setFinalPriceFocus] = useState(false);
    const turnOnFinalPriceFocus = () => {
        setFinalPriceFocus(true);
    };
    const turnOffFinalPriceFocus = () => {
        setFinalPriceFocus(false);
    };

    const [priceDiscountFocus, setPriceDiscountFocus] = useState(false);
    const turnOnPriceDiscountFocus = () => {
        setPriceDiscountFocus(true);
    };
    const turnOffPriceDiscountFocus = () => {
        setPriceDiscountFocus(false);
    };

    const [closeDateTimePicker, setCloseDateTimePicker] = useState(true);
    const [datePickerData, setDatePickerData] = useState(null);
    const onChangeDate = async (date, field) => {
        setCloseDateTimePicker(true);
        setValue(field, getStringDate(date));

        if ([INVOICE_FORM_STAY_FROM, INVOICE_FORM_STAY_TO].includes(field)) {
            const updatedDurations = updateStayDates(getValues());
            setValue(INVOICE_FORM_OVERNIGHT_COUNT, updatedDurations?.[INVOICE_FORM_OVERNIGHT_COUNT]);
            setValue(INVOICE_FORM_GUEST_STAY_RANGE, updatedDurations?.[INVOICE_FORM_GUEST_STAY_RANGE]);
        }
    };

    const onChangeTime = async (date, field) => {
        setCloseDateTimePicker(true);
        setValue(field, getStringTime(date));
    };

    const openDateTimePicker = (mode, field, value, dateBoundary = null) => {
        setDatePickerData({
            datePickerMode: mode,
            datePickerDate: getDateFromDateString(value),
            currentPickerField: field,
            dateBoundary: dateBoundary,
        });
        setCloseDateTimePicker(false);
    };

    const onGuestStayFrom = _ => {
        openDateTimePicker('date', INVOICE_FORM_STAY_FROM, invoice?.[INVOICE_FORM_STAY_FROM]);
    };

    const onGuestStayTo = _ => {
        openDateTimePicker('date', INVOICE_FORM_STAY_TO, invoice?.[INVOICE_FORM_STAY_TO]);
    };

    const onInvoiceDate = () => {
        openDateTimePicker('date', INVOICE_FORM_DATE, invoice?.[INVOICE_FORM_DATE], null);
    };

    const onInvoiceTime = () => {
        openDateTimePicker(
            'time',
            INVOICE_FORM_TIME,
            invoice?.[INVOICE_FORM_TIME],
            getHigherDateBoundary(invoice?.[INVOICE_FORM_DATE])
        );
    };

    const [openLangSelect, setOpenLangSelect] = useState(false);
    const onLangSelected = async chosenLang => {
        if (chosenLang) {
            const code = chosenLang?.['code'];
            if (code) {
                setValue(INVOICE_FORM_LANG, code);
            }
        }
    };

    const [openPaymentSelect, setOpenPaymentSelect] = useState(false);
    const onPaymentTypeSelected = async paymentType => {
        setValue(INVOICE_FORM_PAYMENT_TYPE, paymentType);
    };

    const [openCurrencySelect, setOpenCurrencySelect] = useState(false);
    const onCurrencySelected = async currency => {
        setValue(INVOICE_FORM_CURRENCY, currency);
    };

    const [openOwnerListSelect, setOpenOwnerListSelect] = useState(false);
    const onOwnerSelected = (owner, field) => {
        if (field === INVOICE_FORM_OWNER) {
            setValue(INVOICE_FORM_OWNER, owner?.[INVOICE_FORM_OWNER]);
            setValue(INVOICE_FORM_OWNER_ADDRESS, owner?.[INVOICE_FORM_OWNER_ADDRESS]?.trim());
            setValue(INVOICE_FORM_OWNER_OIB, owner?.[INVOICE_FORM_OWNER_OIB]);
        } else if (field === INVOICE_FORM_IN_NAME) {
            setValue(INVOICE_FORM_IN_NAME, owner?.[INVOICE_FORM_OWNER]);
            setValue(INVOICE_FORM_IN_NAME_ADDRESS, owner?.[INVOICE_FORM_OWNER_ADDRESS]?.trim());
            setValue(INVOICE_FORM_IN_NAME_OIB, owner?.[INVOICE_FORM_OWNER_OIB]);
        }
    };

    const openOwnerList = _ => {
        setOwnerCallbackField(INVOICE_FORM_OWNER);
        setOpenOwnerListSelect(true);
    };

    const openInNameList = _ => {
        setOwnerCallbackField(INVOICE_FORM_IN_NAME);
        setOpenOwnerListSelect(true);
    };

    const [openClientListSelect, setOpenClientListSelect] = useState(false);
    const onClientSelected = client => {
        if (client[NAME]) {
            setValue(INVOICE_FORM_CLIENT, client[NAME]);
        }
        if (client[ADDRESS]) {
            setValue(INVOICE_FORM_GUEST_ADDRESS, client[ADDRESS]);
        }
    };

    const openClientList = _ => setOpenClientListSelect(true);

    const onPresUserDataElectronicInvoice = async _ => {
        const updatedValue = !userDataElectronicInvoice;
        setUserDataElectronicInvoice(updatedValue);
        setValue(INVOICE_FORM_ELECTRONIC_INVOICE, String(updatedValue));
    };

    const onPresUserDataShowOperator = async _ => {
        const updatedValue = !userDataShowOperator;
        setUserDataShowOperator(updatedValue);
        setValue(INVOICE_FORM_SHOW_OPERATER, String(updatedValue));
    };

    const onPresUserDataShowFooter = async _ => {
        const updatedValue = !userDataShowFooter;
        setUserDataShowFooter(updatedValue);
        setValue(INVOICE_FORM_SHOW_FOOTER, String(updatedValue));
    };

    const renderPersonalData = (
        <>
            <View style={styles.dialogSection}>
                <InvoiceNumberEditor
                    initialInvoiceFormNumber={initialInvoiceFormNumber}
                    setValue={setValue}
                    errors={errors}
                    t={t}
                    theme={theme}
                    global={global}
                />

                <View style={global.flexCenter}>
                    <Controller
                        name={INVOICE_FORM_DATE}
                        render={({field}) => (
                            <Input
                                label={t(INVOICE_DATE_LABEL)}
                                labelStyle={{textAlign: 'center'}}
                                value={getDateString(field.value)}
                                errorMessage={errors[INVOICE_FORM_DATE] ? errors[INVOICE_FORM_DATE].message : null}
                                showSoftInputOnFocus={false}
                                caretHidden={true}
                                ref={dateRef}
                                onFocus={_ => {
                                    dateRef.current.blur();
                                    onInvoiceDate();
                                }}
                                inputContainerStyle={{marginBottom: 0}}
                                containerStyle={{paddingRight: 10, width: '50%'}}
                                style={{width: '100%', borderBottomWidth: 0, textAlign: 'center'}}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={INVOICE_FORM_TIME}
                        render={({field}) => (
                            <Input
                                label={t(INVOICE_TIME_LABEL)}
                                labelStyle={{textAlign: 'center'}}
                                value={field.value}
                                errorMessage={errors[INVOICE_FORM_TIME] ? errors[INVOICE_FORM_TIME].message : null}
                                showSoftInputOnFocus={false}
                                caretHidden={true}
                                ref={timeRef}
                                onFocus={_ => {
                                    timeRef.current.blur();
                                    onInvoiceTime();
                                }}
                                inputContainerStyle={{marginBottom: 0}}
                                containerStyle={{paddingLeft: 10, width: '50%'}}
                                style={{width: '100%', borderBottomWidth: 0, textAlign: 'center'}}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                </View>
            </View>

            <View style={styles.dialogSection}>
                <Controller
                    name={INVOICE_FORM_OWNER}
                    render={({field}) => (
                        <Input
                            rightIcon={
                                <Icon name="search" color={theme.colors.primary} size={18} onPress={openOwnerList} />
                            }
                            rightIconContainerStyle={global.inputIconRightContainer}
                            label={t(INVOICE_OWNER_LABEL)}
                            value={field.value}
                            onChangeText={text => onChangeField(text, INVOICE_FORM_OWNER)}
                            errorMessage={errors[INVOICE_FORM_OWNER] ? errors[INVOICE_FORM_OWNER].message : null}
                            containerStyle={[styles.inputContainer]}
                            inputContainerStyle={{marginTop: 5}}
                            onFocus={turnOnOwnerInfoFocus}
                            onBlur={turnOffOwnerInfoFocus}
                            style={[global.inputWithIconRight, styles.inputActive, ownerInfoFocus && global.inputFocus]}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />

                <Controller
                    name={INVOICE_FORM_OWNER_ADDRESS}
                    render={({field}) => (
                        <Input
                            label={t(INVOICE_OWNER_ADDR_LABEL)}
                            value={field.value}
                            onChangeText={text => onChangeField(text, INVOICE_FORM_OWNER_ADDRESS)}
                            errorMessage={
                                errors[INVOICE_FORM_OWNER_ADDRESS] ? errors[INVOICE_FORM_OWNER_ADDRESS].message : null
                            }
                            inputContainerStyle={{marginTop: 5}}
                            containerStyle={[styles.inputContainer]}
                            onFocus={turnOnOwnerAddressFocus}
                            onBlur={turnOffOwnerAddressFocus}
                            style={[styles.inputActive, ownerAddressFocus && global.inputFocus]}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />

                <Controller
                    name={INVOICE_FORM_OWNER_OIB}
                    render={({field}) => (
                        <Input
                            label={t(OIB)}
                            value={field.value}
                            onChangeText={text => onChangeField(text, INVOICE_FORM_OWNER_OIB)}
                            errorMessage={
                                errors[INVOICE_FORM_OWNER_OIB] ? errors[INVOICE_FORM_OWNER_OIB].message : null
                            }
                            containerStyle={[styles.inputContainer]}
                            inputContainerStyle={{marginTop: 5}}
                            onFocus={turnOnOwnerOibFocus}
                            onBlur={turnOffOwnerOibFocus}
                            style={[styles.inputActive, ownerOibFocus && global.inputFocus]}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />

                <Controller
                    name={INVOICE_FORM_IN_NAME}
                    render={({field}) => (
                        <Input
                            label={t(INVOICE_IN_NAME)}
                            rightIcon={
                                <Icon name="search" color={theme.colors.primary} size={18} onPress={openInNameList} />
                            }
                            rightIconContainerStyle={global.inputIconRightContainer}
                            value={field.value}
                            onChangeText={text => onChangeField(text, INVOICE_FORM_IN_NAME)}
                            errorMessage={errors[INVOICE_FORM_IN_NAME] ? errors[INVOICE_FORM_IN_NAME].message : null}
                            containerStyle={[styles.inputContainer]}
                            inputContainerStyle={{marginTop: 5}}
                            onFocus={turnOnInNameFocus}
                            onBlur={turnOffInNameFocus}
                            style={[styles.inputActive, global.inputWithIconRight, inNameFocus && global.inputFocus]}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />
            </View>

            <View style={[styles.dialogSection, {position: 'relative', marginTop: 35, paddingTop: 35}]}>
                <ButtonGroup
                    onPress={updateCustomerTypeIndex}
                    selectedIndex={selectedCustomerTypeIndex}
                    buttons={customerTypeButtons}
                    containerStyle={{
                        marginHorizontal: 0,
                        marginBottom: 20,
                        borderColor: 'rgba(93, 77, 157,  0.4)',
                        backgroundColor: theme.colors.primaryExtraLight,
                        borderRadius: 30,
                        padding: 2,
                        height: 45,
                        position: 'absolute',
                        top: -30,
                        left: 15,
                        right: 15,
                    }}
                    buttonContainerStyle={{borderRadius: 30, overflow: 'hidden'}}
                    buttonStyle={{borderRadius: 30}}
                    selectedButtonStyle={{
                        backgroundColor: theme.colors.white,
                        borderWidth: 1,
                        borderColor: 'rgba(93, 77, 157,  0.4)',
                    }}
                    innerBorderStyle={{width: 0}}
                />

                <Controller
                    name={INVOICE_FORM_CLIENT}
                    render={({field}) => (
                        <Input
                            rightIcon={
                                isGuestSelected(selectedCustomerTypeIndex) ? (
                                    <Icon
                                        name="search"
                                        color={theme.colors.primary}
                                        size={18}
                                        onPress={openClientList}
                                    />
                                ) : null
                            }
                            rightIconContainerStyle={global.inputIconRightContainer}
                            label={
                                isGuestSelected(selectedCustomerTypeIndex)
                                    ? t(INVOICE_CUSTOMER_TYPE_GUEST_LABEL)
                                    : t(INVOICE_CUSTOMER_TYPE_COMPANY_LABEL)
                            }
                            value={field.value}
                            onChangeText={text => onChangeField(text, INVOICE_FORM_CLIENT)}
                            errorMessage={errors[INVOICE_FORM_CLIENT] ? errors[INVOICE_FORM_CLIENT].message : null}
                            containerStyle={[styles.inputContainer]}
                            inputContainerStyle={{marginTop: 5}}
                            onFocus={turnOnClientFocus}
                            onBlur={turnOffClientFocus}
                            style={[global.inputWithIconRight, styles.inputActive, clientFocus && global.inputFocus]}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />

                <Controller
                    name={INVOICE_FORM_GUEST_ADDRESS}
                    render={({field}) => (
                        <Input
                            label={
                                isGuestSelected(selectedCustomerTypeIndex)
                                    ? t(INVOICE_CUSTOMER_TYPE_GUEST_ADDR_LABEL)
                                    : t(INVOICE_CUSTOMER_TYPE_COMPANY_ADDR_LABEL)
                            }
                            value={field.value}
                            onChangeText={text => onChangeField(text, INVOICE_FORM_GUEST_ADDRESS)}
                            errorMessage={
                                errors[INVOICE_FORM_GUEST_ADDRESS] ? errors[INVOICE_FORM_GUEST_ADDRESS].message : null
                            }
                            containerStyle={[styles.inputContainer]}
                            inputContainerStyle={{marginTop: 5}}
                            onFocus={turnOnGuestAddressFocus}
                            onBlur={turnOffGuestAddressFocus}
                            style={[styles.inputActive, guestAddressFocus && global.inputFocus]}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />
                {isCompanySelected(selectedCustomerTypeIndex) && (
                    <Controller
                        name={INVOICE_FORM_COMPANY_OIB}
                        render={({field}) => (
                            <Input
                                label={INVOICE_OIB}
                                value={field.value}
                                onChangeText={text => onChangeField(text, INVOICE_FORM_COMPANY_OIB)}
                                errorMessage={
                                    errors[INVOICE_FORM_COMPANY_OIB] ? errors[INVOICE_FORM_COMPANY_OIB].message : null
                                }
                                containerStyle={[styles.inputContainer]}
                                inputContainerStyle={{marginTop: 5}}
                                onFocus={turnOnCompanyOibFocus}
                                onBlur={turnOffCompanyOibFocus}
                                style={[styles.inputActive, companyOibFocus && global.inputFocus]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                )}
            </View>
        </>
    );

    const renderPaymentData = (
        <>
            <View style={[styles.dialogSection, {position: 'relative', marginTop: 35, paddingTop: 35}]}>
                <View style={[global.flexCenter, styles.buttonsWrapper]}>
                    <Controller
                        name={INVOICE_FORM_STAY_FROM}
                        render={({field}) => (
                            <Button
                                onPress={onGuestStayFrom}
                                title={getShortFormattedDateFromDateString(field.value)}
                                icon={{
                                    type: 'ionicon',
                                    name: 'enter-outline',
                                    size: 16,
                                    color: theme.colors.primary,
                                    containerStyle: {marginLeft: 0, marginRight: 5},
                                }}
                                containerStyle={styles.stayButton}
                                buttonStyle={global.outlineButton}
                                titleStyle={global.outlineButtonTitle}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={INVOICE_FORM_STAY_TO}
                        render={({field}) => (
                            <Button
                                onPress={onGuestStayTo}
                                title={getShortFormattedDateFromDateString(field.value)}
                                icon={{
                                    type: 'ionicon',
                                    name: 'exit-outline',
                                    size: 16,
                                    color: theme.colors.primary,
                                    containerStyle: {marginLeft: 0, marginRight: 5},
                                }}
                                containerStyle={styles.stayButton}
                                buttonStyle={global.outlineButton}
                                titleStyle={global.outlineButtonTitle}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                </View>

                <Controller
                    name={INVOICE_FORM_SERVICE_TYPE}
                    render={({field}) => (
                        <Input
                            label={t(INVOICE_SERVICE_TYPE_LABEL)}
                            value={field.value && field.value === OVERNIGHT ? t(field.value) : field.value}
                            onChangeText={text => onChangeField(text, INVOICE_FORM_SERVICE_TYPE)}
                            errorMessage={
                                errors[INVOICE_FORM_SERVICE_TYPE] ? errors[INVOICE_FORM_SERVICE_TYPE].message : null
                            }
                            onFocus={turnOnServiceTypeFocus}
                            onBlur={turnOffServiceTypeFocus}
                            inputContainerStyle={{marginTop: 5}}
                            style={[styles.inputActive, serviceTypeFocus && global.inputFocus]}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />
                <Controller
                    name={INVOICE_FORM_ACC_UNIT}
                    render={({field}) => (
                        <Input
                            label={t(INVOICE_ACC_UNIT_LABEL)}
                            value={field.value}
                            onChangeText={text => onChangeField(text, INVOICE_FORM_ACC_UNIT)}
                            errorMessage={errors[INVOICE_FORM_ACC_UNIT] ? errors[INVOICE_FORM_ACC_UNIT].message : null}
                            onFocus={turnOnAccomodationUnitFocus}
                            onBlur={turnOffAccomodationUnitFocus}
                            inputContainerStyle={{marginTop: 5}}
                            style={[styles.inputActive, accomodationUnitFocus && global.inputFocus]}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />
            </View>

            <View>
                <Text style={[global.textCaps, {marginTop: 20, paddingHorizontal: 15}]}>
                    {t(INVOICE_PRICE_COUNT_LABEL)}
                </Text>
                <View style={[styles.dialogSection, {position: 'relative', marginTop: 35, paddingTop: 35}]}>
                    <ButtonGroup
                        onPress={updatePriceTypeIndex}
                        selectedIndex={selectedPriceTypeIndex}
                        buttons={priceTypeButtons}
                        containerStyle={{
                            marginHorizontal: 0,
                            marginBottom: 20,
                            borderColor: 'rgba(93, 77, 157,  0.4)',
                            backgroundColor: theme.colors.primaryExtraLight,
                            borderRadius: 30,
                            padding: 2,
                            height: 45,
                            position: 'absolute',
                            top: -30,
                            left: 15,
                            right: 15,
                        }}
                        buttonContainerStyle={{borderRadius: 30, overflow: 'hidden'}}
                        buttonStyle={{borderRadius: 30}}
                        selectedButtonStyle={{
                            backgroundColor: theme.colors.white,
                            borderWidth: 1,
                            borderColor: 'rgba(93, 77, 157,  0.4)',
                        }}
                        innerBorderStyle={{width: 0}}
                    />

                    {isPricePerNightSelected(selectedPriceTypeIndex) && (
                        <>
                            <View style={global.flexCenter}>
                                <Controller
                                    name={INVOICE_FORM_OVERNIGHT_COUNT}
                                    render={({field}) => (
                                        <Input
                                            label={t(INVOICE_QUANTITY)}
                                            value={field.value}
                                            keyboardType="numeric"
                                            onChangeText={text =>
                                                onOvernightPriceChangeField(text, INVOICE_FORM_OVERNIGHT_COUNT)
                                            }
                                            errorMessage={
                                                errors[INVOICE_FORM_OVERNIGHT_COUNT]
                                                    ? errors[INVOICE_FORM_OVERNIGHT_COUNT].message
                                                    : null
                                            }
                                            onFocus={turnOnPriceQuantityFocus}
                                            onBlur={turnOffPriceQuantityFocus}
                                            containerStyle={{paddingRight: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            style={[
                                                priceQuantityFocus && global.inputFocus,
                                                styles.inputActive,
                                                {
                                                    width: '100%',
                                                    fontFamily: theme.fontFamilyMedium,
                                                    color: theme.colors.primary,
                                                },
                                            ]}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                                <Controller
                                    name={INVOICE_FORM_PRICE_PER_NIGHT}
                                    render={({field}) => (
                                        <Input
                                            label={t(INVOICE_PRICE_PER_NIGHT_LABEL)}
                                            placeholder={field.value === '0' ? '0' : ''}
                                            value={field.value === '0' ? '' : field.value}
                                            keyboardType="numeric"
                                            onChangeText={text =>
                                                onOvernightPriceChangeField(
                                                    text === '' ? '0' : text,
                                                    INVOICE_FORM_PRICE_PER_NIGHT
                                                )
                                            }
                                            errorMessage={
                                                errors[INVOICE_FORM_PRICE_PER_NIGHT]
                                                    ? errors[INVOICE_FORM_PRICE_PER_NIGHT].message
                                                    : null
                                            }
                                            containerStyle={{paddingLeft: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            onFocus={turnOnPricePerNightFocus}
                                            onBlur={turnOffPricePerNightFocus}
                                            style={[
                                                styles.inputActive,
                                                pricePerNightFocus && global.inputFocus,
                                                {
                                                    width: '100%',
                                                    fontFamily: theme.fontFamilyMedium,
                                                    color: theme.colors.primary,
                                                },
                                            ]}
                                            labelStyle={{
                                                fontFamily: theme.fontFamilyMedium,
                                                color: theme.colors.primary,
                                            }}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </View>

                            <View style={global.flexCenter}>
                                <Controller
                                    name={INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE}
                                    render={({field}) => (
                                        <Input
                                            label={t(INVOICE_PRICE_DISCOUNT_LABEL)}
                                            placeholder={field.value === '0' ? '0' : ''}
                                            value={field.value === '0' ? '' : field.value}
                                            onChangeText={text =>
                                                onOvernightPriceChangeField(
                                                    text === '' ? '0' : text,
                                                    INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE
                                                )
                                            }
                                            errorMessage={
                                                errors[INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE]
                                                    ? errors[INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE].message
                                                    : null
                                            }
                                            containerStyle={{paddingRight: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            onFocus={turnOnPriceDiscountFocus}
                                            onBlur={turnOffPriceDiscountFocus}
                                            style={[
                                                styles.inputActive,
                                                priceDiscountFocus && global.inputFocus,
                                                {width: '100%'},
                                            ]}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                                <Controller
                                    name={INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV}
                                    render={({field}) => (
                                        <Input
                                            label={t(INVOICE_FORM_PRICE_TOTAL_LABEL)}
                                            value={field.value}
                                            disabled
                                            containerStyle={{paddingLeft: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            style={styles.inputDisabled}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </View>
                        </>
                    )}

                    {isFinalPriceSelected(selectedPriceTypeIndex) && (
                        <>
                            <View style={{alignItems: 'center', marginBottom: 5}}>
                                <Text style={[global.textMain, {marginBottom: 10, fontFamily: theme.fontFamilyMedium}]}>
                                    {t(INVOICE_PRICE_FINAL_LABEL)}:
                                </Text>
                                <View>
                                    <Controller
                                        name={INVOICE_FORM_PRICE_FINAL}
                                        render={({field}) => (
                                            <Input
                                                placeholder={field.value === '0.00' ? '0' : field.value}
                                                value={field.value === '0.00' ? '' : field.value}
                                                keyboardType="numeric"
                                                onChangeText={text =>
                                                    onFinalPriceChangeField(
                                                        text === '' ? '0.00' : text,
                                                        INVOICE_FORM_PRICE_FINAL
                                                    )
                                                }
                                                errorMessage={
                                                    errors[INVOICE_FORM_PRICE_PER_NIGHT]
                                                        ? errors[INVOICE_FORM_PRICE_PER_NIGHT].message
                                                        : null
                                                }
                                                containerStyle={[
                                                    styles.inputContainer,
                                                    {alignItems: 'center', width: 200},
                                                ]}
                                                onFocus={turnOnFinalPriceFocus}
                                                onBlur={turnOffFinalPriceFocus}
                                                style={[
                                                    {
                                                        width: '100%',
                                                        fontFamily: theme.fontFamilyMedium,
                                                        color: theme.colors.primary,
                                                        paddingLeft: 15,
                                                        borderWidth: 1,
                                                        borderRadius: 10,
                                                        opacity: 1,
                                                        backgroundColor: theme.colors.white,
                                                    },
                                                    finalPriceFocus && global.inputFocus,
                                                ]}
                                                inputContainerStyle={{marginBottom: 15}}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                    />
                                </View>
                            </View>
                            <View style={global.flexCenter}>
                                <Controller
                                    name={INVOICE_FORM_OVERNIGHT_COUNT}
                                    render={({field}) => (
                                        <Input
                                            label={t(INVOICE_QUANTITY)}
                                            value={field.value}
                                            keyboardType="numeric"
                                            onChangeText={text =>
                                                onOvernightPriceChangeField(text, INVOICE_FORM_OVERNIGHT_COUNT)
                                            }
                                            errorMessage={
                                                errors[INVOICE_FORM_OVERNIGHT_COUNT]
                                                    ? errors[INVOICE_FORM_OVERNIGHT_COUNT].message
                                                    : null
                                            }
                                            onFocus={turnOnPriceQuantityFocus}
                                            onBlur={turnOffPriceQuantityFocus}
                                            containerStyle={{paddingRight: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            style={[
                                                styles.inputActive,
                                                priceQuantityFocus && global.inputFocus,
                                                {
                                                    width: '100%',
                                                    fontFamily: theme.fontFamilyMedium,
                                                    color: theme.colors.primary,
                                                },
                                            ]}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                                <Controller
                                    name={INVOICE_FORM_PRICE_PER_NIGHT}
                                    render={({field}) => (
                                        <Input
                                            label={t(INVOICE_PRICE_PER_NIGHT_LABEL)}
                                            value={field.value === '0' ? '' : field.value}
                                            containerStyle={{paddingLeft: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            disabled
                                            style={styles.inputDisabled}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </View>

                            <View style={global.flexCenter}>
                                <Controller
                                    name={INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE}
                                    render={({field}) => (
                                        <Input
                                            label={t(INVOICE_PRICE_DISCOUNT_LABEL)}
                                            placeholder={field.value === '0' ? '0' : ''}
                                            value={field.value === '0' ? '' : field.value}
                                            disabled
                                            containerStyle={{paddingRight: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            style={styles.inputDisabled}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                                <Controller
                                    name={INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV}
                                    render={({field}) => (
                                        <Input
                                            label={t(INVOICE_FORM_PRICE_TOTAL_LABEL)}
                                            value={field.value}
                                            disabled
                                            containerStyle={{paddingLeft: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            style={styles.inputDisabled}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </View>
                        </>
                    )}

                    {/* PDV */}
                    <View style={{paddingTop: 5}}>
                        <CheckBox
                            title={t(USER_DATA_IN_PDV_SYSTEM)}
                            checked={userDataInPdvSystem}
                            onPress={onPressUserDataInPdvSystem}
                            containerStyle={[global.CheckBox, {paddingTop: 0, paddingBottom: 0, marginLeft: 0}]}
                            textStyle={global.CheckBoxText}
                            size={21}
                        />
                        {userDataInPdvSystem && (
                            <View style={[global.flexCenter, {marginTop: 15}]}>
                                <Controller
                                    name={INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE}
                                    render={({field}) => (
                                        <Input
                                            label={PDV_PERCENTAGE}
                                            value={field.value}
                                            disabled
                                            containerStyle={{paddingRight: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            style={styles.inputDisabled}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                                <Controller
                                    name={INVOICE_FORM_OVERNIGHT_PDV_AMOUNT}
                                    render={({field}) => (
                                        <Input
                                            label={PDV}
                                            value={field.value}
                                            disabled
                                            containerStyle={{paddingLeft: 10, width: '50%'}}
                                            inputContainerStyle={{marginTop: 5}}
                                            style={styles.inputDisabled}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </View>
                        )}
                    </View>

                    {/* TAX */}
                    <View style={{paddingTop: 5, paddingBottom: 15}}>
                        <CheckBox
                            title={t(USER_DATA_SOJOURN_TAX)}
                            checked={userDataSojournTax}
                            onPress={onPressTouristTax}
                            containerStyle={[global.CheckBox, {paddingTop: 0, paddingBottom: 0, marginLeft: 0}]}
                            textStyle={global.CheckBoxText}
                            size={21}
                        />
                        {!userDataSojournTax && (
                            <>
                                <View style={[global.flexCenter, {marginTop: 15}]}>
                                    <Controller
                                        name={INVOICE_FORM_TOURIST_TAX_ADULT_RATE}
                                        render={({field}) => (
                                            <Input
                                                label={t(INVOICE_FORM_TOURIST_TAX_RATE_LABEL)}
                                                placeholder={field.value === '0' ? '0' : ''}
                                                value={field.value === '0' ? '' : String(field.value)}
                                                keyboardType="numeric"
                                                onChangeText={text =>
                                                    onTouristTaxChangeField(
                                                        text === '' ? '0' : text,
                                                        INVOICE_FORM_TOURIST_TAX_ADULT_RATE
                                                    )
                                                }
                                                errorMessage={
                                                    errors[INVOICE_FORM_TOURIST_TAX_ADULT_RATE]
                                                        ? errors[INVOICE_FORM_TOURIST_TAX_ADULT_RATE].message
                                                        : null
                                                }
                                                containerStyle={{paddingRight: 10, width: '50%'}}
                                                inputContainerStyle={{marginTop: 5}}
                                                onFocus={turnOnAmmountTaxRateFocus}
                                                onBlur={turnOffAmmountTaxRateFocus}
                                                style={[
                                                    styles.inputActive,
                                                    ammountTaxRateFocus && global.inputFocus,
                                                    {
                                                        width: '100%',
                                                        fontFamily: theme.fontFamilyMedium,
                                                        color: theme.colors.primary,
                                                    },
                                                ]}
                                                labelStyle={{
                                                    fontFamily: theme.fontFamilyMedium,
                                                    color: theme.colors.primary,
                                                }}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                    />
                                    <Controller
                                        name={INVOICE_FORM_TOURIST_TAX_TOTAL}
                                        render={({field}) => (
                                            <Input
                                                label={t(INVOICE_SOJOURN_TAX_TOTAL_LABEL)}
                                                value={field.value}
                                                disabled
                                                errorMessage={
                                                    errors[INVOICE_FORM_TOURIST_TAX_TOTAL]
                                                        ? errors[INVOICE_FORM_TOURIST_TAX_TOTAL].message
                                                        : null
                                                }
                                                containerStyle={{paddingLeft: 10, width: '50%'}}
                                                inputContainerStyle={{marginTop: 5}}
                                                style={styles.inputDisabled}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                    />
                                </View>

                                <View style={[global.flexCenter, {marginTop: 5}]}>
                                    <Controller
                                        name={INVOICE_FORM_TOURIST_TAX_ADULT_COUNT}
                                        render={({field}) => (
                                            <Input
                                                label={t(INVOICE_FORM_TOURIST_TAX_ADULT_COUNT_LABEL)}
                                                placeholder={field.value === '0' ? '0' : ''}
                                                value={field.value === '0' ? '' : field.value}
                                                keyboardType="numeric"
                                                onChangeText={text =>
                                                    onTouristTaxChangeField(
                                                        text === '' ? '0' : text,
                                                        INVOICE_FORM_TOURIST_TAX_ADULT_COUNT
                                                    )
                                                }
                                                errorMessage={
                                                    errors[INVOICE_FORM_TOURIST_TAX_ADULT_COUNT]
                                                        ? errors[INVOICE_FORM_TOURIST_TAX_ADULT_COUNT].message
                                                        : null
                                                }
                                                containerStyle={{paddingRight: 10, width: '50%'}}
                                                inputContainerStyle={{marginTop: 5}}
                                                onFocus={turnOnAdultTaxRateFocus}
                                                onBlur={turnOffAdultTaxRateFocus}
                                                style={[
                                                    styles.inputActive,
                                                    adultTaxRateFocus && global.inputFocus,
                                                    {width: '100%'},
                                                ]}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                    />
                                    <Controller
                                        name={INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL}
                                        render={({field}) => (
                                            <Input
                                                label={t(INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL_LABEL)}
                                                value={field.value}
                                                disabled
                                                containerStyle={{paddingLeft: 10, width: '50%'}}
                                                inputContainerStyle={{marginTop: 5}}
                                                style={styles.inputDisabled}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                    />
                                </View>

                                <View style={[global.flexCenter, {marginTop: 5}]}>
                                    <Controller
                                        name={INVOICE_FORM_TOURIST_TAX_CHILD_COUNT}
                                        render={({field}) => (
                                            <Input
                                                label={t(INVOICE_FORM_TOURIST_TAX_CHILD_COUNT_LABEL)}
                                                placeholder={field.value === '0' ? '0' : ''}
                                                value={field.value === '0' ? '' : field.value}
                                                keyboardType="numeric"
                                                onChangeText={text =>
                                                    onTouristTaxChangeField(
                                                        text === '' ? '0' : text,
                                                        INVOICE_FORM_TOURIST_TAX_CHILD_COUNT
                                                    )
                                                }
                                                errorMessage={
                                                    errors[INVOICE_FORM_TOURIST_TAX_CHILD_COUNT]
                                                        ? errors[INVOICE_FORM_TOURIST_TAX_CHILD_COUNT].message
                                                        : null
                                                }
                                                containerStyle={{paddingRight: 10, width: '50%'}}
                                                inputContainerStyle={{marginTop: 5}}
                                                onFocus={turnOnChildTaxRateFocus}
                                                onBlur={turnOffChildTaxRateFocus}
                                                style={[
                                                    styles.inputActive,
                                                    childTaxRateFocus && global.inputFocus,
                                                    {width: '100%'},
                                                ]}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                    />
                                    <Controller
                                        name={INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL}
                                        render={({field}) => (
                                            <Input
                                                label={t(INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL_LABEL)}
                                                value={field.value}
                                                disabled
                                                containerStyle={{paddingLeft: 10, width: '50%'}}
                                                style={styles.inputDisabled}
                                                inputContainerStyle={{marginTop: 5}}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                    />
                                </View>
                            </>
                        )}
                    </View>

                    {isPricePerNightSelected(selectedPriceTypeIndex) && (
                        <View style={{alignItems: 'center', marginBottom: 15}}>
                            <Text
                                style={[
                                    global.textMain,
                                    {marginTop: 10, marginBottom: 10, fontFamily: theme.fontFamilyMedium},
                                ]}>
                                {t(INVOICE_PRICE_FINAL_LABEL)}:
                            </Text>
                            <View
                                style={{
                                    borderRadius: 10,
                                    borderColor: 'rgba(93, 77, 157,  0.4)',
                                    borderWidth: 1,
                                    backgroundColor: theme.colors.primaryExtraLight,
                                }}>
                                <Controller
                                    name={INVOICE_FORM_PRICE_FINAL}
                                    render={({field}) => (
                                        <Input
                                            value={field.value}
                                            disabled
                                            containerStyle={[styles.inputContainer, {alignItems: 'center', width: 200}]}
                                            style={[
                                                {
                                                    textAlign: 'center',
                                                    fontFamily: theme.fontFamilyMedium,
                                                    fontSize: 16,
                                                    borderBottomWidth: 0,
                                                    opacity: 1,
                                                },
                                            ]}
                                            inputContainerStyle={{marginBottom: 0}}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </>
    );

    const renderFinalData = (
        <>
            <View style={[styles.dialogSection, {paddingBottom: 10}]}>
                <Text style={[global.textCaps, {marginBottom: 10}]}>{t(INVOICE_FINAL_NOTES_LABEL)}:</Text>

                <CheckBox
                    title={t(USER_DATA_ELECTRONIC_INVOICE_OPT)}
                    checked={userDataElectronicInvoice}
                    onPress={onPresUserDataElectronicInvoice}
                    containerStyle={global.CheckBox}
                    textStyle={global.CheckBoxText}
                    size={21}
                />

                <CheckBox
                    title={t(INVOICE_SHOW_OPERATER_LABEL)}
                    checked={userDataShowOperator}
                    onPress={onPresUserDataShowOperator}
                    containerStyle={global.CheckBox}
                    textStyle={global.CheckBoxText}
                    size={21}
                />

                <CheckBox
                    title={t(INVOICE_SHOW_FOOTER_LABEL)}
                    checked={userDataShowFooter}
                    onPress={onPresUserDataShowFooter}
                    containerStyle={global.CheckBox}
                    textStyle={global.CheckBoxText}
                    size={21}
                />
            </View>
            <View style={[styles.dialogSection, {position: 'relative', marginBottom: 20}]}>
                <View style={global.flexCenter}>
                    <Controller
                        name={INVOICE_FORM_PAYMENT_TYPE}
                        render={({field}) => (
                            <Input
                                label={t(INVOICE_PAYMENT_TYPE_LABEL)}
                                value={t(field.value)}
                                showSoftInputOnFocus={false}
                                caretHidden={true}
                                ref={paymentTypeRef}
                                onFocus={_ => {
                                    paymentTypeRef.current.blur();
                                    setOpenPaymentSelect(true);
                                }}
                                containerStyle={{paddingRight: 10, flex: 1}}
                                errorMessage={
                                    errors[INVOICE_FORM_PAYMENT_TYPE] ? errors[INVOICE_FORM_PAYMENT_TYPE].message : null
                                }
                                inputContainerStyle={{marginTop: 5}}
                                style={[styles.inputActive, {width: '100%'}]}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                    <Controller
                        name={INVOICE_FORM_CURRENCY}
                        render={({field}) => (
                            <Input
                                label={t(INVOICE_CURRENCY_LABEL)}
                                value={field.value}
                                caretHidden={true}
                                disabled
                                containerStyle={{paddingLeft: 10, width: 100}}
                                inputContainerStyle={{marginTop: 5}}
                                errorMessage={
                                    errors[INVOICE_FORM_CURRENCY] ? errors[INVOICE_FORM_CURRENCY].message : null
                                }
                                style={styles.inputDisabled}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                </View>
            </View>

            <View style={{alignItems: 'center', marginBottom: 15}}>
                <Text style={[global.textMain, {marginTop: 15, marginBottom: 10, fontFamily: theme.fontFamilyMedium}]}>
                    {t(INVOICE_PRICE_FINAL_LABEL)}:
                </Text>
                <View
                    style={{
                        borderRadius: 10,
                        borderColor: 'rgba(93, 77, 157,  0.4)',
                        borderWidth: 1,
                        backgroundColor: theme.colors.primaryExtraLight,
                    }}>
                    <Controller
                        name={INVOICE_FORM_PRICE_FINAL}
                        render={({field}) => (
                            <Input
                                value={field.value}
                                disabled
                                containerStyle={[styles.inputContainer, {alignItems: 'center', width: 200}]}
                                style={[
                                    {
                                        textAlign: 'center',
                                        fontFamily: theme.fontFamilyMedium,
                                        fontSize: 16,
                                        borderBottomWidth: 0,
                                        opacity: 1,
                                    },
                                ]}
                                inputContainerStyle={{marginBottom: 0}}
                            />
                        )}
                        control={control}
                        defaultValue=""
                    />
                </View>
            </View>
        </>
    );

    const renderWizard = (
        <>
            {page === 0 && renderPersonalData}
            {page === 1 && renderPaymentData}
            {page === 2 && renderFinalData}
        </>
    );

    const insets = useSafeAreaInsets();

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}
            style={isMobile && styles.modal}
            avoidKeyboard={true}>
            <View
                style={[
                    global.modalDialog,
                    {height: '100%', maxHeight: 820, backgroundColor: theme.colors.white,
                    paddingTop: insets.top, paddingBottom:insets.bottom},
                    isMobile && styles.modalDialog,
                ]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{isExistingInvoice ? t(EDIT) : t(CREATE)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <ScrollView contentContainerStyle={[global.modalContent, {padding: 0, paddingBottom: 15}]}>
                    {renderWizard}
                </ScrollView>
                <View
                    style={[
                        global.modalFooter,
                        {justifyContent: 'flex-end', borderTopWidth: 1, borderColor: 'rgba(93, 77, 157,  0.1)'},
                    ]}>
                    {page > 0 && (
                        <Button
                            onPress={decrementPage}
                            title={t(BACK)}
                            containerStyle={[
                                global.ModalButton.Negative.containerStyle,
                                {
                                    position: 'absolute',
                                    left: 8,
                                    top: 8,
                                },
                            ]}
                            buttonStyle={global.ModalButton.Negative.buttonStyle}
                            titleStyle={global.ModalButton.Negative.titleStyle}></Button>
                    )}

                    {page < 2 && (
                        <Button
                            onPress={incrementPage}
                            title={t(NEXT)}
                            containerStyle={[
                                global.ModalButton.Negative.containerStyle,
                                {
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                },
                            ]}
                            buttonStyle={global.ModalButton.Negative.buttonStyle}
                            titleStyle={global.ModalButton.Negative.titleStyle}></Button>
                    )}

                    {page === 2 && (
                        <Button
                            onPress={onClickConfirm}
                            title={t(CONFIRM)}
                            containerStyle={global.ModalButton.Positive.containerStyle}
                            buttonStyle={global.ModalButton.Positive.buttonStyle}
                            titleStyle={global.ModalButton.Positive.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                    )}
                </View>
            </View>
            <FormDateTimePicker
                closed={closeDateTimePicker}
                setClosed={setCloseDateTimePicker}
                onChangeDate={onChangeDate}
                onChangeTime={onChangeTime}
                datePickerData={datePickerData}
            />
            <LangSelect
                isOpen={openLangSelect}
                setOpenLangSelect={setOpenLangSelect}
                onLangSelected={onLangSelected}
                langList={APP_LANGS_SETTINGS}
            />
            <PaymentTypeSelect
                isOpen={openPaymentSelect}
                setOpen={setOpenPaymentSelect}
                onSelected={onPaymentTypeSelected}
            />
            <CurrencySelect
                isOpen={openCurrencySelect}
                setOpen={setOpenCurrencySelect}
                onSelected={onCurrencySelected}
            />
            <OwnerListSelect
                isOpen={openOwnerListSelect}
                setOpen={setOpenOwnerListSelect}
                onSelected={onOwnerSelected}
                owners={getOwnersInfo(evaccounts, ownerInfo)}
                callbackField={ownerCallbackField}
            />
            <ClientListSelect
                isOpen={openClientListSelect}
                setOpen={setOpenClientListSelect}
                onSelected={onClientSelected}
                clients={clientList}
            />
        </Modal>
    );
};

export default EditInvoiceDialog;
{
    /*<Controller
        name={INVOICE_FORM_LANG}
        render={({field}) => (
            <Input
                label={t(LANGUAGE)}
                value={field.value}
                showSoftInputOnFocus={false}
                caretHidden={true}
                ref={langRef}
                onFocus={_ => {
                    langRef.current.blur();
                    setOpenLangSelect(true);
                }}
                errorMessage={errors[INVOICE_FORM_LANG] ? errors[INVOICE_FORM_LANG].message : null}
                containerStyle={[styles.inputContainer]}
            />
        )}
        control={control}
        defaultValue=""
    /> */
}

{
    /* <Controller
        name={INVOICE_FORM_GUEST}
        render={({field}) => (
            <Input
                label={t(INVOICE_CUSTOMER_TYPE_GUEST_LABEL)}
                value={field.value}
                onChangeText={text => onChangeField(text, INVOICE_FORM_GUEST)}
                errorMessage={errors[INVOICE_FORM_GUEST] ? errors[INVOICE_FORM_GUEST].message : null}
                containerStyle={[styles.inputContainer]}
                onFocus={turnOnGuestFocus}
                onBlur={turnOffGuestFocus}
                style={[guestFocus && global.inputFocus]}
            />
        )}
        control={control}
        defaultValue=""
    />
    */
}
