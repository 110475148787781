import {v4 as uuidv4} from 'uuid';
import {legacyFieldMapping} from '../../components/invoice/EditInvoiceDialog';
import {
    ADDITIONAL_FIELDS,
    ADDRESS,
    CHECKIN_ID,
    EVISITOR,
    EVISITOR_OIB,
    FACILITY_FIELD,
    FORESEEN_STAY_UNTIL_FIELD,
    INVOICE_FORM_ACC_UNIT,
    INVOICE_FORM_CLIENT,
    INVOICE_FORM_COMPANY_TYPE,
    INVOICE_FORM_CURRENCY,
    INVOICE_FORM_CUSTOMER_TYPE,
    INVOICE_FORM_DATE,
    INVOICE_FORM_DISCOUNT_TOTAL_SUM,
    INVOICE_FORM_ELECTRONIC_INVOICE,
    INVOICE_FORM_FOOTER_TEXT,
    INVOICE_FORM_GUEST_ADDRESS,
    INVOICE_FORM_GUEST_STAY_RANGE,
    INVOICE_FORM_LANG,
    INVOICE_FORM_NUMBER,
    INVOICE_FORM_OPERATOR_NAME,
    INVOICE_FORM_OPERATOR_REF,
    INVOICE_FORM_OVERNIGHT_COUNT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE,
    INVOICE_FORM_OVERNIGHT_PDV_AMOUNT,
    INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE,
    INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV,
    INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV,
    INVOICE_FORM_OWNER,
    INVOICE_FORM_OWNER_ADDRESS,
    INVOICE_FORM_OWNER_OIB,
    INVOICE_FORM_PAYMENT_TYPE,
    INVOICE_FORM_PDV_TOTAL_SUM,
    INVOICE_FORM_PRICE_FINAL,
    CALCULATION_PRICE_TYPE,
    CALCULATION_PRICE_TYPE_PER_NIGHT,
    CALCULATION_PRICE_TYPE_PER_FINAL_PRICE,
    INVOICE_FORM_PRICE_PER_NIGHT,
    INVOICE_FORM_SERVICE_TYPE,
    INVOICE_FORM_SHOW_FOOTER,
    INVOICE_FORM_SHOW_OPERATER,
    INVOICE_FORM_STAY_FROM,
    INVOICE_FORM_STAY_TO,
    INVOICE_FORM_TIME,
    INVOICE_FORM_TOURIST_TAX_ADULT_COUNT,
    INVOICE_FORM_TOURIST_TAX_ADULT_RATE,
    INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL,
    INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED,
    INVOICE_FORM_TOURIST_TAX_CHILD_COUNT,
    INVOICE_FORM_TOURIST_TAX_CHILD_RATE,
    INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL,
    INVOICE_FORM_TOURIST_TAX_TOTAL,
    INVOICE_FORM_USER_IN_PDV_SYSTEM,
    INVOICE_FORM_VER,
    INVOICE_NUMBER,
    NAME,
    OVERNIGHT,
    OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV,
    PAYMENT_CATEGORY,
    PIN,
    STAY_FROM_FIELD,
    STORAGE_USER_DATA,
    TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM,
    TOURIST_NAME,
    TOURIST_SURNAME,
    USER_DATA_FORM_ADDRESS,
    USER_DATA_FORM_CITY,
    USER_DATA_FORM_COUNTRY,
    USER_DATA_FORM_ENTITY,
    USER_DATA_FORM_ENTITY_FLAG,
    USER_DATA_FORM_LEGAL_ENTITY,
    USER_DATA_FORM_LEGAL_NAME,
    USER_DATA_FORM_OIB,
    USER_DATA_FORM_PERSONAL_NAME,
    USER_DATA_INV_FOOTER_DATA,
    USER_DATA_INV_OPERATOR,
    USER_DATA_INV_OPERATOR_ID,
    USER_INFO,
    content,
    guests,
    id,
} from '../../constants/stringsAndFields';
import {getDateFromDateString, getDaysBetweenDates, getNowDate, getNowTime} from '../dateHelper';
import {getGuestRange, getInvoiceDayRange} from '../guestUtils';
import {
    getBoolean,
    getBooleanTrueDefault,
    isStringEmptyOrNull,
    isStringNotEmpty,
    isZeroOrBigger,
    shouldPerformMigration,
    simpleForm,
} from '../helpers';
import {DEFAULT_LANG} from '../i18n';
import {
    OVERNIGHT_PDV_PERCENTAGE,
    handleOvernightPriceChanges,
    handleTouristTaxChanges,
    updateFinalPrice,
} from '../prices';
import {getInvoiceLastSettings, getUserData} from '../userUtils';
import {
    ADULT_TAX_PRICE,
    CHILD_TAX_PRICE,
    DEFAULT_CURRENCY,
    DEFAULT_CUSTOMER_TYPE,
    DEFAULT_INVOICE_NUMBER,
    DEFAULT_PAYMENT_TYPE,
} from './invoice';

export const prepareDefaultInvoice = async (
    checkin,
    userInfoData,
    invoiceNumber,
    evaccounts,
    operatorData,
    footerData
) => {
    const settings = await getInvoiceLastSettings();
    const storedAdultTaxRate = settings?.[INVOICE_FORM_TOURIST_TAX_ADULT_RATE];
    const adultTaxRate = String(storedAdultTaxRate ?? ADULT_TAX_PRICE);
    const taxRates = getTaxRates(adultTaxRate);
    const childTaxRate = String(taxRates[INVOICE_FORM_TOURIST_TAX_CHILD_RATE]) ?? String(CHILD_TAX_PRICE);
    const taxes = getTouristTaxCategories(checkin, taxRates);

    const calculationPriceType = String(settings?.[CALCULATION_PRICE_TYPE] ?? CALCULATION_PRICE_TYPE_PER_NIGHT);
    const userDataInPdvSystem = String(getBooleanTrueDefault(settings?.[INVOICE_FORM_USER_IN_PDV_SYSTEM]));
    const userDataElectronicInvoice = String(getBooleanTrueDefault(settings?.[INVOICE_FORM_ELECTRONIC_INVOICE]));
    const userDataShowOperator = String(getBooleanTrueDefault(settings?.[INVOICE_FORM_SHOW_OPERATER]));
    const userDataShowFooter = String(getBooleanTrueDefault(settings?.[INVOICE_FORM_SHOW_FOOTER]));

    const userDataSojournTax = getBooleanTrueDefault(settings?.[INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED]);
    const includedTaxes = userDataSojournTax;

    return {
        ...getOwnerInfo(userInfoData, checkin, evaccounts),
        [INVOICE_FORM_NUMBER]: invoiceNumber ?? DEFAULT_INVOICE_NUMBER,
        ...getFirstGuestInfo(checkin),
        [INVOICE_FORM_PAYMENT_TYPE]: DEFAULT_PAYMENT_TYPE,
        [INVOICE_FORM_DATE]: checkin?.[FORESEEN_STAY_UNTIL_FIELD] ?? getNowDate(),
        [INVOICE_FORM_TIME]: getNowTime(),
        [INVOICE_FORM_ACC_UNIT]: checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME] ?? '',
        [INVOICE_FORM_LANG]: DEFAULT_LANG,
        [INVOICE_FORM_CUSTOMER_TYPE]: DEFAULT_CUSTOMER_TYPE,
        [INVOICE_FORM_SERVICE_TYPE]: OVERNIGHT,
        [INVOICE_FORM_PRICE_PER_NIGHT]: '0',
        [INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE]: '0',
        [INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT]: '0.00',
        [INVOICE_FORM_DISCOUNT_TOTAL_SUM]: '0.00',
        [INVOICE_FORM_PDV_TOTAL_SUM]: '0.00',
        [TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM]: '0.00',
        [INVOICE_FORM_PRICE_FINAL]: '0.00',
        [INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV]: '0.00',
        [OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV]: '0.00',
        [INVOICE_FORM_OVERNIGHT_PDV_AMOUNT]: '0.00',
        [INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV]: '0',
        [INVOICE_FORM_CURRENCY]: DEFAULT_CURRENCY,
        [INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED]: String(includedTaxes),
        [INVOICE_FORM_TOURIST_TAX_ADULT_COUNT]: includedTaxes ? '0' : String(taxes.adultsCount),
        [INVOICE_FORM_TOURIST_TAX_CHILD_COUNT]: includedTaxes ? '0' : String(taxes.childrenCount),
        [INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL]: includedTaxes ? '0' : String(taxes.totalAdultPrice),
        [INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL]: includedTaxes ? '0' : String(taxes.totalChildrenPrice),
        [INVOICE_FORM_TOURIST_TAX_TOTAL]: includedTaxes ? '0' : String(taxes.totalTaxes),
        [INVOICE_FORM_CLIENT]: '',
        [INVOICE_FORM_TOURIST_TAX_ADULT_RATE]: adultTaxRate,
        [INVOICE_FORM_TOURIST_TAX_CHILD_RATE]: childTaxRate,
        [INVOICE_FORM_OPERATOR_NAME]: operatorData?.[USER_DATA_INV_OPERATOR] ?? '',
        [INVOICE_FORM_OPERATOR_REF]: operatorData?.[USER_DATA_INV_OPERATOR_ID] ?? '',
        [INVOICE_FORM_FOOTER_TEXT]: footerData?.[USER_DATA_INV_FOOTER_DATA] ?? '',
        [INVOICE_FORM_USER_IN_PDV_SYSTEM]: userDataInPdvSystem,
        [INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE]: String(OVERNIGHT_PDV_PERCENTAGE),
        [CALCULATION_PRICE_TYPE]: calculationPriceType,
        [INVOICE_FORM_ELECTRONIC_INVOICE]: userDataElectronicInvoice,
        [INVOICE_FORM_SHOW_OPERATER]: userDataShowOperator,
        [INVOICE_FORM_SHOW_FOOTER]: userDataShowFooter,
        [INVOICE_FORM_VER]: '2',
    };
};

export const updateStayDates = data => {
    return {
        [INVOICE_FORM_OVERNIGHT_COUNT]: getDaysBetweenDates(
            data?.[INVOICE_FORM_STAY_FROM],
            data?.[INVOICE_FORM_STAY_TO]
        ),
        [INVOICE_FORM_GUEST_STAY_RANGE]: getInvoiceDayRange(data),
    };
};

export const getStayDates = (guest, checkin) =>
    guest
        ? {
              [INVOICE_FORM_OVERNIGHT_COUNT]: getDaysBetweenDates(
                  guest?.[STAY_FROM_FIELD],
                  guest?.[FORESEEN_STAY_UNTIL_FIELD]
              ),
              [INVOICE_FORM_GUEST_STAY_RANGE]: getGuestRange(guest),
              [INVOICE_FORM_STAY_FROM]: guest?.[STAY_FROM_FIELD],
              [INVOICE_FORM_STAY_TO]: guest?.[FORESEEN_STAY_UNTIL_FIELD],
              [INVOICE_FORM_GUEST_ADDRESS]: '',
              [INVOICE_FORM_CLIENT]: `${guest?.[TOURIST_NAME]} ${guest?.[TOURIST_SURNAME]}`,
          }
        : {
              [INVOICE_FORM_OVERNIGHT_COUNT]: getDaysBetweenDates(
                  checkin?.[STAY_FROM_FIELD],
                  checkin?.[FORESEEN_STAY_UNTIL_FIELD]
              ),
              [INVOICE_FORM_GUEST_STAY_RANGE]: getGuestRange(checkin),
              [INVOICE_FORM_STAY_FROM]: checkin?.[STAY_FROM_FIELD],
              [INVOICE_FORM_STAY_TO]: checkin?.[FORESEEN_STAY_UNTIL_FIELD],
              [INVOICE_FORM_GUEST_ADDRESS]: '',
              [INVOICE_FORM_CLIENT]: '',
          };

export const getFirstGuestInfo = checkin => {
    const guest = checkin?.[guests]?.[0]?.[content];
    return getStayDates(guest, checkin);
};

export const createInvoiceDb = (invoiceId = null, metaData, invoice) => {
    // this should not be needed any
    /*
    // recalculate tax rate changes
    try {
        const includedTaxes = getBoolean(invoice?.[INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED]);
        if (invoice?.[INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED] !== '' && !includedTaxes) {
            const childTaxRate = parseFloat(invoice?.[INVOICE_FORM_TOURIST_TAX_CHILD_RATE] ?? 0);
            const childrenCount = parseInt(invoice?.[INVOICE_FORM_TOURIST_TAX_CHILD_COUNT] ?? 0);
            const totalChildrenPrice = Number(childrenCount * childTaxRate).toFixed(2) ?? '0';
            invoice[INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL] = totalChildrenPrice;
            const adultsTaxRate = parseFloat(invoice?.[INVOICE_FORM_TOURIST_TAX_ADULT_RATE] ?? 0);
            const adultsCount = parseInt(invoice?.[INVOICE_FORM_TOURIST_TAX_ADULT_COUNT ?? 0]);
            const totalAdultPrice = Number(adultsCount * adultsTaxRate).toFixed(2) ?? '0';
            invoice[INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL] = totalAdultPrice;
        }
        
        const finalPrice = invoice?.[INVOICE_FORM_PRICE_FINAL];
        if (!finalPrice || isNaN(finalPrice)) {
            recalculateTotalAndPdv(invoice);
        }
    } catch (e) {
        console.log(e);
    }
    */
    return {
        [id]: invoiceId ? invoiceId : uuidv4(),
        [INVOICE_NUMBER]: invoice?.[INVOICE_FORM_NUMBER],
        [CHECKIN_ID]: metaData?.[id],
        [EVISITOR_OIB]: invoice?.[INVOICE_FORM_OWNER_OIB] ?? metaData?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN],
        [content]: JSON.stringify(invoice),
        [ADDITIONAL_FIELDS]: JSON.stringify({
            [EVISITOR]: metaData?.[ADDITIONAL_FIELDS]?.[EVISITOR],
            [FACILITY_FIELD]: metaData?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD],
        }),
    };
};

export const getOrCalculateDiscountTotal = (invoice, ownerIsInPdv, includedTaxes) => {
    const invoiceDiscountTotalSum = invoice[INVOICE_FORM_DISCOUNT_TOTAL_SUM];
    if (!isStringEmptyOrNull(invoiceDiscountTotalSum)) {
        return invoiceDiscountTotalSum;
    }
    // fallback calculation, later won't be needed
    const invoiceDiscount = invoice[INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE];
    if (isZeroOrBigger(invoiceDiscount)) {
        const totalPrice = ownerIsInPdv
            ? invoice[INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV]
            : invoice[INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV];
        try {
            const totalPriceNumber = parseFloat(totalPrice);
            const discountNumber = parseFloat(invoiceDiscount);
            return Number(totalPriceNumber * (discountNumber / 100)).toFixed(2);
        } catch (e) {
            console.log(e);
        }
    }
    // TODO: maybe all this is obsolete?!
    const total = invoice[INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV];
    const final = invoice[INVOICE_FORM_PRICE_FINAL];
    try {
        let diff = 0;
        if (!includedTaxes) {
            const {finalWithTaxes} = calculateFinalPrice(invoice);
            diff = Number(finalWithTaxes) - Number(final);
        }
        if (ownerIsInPdv) {
            const pdv = parseFloat(invoice?.[INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE] ?? 0);
            const calculatedPdv = total * (pdv / 100);
            return Number(final - calculatedPdv - total - diff).toFixed(2);
        } else {
            // no pdv
            return Number(Number(final) - Number(total) - Number(diff)).toFixed(2);
        }
    } catch (e) {
        console.log(e);
    }
    return '';
};

// TODO do we need this?
// maybe calculate also pdv here and return it
export const calculateFinalPrice = (invoice, forPdfGeneration = false) => {
    let finalWithTaxes = invoice?.[INVOICE_FORM_PRICE_FINAL];
    let finalPriceWithoutTaxes = 0;

    try {
        const pdv = parseFloat(invoice?.[INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE]);
        const pricePerNight = parseFloat(invoice?.[INVOICE_FORM_PRICE_PER_NIGHT] ?? 0);
        const days = Number(invoice?.[INVOICE_FORM_OVERNIGHT_COUNT] ?? 0);
        //const discount = parseFloat(invoice?.[INVOICE_FORM_PRICE_DISCOUNT]) ?? 0;

        const totalPrice = Number(days) * pricePerNight;
        //finalPriceWithoutTaxes = totalPrice - totalPrice * (discount / 100);
        finalPriceWithoutTaxes = totalPrice;

        if (pdv) {
            const calculatedPdv = finalPriceWithoutTaxes * (pdv / 100);
            finalPriceWithoutTaxes = finalPriceWithoutTaxes + calculatedPdv;
        }
    } catch (e) {
        console.log(e);
    }
    finalPriceWithoutTaxes = finalPriceWithoutTaxes.toFixed(2);

    if (!forPdfGeneration) {
        const includedTaxes = getBoolean(invoice?.[INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED]);
        if (invoice?.[INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED] !== '' && !includedTaxes) {
            try {
                finalWithTaxes = parseFloat(invoice?.[INVOICE_FORM_PRICE_FINAL]);
                const childTaxes = parseFloat(invoice?.[INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL] ?? 0);
                const adultTaxes = parseFloat(invoice?.[INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL] ?? 0);
                finalWithTaxes = Number(finalWithTaxes + childTaxes + adultTaxes).toFixed(2);
            } catch (e) {
                console.log(e);
            }
        }
    }

    return {finalWithTaxes, finalPriceWithoutTaxes};
};

export const sortByDate = (t1, t2) => {
    const fromDateT1 = getDateFromDateString(t1?.[content]?.[INVOICE_FORM_DATE]);
    const fromDateT2 = getDateFromDateString(t2?.[content]?.[INVOICE_FORM_DATE]);
    return Number(fromDateT1) - Number(fromDateT2);
};

export const getTaxRates = tax => {
    try {
        const adultTax = parseFloat(tax);
        const childTax = Number(adultTax / 2);
        return {
            [INVOICE_FORM_TOURIST_TAX_ADULT_RATE]: adultTax,
            [INVOICE_FORM_TOURIST_TAX_CHILD_RATE]: childTax,
        };
    } catch (e) {
        console.log(e);
    }
    // default
    return {
        [INVOICE_FORM_TOURIST_TAX_ADULT_RATE]: ADULT_TAX_PRICE,
        [INVOICE_FORM_TOURIST_TAX_CHILD_RATE]: CHILD_TAX_PRICE,
    };
};

export const reverseAddress = address => {
    if (address) {
        const addressParts = address.split(',');
        if (addressParts && addressParts.length > 2) {
            const country = addressParts[0];
            const city = addressParts[1];
            const street = addressParts.slice(2).join(',');
            return `${street}, ${city}, ${country}`;
        }
    }
    return '';
};

export const getCity = address => {
    if (address) {
        const addressParts = address.split(',');
        if (addressParts && addressParts.length > 2) {
            const city = addressParts[1];
            return city;
        }
    }
    return '';
};

export const getTouristTaxCategories = (checkin, taxRates) => {
    const checkinGuests = checkin?.[guests];
    let adultsCount = 0;
    let childrenCount = 0;

    for (let guest of checkinGuests) {
        const guestContent = guest[content];
        const paymentCat = guestContent[PAYMENT_CATEGORY];
        const stayDates = getStayDates(guestContent, checkin);
        const stayQuantity = Number(stayDates[INVOICE_FORM_OVERNIGHT_COUNT]);
        if (paymentCat === '2') {
            childrenCount = childrenCount + stayQuantity;
        } else if (paymentCat === '14') {
            adultsCount = adultsCount + stayQuantity;
        }
    }
    const totalChildrenPrice = Number(childrenCount * taxRates[INVOICE_FORM_TOURIST_TAX_CHILD_RATE]).toFixed(2) ?? '0';
    const totalAdultPrice = Number(adultsCount * taxRates[INVOICE_FORM_TOURIST_TAX_ADULT_RATE]).toFixed(2) ?? '0';
    const totalTaxes = Number(Number(totalAdultPrice) + Number(totalChildrenPrice)).toFixed(2) ?? '0';
    return {adultsCount, childrenCount, totalChildrenPrice, totalAdultPrice, totalTaxes};
};

export const getCustomerTypeIndex = customer => (customer === INVOICE_FORM_COMPANY_TYPE ? 1 : 0);
export const getPriceTypeIndex = priceType => (priceType === CALCULATION_PRICE_TYPE_PER_FINAL_PRICE ? 1 : 0);

export const getEvAccountInfo = (checkin, evaccounts) => {
    const eVisitorPin = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN];
    if (eVisitorPin) {
        const evAccount = evaccounts.find(ev => ev[PIN] === eVisitorPin);
        if (evAccount) {
            return {
                [INVOICE_FORM_OWNER]: evAccount?.[NAME],
                [INVOICE_FORM_OWNER_ADDRESS]: reverseAddress(evAccount?.[ADDRESS]),
                [INVOICE_FORM_OWNER_OIB]: evAccount?.[PIN],
            };
        }
    }
    return null;
};

export const getOwnersInfo = (evaccounts, ownerInfo) => {
    const evAccountOwners = evaccounts.map(ev => {
        return {
            [INVOICE_FORM_OWNER]: ev?.[NAME],
            [INVOICE_FORM_OWNER_ADDRESS]: reverseAddress(ev?.[ADDRESS]),
            [INVOICE_FORM_OWNER_OIB]: ev?.[PIN],
        };
    });
    if (ownerInfo) {
        const isLegalEntity = ownerInfo[USER_DATA_FORM_ENTITY] === USER_DATA_FORM_LEGAL_ENTITY;
        const owner = {
            [INVOICE_FORM_OWNER]: isLegalEntity
                ? ownerInfo[USER_DATA_FORM_LEGAL_NAME] ?? ''
                : ownerInfo[USER_DATA_FORM_PERSONAL_NAME] ?? '',
            [INVOICE_FORM_OWNER_ADDRESS]: ownerInfo[USER_DATA_FORM_ADDRESS] ?? '',
            [INVOICE_FORM_OWNER_OIB]: ownerInfo[USER_DATA_FORM_OIB] ?? '',
        };
        return [owner, ...evAccountOwners];
    }
    return evAccountOwners;
};

export const getOwnerInfo = (userInfoData, checkin, evaccounts) => {
    if (userInfoData) {
        return {
            [INVOICE_FORM_OWNER_ADDRESS]: isStringNotEmpty(userInfoData?.[USER_DATA_FORM_ADDRESS])
                ? `${userInfoData?.[USER_DATA_FORM_ADDRESS]}, ${userInfoData?.[USER_DATA_FORM_CITY]}, ${userInfoData?.[USER_DATA_FORM_COUNTRY]}`
                : '',
            [INVOICE_FORM_OWNER]:
                userInfoData?.[USER_DATA_FORM_ENTITY_FLAG] === 0
                    ? userInfoData?.[USER_DATA_FORM_PERSONAL_NAME] ?? ''
                    : userInfoData?.[USER_DATA_FORM_LEGAL_NAME] ?? '',
            [INVOICE_FORM_OWNER_OIB]: userInfoData?.[USER_DATA_FORM_OIB] ?? '',
        };
    } else {
        const evAccountInfo = getEvAccountInfo(checkin, evaccounts);
        if (evAccountInfo) {
            return {
                ...evAccountInfo,
            };
        }
        return {
            [INVOICE_FORM_OWNER_ADDRESS]: '',
            [INVOICE_FORM_OWNER]: '',
            [INVOICE_FORM_OWNER_OIB]: '',
        };
    }
};

const generateInvoiceNumber = number => {
    if (number) {
        try {
            const numberParts = number.split('-');
            if (numberParts.length === 3) {
                const prefix = numberParts[0];
                const numPrefix = parseInt(prefix) ?? 0;
                const suffix1 = numberParts[1];
                const suffix2 = numberParts[2];
                return `${numPrefix + 1}-${suffix1}-${suffix2}`;
            } else {
                const numSuffix = parseInt(number) ?? 0;
                return `${numSuffix + 1}`;
            }
        } catch (e) {
            console.log(e);
        }
    }
    // default invoice number
    return `1-1-1`;
};

export const getLastInvoiceNumber = async (checkin, getLastInvoice) => {
    const eVisitorAccountPin = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN];
    try {
        // give priority to invoice created by stored OIB (settings)
        let ownerInfo = await getUserData();
        if (ownerInfo) {
            ownerInfo = ownerInfo[STORAGE_USER_DATA]?.[USER_INFO]?.[USER_DATA_FORM_OIB] ?? '';
        }
        const lastInvoice = await getLastInvoice({
            [PIN]: ownerInfo ? ownerInfo : eVisitorAccountPin,
        });
        const generatedInvoiceNumber = generateInvoiceNumber(lastInvoice?.[content]?.[INVOICE_FORM_NUMBER]);
        return generatedInvoiceNumber;
    } catch (e) {
        console.log(e);
    }
    // default invoice number
    return `1-1-1`;
};

export const legacyFieldMigration = invoice => {
    for (const legacyKey of Object.keys(legacyFieldMapping)) {
        const newMatchKey = invoice[legacyKey];
        if (newMatchKey) {
            invoice[legacyFieldMapping[legacyKey]] = newMatchKey;
            delete invoice[legacyKey];
        }
    }
};

export const convertAndCalculateLegacyData = invoiceData => {
    if (shouldPerformMigration(invoiceData, 2)) {
        console.log('Performing invoice migration');
        legacyFieldMigration(invoiceData);
        // add missing fields for PDV
        if (isStringEmptyOrNull(invoiceData[INVOICE_FORM_USER_IN_PDV_SYSTEM])) {
            invoiceData[INVOICE_FORM_USER_IN_PDV_SYSTEM] = String(true);
        }
        if (isStringEmptyOrNull(invoiceData[INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE])) {
            invoiceData[INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE] = String(OVERNIGHT_PDV_PERCENTAGE);
        }
        if (isStringEmptyOrNull(invoiceData[INVOICE_FORM_PDV_TOTAL_SUM])) {
            invoiceData[INVOICE_FORM_PDV_TOTAL_SUM] = String(0);
        }
        // calculate all new values
        const {values, getValues, setValue, clear} = simpleForm();
        try {
            for (const [key, value] of Object.entries(invoiceData)) {
                setValue(key, value);
            }
            handleTouristTaxChanges(getValues, setValue);
            handleOvernightPriceChanges(getValues, setValue);
            updateFinalPrice(getValues, setValue);
            setValue(INVOICE_FORM_VER, '2');
            for (const [key, value] of Object.entries(values)) {
                invoiceData[key] = value;
            }
        } catch (e) {
            console.log(e);
        } finally {
            clear();
        }
    }
};
