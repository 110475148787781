import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SafeAreaView, ScrollView, View, useWindowDimensions} from 'react-native';
import {Text, useTheme} from 'react-native-elements';
import FastCheckinActions from '../components/guest/FastCheckinActions';
import GuestForm from '../components/guest/GuestForm';
import {useSharedCheckinActions} from '../components/guest/useCheckinActions';
import {INVALID_FORM_DETAILS, content} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {findGuestInCheckin} from '../utils/arrayHelpers';
import {getPaymentCategories, getZeroCheckin, restoreGuest} from '../utils/guestUtils';
import {isGuestEmpty} from '../utils/helpers';
import {DEFAULT_LANG} from '../utils/i18n';
import {setupUserLang} from '../utils/userUtils';
import CheckoutActionDialog from '../components/guest/CheckoutActionDialog';

const GuestScreen = ({route, navigation}) => {
    const {
        eVisitorStaticData,
        clearErrors,
        getCheckin,
        onUpdateCheckin,
        onAddCheckin,
        createOrUpdateGuest,
        apiErrors,
        apiMessages,
        onCreateIfNotExistCheckin,
        getEvisitorByPin,
    } = useContext(DataContext);
    const {
        checkinId,
        guestId,
        isNewGuest = false,
        guestData = null,
        manualInput = false,
        calendarInput = false,
    } = route.params;
    const {theme} = useTheme();
    const {t} = useTranslation();
    const global = globalStyle(theme);
    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;
    const [checkin, setCheckin] = useState(null);
    const [lang, setLang] = useState(DEFAULT_LANG);
    const [guest, setGuest] = useState(null);
    const isFocused = useIsFocused();
    const [accomodationPaymentCategories, setAccomodationPaymentCategories] = useState(null);
    const [showFormErrors, setShowFormErrors] = useState(false);

    const refreshCheckin = async checkinId => {
        const dbCheckin = await getCheckin(checkinId);
        if (dbCheckin) {
            if (guestId) {
                const dbGuest = findGuestInCheckin(dbCheckin, guestId);
                dbGuest && setGuest(dbGuest);
            }
            setCheckin(dbCheckin);
        }
    };

    useEffect(() => {
        isFocused && clearErrors();
        let isMounted = true;
        const initGuest = async () => {
            if (isFocused) {
                if (calendarInput && guestData && isNewGuest) {
                    const zeroCheckin = await getZeroCheckin(guestData, true, null);
                    isMounted && zeroCheckin && setCheckin(zeroCheckin);
                } else if (calendarInput && guestData && !isNewGuest) {
                    const dbCheckin = await getCheckin(checkinId);
                    if (dbCheckin && guestId) {
                        let dbGuest = findGuestInCheckin(dbCheckin, guestId);
                        if (dbGuest) {
                            if (isGuestEmpty(dbGuest?.[content])) {
                                const restoredGuest = restoreGuest(guestData, guestId, checkinId);
                                isMounted && restoredGuest && setGuest(restoredGuest);
                            } else {
                                isMounted && dbGuest && setGuest(dbGuest);
                            }
                        } else {
                            console.log('Could not find guest');
                        }
                        isMounted && setCheckin(dbCheckin);
                    } else {
                        // remote guest from evisitor (not present in local db)
                        console.log('Could not find checkin, creating one!');
                        if (guestId) {
                            const restoredGuest = restoreGuest(guestData, guestId, checkinId);
                            if (restoredGuest) {
                                isMounted && setGuest(restoredGuest);
                                const zeroCheckin = await getZeroCheckin(guestData, true, restoredGuest);
                                isMounted && zeroCheckin && setCheckin(zeroCheckin);
                            }
                        }
                    }
                } else {
                    isMounted && checkinId && refreshCheckin(checkinId).catch(console.error);
                }
            }
        };
        initGuest().catch(console.error);
        return () => {
            isMounted = false;
        };
    }, [isFocused]);

    useEffect(() => {
        setupUserLang().then(storedLang => storedLang && setLang(storedLang));
    }, []);

    useEffect(() => {
        if (checkin) {
            getPaymentCategories(checkin, getEvisitorByPin).then(paymentCategories => {
                paymentCategories && setAccomodationPaymentCategories(paymentCategories);
            });
        }
    }, [checkin]);

    return (
        <SafeAreaView style={global.containerBg}>
            {showFormErrors && (
                <View style={{paddingBottom: 8}}>
                    <Text style={global.guestInvalidForm}>{t(INVALID_FORM_DETAILS)}</Text>
                </View>
            )}
            <ScrollView automaticallyAdjustKeyboardInsets={true}>
                <View style={[global.containerInner, isMobile && {paddingHorizontal: 10}, {paddingBottom: 60}]}>
                    <View
                        style={[
                            global.card,
                            global.boxShadow,
                            {overflow: 'visible'},
                            isMobile && {marginVertical: 15, paddingTop: 10, paddingHorizontal: 0},
                            !isMobile && {marginVertical: 30, paddingTop: 30, paddingHorizontal: 30, paddingBottom: 10},
                        ]}>
                        <GuestForm
                            eVisitorStaticData={eVisitorStaticData}
                            createOrUpdateGuest={createOrUpdateGuest}
                            apiErrors={apiErrors}
                            apiMessages={apiMessages}
                            existingGuest={guest}
                            isNewGuest={isNewGuest}
                            guestData={guestData}
                            checkin={checkin}
                            isSelfCheckin={false}
                            getCheckin={getCheckin}
                            onUpdateCheckin={onUpdateCheckin}
                            navigation={navigation}
                            lang={lang}
                            calendarInput={calendarInput}
                            onAddCheckin={onAddCheckin}
                            onCreateIfNotExistCheckin={onCreateIfNotExistCheckin}
                            isFocused={isFocused}
                            accomodationPaymentCategories={accomodationPaymentCategories}
                            manualInput={manualInput}
                            setShowFormErrors={setShowFormErrors}
                            useSharedCheckinActions={useSharedCheckinActions}
                        />
                    </View>
                </View>
            </ScrollView>
            <FastCheckinActions
                checkin={checkin}
                setCheckin={setCheckin}
                isFocused={isFocused}
                navigation={navigation}
                useSharedCheckinActions={useSharedCheckinActions}
            />
            <CheckoutActionDialog useSharedCheckinActions={useSharedCheckinActions} checkin={checkin} />
        </SafeAreaView>
    );
};

export default GuestScreen;
