import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    bgImage: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingTop: 80,
        paddingBottom: 40,
    },

    logoWrapper: {
        position: 'absolute',
        left: 20,
        top: 24,
    },

    formContainer: {
        backgroundColor: '#fff',
        borderRadius: 20,
        padding: 30,
        alignItems: 'center',
        width: '100%',
        maxWidth: 400,
        minHeight: 530,
    },

    formContainerWeb: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(30px)',
    },

    formContainerMobile: {
        paddingHorizontal: 20,
        paddingVertical: 15,
    },

    buttonsWrapper: {
        flexDirection: 'row',
        width: '100%',
        maxWidth: 300,
        justifyContent: 'space-around',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(93, 77, 157,  0.2)',
        paddingBottom: 10,
    },

    tabButton: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        paddingHorizontal: 0,
    },

    tabButtonText: {
        opacity: 0.5,
    },

    selectedTabButtonText: {
        opacity: 1,
    },

    loginButtonContainer: {
        marginTop: 10,
        marginBottom: 15,
    },

    loginButton: {
        height: 40,
        paddingVertical: 8,
    },

    resetPasswordButtonContainer: {
        marginBottom: 3,
    },

    resetPasswordButton: {
        backgroundColor: 'transparent',
        borderWidth: 0,
    },

    helpContainer: {
        height: 64,
        alignItems: 'center',
        justifyContent: 'center',
    },

    consentCheckbox: {
        backgroundColor: 'transparent',
        color: theme.colors.textColor,
    },

    appVersion: {
        position: 'absolute',
        right: 20,
        bottom: 10,
        color: theme.colors.greyDark,
    },
}));

export default useStyles;
