import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, View} from 'react-native';
import {Button, CheckBox, Icon, Input, ListItem, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    ACCEPT,
    CHECKIN_ACTIONS,
    CHECKOUT_ACTION,
    CHECKOUT_ALL,
    CHECKOUT_CONFIRM,
    CHECKOUT_WITH_CUSTOM_DATE,
    CHECKOUT_WITH_PREDEFINED_DATE,
    DATA,
    DECLINE,
    FORESEEN_STAY_UNTIL_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {
    getDateFromDateString,
    getDateFromTime,
    getFormattedDateFromDateString,
    getStringDate,
    getStringTime,
} from '../../utils/dateHelper';
import FormDateTimePicker from '../datetime/FormDateTimePicker';

const IS_WEB = Platform.OS === 'web';

const CheckoutActionDialog = ({useSharedCheckinActions, checkin}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const [predefinedCheckoutDate, setPredefinedCheckoutDate] = useState(true);
    const [customCheckoutDate, setCustomCheckoutDate] = useState(false);
    const [datePickerData, setDatePickerData] = useState(null);
    const [closeDateTimePicker, setCloseDateTimePicker] = useState(true);
    const [checkoutDateData, setCheckoutDateData] = useState({});

    const toDayRef = useRef(null);
    const toTimeRef = useRef(null);

    const onPredefinedCheckoutDate = _ => {
        setPredefinedCheckoutDate(true);
        setCustomCheckoutDate(false);
    };

    const onCustomCheckoutDate = _ => {
        setCustomCheckoutDate(true);
        setPredefinedCheckoutDate(false);
    };

    const onChangeDate = async (date, field) => {
        setCheckoutDateData({...checkoutDateData, [field]: getStringDate(date)});
    };

    const onChangeTime = async (date, field) => {
        setCheckoutDateData({...checkoutDateData, [field]: getStringTime(date)});
    };

    const forseenStayUntil =
        checkoutDateData?.[FORESEEN_STAY_UNTIL_FIELD] ?? checkin?.[FORESEEN_STAY_UNTIL_FIELD] ?? '';
    const timeEstimatedUntil =
        checkoutDateData?.[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? checkin?.[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? '';

    const {isOpenActionsDialog, hideActionsDialog, prepareGuestForCheckin, triggerCheckoutAction} =
        useSharedCheckinActions();

    const isVisible = isOpenActionsDialog ? true : false;

    const onSelectAction = async action => {
        if (action === CHECKOUT_ALL) {
            if (customCheckoutDate) {
                triggerCheckoutAction({
                    [CHECKIN_ACTIONS]: CHECKOUT_ACTION,
                    [DATA]: {forseenStayUntil, timeEstimatedUntil},
                });
            } else {
                triggerCheckoutAction({[CHECKIN_ACTIONS]: CHECKOUT_ACTION});
            }
        }
    };

    const onCheckoutPress = async () => {
        hideActionsDialog();
        await onSelectAction(CHECKOUT_ALL);
    };

    const handleClose = _ => {
        setCheckoutDateData({});
        prepareGuestForCheckin(null);
        hideActionsDialog();
    };

    const openDateTimePicker = (mode, field, value) => {
        setDatePickerData({
            datePickerMode: mode,
            datePickerDate: mode === 'date' ? getDateFromDateString(value) : getDateFromTime(value),
            currentPickerField: field,
            checkinData: checkin,
        });
        setCloseDateTimePicker(false);
    };

    return (
        <Modal
            isVisible={isVisible}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(CHECKOUT_CONFIRM)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={global.modalContent}>
                    <Text style={[global.link, {marginBottom: 10}]}>{`${t(CHECKOUT_ALL)}:`}</Text>
                    <CheckBox
                        title={t(CHECKOUT_WITH_PREDEFINED_DATE)}
                        checked={predefinedCheckoutDate}
                        onPress={onPredefinedCheckoutDate}
                        containerStyle={global.CheckBox}
                        textStyle={global.CheckBoxText}
                        size={21}
                    />
                    <CheckBox
                        title={t(CHECKOUT_WITH_CUSTOM_DATE)}
                        checked={customCheckoutDate}
                        onPress={onCustomCheckoutDate}
                        containerStyle={global.CheckBox}
                        textStyle={global.CheckBoxText}
                        size={21}
                    />
                    {customCheckoutDate && (
                        <View
                            style={[
                                global.timeBox,
                                {
                                    marginLeft: 40,
                                    marginTop: 10,
                                    marginBottom: 5,
                                    paddingTop: 3,
                                    paddingBottom: 0,
                                    paddingLeft: 10,
                                    maxWidth: 160,
                                },
                            ]}>
                            <View style={global.timeInputWrapper}>
                                <Input
                                    leftIcon={
                                        <Pressable
                                            onPress={_ => {
                                                toDayRef.current.blur();
                                                openDateTimePicker('date', FORESEEN_STAY_UNTIL_FIELD, forseenStayUntil);
                                            }}
                                            style={({pressed}) => ({
                                                opacity: pressed ? 0.5 : 1,
                                            })}>
                                            <Icon
                                                type="ionicon"
                                                name={'calendar-outline'}
                                                size={16}
                                                color={theme.colors.iconColor}
                                            />
                                        </Pressable>
                                    }
                                    leftIconContainerStyle={global.inputIconContainer}
                                    ref={toDayRef}
                                    onFocus={_ => {
                                        toDayRef.current.blur();
                                        openDateTimePicker('date', FORESEEN_STAY_UNTIL_FIELD, forseenStayUntil);
                                    }}
                                    showSoftInputOnFocus={false}
                                    selectTextOnFocus={false}
                                    value={getFormattedDateFromDateString(forseenStayUntil)}
                                    inputContainerStyle={global.timeInputContainer}
                                    inputStyle={[global.timeInput, IS_WEB && {cursor: 'pointer'}]}
                                />
                            </View>
                            <View style={global.timeInputWrapper}>
                                <Input
                                    leftIcon={
                                        <Pressable
                                            onPress={_ => {
                                                toTimeRef.current.blur();
                                                openDateTimePicker(
                                                    'time',
                                                    TIME_ESTIMATED_STAY_UNTIL_FIELD,
                                                    timeEstimatedUntil
                                                );
                                            }}
                                            style={({pressed}) => ({
                                                opacity: pressed ? 0.5 : 1,
                                            })}>
                                            <Icon
                                                type="ionicon"
                                                name={'time-outline'}
                                                size={16}
                                                color={theme.colors.iconColor}
                                            />
                                        </Pressable>
                                    }
                                    leftIconContainerStyle={global.inputIconContainer}
                                    ref={toTimeRef}
                                    onFocus={_ => {
                                        toTimeRef.current.blur();
                                        openDateTimePicker('time', TIME_ESTIMATED_STAY_UNTIL_FIELD, timeEstimatedUntil);
                                    }}
                                    showSoftInputOnFocus={false}
                                    selectTextOnFocus={false}
                                    value={timeEstimatedUntil}
                                    inputContainerStyle={global.timeInputContainer}
                                    inputStyle={[global.timeInput, IS_WEB && {cursor: 'pointer'}]}
                                />
                            </View>
                        </View>
                    )}
                    <FormDateTimePicker
                        closed={closeDateTimePicker}
                        setClosed={setCloseDateTimePicker}
                        onChangeDate={onChangeDate}
                        onChangeTime={onChangeTime}
                        datePickerData={datePickerData}
                    />
                </View>
                <View style={global.modalFooter}>
                    <Button
                        onPress={handleClose}
                        title={t(DECLINE)}
                        containerStyle={global.ModalButton.Negative.containerStyle}
                        buttonStyle={global.ModalButton.Negative.buttonStyle}
                        titleStyle={global.ModalButton.Negative.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                    <Button
                        onPress={onCheckoutPress}
                        title={t(ACCEPT)}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default CheckoutActionDialog;
