import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, View} from 'react-native';
import {Icon, ListItem, SearchBar, Text, useTheme} from 'react-native-elements';
import {FlatList} from 'react-native-gesture-handler';
import Modal from 'react-native-modal';
import {
    CHOOSE_FACILITY_TITLE,
    FACILITY_ERROR_NO_ACTIVE_FACILITY,
    FACILITY_ID,
    NAME,
    NAME_CODE,
    SEARCH,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {isNotEmpty} from '../../utils/arrayHelpers';

const FacilityChooserDialog = props => {
    const {t} = useTranslation();
    const {facilities, title = t(CHOOSE_FACILITY_TITLE), open, handleOpen, setFacility, onChoosedFacility} = props;
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [search, setSearch] = useState('');
    const [focusSearch, setSearcFocus] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    const filterPropertyFirst = NAME;
    const filterPropertySecond = NAME_CODE;

    const updateSearch = text => {
        setSearch(text);
    };

    const handleClose = () => {
        setSearch('');
        handleOpen(!open);
    };

    const onClickEvAccountItem = item => {
        setFacility(item);
        onChoosedFacility(item);
        handleClose();
    };

    useEffect(() => {
        if (isNotEmpty(facilities)) {
            const filtered =
                facilities.filter(
                    item =>
                        item[filterPropertyFirst]?.toLowerCase().includes(search.toLowerCase()) ||
                        item[filterPropertySecond]?.toLowerCase().includes(search.toLowerCase())
                ) ?? [];
            setFilteredList(filtered);
        } else {
            setFilteredList([]);
        }
    }, [facilities, search]);

    const render = ({item}) => (
        <ListItem
            key={`${item?.[FACILITY_ID]}_${item?.[NAME]}_${item?.[NAME_CODE]}`}
            bottomDivider
            onPress={_ => onClickEvAccountItem(item)}>
            <ListItem.Content>
                <ListItem.Title style={global.textMain}>
                    {item?.[NAME]} - {item?.[NAME_CODE]}
                </ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    const emptyList = <EmptyList t={t} global={global} />;

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{title}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <SearchBar
                    placeholder={t(SEARCH)}
                    onChangeText={updateSearch}
                    value={search}
                    onFocus={() => setSearcFocus(true)}
                    onBlur={() => setSearcFocus(false)}
                    searchIcon={{
                        name: 'search-outline',
                        type: 'ionicon',
                    }}
                    containerStyle={{
                        backgroundColor: theme.colors.lightBg,
                        borderTopWidth: 0,
                        borderBottomColor: theme.colors.greyLight,
                    }}
                    inputContainerStyle={{borderBottomWidth: 0, backgroundColor: 'transparent', marginBottom: 0}}
                    inputStyle={[
                        {
                            backgroundColor: theme.colors.white,
                            borderWidth: 1,
                            borderRadius: 40,
                            minHeight: 40,
                            paddingLeft: 35,
                            paddingRight: 35,
                            color: theme.colors.textColor,
                            marginLeft: 0,
                        },
                        focusSearch && global.inputFocus,
                    ]}
                    leftIconContainerStyle={{
                        position: 'absolute',
                        left: 12,
                        marginVertical: 0,
                        paddingRight: 0,
                        marginLeft: 0,
                        zIndex: 1,
                    }}
                    rightIconContainerStyle={{
                        position: 'absolute',
                        right: 12,
                        marginVertical: 0,
                        paddingRight: 0,
                        marginRight: 0,
                        zIndex: 1,
                    }}
                />

                <FlatList
                    data={filteredList}
                    renderItem={render}
                    keyExtractor={(item, index) => `${item?.[FACILITY_ID]}_${item?.[NAME]}_${item?.[NAME_CODE]}`}
                    ListEmptyComponent={emptyList}
                />
            </View>
        </Modal>
    );
};

export default FacilityChooserDialog;

const EmptyList = ({t, global}) => (
    <View style={global.listEmptyMsgWithBackground}>
        <Text style={global.listEmptyMsgText}>{t(FACILITY_ERROR_NO_ACTIVE_FACILITY)}</Text>
    </View>
);
