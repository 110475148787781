import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Image,
    ImageBackground,
    Keyboard,
    LayoutAnimation,
    Platform,
    Text,
    UIManager,
    View,
    useWindowDimensions,
} from 'react-native';
import {Button, Icon, Input, LinearProgress, useTheme} from 'react-native-elements';
import {
    ABOUT_EMAIL_OFFICE,
    APP_LANGS_SETTINGS,
    APP_VERSION,
    CONFIRM_PASSWORD,
    EMAIL,
    EVISITOR_PASS,
    LOGIN_INTRO,
    LOGIN_NOTE,
    LOGIN_TITLE,
    PLEASE_WAIT,
    REFERRAL_INTRO,
    REGISTER_USER_DIALOG_ONGOING,
    REGISTER_USER_DIALOG_SUCCESS,
    REGISTER_USER_DIALOG_TITLE,
    RESET_PASSWORD_TITLE,
    SIGNUP_TITLE,
    SIGN_IN_BUTTON_TITLE,
    SIGN_UP_BUTTON_TITLE,
    SIGN_UP_INTRO,
    STORAGE_LANGUAGE,
    USER_CREDS_INVALID,
    WELCOME,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {themes} from '../../theme/themes';
//import {ReactComponent as Logo} from '../../../assets/graphics/logo3.svg';

import {addRefToUser, getInitialCategory, validateEmail} from '../../utils/helpers';
import ProgressDialog from '../ProgressDialog';
import ConsentDialog from './ConsentDialog';
import useStyles from './LoginForm.styles';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {useRoute} from '@react-navigation/native';
import {DEFAULT_LANG} from '../../utils/i18n';
import {sleep} from '../../utils/promiseHelpers';
import {getUserTheme, setupUserLang} from '../../utils/userUtils';
import LangSelect from '../LangSelect';
import {logoSvg} from './LoginForm.logo';
import ResetPasswordDialog from './ResetPasswordDialog';
const BG_IMAGE = require('../../../assets/images/backgrounds/full-screen-bg_1.png');
const BG_IMAGE_BIBINJE = require('../../../assets/images/bg_login_bibinje.png');
const LOGO_BIBINJE = require('../../../assets/images/logo_bibinje_h.png');

const SLEEP_REGISTER_DIALOG = 2000;
const IS_WEB = Platform.OS === 'web';

// Enable LayoutAnimation on Android
//UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);
if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(false);
    }
}

const LoginForm = ({navigation, fetchInitData, login, setLoggedIn, signUp, apiErrors, apiMessages}) => {
    const {t, i18n} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isEmailValid, setEmailValid] = useState(true);
    const [isPasswordValid, setPasswordValid] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isConfirmPasswordValid, setConfirmPasswordValid] = useState(true);
    const [visibleProgress, setVisibleProgress] = useState(true);
    const [openLangSelect, setOpenLangSelect] = useState(false);
    const [lang, setLang] = useState(DEFAULT_LANG);

    const [openMessage, setOpenMessage] = useState(false);
    const [openProgress, setOpenProgress] = useState(false);
    const [hidePassword, setHidePassword] = useState(true);
    const [hideRegisterPassword, setHideRegisterPassword] = useState(true);
    const [disableButton, setDisableButton] = useState(true);
    const [showConsent, setShowConsent] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [showSignUpDialog, setShowSignUpDialog] = useState(false);
    const [signUpMessage, setSignUpMessage] = useState(null);

    const [focusEmail, setEmailFocus] = useState(false);
    const [focusPassword, setPasswordFocus] = useState(false);
    const [focusConfirmPassword, setConfirmPasswordFocus] = useState(false);
    //@Samuele zasto koristimo ova tri set-a, a ne selectedCategory === 0 ili 1 ili 2
    // zato što je selectedCategory pregeneričan naziv, i služi samo za promjenu view-a
    // dok page state-ovi deskriptivnije opisuju stilove i promjene među njima
    const [isLoginPage, setIsLoginPage] = useState(true);
    const [isSignUpPage, setIsSignUpPage] = useState(false);
    const [isReferralPage, setIsReferralPage] = useState(false);

    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    const confirmationInput = useRef(null);

    const width = useWindowDimensions().width;
    const isMobile = width < 500;

    const route = useRoute();
    const [selectedCategory, setSelectedCategory] = useState(getInitialCategory(route, IS_WEB));

    const selectCategory = useCallback(selectedCategoryIndex => {
        LayoutAnimation.easeInEaseOut();
        setLoading(false);
        setSelectedCategory(selectedCategoryIndex);
    }, []);

    const onConfirmConsent = () => {
        setDisableButton(false);
    };

    const generateMessage = useCallback(() => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        }
        if (apiMessages) {
            return `${apiMessages?.message}`;
        }
    }, [apiErrors, apiMessages]);

    const extractRestError = useCallback(error => {
        if (error?.RESTErrors) {
            const {type, description} = error?.RESTErrors;
            return `${type}: ${description}`;
        } else {
            return `${error?.signal}: ${error?.message}`;
        }
    }, []);

    const openLangSelectModal = _ => {
        setOpenLangSelect(true);
    };

    const onLangSelected = useCallback(async chosenLang => {
        if (chosenLang) {
            const code = chosenLang?.['code'];
            if (code) {
                await AsyncStorage.setItem(STORAGE_LANGUAGE, code);
                setLang(code);
            }
        }
    }, []);

    const loginFn = useCallback(() => {
        Keyboard.dismiss();
        const user = {};
        const isEmailValidFlag = validateEmail(email) || emailInput.current.shake();
        const isPasswordValidFlag = password.length >= 6 || passwordInput.current.shake();

        LayoutAnimation.easeInEaseOut();
        setLoading(false);
        setEmailValid(validateEmail(email) || emailInput.current.shake());
        setPasswordValid(password.length >= 6 || passwordInput.current.shake());

        if (isEmailValidFlag && isPasswordValidFlag) {
            user['email'] = email;
            user['password'] = password;
            setOpenProgress(true);
            login(user)
                .then(userRes => {
                    getUserTheme()
                        .then(userThemeName => {
                            if (userThemeName && themes?.[userThemeName]) {
                                theme.changeTheme(themes[userThemeName]);
                            }
                        })
                        .then(_ => {
                            fetchInitData()
                                .then(_ => {
                                    setLoginError(null);
                                    setOpenProgress(false);
                                    setLoggedIn(true);
                                    navigation.navigate('Root', {
                                        screen: 'Checkins',
                                    });
                                })
                                .catch(e => {
                                    console.log('Login init data failed failed');
                                    console.log(e);
                                    setOpenProgress(false);
                                });
                        });
                })
                .catch(e => {
                    console.log('Login fail');
                    console.log(e);
                    setLoginError(true);
                    setOpenProgress(false);
                });
        }
    }, [email, password]);

    const signUpFn = useCallback(() => {
        Keyboard.dismiss();
        const user = {};
        const isEmailValidFlag = validateEmail(email) || emailInput.current.shake();
        const isPasswordValidFlag = password.length >= 6 || passwordInput.current.shake();
        const isConfirmPasswordValidFlag = password === confirmPassword || confirmationInput.current.shake();
        LayoutAnimation.easeInEaseOut();
        setLoading(false);
        setEmailValid(validateEmail(email) || emailInput.current.shake());
        setPasswordValid(password.length >= 6 || passwordInput.current.shake());
        setConfirmPasswordValid(password === confirmPassword || confirmationInput.current.shake());

        if (isEmailValidFlag && isPasswordValidFlag && isConfirmPasswordValidFlag) {
            user['email'] = email;
            user['password'] = password;
            addRefToUser(user, isReferralPage);
            setSignUpMessage(null);
            setShowSignUpDialog(true);
            setSignUpMessage(t(REGISTER_USER_DIALOG_ONGOING));
            signUp(user)
                .then(_ => {
                    console.log('SignUp success');
                    fetchInitData()
                        .then(_ => {
                            setOpenProgress(false);
                            setSignUpMessage(t(REGISTER_USER_DIALOG_SUCCESS));
                            setLoggedIn(true);
                            sleep(SLEEP_REGISTER_DIALOG).then(() => {
                                setShowSignUpDialog(false);
                                navigation.navigate('Root', {
                                    screen: 'Checkins',
                                });
                            });
                        })
                        .catch(e => {
                            console.log('SignUp init data failed failed');
                            console.log(e);
                            setOpenProgress(false);
                            setSignUpMessage(extractRestError(e));
                        });
                })
                .catch(e => {
                    console.log('SignUp fail');
                    console.log(e);
                    setOpenProgress(false);
                    setSignUpMessage(extractRestError(e));
                });
        }
    }, [email, password, confirmPassword]);

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiErrors, apiMessages]);

    // show consent only on signup tab
    useEffect(() => {
        if (selectedCategory === 0) {
            setIsLoginPage(true);
            setIsSignUpPage(false);
            setShowConsent(false);
            setIsReferralPage(false);
        } else if (selectedCategory === 2) {
            setIsReferralPage(true);
            setIsSignUpPage(true);
            setIsLoginPage(false);
            setShowConsent(true);
        } else {
            setIsSignUpPage(true);
            setIsLoginPage(false);
            setShowConsent(true);
            setIsReferralPage(false);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (lang) i18n.changeLanguage(lang);
    }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setupUserLang().then(storedLang => storedLang && setLang(storedLang));
    }, []);

    return (
        <ImageBackground
            source={theme.name === themes.bibinje.name ? BG_IMAGE_BIBINJE : BG_IMAGE}
            style={styles.bgImage}>
            {openProgress && (
                <ProgressDialog
                    setVisible={setOpenProgress}
                    visible={openProgress}
                    details={generateMessage()}
                    title={t(PLEASE_WAIT)}
                />
            )}
            {showSignUpDialog && (
                <ProgressDialog
                    setVisible={setShowSignUpDialog}
                    visible={showSignUpDialog}
                    details={signUpMessage}
                    title={t(REGISTER_USER_DIALOG_TITLE)}
                />
            )}
            {false && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
            {theme.name === themes.echeckin.name && <View style={styles.logoWrapper}>{logoSvg}</View>}
            <Button
                title={lang}
                onPress={openLangSelectModal}
                buttonStyle={global.langSelectButton}
                titleStyle={global.langSelectButtonTitle}
                containerStyle={global.langSelectWrapper}
                icon={{
                    name: 'keyboard-arrow-down',
                    size: 16,
                    color: theme.colors.primary,
                }}
                iconContainerStyle={{position: 'absolute', zIndex: 1, right: 5, marginHorizontal: 0}}
            />
            <LangSelect
                isOpen={openLangSelect}
                setOpenLangSelect={setOpenLangSelect}
                onLangSelected={onLangSelected}
                langList={APP_LANGS_SETTINGS}
            />

            <View
                style={[
                    global.boxShadow,
                    styles.formContainer,
                    isMobile && styles.formContainerMobile,
                    IS_WEB && styles.formContainerWeb,
                ]}>
                {theme.name === themes.bibinje.name && (
                    <Image
                        source={LOGO_BIBINJE}
                        resizeMode="contain"
                        style={{
                            height: 75,
                            width: 221,
                            marginBottom: 30,
                        }}
                    />
                )}
                {selectedCategory !== 2 && (
                    <View style={styles.buttonsWrapper}>
                        <Button
                            disabled={isLoading}
                            activeOpacity={0.7}
                            onPress={() => selectCategory(0)}
                            containerStyle={[global.Button.containerStyle, {width: '50%'}]}
                            buttonStyle={[global.Button.buttonStyle, styles.tabButton]}
                            titleStyle={[
                                global.outlineButtonTitle,
                                styles.tabButtonText,
                                isLoginPage && styles.selectedTabButtonText,
                            ]}
                            title={t(LOGIN_TITLE)}
                        />
                        <Button
                            disabled={isLoading}
                            activeOpacity={0.7}
                            onPress={() => selectCategory(1)}
                            containerStyle={[global.Button.containerStyle, {width: '50%'}]}
                            buttonStyle={[global.Button.buttonStyle, styles.tabButton]}
                            titleStyle={[
                                global.outlineButtonTitle,
                                styles.tabButtonText,
                                isSignUpPage && styles.selectedTabButtonText,
                            ]}
                            title={t(SIGNUP_TITLE)}
                        />
                    </View>
                )}

                {isLoginPage && (
                    <View style={{marginVertical: 30, marginHorizontal: 'auto', width: '90%'}}>
                        <Text
                            style={{
                                fontFamily: theme.fontFamilyMedium,
                                color: theme.colors.textColor,
                                fontSize: 18,
                                lineHeight: 22,
                                textAlign: 'center',
                                marginBottom: 10,
                            }}>
                            {t(WELCOME)}
                        </Text>
                        <Text style={[global.textIntro, isMobile && {fontSize: 14, lineHeight: 18}]}>
                            {t(LOGIN_INTRO)}
                        </Text>
                    </View>
                )}
                {isSignUpPage && !isReferralPage && (
                    <View style={{marginVertical: 35}}>
                        <Text style={[global.textIntro, , isMobile && {fontSize: 14, lineHeight: 18}]}>
                            {t(SIGN_UP_INTRO)}
                        </Text>
                    </View>
                )}

                {isReferralPage && (
                    <View style={{marginTop: 15, marginBottom: 20}}>
                        <Text
                            style={{
                                fontFamily: theme.fontFamilyMedium,
                                color: theme.colors.textColor,
                                fontSize: 18,
                                lineHeight: 22,
                                textAlign: 'center',
                                marginBottom: 10,
                            }}>
                            {t(WELCOME)}
                        </Text>
                        <Text style={[global.textIntro, , isMobile && {fontSize: 14, lineHeight: 18}]}>
                            {t(REFERRAL_INTRO)}
                        </Text>
                    </View>
                )}

                <Input
                    leftIcon={
                        <Icon
                            name="person"
                            size={22}
                            iconStyle={focusEmail ? global.inputIconFocus : global.inputIcon}
                        />
                    }
                    leftIconContainerStyle={global.inputRoundedIconContainer}
                    value={email}
                    keyboardAppearance="light"
                    autoFocus={false}
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="email-address"
                    returnKeyType="next"
                    placeholder={t(EMAIL)}
                    ref={emailInput}
                    onSubmitEditing={() => passwordInput.current.focus()}
                    onChangeText={text => setEmail(text)}
                    errorMessage={isEmailValid ? '' : t(USER_CREDS_INVALID)}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    style={[focusEmail && global.inputFocus, global.inputRoundedWithIcon, global.inputRounded]}
                    errorStyle={{textAlign: 'center'}}
                />
                <Input
                    leftIcon={
                        <Icon
                            name="lock"
                            size={22}
                            iconStyle={focusPassword ? global.inputIconFocus : global.inputIcon}
                        />
                    }
                    leftIconContainerStyle={global.inputRoundedIconContainer}
                    rightIcon={
                        <Icon
                            name={hidePassword ? 'eye' : 'eye-off'}
                            type="ionicon"
                            size={22}
                            iconStyle={global.inputIcon}
                            onPress={_ => setHidePassword(!hidePassword)}
                        />
                    }
                    rightIconContainerStyle={global.inputIconRightContainer}
                    value={password}
                    keyboardAppearance="light"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={hidePassword}
                    returnKeyType={isSignUpPage ? 'next' : 'done'}
                    blurOnSubmit={true}
                    placeholder={t(EVISITOR_PASS)}
                    ref={passwordInput}
                    onSubmitEditing={() => {
                        isSignUpPage ? confirmationInput.current.focus() : loginFn();
                    }}
                    onChangeText={text => setPassword(text)}
                    errorMessage={isPasswordValid ? '' : t(USER_CREDS_INVALID)}
                    onFocus={() => setPasswordFocus(true)}
                    onBlur={() => setPasswordFocus(false)}
                    style={[
                        focusPassword && global.inputFocus,
                        global.inputRoundedWithIcon,
                        global.inputRounded,
                        global.inputWithIconRight,
                    ]}
                    errorStyle={{textAlign: 'center'}}
                />
                {isSignUpPage && (
                    <Input
                        leftIcon={
                            <Icon
                                name="lock"
                                size={22}
                                iconStyle={focusConfirmPassword ? global.inputIconFocus : global.inputIcon}
                            />
                        }
                        leftIconContainerStyle={global.inputRoundedIconContainer}
                        rightIcon={
                            <Icon
                                name={hidePassword ? 'eye' : 'eye-off'}
                                type="ionicon"
                                size={22}
                                iconStyle={global.inputIcon}
                                onPress={_ => setHideRegisterPassword(!hideRegisterPassword)}
                            />
                        }
                        rightIconContainerStyle={global.inputIconRightContainer}
                        value={confirmPassword}
                        secureTextEntry={hideRegisterPassword}
                        keyboardAppearance="light"
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="default"
                        returnKeyType={'done'}
                        blurOnSubmit={true}
                        placeholder={t(CONFIRM_PASSWORD)}
                        ref={confirmationInput}
                        onSubmitEditing={signUpFn}
                        onChangeText={text => setConfirmPassword(text)}
                        errorMessage={isConfirmPasswordValid ? '' : 'Please enter the same password'}
                        onFocus={() => setConfirmPasswordFocus(true)}
                        onBlur={() => setConfirmPasswordFocus(false)}
                        style={[
                            focusConfirmPassword && global.inputFocus,
                            global.inputRoundedWithIcon,
                            global.inputRounded,
                            global.inputWithIconRight,
                        ]}
                        errorStyle={{textAlign: 'center'}}
                    />
                )}
                <Button
                    containerStyle={[global.Button.containerStyle, styles.loginButtonContainer]}
                    buttonStyle={[global.Button.buttonStyle, styles.loginButton]}
                    titleStyle={global.Button.titleStyle}
                    activeOpacity={0.8}
                    title={isLoginPage ? t(SIGN_IN_BUTTON_TITLE) : t(SIGN_UP_BUTTON_TITLE)}
                    onPress={isLoginPage ? loginFn : signUpFn}
                    loading={isLoading}
                    disabled={isLoginPage ? false : disableButton}
                    disabledStyle={global.Button.disabledStyle}
                    disabledTitleStyle={global.Button.disabledTitleStyle}
                />
                {loginError && (
                    <Text style={{fontFamily: 'Montserrat-Medium', color: theme.colors.error, textAlign: 'center'}}>
                        {t(USER_CREDS_INVALID)}
                    </Text>
                )}

                {isLoginPage && (
                    <Button
                        containerStyle={[global.Button.containerStyle, styles.resetPasswordButtonContainer]}
                        buttonStyle={[global.Button.buttonStyle, styles.resetPasswordButton]}
                        titleStyle={[global.link]}
                        activeOpacity={0.8}
                        title={t(RESET_PASSWORD_TITLE)}
                        onPress={_ => setShowResetPassword(true)}
                        loading={isLoading}
                    />
                )}
                {showConsent && (
                    <ConsentDialog
                        consentAccepted={onConfirmConsent}
                        disableButton={setDisableButton}
                        isGuest={false}
                    />
                )}
                {showResetPassword && <ResetPasswordDialog open={showResetPassword} setOpen={setShowResetPassword} />}
                {/* {isFocused && (
                    <MessageDialog
                        message={generateMessage()}
                        isError={apiErrors}
                        open={openMessage}
                        handleOpen={setOpenMessage}
                    />
                )} */}
                <Text style={global.textNote}>
                    {t(LOGIN_NOTE)}
                    <Text style={[global.link, {fontSize: 12}]}>{t(ABOUT_EMAIL_OFFICE)}.</Text>
                </Text>
            </View>
            <Text style={[global.textNote, styles.appVersion, isMobile && {left: 20}]}>v.{APP_VERSION}</Text>
        </ImageBackground>
    );
};

export default LoginForm;
