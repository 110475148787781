import {useContext, useEffect, useState} from 'react';
import {Icon, LinearProgress, useTheme} from 'react-native-elements';
import {checkinGuest, checkoutGuest} from '../../api/inCheckinRestService';
import {
    ADDITIONAL_FIELDS,
    CHECKED_IN_STATUS,
    CHECKED_OUT_STATUS,
    CHECKOUT_ACTION,
    CREDITS_DEPLETED_MSG,
    CREDITS_DEPLETED_TITLE,
    CREDITS_EXPIRED_MSG,
    CREDITS_EXPIRED_TITLE,
    DATA,
    EVISITOR,
    FACILITY,
    FACILITY_CODE,
    FORESEEN_STAY_UNTIL_FIELD,
    GUEST_CHECKIN_ACTION_ERROR,
    GUEST_CHECKOUT_ACTION_ERROR,
    PIN_ID,
    REST_ERRORS,
    SWIPE_CHECKIN_ACTION,
    SWIPE_CHECKIN_TOURIST,
    SWIPE_CHECKOUT_TOURIST,
    SWIPE_VISIBLE,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TOURIST_NAME,
    TOURIST_SURNAME,
    UPDATED_STATUS,
    content,
    guests,
    status,
} from '../../constants/stringsAndFields';
import {showCreditsDialog, useCreditsAfterExpireConfirm} from '../../utils/credits';
import {getForCheckin, getForCheckout, syncTouristCheckoutStatus} from '../../utils/guestUtils';
import {deepCopy, isFacilityError} from '../../utils/helpers';
import {useSharedDialogs} from './useDialogs';
import {useSharedErrors} from './useErrors';
//import SwipeButton from './SwipeButton';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import DataContext from '../../context/dataContext';
import {SwipeButton} from '../../libs/react-native-swipe-button';
import globalStyle from '../../theme/globalStyle';
import Confirm2For1Dialog from '../Confirm2For1Dialog';
import CreditsDepletedDialog from '../CreditsDepletedDialog';
import ErrorMessageDialog from '../ErrorMessageDialog';
import FacilityErrorMessageDialog from '../checkin/FacilityErrorMessageDialog';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const FastCheckinActions = ({checkin, setCheckin, isFocused, navigation, useSharedCheckinActions}) => {
    const {onUpdateCheckin, updateGuest} = useContext(DataContext);
    const {theme} = useTheme();
    const {t} = useTranslation();
    const global = globalStyle(theme);
    const [retry, setRetry] = useState(false);
    const [potentiallyRetryGuestData, setPotentiallyRetryGuestData] = useState(null);
    const insets = useSafeAreaInsets();

    const {
        guestForCheckin,
        prepareGuestForCheckin,
        handleCheckinSwipeToggle,
        isSwipeReset,
        resetSwipeButton,
        swipeButtonType,
        fastCheckinInProgress,
        startFastCheckinInProgress,
        endFastCheckinInProgress,
        showActionsDialog,
        checkoutAction,
        triggerCheckoutAction,
    } = useSharedCheckinActions();
    const {closeAllErrors, setCheckinErrors, openCheckinErrorMsg, openFacilityErrorMsg} = useSharedErrors();
    const {openCreditsDepleted, openCreditsAfterExpire} = useSharedDialogs();

    const updateGuestStatus = async (guest, evisitorStatus, editedStatus = false) => {
        try {
            guest[status] = evisitorStatus;
            guest[UPDATED_STATUS] = editedStatus;
            await updateGuest(guest, true);
        } catch (e) {
            console.log(e);
        }
    };

    const showErrors = async (error, guest) => {
        const showedCreditsDialog = await showCreditsDialog(error, openCreditsDepleted, openCreditsAfterExpire);
        if (showedCreditsDialog) return;
        if (isFacilityError(error)) {
            const {type, description} = error?.[REST_ERRORS];
            setCheckinErrors({
                type: type,
                description: description,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openFacilityErrorMsg();
        } else if (error?.[REST_ERRORS]) {
            const {type, description} = error?.[REST_ERRORS];
            setCheckinErrors({
                type: type,
                description: description,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        } else if (error?.response) {
            const {type, description} = error.response?.data;
            setCheckinErrors({
                type: type,
                description: description,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        } else if (error?.message) {
            setCheckinErrors({
                type: 'Guest',
                description: error.message,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        }
    };

    const fastCheckinGuest = async guest => {
        const pinId = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        if (getForCheckin(guest[status], guest[UPDATED_STATUS]) && pinId) {
            try {
                startFastCheckinInProgress();
                await checkinGuest(pinId, guest[content]);
                guest[status] = CHECKED_IN_STATUS;
                guest[UPDATED_STATUS] = false;
                await updateGuest(guest, true);
                navigation.pop();
            } catch (error) {
                await showErrors(error, guest);
            } finally {
                endFastCheckinInProgress();
            }
        } else {
            console.log('Could not do a fast checkin, check if guest is edited!');
            const error = new Error(t(GUEST_CHECKIN_ACTION_ERROR));
            await showErrors(error, guest);
        }
        resetSwipeButton(true);
    };

    const fastCheckoutGuest = async (guest, action) => {
        const pinId = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        if (getForCheckout(guest[status]) && pinId) {
            try {
                startFastCheckinInProgress();
                const isAlreadyCheckedOut = await syncTouristCheckoutStatus(
                    pinId,
                    guest,
                    updateGuestStatus,
                    true,
                    null
                );
                if (!isAlreadyCheckedOut) {
                    if (action[DATA]) {
                        const {forseenStayUntil, timeEstimatedUntil} = action[DATA];
                        guest[content][FORESEEN_STAY_UNTIL_FIELD] = forseenStayUntil;
                        guest[content][TIME_ESTIMATED_STAY_UNTIL_FIELD] = timeEstimatedUntil;
                    }
                    await checkoutGuest(pinId, guest[content]);
                    guest[status] = CHECKED_OUT_STATUS;
                    guest[UPDATED_STATUS] = false;
                    await updateGuest(guest, true);
                }
                navigation.pop();
            } catch (error) {
                await showErrors(error, guest);
            } finally {
                endFastCheckinInProgress();
            }
        } else {
            console.log('Could not do a fast checkout!');
            const error = new Error(t(GUEST_CHECKOUT_ACTION_ERROR));
            await showErrors(error, guest);
        }
        resetSwipeButton(true);
    };

    const onUpdateAccomodation = async accomodation => {
        const filteredGuests = checkin?.[guests] ?? [];
        for (let filteredGuest of filteredGuests) {
            if (getForCheckin(filteredGuest[status], filteredGuest[UPDATED_STATUS])) {
                if (filteredGuest[content][FACILITY] !== accomodation[FACILITY_CODE]) {
                    filteredGuest[content][FACILITY] = accomodation[FACILITY_CODE];
                    await updateGuest(filteredGuest, false);
                }
            }
        }
        if (potentiallyRetryGuestData) {
            potentiallyRetryGuestData[content][FACILITY] = accomodation[FACILITY_CODE];
            setRetry(true);
        }
    };

    const onBuyCreditsPress = async () => {
        navigation.navigate('Packages');
    };

    useEffect(() => {
        if (isFocused) {
            if (guestForCheckin || (retry && potentiallyRetryGuestData)) {
                if (guestForCheckin) {
                    setPotentiallyRetryGuestData(deepCopy(guestForCheckin));
                }
                const fastGuest = guestForCheckin ?? potentiallyRetryGuestData;
                const isCheckinGuestAction = getForCheckin(fastGuest[status], fastGuest[UPDATED_STATUS]);
                const isCheckoutGuestAction = getForCheckout(fastGuest[status]);

                if (isCheckoutGuestAction && !checkoutAction) {
                    handleCheckinSwipeToggle(false);
                    resetSwipeButton(true);
                    showActionsDialog(CHECKOUT_ACTION);
                } else if (isCheckoutGuestAction && checkoutAction) {
                    fastCheckoutGuest(fastGuest, checkoutAction)
                        .catch(console.error)
                        .finally(_ => {
                            triggerCheckoutAction(null);
                            if (retry) {
                                setPotentiallyRetryGuestData(null);
                            }
                            setRetry(false);
                            handleCheckinSwipeToggle(false);
                            prepareGuestForCheckin(null);
                        });
                } else if (isCheckinGuestAction) {
                    fastCheckinGuest(fastGuest)
                        .catch(console.error)
                        .finally(_ => {
                            if (retry) {
                                setPotentiallyRetryGuestData(null);
                            }
                            setRetry(false);
                            handleCheckinSwipeToggle(false);
                            prepareGuestForCheckin(null);
                        });
                }
            }
        }
    }, [guestForCheckin, retry, checkoutAction]);

    if (!checkin || !swipeButtonType?.[SWIPE_VISIBLE]) {
        return null;
    }

    const isCheckin = swipeButtonType?.[SWIPE_CHECKIN_ACTION];
    const swipeButtonTitle = isCheckin ? t(SWIPE_CHECKIN_TOURIST) : t(SWIPE_CHECKOUT_TOURIST);
    const swipeIcon = isCheckin ? (
        <Icon
            type="material-community"
            name="account-plus"
            color={theme.colors.white}
            size={24}
            style={{marginBottom: 2}}
        />
    ) : (
        <Icon
            type="material-community"
            name="account-minus"
            color={theme.colors.white}
            size={24}
            style={{marginBottom: 2}}
        />
    );

    const onComplete = _ => {};

    return (
        <>
            {fastCheckinInProgress ? (
                <LinearProgress
                    color={theme.colors.primary}
                    variant="indeterminate"
                    style={{position: 'absolute', left: 0, top: 0}}
                />
            ) : (
                <View style={[global.swipeButtonRoot, {paddingBottom: insets.bottom}]}>
                    <SwipeButton
                        Icon={swipeIcon}
                        onToggle={handleCheckinSwipeToggle}
                        isSwipeReset={isSwipeReset}
                        resetSwipeButton={resetSwipeButton}
                        title={swipeButtonTitle}
                        onComplete={onComplete}
                        isCheckin={isCheckin}
                    />
                </View>
            )}

            <Confirm2For1Dialog
                title={t(CREDITS_EXPIRED_TITLE)}
                content={t(CREDITS_EXPIRED_MSG)}
                onConfirm={useCreditsAfterExpireConfirm}
                onBuy={onBuyCreditsPress}
                useSharedDialogs={useSharedDialogs}
            />
            <CreditsDepletedDialog
                title={t(CREDITS_DEPLETED_TITLE)}
                content={t(CREDITS_DEPLETED_MSG)}
                onBuy={onBuyCreditsPress}
                useSharedDialogs={useSharedDialogs}
            />
            <ErrorMessageDialog
                isScreenFocused={isFocused}
                useSharedErrors={useSharedErrors}
                closeErrorDialogs={closeAllErrors}
            />
            <FacilityErrorMessageDialog
                checkin={checkin}
                onUpdateCheckin={onUpdateCheckin}
                setCheckin={setCheckin}
                onUpdateAccomodation={onUpdateAccomodation}
                isScreenFocused={isFocused}
                useSharedErrors={useSharedErrors}
                closeErrorDialogs={closeAllErrors}
            />
        </>
    );
};

export default FastCheckinActions;
