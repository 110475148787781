import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, View, Pressable} from 'react-native';
import {ListItem, Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {ID, NAME, CHOOSE_EVACCOUNT_TITLE} from '../../constants/stringsAndFields';
import useStyles from './SelectOwnerDialog.styles';
import Modal from 'react-native-modal';
import DataContext from '../../context/dataContext';

const SelectOwnerDialog = ({isOpen, setOpen, onSelected, evaccounts}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const onItemClick = async item => {
        handleClose();
        await onSelected(item);
    };

    const render = ({item}) => (
        <ListItem key={item[ID]} bottomDivider onPress={_ => onItemClick(item)}>
            <ListItem.Content>
                <ListItem.Title style={global.textMain}>{item[NAME]}</ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(CHOOSE_EVACCOUNT_TITLE)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <FlatList data={evaccounts} renderItem={render} keyExtractor={(item, index) => item[ID]} />
            </View>
        </Modal>
    );
};

export default SelectOwnerDialog;
