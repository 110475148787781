import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Pressable} from 'react-native';
import {Button, Input, Icon, Text, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {
    ADD_EVISITOR_DIALOG_TITLE,
    EVISITOR_CONNECT,
    EVISITOR_PASS,
    EVISITOR_USERNAME,
} from '../../constants/stringsAndFields';
import Modal from 'react-native-modal';

const AddEvisitorDialog = ({open, setOpen, onConnect}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [user, setUser] = useState();
    const [password, setPassword] = useState();
    const [hidePassword, setHidePassword] = useState(true);
    const [disableConfirm, setDisableConfirm] = useState(false);
    const [focusUser, setUserFocus] = useState(false);
    const [focusPassword, setPasswordFocus] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setUser();
        setPassword();
    };

    const onConfirmClick = async () => {
        await onConnect({pin: user, pin_password: password});
    };

    const setUserField = text => setUser(text);
    const setPasswordField = text => setPassword(text);

    const focusUserFn = () => setUserFocus(true);
    const unfocusUserFn = () => setUserFocus(false);
    const focusPassFn = () => setPasswordFocus(true);
    const unfocusPassFn = () => setPasswordFocus(false);

    useEffect(() => {
        if (user && user.length > 1 && password && password.length > 1) {
            setDisableConfirm(false);
        } else {
            setDisableConfirm(true);
        }
    }, [user, password]);

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}
            avoidKeyboard={true}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(ADD_EVISITOR_DIALOG_TITLE)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={global.modalContent}>
                    <Input
                        label={t(EVISITOR_USERNAME)}
                        leftIcon={
                            <Icon
                                name="person"
                                size={20}
                                iconStyle={focusUser ? global.inputIconFocus : global.inputIcon}
                            />
                        }
                        leftIconContainerStyle={global.inputIconContainer}
                        onChangeText={setUserField}
                        onFocus={focusUserFn}
                        onBlur={unfocusUserFn}
                        style={[focusUser && global.inputFocus, global.inputWithIcon]}
                        containerStyle={{marginBottom: 10}}
                    />
                    <Input
                        label={t(EVISITOR_PASS)}
                        secureTextEntry={hidePassword}
                        leftIcon={
                            <Icon
                                name="lock"
                                size={20}
                                iconStyle={focusPassword ? global.inputIconFocus : global.inputIcon}
                            />
                        }
                        leftIconContainerStyle={global.inputIconContainer}
                        rightIcon={
                            <Icon
                                name={hidePassword ? 'eye' : 'eye-off'}
                                type="ionicon"
                                size={20}
                                iconStyle={global.inputIcon}
                                onPress={_ => setHidePassword(!hidePassword)}
                            />
                        }
                        rightIconContainerStyle={global.inputIconRightContainer}
                        onChangeText={setPasswordField}
                        onFocus={focusPassFn}
                        onBlur={unfocusPassFn}
                        style={[focusPassword && global.inputFocus, global.inputWithIcon, global.inputWithIconRight]}
                    />
                </View>
                <View style={global.modalFooter}>
                    <Button
                        onPress={onConfirmClick}
                        disabled={disableConfirm}
                        title={t(EVISITOR_CONNECT)}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default AddEvisitorDialog;
