import * as yup from 'yup';
import {
    ARRIVAL_ORGANIZATION,
    CITIZENSHIP,
    CITY_OF_RESIDENCE,
    COUNTRY_OF_BIRTH,
    COUNTRY_OF_RESIDENCE,
    DATE_OF_BIRTH_FIELD,
    DOCUMENT_NUMBER,
    DOCUMENT_TYPE,
    FORESEEN_STAY_UNTIL_FIELD,
    GENDER_FIELD,
    OFFERED_SERVICE_TYPE,
    PAYMENT_CATEGORY,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
    TOURIST_NAME,
    TOURIST_SURNAME,
    ARRIVAL_DATE_LABEL_ERROR,
    DEPARTURE_DATE_LABEL_ERROR,
    DATE_OF_BIRTH_LABEL_ERROR,
} from '../../constants/stringsAndFields';
import {getDateFromDateString} from '../../utils/dateHelper';

export const schema = t =>
    yup.object().shape(
        {
            [TOURIST_NAME]: yup.string().required(),
            [TOURIST_SURNAME]: yup.string().required(),
            [DOCUMENT_NUMBER]: yup.string().required(),
            [GENDER_FIELD]: yup.string().required(),
            [DATE_OF_BIRTH_FIELD]: yup.string().test(
                'dateOfBirth',
                d => `${t(DATE_OF_BIRTH_LABEL_ERROR)}`,
                value => {
                    if (value) {
                        const birthDate = getDateFromDateString(value);
                        const today = new Date();
                        return birthDate < today ? true : false;
                    }
                    return false;
                }
            ),
            [CITIZENSHIP]: yup.string().required(),
            [COUNTRY_OF_RESIDENCE]: yup.string().required(),
            [COUNTRY_OF_BIRTH]: yup.string().required(),
            [CITY_OF_RESIDENCE]: yup.string().required(),
            [DOCUMENT_TYPE]: yup.string().required(),
            [PAYMENT_CATEGORY]: yup.string().required(),
            [OFFERED_SERVICE_TYPE]: yup.string().required(),
            [ARRIVAL_ORGANIZATION]: yup.string().required(),
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: yup.string().required(),
            [TIME_STAY_FROM_FIELD]: yup.string().required(),
            [STAY_FROM_FIELD]: yup.string().test({
                name: 'dateBefore',
                exclusive: false,
                params: {},
                /* eslint-disable no-template-curly-in-string */
                message: t(ARRIVAL_DATE_LABEL_ERROR),
                test: function (value) {
                    const fromDate = getDateFromDateString(value);
                    const toDate = getDateFromDateString(this.parent[FORESEEN_STAY_UNTIL_FIELD]);
                    return fromDate <= toDate ? true : false;
                },
            }),
            [FORESEEN_STAY_UNTIL_FIELD]: yup.string().test({
                name: 'dateAfter',
                exclusive: false,
                params: {},
                message: t(DEPARTURE_DATE_LABEL_ERROR),
                test: function (value) {
                    const fromDate = getDateFromDateString(this.parent[STAY_FROM_FIELD]);
                    const toDate = getDateFromDateString(value);
                    return toDate >= fromDate ? true : false;
                },
            }),
        },
        [STAY_FROM_FIELD, FORESEEN_STAY_UNTIL_FIELD]
    );

/*
    "ArrivalOrganisation": "I",
    "Citizenship": "HRV",
    "CityOfResidence": "Osijek",
    "CountryOfBirth": "HRV",
    "CountryOfResidence": "HRV",
    "DateOfBirth": "19910130",
    "DocumentNumber": "1235615649",
    "DocumentType": "051",
    "Facility": "0033302",
    "ForeseenStayUntil": "20210520",
    "Gender": "Muški",
    "ID": "6ac7fe96-f3f6-46b7-a85c-ff6f3e642655",
    "OfferedServiceType": "Noćenje",
    "StayFrom": "20210514",
    "TTPaymentCategory": "14",
    "TimeEstimatedStayUntil": "10:00",
    "TimeStayFrom": "23:45",
    "TouristMiddleName": "",
    "TouristName": "Mina",
    "TouristSurname": "Minić",
*/

export default schema;
