import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    searchInput: {
        backgroundColor: theme.colors.lightBg,
        borderColor: 'rgba(93, 77, 157, 0.3)',
        borderWidth: 1,
        borderRadius: 40,
        paddingRight: 35,
        paddingLeft: 35,
        paddingVertical: 0,
    },

    linearProgress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 50,
        zIndex: 2,
    },
}));

export default useStyles;
