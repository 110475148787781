import React, {useRef, useEffect} from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {fromBase64, toBase64} from '../utils/jwt';
import {DEFAULT_LANG} from '../utils/i18n';
import {content, EV_ACCOUNT, FACILITY} from '../constants/stringsAndFields';
import {parseCamResults} from '../utils/cameraCommon';
import {checkAndOpenCamera, closeCamera} from '../utils/camera';

const IS_WEB = Platform.OS === 'web';

const ScannerScreen = ({route, navigation}) => {
    const {
        checkinId,
        callbackScreen = 'Guest',
        lang = DEFAULT_LANG,
        facilityData = null,
        additionalParams = null,
        importFromGallery = 'false',
    } = route.params;

    const cameraRef = useRef(null);

    const onScanResult = scanData => {
        try {
            const guestData = !IS_WEB ? parseCamResults(scanData, true) : scanData;
            // this is for calendar input scanner
            if (facilityData && additionalParams) {
                const decodedData = JSON.parse(fromBase64(facilityData));
                const guestAndFacilityData = {
                    [content]: guestData,
                    [EV_ACCOUNT]: decodedData?.[EV_ACCOUNT],
                    [FACILITY]: decodedData?.[FACILITY],
                };
                navigation.replace(callbackScreen, {
                    checkinId: checkinId,
                    isNewGuest: true,
                    guestData: toBase64(JSON.stringify(guestAndFacilityData)),
                    consent: true,
                    lang: lang,
                    ...additionalParams,
                    scanned: 'true',
                });
            } else {
                // this is for self-checkin and regular checkin screens
                navigation.replace(callbackScreen, {
                    checkinId: checkinId,
                    isNewGuest: true,
                    guestData: toBase64(JSON.stringify({[content]: guestData})),
                    consent: true,
                    lang: lang,
                    scanned: 'true',
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onScanError = error => {
        console.log('onScanError');
        console.log(error);
        if (IS_WEB) {
            navigation.goBack();
        } else {
            navigation.pop();
        }
    };

    const openCamera = async importFromGallery => {
        const importFromGalleryFlag = importFromGallery === 'true' ? true : false;
        await checkAndOpenCamera(onScanResult, onScanError, cameraRef, importFromGalleryFlag);
    };

    useEffect(() => {
        if (checkinId || facilityData) {
            openCamera(importFromGallery).catch(console.error);
        }
        return () => {
            // componentWillUnmount event
            IS_WEB && closeCamera();
        };
    }, []);

    return <View style={styles.container} ref={cameraRef}></View>;
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

export default ScannerScreen;
