import {utils, write} from 'xlsx-js-style';
import {
    EV_ACCOUNTS_DATA,
    EXPORT_HEADER_ADDRESS,
    EXPORT_HEADER_INTRO,
    EXPORT_HEADER_NAME,
    EXPORT_HEADER_OIB,
    EXPORT_HEADER_PLACE,
    EXPORT_HEADER_REPORT_NAME,
    EXPORT_HEADER_REPORT_OBJECT,
    EXPORT_HEADER_REPORT_OBJECT_ALL,
    EXPORT_HEADER_REPORT_PERIOD,
    EXPORT_HEADER_REPORT_TIME,
    EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME,
    EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME,
} from '../constants/stringsAndFields';
import {getNowDayDateReportFormated} from './dateHelper';
import {EXPORT_CHECKIN_SHEET, EXPORT_INVOICE_SHEET} from './export';
import {writeBufferXlsxToFile} from './exportData';
import {getExportUserData} from './helpers';

export const writeToXlsxWithHeader = async (data, headerData, fileName, sheetName, t) => {
    const wb = utils.book_new();
    const reportTitle =
        sheetName === EXPORT_CHECKIN_SHEET
            ? EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME
            : EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME;

    // Report Header
    const headerReportInfo = [
        [
            {v: t(EXPORT_HEADER_REPORT_NAME), t: 's', s: {font: {bold: true}}},
            {v: t(reportTitle), t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_REPORT_TIME), t: 's', s: {font: {bold: true}}},
            {v: getNowDayDateReportFormated(), t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_REPORT_OBJECT), t: 's', s: {font: {bold: true}}},
            {v: t(EXPORT_HEADER_REPORT_OBJECT_ALL), t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_REPORT_PERIOD), t: 's', s: {font: {bold: true}}},
            {v: headerData?.['dateRange'], t: 's'},
        ],
        [{v: ''}],
    ];

    const evAccounts = headerData?.[EV_ACCOUNTS_DATA];
    const multiEvAccounts = evAccounts && evAccounts.length > 1;

    const headerUserData = getExportUserData(evAccounts.pop());
    const headerUserInfo = [
        [{v: t(EXPORT_HEADER_INTRO), t: 's', s: {font: {bold: true}}}],
        [
            {v: t(EXPORT_HEADER_NAME), t: 's', s: {font: {bold: true}}},
            {v: headerUserData[EXPORT_HEADER_NAME], t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_OIB), t: 's', s: {font: {bold: true}}},
            {v: headerUserData[EXPORT_HEADER_OIB], t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_PLACE), t: 's', s: {font: {bold: true}}},
            {v: headerUserData[EXPORT_HEADER_PLACE], t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_ADDRESS), t: 's', s: {font: {bold: true}}},
            {v: headerUserData[EXPORT_HEADER_ADDRESS], t: 's'},
        ],
        [{v: ''}],
    ];

    // Data Header
    const dataNameHeader = [
        Object.keys(data[0]).map(name => {
            return {v: name, t: 's', s: {font: {bold: true}}};
        }),
    ];

    // Data Items
    const dataItems = [];
    data.map(item => {
        const cellData = Object.values(item);
        const out = [];
        cellData.forEach(cell => out.push({v: cell, t: 's'}));
        dataItems.push(out);
    });

    const ws = multiEvAccounts
        ? utils.aoa_to_sheet([...headerReportInfo, ...dataNameHeader, ...dataItems])
        : utils.aoa_to_sheet([...headerReportInfo, ...headerUserInfo, ...dataNameHeader, ...dataItems]);

    // settings column witdh
    // TODO: caclulate this column sizes dynamically by counting chars for each cell
    let wscols = [{wch: 27}, {wch: 20}, {wch: 10}, {wch: 20}, {wch: 10}];
    if (sheetName === EXPORT_CHECKIN_SHEET) {
        //       ownerOib	ownerName	facilityName	accomodationUnit	touristName	touristStayFrom	touristForseenStayUntil	touristStatus
        wscols = [{wch: 27}, {wch: 20}, {wch: 30}, {wch: 10}, {wch: 25}, {wch: 25}, {wch: 25}, {wch: 10}];
    } else if (sheetName === EXPORT_INVOICE_SHEET) {
        //      Redni broj	Broj računa	Nadnevak	Ime i prezime	Iznos računa Način plaćanja
        wscols = [{wch: 27}, {wch: 20}, {wch: 10}, {wch: 20}, {wch: 10}, {wch: 18}];
    }
    ws['!cols'] = wscols;
    utils.book_append_sheet(wb, ws, sheetName);
    const wbout = write(wb, {type: 'buffer', bookType: 'xlsx'});
    await writeBufferXlsxToFile(wbout, fileName, t);
};
