import {
    ADDITIONAL_FIELDS,
    CREATED_AT,
    EVISITOR,
    FORESEEN_STAY_UNTIL_FIELD,
    INVOICE_EXISTS,
    PIN,
    SLUG,
    STATUS,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
    UPDATED_AT,
    USER_ID,
    USER_ID_IDX,
    guests as guests_field,
    id,
} from '../constants/stringsAndFields';
import {getCheckinsYear, getUserId} from '../utils/userUtils';
import {checkTableExists, deleteData, insertData, selectData, selectDataFromYear, updateData} from './common';
import {GUESTS_TABLE, deleteGuest, getGuestByCheckin, getGuestsByCheckinWithoutContent} from './guest';
import {getInvoiceExistenceByCheckin} from './invoice';
import {runSqlCmd} from './sqlOperations';

export const CHECKINS_TABLE = 'checkins';
const keys = [
    id,
    USER_ID,
    FORESEEN_STAY_UNTIL_FIELD,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
    ADDITIONAL_FIELDS,
    SLUG,
    STATUS,
    CREATED_AT,
    UPDATED_AT,
];

export const reCreateCheckinTable = async tx => {
    await runSqlCmd(tx, `DROP TABLE IF EXISTS ${CHECKINS_TABLE};`);
    await runSqlCmd(tx, `DROP INDEX IF EXISTS ${USER_ID_IDX}`);
    await runSqlCmd(
        tx,
        `CREATE TABLE IF NOT EXISTS ${CHECKINS_TABLE}(
      ${id} TEXT PRIMARY KEY NOT NULL,
      ${USER_ID} TEXT,
      ${FORESEEN_STAY_UNTIL_FIELD} TEXT,
      ${STAY_FROM_FIELD} TEXT,
      ${TIME_ESTIMATED_STAY_UNTIL_FIELD} TEXT,
      ${TIME_STAY_FROM_FIELD} TEXT,
      ${ADDITIONAL_FIELDS} TEXT,
      ${SLUG} TEXT,
      ${STATUS} TEXT,
      ${CREATED_AT} TEXT,
      ${UPDATED_AT} TEXT);`
    );
    await runSqlCmd(tx, `CREATE INDEX ${USER_ID_IDX} ON ${CHECKINS_TABLE} (${USER_ID});`);
};

export const insertCheckin = async (tx, checkin) => {
    const userId = await getUserId();
    await insertData(tx, CHECKINS_TABLE, keys, {...checkin, [USER_ID]: userId});
};

export const deleteAllCheckins = async tx => {
    await deleteData(tx, CHECKINS_TABLE);
    await deleteData(tx, GUESTS_TABLE);
};

export const deleteCheckin = async (tx, checkin) => {
    await deleteData(tx, CHECKINS_TABLE, checkin[id]);
    const guests = await getGuestByCheckin(tx, checkin);
    if (guests) {
        for (let guest of guests) {
            await deleteGuest(tx, guest);
        }
    }
};

export const updateCheckin = async (tx, checkin) => {
    await updateData(tx, CHECKINS_TABLE, checkin, id);
};

export const getCheckins = async (tx, evAccount = null) => {
    const userId = await getUserId();
    const year = await getCheckinsYear();
    const checkins = await selectDataFromYear(tx, CHECKINS_TABLE, keys, USER_ID, userId, CREATED_AT, year);
    const deserializedCheckins = [];
    const evAccountPin = evAccount?.[PIN];
    if (checkins) {
        for (let checkin of checkins) {
            checkin[ADDITIONAL_FIELDS] = JSON.parse(checkin[ADDITIONAL_FIELDS]);
            const checkinEvisitorPin = checkin[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN];
            // if ev account is set, check it, if not set then ignore it
            if ((evAccountPin && evAccountPin === checkinEvisitorPin) || !evAccountPin) {
                const guests = await getGuestByCheckin(tx, checkin);
                checkin[guests_field] = guests;
                deserializedCheckins.push(checkin);
            }
        }
    }
    return deserializedCheckins;
};

export const getCheckinsWithoutGuestData = async tx => {
    const userId = await getUserId();
    const year = await getCheckinsYear();
    const checkins = await selectDataFromYear(tx, CHECKINS_TABLE, keys, USER_ID, userId, CREATED_AT, year);
    const deserializedCheckins = [];
    if (checkins) {
        for (let checkin of checkins) {
            checkin[ADDITIONAL_FIELDS] = JSON.parse(checkin[ADDITIONAL_FIELDS]);
            const guests = await getGuestsByCheckinWithoutContent(tx, checkin);
            checkin[guests_field] = guests;
            checkin[INVOICE_EXISTS] = await getInvoiceExistenceByCheckin(tx, checkin);
            deserializedCheckins.push(checkin);
        }
    }
    return deserializedCheckins;
};

export const getCheckinDb = async (tx, dataId) => {
    const checkins = await selectData(tx, CHECKINS_TABLE, keys, id, dataId);
    const deserializedCheckins = [];
    if (checkins) {
        for (let checkin of checkins) {
            checkin[ADDITIONAL_FIELDS] = JSON.parse(checkin[ADDITIONAL_FIELDS]);
            const guests = await getGuestByCheckin(tx, checkin);
            checkin[guests_field] = guests;
            deserializedCheckins.push(checkin);
        }
    }
    return deserializedCheckins.pop();
};

export const checkCheckinTable = async tx => {
    return await checkTableExists(tx, CHECKINS_TABLE);
};

/*
CHECKIN BACKEND
id: uuid.UUID = db.Column(db.UUID, default=uuid.uuid4, primary_key=True)
user_id: uuid.UUID = db.Column(db.UUID, db.ForeignKey('users.id'), nullable=False)
status: str = db.Column(db.String(255), nullable=False)
StayFrom: str = db.Column(db.String(255), nullable=False)
ForeseenStayUntil: str = db.Column(db.String(255), nullable=False)
TimeStayFrom: str = db.Column(db.String(255), nullable=False)
TimeEstimatedStayUntil: str = db.Column(db.String(255), nullable=False)
additional_info: Dict = db.Column(JSONB, default={})
created_at: datetime.datetime = db.Column(db.DateTime, default=datetime.datetime.utcnow)
updated_at: datetime.datetime = db.Column(db.DateTime, onupdate=datetime.datetime.utcnow)

CHECKIN JS
const newCheckin = {
    [FORESEEN_STAY_UNTIL_FIELD]: checkinTime[FORESEEN_STAY_UNTIL_FIELD] ?? getTomorrowDate(),
    [id]: newCheckinId,
    [STAY_FROM_FIELD]: checkinTime[STAY_FROM_FIELD] ?? getNowDate(),
    [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkinTime[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? getNowDate(),
    [TIME_STAY_FROM_FIELD]: checkinTime[TIME_STAY_FROM_FIELD] ?? getNowDate(),
    [ADDITIONAL_FIELDS]: {
        [DATE_CREATED]: Date.now(),
        [EVISITOR]: {
            [NAME]: location?.[EV_ACCOUNT]?.[NAME],
            [PIN_ID]: location?.[EV_ACCOUNT]?.[PIN_ID]
        },
        [FACILITY_FIELD]: {
            [NAME]: location?.[FACILITY_FIELD]?.[NAME],
            [FACILITY_CODE]: facilityCode,
            [NAME_CODE]: location?.[FACILITY_FIELD]?.[NAME_CODE]
        },
        [ACCOMODATION]: {
            [NAME_CODE]: ""
        },
        [MAX_GUEST_NUMBER]: numberOfGuests
    }
}
*/
