import React from 'react';
import {View, Linking, Platform, useWindowDimensions} from 'react-native';
import {Text, Button, useTheme} from 'react-native-elements';
import {useTranslation} from 'react-i18next';
import globalStyle from '../../theme/globalStyle';
import useStyles from './Package.styles';
import {BUY, DURATION, TITLE_NUMBER_OF_GUESTS} from '../../constants/stringsAndFields';
import {getUserId} from '../../utils/userUtils';

const Package = ({product, iapHandler}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const width = useWindowDimensions().width;
    const isTablet = width >= 500 && width < theme.desktopBP;
    const isMobileSmall = width < 500;

    const IS_IOS = Platform.OS === 'ios';
    const onPress = async () => {
        if (IS_IOS) {
            iapHandler(product);
        } else {
            let url = product.url;
            const userId = await getUserId();
            if (userId) {
                url = url.replace('proforma-invoice-email', `${userId}/proforma-invoice-input`);
            }
            Linking.openURL(url);
        }
    };

    return (
        <View
            style={[
                styles.packageWrapper,
                isMobileSmall && {width: '100%'},
                isTablet && {width: '50%', maxWidth: 280},
            ]}>
            <View style={[global.boxShadow, {borderRadius: 20}]}>
                <View style={[global.card, styles.packageBox]}>
                    <View style={styles.packageTitleBox}>
                        <Text style={styles.packageTitle}>{product.title}</Text>
                    </View>
                    <Text style={[global.textMain, styles.packagesText]}>{t(TITLE_NUMBER_OF_GUESTS)}:</Text>
                    <Text style={styles.checkins}>{product.credits}</Text>
                    <Text style={[global.textMain, styles.packagesText]}>{t(DURATION)}:</Text>
                    <Text style={styles.expiration}>{product.duration_text}</Text>
                    <View style={styles.packagePriceBox}>
                        <Text style={styles.price}>{product.price}</Text>
                    </View>

                    <Button
                        onPress={onPress}
                        title={t(BUY)}
                        containerStyle={[global.Button.containerStyle, styles.packageBtn]}
                        buttonStyle={global.Button.buttonStyle}
                        titleStyle={global.Button.titleStyle}></Button>
                </View>
            </View>
        </View>
    );
};

export default Package;
