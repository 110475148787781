import * as yup from 'yup';
import {
    INVOICE_FORM_ACC_UNIT,
    INVOICE_FORM_CLIENT,
    INVOICE_FORM_COMPANY_OIB,
    INVOICE_FORM_CURRENCY,
    INVOICE_FORM_DATE,
    INVOICE_FORM_DISCOUNT_TOTAL_SUM,
    INVOICE_FORM_ELECTRONIC_INVOICE,
    INVOICE_FORM_GUEST,
    INVOICE_FORM_GUEST_ADDRESS,
    INVOICE_FORM_IN_NAME,
    INVOICE_FORM_IN_NAME_ADDRESS,
    INVOICE_FORM_IN_NAME_OIB,
    INVOICE_FORM_LANG,
    INVOICE_FORM_NUMBER,
    INVOICE_FORM_OVERNIGHT_COUNT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE,
    INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV,
    INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV,
    INVOICE_FORM_OWNER,
    INVOICE_FORM_OWNER_ADDRESS,
    INVOICE_FORM_OWNER_OIB,
    INVOICE_FORM_PAYMENT_TYPE,
    INVOICE_FORM_PDV_TOTAL_SUM,
    INVOICE_FORM_PRICE_FINAL,
    INVOICE_FORM_PRICE_PER_NIGHT,
    INVOICE_FORM_PRICE_PER_NIGHT_LABEL,
    INVOICE_FORM_SERVICE_TYPE,
    INVOICE_FORM_SHOW_FOOTER,
    INVOICE_FORM_SHOW_OPERATER,
    INVOICE_FORM_STAY_FROM,
    INVOICE_FORM_STAY_TO,
    INVOICE_FORM_TIME,
    INVOICE_FORM_TOURIST_TAX_ADULT_RATE,
    INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL,
    INVOICE_FORM_TOURIST_TAX_CHILD_RATE,
    INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL,
    INVOICE_FORM_TOURIST_TAX_TOTAL,
    INVOICE_FORM_USER_IN_PDV_SYSTEM,
    CALCULATION_PRICE_TYPE,
    INVOICE_FORM_VER,
    OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV,
    TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM,
} from '../../constants/stringsAndFields';

export const schema = yup.object().shape(
    {
        [INVOICE_FORM_NUMBER]: yup.string().optional(),
        [INVOICE_FORM_LANG]: yup.string().optional(),
        [INVOICE_FORM_OWNER]: yup.string().optional(),
        [INVOICE_FORM_OWNER_ADDRESS]: yup.string().optional(),
        [INVOICE_FORM_DATE]: yup.string().optional(),
        [INVOICE_FORM_TIME]: yup.string().optional(),
        [INVOICE_FORM_GUEST]: yup.string().optional(),
        [INVOICE_FORM_GUEST_ADDRESS]: yup.string().optional(),
        [INVOICE_FORM_SERVICE_TYPE]: yup.string().optional(),
        [INVOICE_FORM_ACC_UNIT]: yup.string().optional(),
        [INVOICE_FORM_PAYMENT_TYPE]: yup.string().optional(),
        [INVOICE_FORM_CURRENCY]: yup.string().optional(),
        [INVOICE_FORM_PRICE_PER_NIGHT]: yup.string().optional(),
        [INVOICE_FORM_PRICE_PER_NIGHT_LABEL]: yup.string().optional(),
        [INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE]: yup.string().optional(),
        [INVOICE_FORM_PRICE_FINAL]: yup.string().optional(),
        [INVOICE_FORM_STAY_FROM]: yup.string().optional(),
        [INVOICE_FORM_STAY_TO]: yup.string().optional(),
        [INVOICE_FORM_OWNER_OIB]: yup.string().optional(),
        [INVOICE_FORM_CLIENT]: yup.string().optional(),
        [INVOICE_FORM_TOURIST_TAX_ADULT_RATE]: yup.string().optional(),
        [INVOICE_FORM_TOURIST_TAX_CHILD_RATE]: yup.string().optional(),
        [INVOICE_FORM_COMPANY_OIB]: yup.string().optional(),
        [INVOICE_FORM_IN_NAME]: yup.string().optional(),
        [INVOICE_FORM_IN_NAME_OIB]: yup.string().optional(),
        [INVOICE_FORM_IN_NAME_ADDRESS]: yup.string().optional(),
        [INVOICE_FORM_USER_IN_PDV_SYSTEM]: yup.string().optional(),
        [INVOICE_FORM_TOURIST_TAX_TOTAL]: yup.string().optional(),
        [CALCULATION_PRICE_TYPE]: yup.string().optional(),
        [INVOICE_FORM_ELECTRONIC_INVOICE]: yup.string().optional(),
        [INVOICE_FORM_SHOW_OPERATER]: yup.string().optional(),
        [INVOICE_FORM_SHOW_FOOTER]: yup.string().optional(),
        [INVOICE_FORM_VER]: yup.string().optional(),
        [INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT]: yup.string().optional(),
        [INVOICE_FORM_DISCOUNT_TOTAL_SUM]: yup.string().optional(),
        [INVOICE_FORM_OVERNIGHT_COUNT]: yup.string().optional(),
        [INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV]: yup.string().optional(),
        [INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV]: yup.string().optional(),
        [INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL]: yup.string().optional(),
        [INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL]: yup.string().optional(),
        [INVOICE_FORM_PDV_TOTAL_SUM]: yup.string().optional(),
        [TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM]: yup.string().optional(),
        [OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV]: yup.string().optional(),
    },
    []
);

export default schema;
