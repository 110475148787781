import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    modal: {
        margin: 0,
    },

    modalDialog: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        maxWidth: 800,
        borderRadius: 0,
        overflow: 'hidden',
    },

    dialogSection: {
        borderRadius: 10,
        marginTop: 15,
        marginHorizontal: 15,
        backgroundColor: 'rgba(244, 245, 251, 0.4)',
        paddingHorizontal: 10,
        paddingTop: 15,
        borderColor: 'rgba(93, 77, 157,  0.4)',
        borderWidth: 1,
    },

    dots: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
    },

    dot: {
        width: 7,
        height: 7,
        borderRadius: 10,
        backgroundColor: theme.colors.primaryExtraLight,
        margin: 5,
    },

    dotActive: {
        backgroundColor: theme.colors.primaryMedium,
    },

    formWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },

    inputContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },

    inputActive: {
        width: '100%',
        borderWidth: 1,
        borderRadius: 10,
        paddingLeft: 10,
        opacity: 1,
        backgroundColor: theme.colors.white,
    },

    inputDisabled: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.colors.greyExtraLight,
        borderRadius: 10,
        paddingLeft: 10,
        opacity: 1,
        color: '#656565',
    },

    buttonsWrapper: {
        justifyContent: 'space-around',
        marginBottom: 30,
        position: 'absolute',
        top: -20,
        left: 5,
        right: 5,
    },

    stayButton: {
        width: '45%',
        overflow: 'hidden',
        borderRadius: 40,
    },
}));

export default useStyles;
