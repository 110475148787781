import {Platform} from 'react-native';
import {
    BANK,
    BANK_ADDRESS,
    BANK_INFO,
    CASH,
    CIJENA,
    CREDIT_CARD,
    DATE,
    DATUM,
    DISCOUNT,
    FOR_BILLING,
    IBAN,
    INVOICE_CHECKIN,
    INVOICE_CHECKOUT,
    INVOICE_ELECTRONIC_INVOICE,
    INVOICE_FORM_ACC_UNIT,
    INVOICE_FORM_CLIENT,
    INVOICE_FORM_COMPANY_OIB,
    INVOICE_FORM_COMPANY_TYPE,
    INVOICE_FORM_CURRENCY,
    INVOICE_FORM_DATE,
    INVOICE_FORM_DISCOUNT_TOTAL_SUM,
    INVOICE_FORM_ELECTRONIC_INVOICE,
    INVOICE_FORM_FOOTER_TEXT,
    INVOICE_FORM_GUEST_ADDRESS,
    INVOICE_FORM_GUEST_STAY_RANGE,
    INVOICE_FORM_GUEST_TYPE,
    INVOICE_FORM_IN_NAME,
    INVOICE_FORM_IN_NAME_ADDRESS,
    INVOICE_FORM_IN_NAME_OIB,
    INVOICE_FORM_NUMBER,
    INVOICE_FORM_OPERATOR_NAME,
    INVOICE_FORM_OPERATOR_REF,
    INVOICE_FORM_OVERNIGHT_COUNT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE,
    INVOICE_FORM_OVERNIGHT_PDV_AMOUNT,
    INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE,
    INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV,
    INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV,
    INVOICE_FORM_OWNER,
    INVOICE_FORM_OWNER_ADDRESS,
    INVOICE_FORM_OWNER_OIB,
    INVOICE_FORM_PAYMENT_TYPE,
    INVOICE_FORM_PDV_TOTAL_SUM,
    INVOICE_FORM_PRICE_FINAL,
    INVOICE_FORM_PRICE_PER_NIGHT,
    INVOICE_FORM_SERVICE_TYPE,
    INVOICE_FORM_SHOW_FOOTER,
    INVOICE_FORM_SHOW_OPERATER,
    INVOICE_FORM_TIME,
    INVOICE_FORM_TOURIST_TAX_ADULT_COUNT,
    INVOICE_FORM_TOURIST_TAX_ADULT_RATE,
    INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL,
    INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED,
    INVOICE_FORM_TOURIST_TAX_CHILD_COUNT,
    INVOICE_FORM_TOURIST_TAX_CHILD_RATE,
    INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL,
    INVOICE_FORM_USER_IN_PDV_SYSTEM,
    INVOICE_IN_NAME_LABEL,
    INVOICE_OIB,
    INVOICE_OWNER_IN_PDV_SYSTEM,
    INVOICE_PIN,
    INVOICE_SOJOURN_TAX_LABEL,
    INVOICE_SOJOURN_TAX_NOT_ICLUDED,
    JEDINICA,
    KOLICINA,
    NACIN_PLACANJA,
    OTHER_PAYMENT,
    OVERNIGHT,
    PAYMENT_TYPE,
    PDV,
    POPUST,
    PORTAL_PAYMENT,
    PRICE,
    QUANTITY,
    SERVICE,
    STORAGE_USER_DATA,
    SWIFT,
    TIME,
    TOTAL,
    TOTAL_PRICE,
    TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM,
    TRANSACTION_ACCOUNT,
    UKUPNA_CIJENA,
    UKUPNO,
    UNIT,
    USER_DATA_CONTACT,
    USER_DATA_EMAIL,
    USER_DATA_FORM_BANK,
    USER_DATA_FORM_BANK_ADDRESS,
    USER_DATA_FORM_BANK_IBAN,
    USER_DATA_FORM_BANK_SWIFT,
    USER_DATA_FORM_EMAIL,
    USER_DATA_FORM_TEL,
    USER_INFO,
    USLUGA,
    VAT,
    VRIJEME,
    ZA_NAPLATU,
} from '../../constants/stringsAndFields';
import {sanitizeObject as sanitizeHtmlObject} from '../data';
import {getDateString} from '../dateHelper';
import {getBoolean, getBooleanTrueDefault, isCompanyType, isStringNotEmpty} from '../helpers';
import {getInvoiceLogo, getUserData} from '../userUtils';
import {convertAndCalculateLegacyData, getOrCalculateDiscountTotal} from './invoice.functions';
import {head} from './invoice.html.header';

export const DEFAULT_INVOICE_NUMBER = '1';

export const DEFAULT_PAYMENT_TYPE = TRANSACTION_ACCOUNT;
export const PAYMENT_TYPES = [CASH, TRANSACTION_ACCOUNT, CREDIT_CARD, PORTAL_PAYMENT, OTHER_PAYMENT];

export const DEFAULT_CUSTOMER_TYPE = INVOICE_FORM_GUEST_TYPE;
export const CUSTOMER_TYPES = [INVOICE_FORM_GUEST_TYPE, INVOICE_FORM_COMPANY_TYPE];

export const PRICE_CURRENCIES = ['EUR'];
export const DEFAULT_CURRENCY = '€';

export const ADULT_TAX_PRICE = 1.59;
export const CHILD_TAX_PRICE = (ADULT_TAX_PRICE / 2).toFixed(2);

export const LIST_OF_COMPANY_CLIENTS = ['Booking.com', 'Airbnb', 'Traum', 'TripAdvisor'];

const IS_WEB = Platform.OS === 'web';
const IS_IOS = Platform.OS === 'ios';
const IS_ANDROID = Platform.OS === 'android';

export const generateHtml = (invoiceData, logoData, t) => {
    convertAndCalculateLegacyData(invoiceData);
    const invoice = sanitizeHtmlObject(invoiceData);
    const userInfo = sanitizeHtmlObject(invoiceData[USER_INFO]);
    const ownerIsInPdv = getBooleanTrueDefault(invoiceData[INVOICE_FORM_USER_IN_PDV_SYSTEM]);
    const taxAlreadyIncluded = getBooleanTrueDefault(invoiceData[INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED]);
    const isElectronicInvoice = getBoolean(invoiceData[INVOICE_FORM_ELECTRONIC_INVOICE]);
    const showFooter = getBoolean(invoiceData[INVOICE_FORM_SHOW_FOOTER]);
    const discount = getOrCalculateDiscountTotal(invoiceData, ownerIsInPdv, taxAlreadyIncluded);

    return `
	<!DOCTYPE html>
	<html>
		${head}
		<body>
			<div class="page ${IS_WEB ? 'web' : ''} ${IS_ANDROID ? 'android' : ''} ${IS_IOS ? 'ios' : ''}" >
                <header>
                    <div class="header-info">
                        <!-- OWNER DATA --> 
                        <div>
                            ${generateLogoHtml(logoData)}
                            <div class="owner-info">
                                ${generateOwnerInfoHtml(invoice, t)}
                            </div>
                                ${generateOwnerBankInfoHtml(userInfo, t)}
                        </div>
                        <!-- INVOICE METADATA --> 
                        <div>
                            ${generateInvoiceDateTimeHtml(invoice)}
                            ${generateInNameHtml(invoice, t)}
                        </div>
                    </div>
                    <div class="header-title">
                       ${generateFormNumberHtml(invoice)}
                    </div>
                </header>
                <main>
                    <div class="information">
                        <div>
                            <!-- CLIENT DATA --> 
                            <div class="guest-info">
                               ${generateClientHtml(invoice, t)}
                        </div>
                    </div>
                    <!-- TABLE --> 
                    <div class="table-wrapper">
                        <table id="invoice-table" cellpadding="0" cellspacing="0">
                            <thead>
                                ${generateTableHeader(ownerIsInPdv, discount)}
                            </thead>
                            <tbody>
                                ${generateTableOvernightRow(ownerIsInPdv, discount, invoice)}
                                ${generateTableTaxRows(ownerIsInPdv, discount, invoice, t)}
                            </tbody>
                        </table>
                    </div>
                    <!-- TABLE FOOTER --> 
                    <div class="total">
                        ${generateTableFooter(ownerIsInPdv, discount, invoice)}
                    </div>
                    <!-- OTHER FOOTER DATA --> 
                    <div class="operator">
                        ${generateOperatorHtml(invoice)}
                    </div>
                </main>
                <div class="footer">
                    ${generateFooterTextHtml(
                        showFooter,
                        ownerIsInPdv,
                        taxAlreadyIncluded,
                        isElectronicInvoice,
                        invoice
                    )}
                </div>
			</div>
		</body>
	</html>
	`;
};

const generateTableHeader = (ownerIsInPdv, discount) => {
    const showDiscount = discount == 0 ? false : true;
    return `<tr>
                <th>${USLUGA}</br>${SERVICE}</th>
                <th>${KOLICINA}</br>${QUANTITY}</th>
                <th>${CIJENA}</br>${PRICE}</th>
                ${showDiscount ? `<th>${POPUST}</br>${DISCOUNT}</th>` : ``}
                <th>${UKUPNO}</br>${TOTAL}</th>
                ${ownerIsInPdv ? `<th>${PDV}</br>${VAT}</th>` : ''}
                ${ownerIsInPdv ? `<th>${UKUPNO}</br>${TOTAL}</th>` : ''}
            </tr>`;
};

const generateTableOvernightRow = (ownerIsInPdv, discount, invoice) => {
    const showDiscount = discount == 0 ? false : true; // TODO: maybe this is obsolete
    const discountPercentage = invoice[INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE];
    const pricePerNight = Number(invoice[INVOICE_FORM_PRICE_PER_NIGHT]).toFixed(2);
    const overnightPdvPercentage = invoice[INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE];
    const overnightPdvAmount = invoice[INVOICE_FORM_OVERNIGHT_PDV_AMOUNT];
    const overnightTotalWithPDV = invoice[INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV];
    const c = invoice[INVOICE_FORM_CURRENCY];
    const serviceType =
        invoice[INVOICE_FORM_SERVICE_TYPE] === 'OVERNIGHT_STAY' ? OVERNIGHT : invoice[INVOICE_FORM_SERVICE_TYPE];

    return `<tr>
                <td>${serviceType}</td>
                <td>${invoice[INVOICE_FORM_OVERNIGHT_COUNT]}</td>
                <td>${pricePerNight} ${c}</td>
                ${showDiscount ? `<td>${discountPercentage}%</td>` : ``}
                <td>${invoice[INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV]} ${c}</td>
                ${ownerIsInPdv ? `<td>${overnightPdvPercentage}%</br>${overnightPdvAmount} € </td>` : ''}
                ${ownerIsInPdv ? ` <td>${overnightTotalWithPDV} ${c}</td>` : ''}
            </tr>`;
};

const generateTaxRow = (msg, count, price, currency, ownerIsInPdv, totalPrice, showDiscount) =>
    `<tr>
        <td>${msg}</td>
        <td>${count}</td>
        <td>${price} ${currency}</td>
        ${showDiscount ? '<td>0</td>' : ''}
        <td>${totalPrice} ${currency}</td>
        ${ownerIsInPdv ? '<td>0</td>' : ''}
        ${ownerIsInPdv ? `<td>${totalPrice} ${currency}</td>` : ''}
    </tr>`;

const generateTableTaxRows = (ownerIsInPdv, discount, invoice, t) => {
    const showDiscount = discount == 0 ? false : true;
    const includedTaxesField = invoice[INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED];
    const taxesNotIncluded = !getBoolean(includedTaxesField);
    const currency = invoice[INVOICE_FORM_CURRENCY] ?? '';
    let output = '';
    if (isStringNotEmpty(includedTaxesField) && taxesNotIncluded) {
        try {
            const adultsCount = Number(invoice[INVOICE_FORM_TOURIST_TAX_ADULT_COUNT]);
            if (adultsCount) {
                const msg = `Turistička pristojba: odrasli Tourist tax: adult`; // replace with trans string
                const price = Number(invoice[INVOICE_FORM_TOURIST_TAX_ADULT_RATE]).toFixed(2);
                const totalPrice = Number(invoice[INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL]).toFixed(2);
                output = generateTaxRow(msg, adultsCount, price, currency, ownerIsInPdv, totalPrice, showDiscount);
            }
            const childrenCount = Number(invoice[INVOICE_FORM_TOURIST_TAX_CHILD_COUNT]);
            if (childrenCount) {
                const msg = `Turistička pristojba: djeca 12 do 18 god Tourist tax: children 12 to 18 years `; // replace with trans string
                const price = Number(invoice[INVOICE_FORM_TOURIST_TAX_CHILD_RATE]).toFixed(2);
                const totalPrice = Number(invoice[INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL]).toFixed(2);
                output += generateTaxRow(msg, childrenCount, price, currency, ownerIsInPdv, totalPrice, showDiscount);
            }
        } catch (e) {
            console.log(e);
        }
    }
    return output;
};

const generateTableFooter = (ownerIsInPdv, discount, invoice) => {
    const showDiscount = discount == 0 ? false : true;
    const totalSumWithoutDiscountBeforePdv = invoice[TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM];
    const discountTotalSum = invoice[INVOICE_FORM_DISCOUNT_TOTAL_SUM];
    const pdvTotalSum = Number(invoice[INVOICE_FORM_PDV_TOTAL_SUM]).toFixed(2);
    const finalPrice = invoice[INVOICE_FORM_PRICE_FINAL];
    const c = invoice[INVOICE_FORM_CURRENCY];

    return `<p>${UKUPNA_CIJENA}/${TOTAL_PRICE}: ${totalSumWithoutDiscountBeforePdv} ${c}</p>
            ${showDiscount ? `<p>${POPUST}/${DISCOUNT}: ${discountTotalSum} ${c}</p>` : ''}
            ${ownerIsInPdv ? `<p>${PDV}/${VAT}: ${pdvTotalSum} ${c} </p>` : ''}
            <p class="total-price">
                <strong>${ZA_NAPLATU}/${FOR_BILLING}: ${finalPrice} ${c}</strong>
            </p>`;
};

const generateLogoHtml = logoData => {
    return logoData ? `<div class="logo"><img src="${logoData}" /></div>` : '';
};

const generateFormNumberHtml = invoice => {
    return `<h1>Račun/Invoice: <strong>${invoice[INVOICE_FORM_NUMBER]}</strong></h1>`;
};

const generateOwnerInfoHtml = (invoice, t) => `
	<p><strong>${invoice[INVOICE_FORM_OWNER]}</strong> </p>
	<p>${invoice[INVOICE_FORM_OWNER_OIB] ? `${INVOICE_OIB}/${INVOICE_PIN}: ${invoice[INVOICE_FORM_OWNER_OIB]} </p>` : ''} 
	<p>${invoice[INVOICE_FORM_OWNER_ADDRESS]} </p>`;

const generateInvoiceDateTimeHtml = invoice => {
    return `<p class="date">${DATUM}/${DATE}: <strong>${getDateString(invoice[INVOICE_FORM_DATE])}</strong></p>
           <p class="time">${VRIJEME}/${TIME}: <strong>${invoice[INVOICE_FORM_TIME] ?? ''}</strong></p>`;
};

const generateInNameHtml = (invoice, t) => {
    const inName = invoice[INVOICE_FORM_IN_NAME];
    const inNameOib = invoice[INVOICE_FORM_IN_NAME_OIB];
    return inName
        ? `
        <div class="in-name">
            <p><strong>${t(INVOICE_IN_NAME_LABEL)}: ${inName}</strong> </p>
            <p>${inNameOib ? `${INVOICE_OIB}/${INVOICE_PIN}: ${inNameOib} </p>` : ''} 
            <p>${invoice[INVOICE_FORM_IN_NAME_ADDRESS] ?? ''} </p>
        </div>`
        : '';
};

const generateClientHtml = (invoice, t) => {
    const oib = invoice[INVOICE_FORM_COMPANY_OIB];
    const address = invoice[INVOICE_FORM_GUEST_ADDRESS];
    console.log(invoice[INVOICE_FORM_CLIENT] + ',');
    console.log(address);
    return `<p>${isCompanyType(invoice) ? 'Tvrtka/Company' : 'Gost/Guest'}:
            <strong>${invoice[INVOICE_FORM_CLIENT] ?? ''}</strong>${
        isStringNotEmpty(address) ? `, ${address}</p>` : ''
    }${isCompanyType(invoice) && oib ? `<p>OIB: <strong>${oib}</strong></p>` : ``}
            </div>
            <p>${JEDINICA}/${UNIT}: <strong>${invoice[INVOICE_FORM_ACC_UNIT]}</strong></p>
            <p>${INVOICE_CHECKIN} - ${INVOICE_CHECKOUT}:
            <strong>${invoice[INVOICE_FORM_GUEST_STAY_RANGE]}</strong>
            </p>
            <p>${NACIN_PLACANJA}/${PAYMENT_TYPE}:
            <strong>${t(invoice[INVOICE_FORM_PAYMENT_TYPE])}</strong>
            </p>`;
};

const generateOwnerBankInfoHtml = (userData, t) => {
    //show bank info only if USER_DATA_EMAIL
    return `<div class="owner-info">
            ${showLabelAndField(USER_DATA_CONTACT, userData?.[USER_DATA_FORM_TEL], t)}
            ${showLabelAndField(USER_DATA_EMAIL, userData?.[USER_DATA_FORM_EMAIL], t)}
            <p><strong>${t(BANK_INFO)}</strong></p>
            ${showLabelAndField(IBAN, userData?.[USER_DATA_FORM_BANK_IBAN], t)}
            ${showLabelAndField(SWIFT, userData?.[USER_DATA_FORM_BANK_SWIFT], t)}
            ${showLabelAndField(BANK, userData?.[USER_DATA_FORM_BANK], t)}
            ${showLabelAndField(BANK_ADDRESS, userData?.[USER_DATA_FORM_BANK_ADDRESS], t)}
        </div>`;
};

const generateOperatorHtml = invoiceData => {
    const show = getBoolean(invoiceData[INVOICE_FORM_SHOW_OPERATER]);
    let out = '';
    if (show) {
        const operatorRef = invoiceData[INVOICE_FORM_OPERATOR_REF];
        if (operatorRef) {
            out = `<p class="operator-reference">Oznaka operatera / Reference: <strong>${operatorRef}</strong></p>`;
        }
        const operatorName = invoiceData[INVOICE_FORM_OPERATOR_NAME];
        if (operatorName) {
            out = out + `<p class="operator-name">Dokument izdao / Issued by: <strong>${operatorName}</strong></p>`;
        }
    }
    return out;
};

const generateFooterTextHtml = (showFooter, ownerIsInPdv, taxAlreadyIncluded, isElectronicInvoice, invoice) => {
    return `<p class="footer-notes" style="${!showFooter ? `border-top: 1px solid #dfdfdf` : ``}">
            ${ownerIsInPdv ? `` : `${INVOICE_OWNER_IN_PDV_SYSTEM}</br>`}
            ${taxAlreadyIncluded ? `${INVOICE_SOJOURN_TAX_LABEL}</br>` : `${INVOICE_SOJOURN_TAX_NOT_ICLUDED}</br>`}
            ${isElectronicInvoice ? `${INVOICE_ELECTRONIC_INVOICE}</br>` : ``}
        </p>
        ${showFooter ? `<p class="footer-user-text">${invoice[INVOICE_FORM_FOOTER_TEXT]}</p>` : ``}`;
};

const showLabelAndField = (label, field, t) => {
    return label && field && t ? `<p>${t(label)}: ${field}</p>` : '';
};

export const updateInvoiceHtml = async (setHtmlContent, invoice, t, setOpenProgress) => {
    try {
        setOpenProgress(true);
        const logoData = await getInvoiceLogo();
        const userData = await getUserData();
        const html = generateHtml(
            {
                ...invoice,
                [USER_INFO]: {
                    ...userData?.[STORAGE_USER_DATA]?.[USER_INFO],
                },
            },
            logoData,
            t
        );
        setHtmlContent(html);
    } catch (e) {
        console.log(e);
    } finally {
        setOpenProgress(false);
    }
};
