import {useEffect, useState} from 'react';
import {checkinGuest, checkoutGuest} from '../../api/inCheckinRestService';
import {
    ADDITIONAL_FIELDS,
    CHECKED_IN_STATUS,
    CHECKED_OUT_STATUS,
    CHECKIN_ACTION,
    CHECKIN_ACTIONS,
    CHECKOUT_ACTION,
    DATA,
    EVISITOR,
    FORESEEN_STAY_UNTIL_FIELD,
    PIN_ID,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    UPDATED_STATUS,
    content,
    guests,
    id,
    status,
} from '../../constants/stringsAndFields';
import {getForCheckin, getForCheckout, syncTouristCheckoutStatus} from '../../utils/guestUtils';

const CheckinActions = ({
    checkin,
    refreshLocalCheckin,
    updateGuestStatus,
    updateGuest,
    setOpenProgress,
    showErrors,
    useSharedCheckinActions,
}) => {
    const {actionsCount, checkinAction, triggerCheckinAction} = useSharedCheckinActions();
    const [isRunning, setIsRunning] = useState(false);

    const executeAction = async (pinId, action, targetCheckin) => {
        if (!isRunning) {
            const filteredGuests = targetCheckin?.[guests] ?? [];
            let dataUpdated = false;
            // CHECKIN
            if (action[CHECKIN_ACTIONS] === CHECKIN_ACTION) {
                if (actionsCount?.[CHECKIN_ACTION] > 0) {
                    setOpenProgress(true);
                    setIsRunning(true);
                    for (let guest of filteredGuests) {
                        if (getForCheckin(guest[status], guest[UPDATED_STATUS])) {
                            try {
                                dataUpdated = true;
                                await checkinGuest(pinId, guest[content]);
                                await updateGuestStatus(guest, CHECKED_IN_STATUS, false);
                            } catch (error) {
                                await showErrors(error, guest);
                                break;
                            }
                        }
                    }
                    triggerCheckinAction(null);
                    dataUpdated && (await refreshLocalCheckin(targetCheckin?.[id]));
                    setOpenProgress(false);
                }
            }
            // CHECKOUT
            else if (action[CHECKIN_ACTIONS] === CHECKOUT_ACTION) {
                if (actionsCount?.[CHECKOUT_ACTION] > 0) {
                    setOpenProgress(true);
                    setIsRunning(true);
                    for (let guest of filteredGuests) {
                        if (getForCheckout(guest[status])) {
                            try {
                                dataUpdated = true;
                                const isAlreadyCheckedOut = await syncTouristCheckoutStatus(
                                    pinId,
                                    guest,
                                    updateGuestStatus,
                                    true,
                                    null
                                );
                                if (!isAlreadyCheckedOut) {
                                    if (action[DATA]) {
                                        const {forseenStayUntil, timeEstimatedUntil} = action[DATA];
                                        guest[content][FORESEEN_STAY_UNTIL_FIELD] = forseenStayUntil;
                                        guest[content][TIME_ESTIMATED_STAY_UNTIL_FIELD] = timeEstimatedUntil;
                                    }
                                    await checkoutGuest(pinId, guest[content]);
                                    guest[status] = CHECKED_OUT_STATUS;
                                    guest[UPDATED_STATUS] = false;
                                    await updateGuest(guest, true);
                                }
                            } catch (error) {
                                await showErrors(error, guest);
                                break;
                            }
                        }
                    }
                    triggerCheckinAction(null);
                    dataUpdated && (await refreshLocalCheckin(targetCheckin?.[id]));
                    setOpenProgress(false);
                }
            }
        }
    };

    useEffect(() => {
        const pinId = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
        if (pinId && checkinAction?.[CHECKIN_ACTIONS]) {
            executeAction(pinId, checkinAction, checkin).finally(_ => {
                setIsRunning(false);
            });
        }
        return () => setIsRunning(false);
    }, [checkinAction, JSON.stringify(checkin?.[guests])]); // eslint-disable-line react-hooks/exhaustive-deps

    return <></>;
};

export default CheckinActions;
