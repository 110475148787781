import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    footerWrapper: {
        backgroundColor: theme.colors.primary,
        width: '100%',
        paddingVertical: 30,
        paddingHorizontal: 15,
    },

    footerSection: {
        paddingLeft: 30,
    },

    footerSectionTitle: {
        color: 'rgba(255, 255, 255, 0.4)',
        textTransform: 'uppercase',
        fontFamily: theme.fontFamilySemibold,
        fontSize: 14,
        marginBottom: 15,
    },

    footerPartnersImages: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    footerText: {
        fontFamily: theme.fontFamily,
        fontSize: 12,
        color: theme.colors.white,
    },

    footerLink: {
        fontFamily: theme.fontFamilySemibold,
        fontSize: 12,
        color: '#c0cdf1',
    },

    echeckinLink: {
        marginBottom: 5,
    },

    sectionBorder: {
        borderTopWidth: 1,
        borderTopColor: 'rgba(255, 255, 255, 0.1)',
        paddingTop: 20,
        marginTop: 20,
        width: '100%',
    },

    storeBtn: {
        paddingVertical: 5,
        paddingHorizontal: 15,
        backgroundColor: 'transparent',
        borderColor: 'rgba(255, 255, 255,  0.3)',
        borderWidth: 1,
        borderRadius: theme.buttonBorderRadius,
    },

    storeBtnTitle: {
        color: theme.colors.white,
        fontSize: 12,
        fontFamily: theme.fontFamilySemibold,
        letterSpacing: 0.2,
        textTransform: 'uppercase',
    },
}));

export default useStyles;
