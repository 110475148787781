import React, {useContext, useEffect} from 'react';
import {SafeAreaView, ScrollView, BackHandler} from 'react-native';
import {useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import {useIsFocused} from '@react-navigation/native';
import LoginForm from '../components/login/LoginForm';
import DataContext from '../context/dataContext';

const LoginScreen = ({navigation}) => {
    const {login, setLoggedIn, clearErrors, signUp, initStaticData, fetchInitData, apiErrors, apiMessages, loggedIn} =
        useContext(DataContext);
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const isFocused = useIsFocused();

    const backButtonHandler = () => {
        BackHandler.exitApp();
        return true;
    };

    useEffect(() => {
        isFocused && clearErrors();
    }, [isFocused]);

    useEffect(() => {
        if (loggedIn) {
            navigation.navigate('Root', {
                screen: 'Checkins',
            });
        }
    }, [loggedIn]);

    useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', backButtonHandler);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', backButtonHandler);
        };
    }, []);

    return (
        <SafeAreaView style={global.container}>
            <ScrollView contentContainerStyle={{flexGrow: 1}} keyboardShouldPersistTaps="handled">
                <LoginForm
                    navigation={navigation}
                    login={login}
                    setLoggedIn={setLoggedIn}
                    signUp={signUp}
                    initStaticData={initStaticData}
                    apiErrors={apiErrors}
                    apiMessages={apiMessages}
                    fetchInitData={fetchInitData}
                />
            </ScrollView>
        </SafeAreaView>
    );
};

export default LoginScreen;
