import {
    ACCOMODATION_OBJECT,
    ACCOMODATION_UNIT,
    AUTO_TOURIST_CHECKOUT,
    CHECKIN_CHECKOUT,
    CHECKIN_FIELD,
    CHECKOUT_DATE,
    CHECKOUT_FIELD,
    CHECKOUT_TIME,
    content,
    EMAIL_FIELD,
    FORESEEN_STAY_UNTIL_FIELD,
    id,
    ID,
    NETWORK_OFFLINE_ERROR_MSG,
    PIN,
    PIN_ID,
    PRIVACY_URL,
    RECORDS,
    settings,
    STORAGE_USER_DATA,
    TERMS_URL,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    UPDATED_AT,
    USER_ID,
} from '../constants/stringsAndFields';
import {getEmailFormData} from '../utils/contact';
import {getEvisitorData} from '../utils/dataHelper';
import {getCheckInOutTime, getNowDate, getNowTime} from '../utils/dateHelper';
import {checkExcludedOibs, generateExternalDocLink, serializeData} from '../utils/helpers';
import {clearUserData, getInvoicesYear, getUserId} from '../utils/userUtils';
import {DEFAULT_LANG} from '../utils/i18n';
import {isOnline} from '../utils/network';
import restClient, {API_VER, BASE_URL, storeResponseData} from './restClient';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {cleanGuestForCheckin} from '../utils/guestUtils';
import {INVOICES_PAGE_COUNT} from '../screens/InvoicesScreen';

export async function signUpUser(user) {
    const signUpRes = await restClient.post(`${BASE_URL}/${API_VER}/users`, user);
    await storeResponseData(signUpRes, user);
    return signUpRes;
}

export async function loginUser(user) {
    const loginRes = await restClient.post(`${BASE_URL}/${API_VER}/auth`, user);
    await storeResponseData(loginRes, user);
    return loginRes;
}

export async function deleteAccount(accountAndMessage) {
    const deleteAccountRes = await restClient.post(`${BASE_URL}/${API_VER}/users/me/delete`, accountAndMessage);
    return deleteAccountRes;
}

export async function logoutUser() {
    // maybe revoke tokens?
    await clearUserData();
}

export async function changePassword(user) {
    const changePassRes = await restClient.put(`${BASE_URL}/${API_VER}/users/me/password`, user);
    return changePassRes;
}

export async function getUser(storeUser = true) {
    const userRes = await restClient.get(`${BASE_URL}/${API_VER}/users/me`);
    storeUser && (await AsyncStorage.setItem(STORAGE_USER_DATA, serializeData(userRes?.[settings])));
    return userRes;
}

// user object => {'settings': { 'user_data': {'name': 'pero' }}}
export async function updateUser(user, storeUser = true) {
    const updateUserRes = await restClient.put(`${BASE_URL}/${API_VER}/users/me`, user);
    storeUser && (await AsyncStorage.setItem(STORAGE_USER_DATA, serializeData(updateUserRes?.[settings])));
    return updateUserRes;
}

export async function addEVisitor(user) {
    const evisitorUsersRes = await restClient.post(`${BASE_URL}/${API_VER}/users/me/evisitor`, user);
    const recordData = evisitorUsersRes?.[RECORDS]?.[0];
    if (recordData) {
        return Object.assign(evisitorUsersRes, recordData);
    }
    return evisitorUsersRes;
}

export async function deleteEVisitorAcc(eVisitorAccount) {
    return restClient.delete(`${BASE_URL}/${API_VER}/users/me/evisitor/${eVisitorAccount[PIN_ID]}`);
}

export async function fetchStaticData() {
    return restClient.get(`${BASE_URL}/${API_VER}/all-settlements`);
}

export const REST_COMMUNICATION = 'REST_COMMUNICATION';
export async function fetchEvisitorAccountsFromRest(emitMessage, fullEvisitorDownload = false) {
    const {t, setApiMessages} = emitMessage();
    let pinsRes = [];
    try {
        pinsRes = await restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor?pin_id=all&detailed=false`);
    } catch (e) {
        console.log(e);
    }

    const eVisitorsAccounts = [];
    let eVisitorCounter = 1;
    for (let pin of pinsRes) {
        try {
            const pinId = pin?.[PIN_ID] ?? pin;
            const evisitorAccount = await restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}`);
            const evisitorAccountData = evisitorAccount[RECORDS][0];
            if (fullEvisitorDownload) {
                const eVisitorData = await getEvisitorData(evisitorAccountData, pinId, eVisitorCounter, setApiMessages);
                eVisitorsAccounts.push(eVisitorData);
            } else {
                evisitorAccountData[PIN_ID] = pinId;
                evisitorAccountData[ACCOMODATION_OBJECT] = [];
                evisitorAccountData[ACCOMODATION_UNIT] = [];
                eVisitorsAccounts.push(evisitorAccountData);
            }
        } catch (e) {
            console.log(e);
        }
        eVisitorCounter++;
    }
    setApiMessages(null);
    return eVisitorsAccounts;
}

export async function fetchEvisitorAccountFromRest(evisitorAccount, pinId, emitMessage) {
    const {t, setApiMessages} = emitMessage();
    const eVisitorData = await getEvisitorData(evisitorAccount, pinId, 1, setApiMessages);
    return eVisitorData;
}

// STORAGE SUPPORT
export async function fetchCheckinsFromRest(page = CHECKINS_FIRST_PAGE, filter) {
    const queryFilter = filter ? filter : 'all';
    // TODO: add page support
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/self-checkin/checkin/${queryFilter}`);
}

// NOT USED
export async function fetchGuests(filter) {
    const queryFilter = filter ? filter : 'all';
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${queryFilter}`);
}

export async function fetchGuest(guestId) {
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${guestId}`);
}

// STORAGE SUPPORT
export async function saveCheckinRest(checkin) {
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/self-checkin/checkin`, checkin);
}

// STORAGE SUPPORT
export async function updateCheckinRest(checkin) {
    return restClient.put(`${BASE_URL}/${API_VER}/users/me/self-checkin/checkin/${checkin[id]}`, checkin);
}

// STORAGE SUPPORT
export async function deleteCheckinRest(checkin) {
    return restClient.delete(`${BASE_URL}/${API_VER}/users/me/self-checkin/checkin/${checkin[id]}`);
}

// STORAGE SUPPORT
export async function saveGuestRest(guest) {
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest`, guest);
}

// STORAGE SUPPORT
export async function updateGuestRest(guest) {
    return restClient.put(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${guest[id]}`, guest);
}

// STORAGE SUPPORT
export async function createOrUpdateGuestRest(guest, create) {
    const userId = await getUserId();
    if (userId) {
        guest[USER_ID] = userId;
    }
    if (create) {
        return saveGuestRest(guest);
    } else {
        return restClient.put(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${guest[id]}`, guest);
    }
}

// STORAGE SUPPORT
export async function deleteGuestRest(guest) {
    const userId = await getUserId();
    guest[USER_ID] = userId;
    return restClient.delete(`${BASE_URL}/${API_VER}/users/me/self-checkin/guest/${guest[id]}`);
}

export async function sendMail(email) {
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/self-checkin/mail`, email);
}

// HTZ Checkin routes
export async function checkinGuest(pinId, guest) {
    const online = await isOnline();
    if (online) {
        return restClient.post(
            `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/CheckInTourist/`,
            cleanGuestForCheckin(guest)
        );
    } else {
        throw new Error(NETWORK_OFFLINE_ERROR_MSG);
    }
}

export async function checkoutGuest(pinId, guest) {
    const online = await isOnline();
    if (online) {
        const checkoutData = {
            [ID]: guest[ID],
            [CHECKOUT_DATE]: guest[FORESEEN_STAY_UNTIL_FIELD] ?? getNowDate(),
            [CHECKOUT_TIME]: guest[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? getNowTime(),
        };
        return restClient.post(
            `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/CheckOutTourist/`,
            checkoutData
        );
    } else {
        throw new Error(NETWORK_OFFLINE_ERROR_MSG);
    }
}

export async function cancelGuest(pinId, guest) {
    const online = await isOnline();
    if (online) {
        const cancelData = {[ID]: guest[ID]};
        return restClient.post(
            `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/CancelTouristCheckIn/`,
            cancelData
        );
    } else {
        throw new Error(NETWORK_OFFLINE_ERROR_MSG);
    }
}

// https://github.com/Rhetos/Rhetos
export async function fetchTourists(
    pinId,
    pageSize = 50,
    page = 1,
    nameFilter = null,
    facilityFilter = null,
    dateFilter = null
) {
    const online = await isOnline();
    if (online) {
        let filter = nameFilter
            ? `&filters=[{"Property":"SurnameAndName","Operation":"contains","Value":"${nameFilter}"}]`
            : '';
        filter =
            facilityFilter && !dateFilter
                ? `&filters=[{"Property":"FacilityID","Operation":"equal","Value":"${facilityFilter}"}]`
                : filter;
        if (dateFilter) {
            const {startDate, endDate} = dateFilter;
            filter = facilityFilter
                ? `&filters=[{"Property":"FacilityID","Operation":"equal","Value":"${facilityFilter}"}, {"Property":"DateTimeOfArrival","Operation":"GreaterEqual","Value":"${startDate}"}, {"Property":"DateTimeOfArrival","Operation":"LessEqual","Value":"${endDate}"}]`
                : filter;
            filter = !facilityFilter
                ? `&filters=[{"Property":"DateTimeOfArrival","Operation":"GreaterEqual","Value":"${startDate}"}, {"Property":"DateTimeOfArrival","Operation":"LessEqual","Value":"${endDate}"}]`
                : filter;
        }
        return restClient.get(
            `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/ListOfTouristsExtended?psize=${pageSize}&page=${page}&sort=DateTimeOfArrival%20desc${filter}`
        );
    }
    return null;
}

export async function fetchTouristsAllPages(
    pinId,
    pageSize = 50,
    page = 1,
    nameFilter = null,
    facilityFilter = null,
    dateFilter = null
) {
    const online = await isOnline();
    if (online) {
        let filter = nameFilter
            ? `&filters=[{"Property":"SurnameAndName","Operation":"contains","Value":"${nameFilter}"}]`
            : '';
        filter =
            facilityFilter && !dateFilter
                ? `&filters=[{"Property":"FacilityID","Operation":"equal","Value":"${facilityFilter}"}]`
                : filter;
        if (dateFilter) {
            const {startDate, endDate} = dateFilter;
            filter = facilityFilter
                ? `&filters=[{"Property":"FacilityID","Operation":"equal","Value":"${facilityFilter}"}, {"Property":"DateTimeOfArrival","Operation":"GreaterEqual","Value":"${startDate}"}, {"Property":"DateTimeOfArrival","Operation":"LessEqual","Value":"${endDate}"}]`
                : filter;
            filter = !facilityFilter
                ? `&filters=[{"Property":"DateTimeOfArrival","Operation":"GreaterEqual","Value":"${startDate}"}, {"Property":"DateTimeOfArrival","Operation":"LessEqual","Value":"${endDate}"}]`
                : filter;
        }
        const firstResponse = await restClient.get(
            `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/ListOfTouristsExtended?psize=${pageSize}&page=${page}&sort=DateTimeOfArrival%20desc${filter}`
        );
        const responseCollector = firstResponse;
        let numberOfRecords = firstResponse?.[RECORDS]?.length ?? 0;
        let nextPage = page + 1;
        const maxIterations = 50; // safeguard
        while (numberOfRecords > 0 && numberOfRecords === pageSize && nextPage < maxIterations) {
            const nextResponse = await restClient.get(
                `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/ListOfTouristsExtended?psize=${pageSize}&page=${nextPage}&sort=DateTimeOfArrival%20desc${filter}`
            );
            numberOfRecords = nextResponse?.[RECORDS]?.length ?? 0;
            if (numberOfRecords) {
                responseCollector[RECORDS] = [...responseCollector[RECORDS], ...nextResponse[RECORDS]];
            }
            nextPage++;
        }
        return responseCollector;
    }
    return null;
}

export async function fetchTourist(pinId, pageSize = 1, page = 1, touristId = 'b99fffed-d66e-45d4-a292-a42f8ce13ffd') {
    const online = await isOnline();
    if (online) {
        const filter = `&filters=[{"Property":"ID","Operation":"equal","Value":"${touristId}"}]`;
        return restClient.get(
            `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/ListOfTouristsExtended?psize=${pageSize}&page=${page}&sort=DateTimeOfArrival%20desc${filter}`
        );
    }
    return null;
}

export async function fetchAccomodationObjectCharacetistics(pinId, propertyId) {
    const filter = `filters=[{"Property":"ID","Operation":"equal","Value":"${propertyId}"}]`;
    return restClient.get(
        `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/FacilityCharacteristicsBrowse?${filter}`
    );
}

export async function updateAccomodationObjectCharacetistics(eVisitorUser, isAutoCheckoutActive) {
    for (let accUnit of eVisitorUser[ACCOMODATION_UNIT]) {
        const {checkinTime = null, checkoutTime = null} = getCheckInOutTime(
            accUnit?.[CHECKIN_CHECKOUT] ?? '10:00 / 17:00'
        );
        const accomodationCharacteristics = {
            [ID]: accUnit[ID],
            [AUTO_TOURIST_CHECKOUT]: isAutoCheckoutActive,
            [CHECKIN_FIELD]: checkinTime ?? null,
            [CHECKOUT_FIELD]: checkoutTime ?? null,
            [EMAIL_FIELD]: accUnit[EMAIL_FIELD] ?? null,
        };
        await postAccomodationObjectCharacetistics(eVisitorUser[PIN_ID], accomodationCharacteristics);
        const idx = eVisitorUser[ACCOMODATION_UNIT].findIndex(u => u[ID] === accUnit[ID]);
        if (idx !== -1) {
            eVisitorUser[ACCOMODATION_UNIT][idx][AUTO_TOURIST_CHECKOUT] = isAutoCheckoutActive;
        }
    }
}

export async function postAccomodationObjectCharacetistics(pinId, accomodationCharacteristics) {
    return restClient.post(
        `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/SaveFacilityCharacteristics/`,
        accomodationCharacteristics
    );
}

export async function fetchFacilities(pinId) {
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/AccommodationUnitFacilityType`);
}

export async function fetchFacility(pinId, facilityId) {
    const filter = `filters=[{"Property":"FacilityID","Operation":"equal","Value":"${facilityId}"}]`;
    return restClient.get(
        `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/AccommodationUnitFacilityType?${filter}`
    );
}

export async function fetchAccomodations(pinId) {
    const filter = `filters=[{"Property":"Active","Operation":"equal","Value":"true"}]`;
    return restClient.get(
        `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/FacilityTouristCheckInLookup?${filter}`
    );
}

export async function fetchPaymentCategory(pinId, facilityId) {
    const filter = `filters=[{"Property":"Active","Operation":"equal","Value":"true"},{"Property":"FacilityID","Operation":"equal","Value":"${facilityId}"}]`;
    return restClient.get(
        `${BASE_URL}/${API_VER}/users/me/evisitor/${pinId}/Rest/Htz/TTPaymentCategoryLookup2?${filter}`
    );
}

// content = { "url": "", "method": "get"}
export async function postProxyRequest(content) {
    return restClient.post(`${BASE_URL}/${API_VER}/proxy`, content);
}

export async function fetchTermsData(t) {
    const link = generateExternalDocLink(t, TERMS_URL);
    return restClient.get(link);
}

export async function fetchPrivacyData(t) {
    const link = generateExternalDocLink(t, PRIVACY_URL);
    return restClient.get(link);
}

export async function fetchCredits() {
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/credits`);
}

// https://api.incheckin.hr/hr/passwd/forgotten-password
export async function postResetPassword(lang = DEFAULT_LANG, email) {
    const form = getEmailFormData(email);
    return restClient.post(`${BASE_URL}/${lang}/passwd/forgotten-password`, form, {
        headers: {'Content-Type': 'multipart/form-data;'},
    });
}

export async function fetchInvoicesFromRest(evAccount, evaccounts, page = INVOICE_FIRST_PAGE) {
    const year = await getInvoicesYear();
    const sort = 'desc';
    const excludedOibs = await checkExcludedOibs(evAccount, evaccounts);
    if (excludedOibs) {
        const excludedOibsList = excludedOibs.join();
        return restClient.get(
            `${BASE_URL}/${API_VER}/users/me/evs-invoices?exclude_oibs=${excludedOibsList}&psize=${INVOICES_PAGE_COUNT}&page=${page}&sort=${sort}&year=${year}`
        );
    } else if (evAccount && evAccount[PIN] !== '-1')
        return restClient.get(
            `${BASE_URL}/${API_VER}/users/me/evs-invoices?evisitor_oib=${evAccount[PIN]}&psize=${INVOICES_PAGE_COUNT}&page=${page}&sort=${sort}&year=${year}`
        );
    else {
        return restClient.get(
            `${BASE_URL}/${API_VER}/users/me/evs-invoices?psize=${INVOICES_PAGE_COUNT}&page=${page}&sort=${sort}&year=${year}`
        );
    }
}

export async function postInvoice(invoice) {
    try {
        invoice[content] = JSON.parse(invoice[content]);
    } catch (e) {
        console.log(e);
    }
    return restClient.post(`${BASE_URL}/${API_VER}/users/me/evs-invoices`, invoice);
}

export async function putInvoice(invoice) {
    try {
        invoice[content] = JSON.parse(invoice[content]);
        delete invoice[UPDATED_AT];
    } catch (e) {
        console.log(e);
    }
    return restClient.put(`${BASE_URL}/${API_VER}/users/me/evs-invoices/${invoice[id]}`, invoice);
}

export async function deleteInvoice(invoice) {
    return restClient.delete(`${BASE_URL}/${API_VER}/users/me/evs-invoices/${invoice[id]}`, invoice);
}

export async function fetchIapsAppleProducts(lang = DEFAULT_LANG, platform = '') {
    let url = `${BASE_URL}/${API_VER}/iaps/apple/products?lang=${lang}&platform=${platform}`;
    const products = await restClient.get(url);
    return products;
}

export async function postIapsAppleOrder(orderData) {
    return restClient.post(`${BASE_URL}/${API_VER}/iaps/apple/order`, orderData);
}

export async function postIapsAppleTopUp(topUpData) {
    return restClient.post(`${BASE_URL}/${API_VER}/iaps/apple/top-up`, topUpData);
}

export async function getNotifications() {
    return restClient.get(`${BASE_URL}/${API_VER}/users/me/notifications`);
}

export async function saveNotifications(notificationSettings) {
    return restClient.put(`${BASE_URL}/${API_VER}/users/me/notifications`, notificationSettings);
}
