import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SafeAreaView, ScrollView, TouchableOpacity, View, useWindowDimensions} from 'react-native';
import {Button, CheckBox, Icon, LinearProgress, Text, useTheme} from 'react-native-elements';
import useSWR from 'swr';
import {changePassword} from '../api/inCheckinRestService';
import ConfirmDialog from '../components/ConfirmDialog';
import MessageDialog from '../components/MessageDialog';
import ChangePasswordDialog from '../components/account/ChangePasswordDialog';
import DeleteDialog from '../components/account/DeleteDialog';
import EditNotifications from '../components/account/EditNotifications';
import ExportDialog from '../components/account/ExportDialog';
import InviteFriendDialog from '../components/account/InviteFriendDialog';
import EditUserDataDialog from '../components/invoices/EditUserDataDialog';
import {
    BEST_BEFORE,
    BUY_CREDITS,
    CHANGE_PASS,
    CREDITS,
    CREDITS_EXPIRATION_OVERRIDE,
    DELETE_ACCOUNT,
    EDIT_NOTIFICATIONS,
    EMAIL,
    EXPORT_ACCOUNT_INFO,
    SEND_REFERRAL,
    STORAGE_USER_DATA,
    TWO_FOR_ONE_DIALOG_MSG,
    TWO_FOR_ONE_DIALOG_TITLE,
    TWO_FOR_ONE_OPTION,
    UNLIMITED,
    USER_INFO,
    data_credits,
    data_credits_best_before,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {getFormattedDateFromSqlOrUnlimited} from '../utils/dateHelper';
import {getUserData, getUserEmail, setPassword, setUserSettings} from '../utils/userUtils';
import useStyles from './AccountScreen.styles';

const ProfileScreen = ({route, navigation}) => {
    const {apiErrors, apiMessages, setApiErrors, loggedIn} = useContext(DataContext);
    const {data, mutate} = useSWR('refreshUserCredits');
    const isFocused = useIsFocused();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();
    const width = useWindowDimensions().width;
    const isMobileSmall = width < 550;
    const isTablet = width >= 500 && width < theme.desktopBP;

    const [openProgress, setOpenProgress] = useState(false);
    const [user, setUser] = useState('');
    const [openChangePassDialog, setOpenChangePassDialog] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openEditUserData, setOpenEditUserData] = useState(false);
    const [openInviteFriend, setOpenInviteFriend] = useState(false);
    const [userData, setUserData] = useState(null);
    const [openExport, setOpenExport] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [useCreditsAfterExpire, setUseCreditsAfterExpire] = useState(false);
    const [useCreditsAfterExpireOpen, setUseCreditsAfterExpireOpen] = useState(false);
    const [openEditNotifications, setOpenEditNotifications] = useState(false);

    const onPressUseCreditsAfterExpire = async () => {
        if (useCreditsAfterExpire) {
            setUseCreditsAfterExpire(false);
            await setUserSettings(CREDITS_EXPIRATION_OVERRIDE, false);
        } else {
            setUseCreditsAfterExpireOpen(true);
        }
    };

    const useCreditsAfterExpireConfirm = async () => {
        setUseCreditsAfterExpire(true);
        await setUserSettings(CREDITS_EXPIRATION_OVERRIDE, true);
    };

    useEffect(() => {
        userData && setUseCreditsAfterExpire(userData[CREDITS_EXPIRATION_OVERRIDE]);
    }, [userData]);

    useEffect(() => {
        if (isFocused) {
            isTablet && mutate();
        }
    }, [isFocused]);

    const onExport = _ => {
        setOpenExport(true);
    };

    const onDelete = _ => {
        setOpenDelete(true);
    };

    const onPressSendReferral = () => {
        setOpenInviteFriend(true);
    };

    const openOpenEditNotificationsFn = () => setOpenEditNotifications(true);
    const openChangePassDialogFn = () => setOpenChangePassDialog(true);

    const onChangePassConfirm = async password => {
        try {
            setOpenProgress(true);
            await changePassword(password);
            await setPassword(password['password']);
        } catch (e) {
            console.log(e);
            setApiErrors({signal: 'changePassword', message: e?.RESTErrors?.description ?? e?.message});
        } finally {
            setOpenChangePassDialog(false);
            setOpenProgress(false);
        }
    };

    const onEditUserDataConfirm = async userInfoData => {
        try {
            setOpenProgress(true);
            await setUserSettings(USER_INFO, userInfoData);
        } catch (e) {
            console.log(e);
            setApiErrors({signal: 'editUserData', message: e?.RESTErrors?.description ?? e?.message});
        } finally {
            setOpenEditUserData(false);
            setOpenProgress(false);
            const data = await getUserData();
            data && setUserData(data?.[STORAGE_USER_DATA]);
        }
    };

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        } else if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
        return null;
    };

    useEffect(() => {
        if (loggedIn) {
            if (!user) {
                getUserEmail().then(data => data && setUser(data));
            }
        } else {
            if (user) {
                setUser(null);
            }
        }
    }, [loggedIn]);

    return (
        <SafeAreaView style={global.containerBg}>
            <ScrollView contentContainerStyle={{flexGrow: 1}}>
                {openProgress && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
                <View style={[global.containerInner, {paddingTop: 15}]}>
                    <View style={[global.boxShadow, {marginBottom: 15, borderRadius: 10}]}>
                        <View style={styles.accountBox}>
                            <View style={[global.cardHeader]}>
                                <View style={global.flexCenter}>
                                    <Text style={global.cardHeaderText}>{t(EMAIL)}: </Text>
                                    <Text style={styles.itemValuePrimary}>{user}</Text>
                                </View>
                            </View>

                            <View style={[styles.accountCredits, !isMobileSmall && styles.accountCreditsDesktop]}>
                                <View style={{flex: 1}}>
                                    <View style={global.flexCenter}>
                                        <Text style={global.textMain}>{t(CREDITS)}</Text>
                                        <Text style={styles.itemValuePrimary}>{data?.[data_credits] ?? ''}</Text>
                                    </View>

                                    <View style={global.flexCenter}>
                                        <Text style={global.textMain}>{t(BEST_BEFORE)}</Text>
                                        <Text style={styles.itemValuePrimary}>
                                            {getFormattedDateFromSqlOrUnlimited(
                                                data?.[data_credits_best_before],
                                                t(UNLIMITED)
                                            )}
                                        </Text>
                                    </View>

                                    <CheckBox
                                        title={t(TWO_FOR_ONE_OPTION)}
                                        checked={useCreditsAfterExpire}
                                        onPress={onPressUseCreditsAfterExpire}
                                        containerStyle={[global.CheckBox, {marginLeft: 0, paddingVertical: 10}]}
                                        textStyle={global.CheckBoxText}
                                        size={21}
                                    />
                                </View>

                                <Button
                                    onPress={() => navigation.navigate('Packages')}
                                    title={t(BUY_CREDITS)}
                                    containerStyle={[
                                        global.Button.containerStyle,
                                        styles.creditsBtnContainer,
                                        isMobileSmall && {marginTop: 15, marginBottom: 10},
                                    ]}
                                    buttonStyle={[global.Button.buttonStyle, {width: 140, paddingHorizontal: 0}]}
                                    titleStyle={global.Button.titleStyle}></Button>
                            </View>
                            <View style={styles.accountItem}>
                                <TouchableOpacity onPress={onPressSendReferral} style={global.flexCenter}>
                                    <Icon
                                        type="ionicon"
                                        name="cash-outline"
                                        color={theme.colors.primary}
                                        size={18}
                                        containerStyle={{marginRight: 10}}
                                    />
                                    <Text style={global.link}>{t(SEND_REFERRAL)}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.accountItem}>
                                <TouchableOpacity onPress={openOpenEditNotificationsFn} style={global.flexCenter}>
                                    <Icon
                                        type="ionicon"
                                        name="notifications-outline"
                                        color={theme.colors.primary}
                                        size={18}
                                        containerStyle={{marginRight: 10}}
                                    />
                                    <Text style={global.link}>{t(EDIT_NOTIFICATIONS)}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.accountItem}>
                                <TouchableOpacity onPress={openChangePassDialogFn} style={global.flexCenter}>
                                    <Icon
                                        type="ionicon"
                                        name="lock-closed-outline"
                                        color={theme.colors.primary}
                                        size={18}
                                        containerStyle={{marginRight: 10}}
                                    />
                                    <Text style={global.link}>{t(CHANGE_PASS)}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.accountItem}>
                                <TouchableOpacity onPress={onExport} style={global.flexCenter}>
                                    <Icon
                                        type="ionicon"
                                        name="download-outline"
                                        color={theme.colors.primary}
                                        size={18}
                                        containerStyle={{marginRight: 10}}
                                    />
                                    <Text style={global.link}>{t(EXPORT_ACCOUNT_INFO)}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.accountItem}>
                                <TouchableOpacity onPress={onDelete} style={global.flexCenter}>
                                    <Icon
                                        type="ionicon"
                                        name="person-remove-outline"
                                        color={theme.colors.primary}
                                        size={18}
                                        containerStyle={{marginRight: 10}}
                                    />
                                    <Text style={global.link}>{t(DELETE_ACCOUNT)}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
                <InviteFriendDialog open={openInviteFriend} setOpen={setOpenInviteFriend} />
                <EditUserDataDialog
                    open={openEditUserData}
                    setOpen={setOpenEditUserData}
                    onConfirm={onEditUserDataConfirm}
                    userInfo={userData?.[USER_INFO]}
                />
                <ChangePasswordDialog
                    open={openChangePassDialog}
                    setOpen={setOpenChangePassDialog}
                    onConfirm={onChangePassConfirm}
                />
                <EditNotifications visible={openEditNotifications} setVisible={setOpenEditNotifications} />
                <ExportDialog visible={openExport} setVisible={setOpenExport} />
                <DeleteDialog visible={openDelete} setVisible={setOpenDelete} />
                <ConfirmDialog
                    title={t(TWO_FOR_ONE_DIALOG_TITLE)}
                    open={useCreditsAfterExpireOpen}
                    setOpen={setUseCreditsAfterExpireOpen}
                    onConfirm={useCreditsAfterExpireConfirm}
                    content={t(TWO_FOR_ONE_DIALOG_MSG)}
                />
                {isFocused && (
                    <MessageDialog
                        message={generateMessage()}
                        isError={apiErrors}
                        open={openMessage}
                        handleOpen={setOpenMessage}
                    />
                )}
            </ScrollView>
        </SafeAreaView>
    );
};

export default ProfileScreen;
