import {getUserTheme} from '../utils/userUtils';

const baseTheme = {
    changeTheme: newTheme => {},
    dark: false,

    /* Font: */
    fontFamily: 'Montserrat-Regular',
    fontFamilyMedium: 'Montserrat-Medium', //500
    fontFamilySemibold: 'Montserrat-SemiBold', //600

    /* Buttons */
    buttonBorderRadius: 30,

    /* Colors: */
    colors: {
        white: '#ffffff',
        black: '#292929',

        greyExtraLight: '#e1e1e1',
        greyLight: '#d6d6d6',
        greyMedium: '#888',
        greyDark: '#656565',

        lightBg: '#fafafa',
        navBg: '#f2f2f2',

        borderLight: '#f2f2f2',
        borderPrimaryLight: 'rgba(93, 77, 157,  0.3)',

        textColor: '#292929',
        textColorMedium: '#656565',
        textColorLight: '#888',

        success: 'rgb(70, 159, 157)',
        error: 'rgb(232, 106, 106)',
        default: 'rgb(117, 154, 223)',

        iconColor: '#888',
    },

    /* Layout breakpoints:: */
    tabletBP: 768,
    desktopBP: 1024,

    Icon: {
        color: '#888',
    },

    /* Input */
    Input: {
        inputContainerStyle: {
            borderBottomWidth: 0,
            marginBottom: 20,
            position: 'relative',
        },

        containerStyle: {
            paddingLeft: 0,
            paddingRight: 0,
        },

        inputStyle: {
            borderBottomWidth: 1,
            borderColor: '#d6d6d6',
            color: '#292929',
            fontSize: 14,
            height: 40,
            minHeight: 40,
            fontFamily: 'Montserrat-Regular',
            paddingLeft: 0,
            paddingRight: 0,
        },

        labelStyle: {
            fontSize: 11,
            textTransform: 'uppercase',
            fontFamily: 'Montserrat-SemiBold',
            color: '#656565',
        },

        errorStyle: {
            fontFamily: 'Montserrat-Medium',
            fontSize: 11,
            margin: 0,
            position: 'absolute',
            bottom: 5,
            left: 10,
            right: 0,
        },
    },
};

export default baseTheme;

const echeckinTheme = {
    ...baseTheme,
    name: 'echeckinTheme',
    colors: {
        ...baseTheme.colors,
        primary: '#5D4D9D',
        primaryDark: '#423875',
        primaryMedium: '#8A87CA',
        primaryBgDark: '#736FA8',
        primaryBgMedium: '#817AAC',
        secondary: '#3E9BAE',
        secondaryDark: '#0E8199',
        primaryExtraLight: '#F4F5FB',
    },
};

const bibinjeTheme = {
    ...baseTheme,
    name: 'bibinjeTheme',
    colors: {
        ...baseTheme.colors,
        primary: '#263574',
        primaryDark: '#36297a',
        primaryMedium: '#93a0da',
        //primaryLight: '#93a0da',
        //primaryExtraLight: '#CCCAE9',
        //secondary: '#5072b2',
    },
};

export const themes = {
    echeckin: echeckinTheme,
    bibinje: bibinjeTheme,
};

export const initTheme = async setAppTheme => {
    // test - localstorage key: user_data value: {"user_data": {"theme": "bibinje"}}
    try {
        const themeName = await getUserTheme();
        if (themeName) {
            const theme = themes?.[themeName];
            theme && setAppTheme(theme);
        }
    } catch (e) {
        console.log(e);
    }
    for (const themeName in themes) {
        themes[themeName].changeTheme = newTheme => {
            setAppTheme(newTheme);
        };
    }
};
