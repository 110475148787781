import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, SafeAreaView, ScrollView, View, useWindowDimensions} from 'react-native';
import {Icon, LinearProgress, useTheme} from 'react-native-elements';
import WebView from 'react-native-webview';
import {useReactToPrint} from 'react-to-print';
import Confirm2For1Dialog from '../components/Confirm2For1Dialog';
import ConfirmDialog from '../components/ConfirmDialog';
import CreditsDepletedDialog from '../components/CreditsDepletedDialog';
import MessageDialog from '../components/MessageDialog';
import EditInvoiceDialog from '../components/invoice/EditInvoiceDialog';
import FooterActionButtons from '../components/invoice/FooterActionButtons';
import {useSharedDialogs} from '../components/invoice/useDialogs';
import {
    CHECKIN_ID,
    CONFIRM_DELETE_INVOICE_TITLE,
    CREDITS_DEPLETED_MSG,
    CREDITS_DEPLETED_TITLE,
    CREDITS_EXPIRED_MSG,
    CREDITS_EXPIRED_TITLE,
    INVOICE_DELETE_WARNING,
    NETWORK_OFFLINE_ERROR_MSG,
    SHARE_DOCUMENT_TEXT,
    content,
    id,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {
    checkIsCreditsProblemError,
    checkIsNetworkError,
    showCreditsDialog,
    useCreditsAfterExpireConfirm,
} from '../utils/credits';
import {updateInvoiceHtml} from '../utils/invoice/invoice';
import {createInvoiceDb, getLastInvoiceNumber, prepareDefaultInvoice} from '../utils/invoice/invoice.functions';
import {generatePdf} from '../utils/pdf';
import {sleep} from '../utils/promiseHelpers';
import {getInvoiceUserData} from '../utils/userUtils';
import useStyles from './InvoiceScreen.styles';

const isNativeWebView = Platform.OS !== 'web' ? true : false;
const fileName = 'eCheckinInvoice';

const InvoiceScreen = ({route, navigation}) => {
    const {
        clearErrors,
        getCheckin,
        apiErrors,
        apiMessages,
        createInvoice,
        getInvoice,
        getLastInvoice,
        updateInvoice,
        evaccounts,
        deleteInvoice,
    } = useContext(DataContext);
    const {checkinId = null, invoiceId = null} = route.params ?? {};
    const isFocused = useIsFocused();
    const [openProgress, setOpenProgress] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openEditInvoice, setOpenEditInvoice] = useState(false);
    const [htmlContent, setHtmlContent] = useState(null);
    const [checkin, setCheckin] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [invoiceMetaData, setInvoiceMetaData] = useState(null);
    const [savedInvoiceId, setSavedInvoiceId] = useState(null);
    const [confirmDeleteInvoiceOpen, setConfirmDeleteInvoiceOpen] = useState(false);
    const [isExistingInvoice, setIsExistingInvoice] = useState(false);
    const [offlineError, setOfflineError] = useState(false);

    const {openCreditsDepleted, openCreditsAfterExpire} = useSharedDialogs();

    const componentRef = useRef();

    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;

    const shareTitle = t(SHARE_DOCUMENT_TEXT);
    const shareText = t(SHARE_DOCUMENT_TEXT);

    const onEditInvoiceConfirm = async invoiceFormData => {
        setInvoice({...invoiceFormData});
        if (invoiceId || invoiceMetaData) {
            console.log('Editing existing invoice');
            const existingInvoiceId = invoiceId ?? invoiceMetaData[id];
            const invoiceDb = createInvoiceDb(existingInvoiceId, invoiceMetaData, invoiceFormData);
            await updateInvoice(invoiceDb);
        } else if (savedInvoiceId) {
            console.log('Updating new invoice');
            const invoiceDb = createInvoiceDb(savedInvoiceId, checkin, invoiceFormData);
            await updateInvoice(invoiceDb);
        } else {
            console.log('Creating new invoice');
            try {
                const invoiceDb = createInvoiceDb(null, checkin, invoiceFormData);
                await createInvoice(invoiceDb);
                setSavedInvoiceId(invoiceDb?.[id]);
            } catch (e) {
                await invoiceCreditsErrorHandling(e);
            }
        }
    };

    const invoiceCreditsErrorHandling = async e => {
        if (checkIsNetworkError(e)) {
            setOpenEditInvoice(false);
            await sleep(200);
            setOfflineError(true);
            setOpenMessage(true);
        } else if (checkIsCreditsProblemError(e)) {
            await sleep(200);
            await showCreditsDialog(e, openCreditsDepleted, openCreditsAfterExpire);
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onButtonPrint = async _ => {
        if (isNativeWebView) {
            await generatePdf(htmlContent, fileName, shareTitle, shareText);
        } else {
            handlePrint();
        }
    };

    const onButtonShare = async _ => {
        await generatePdf(htmlContent, fileName, shareTitle, shareText);
    };

    const generateMessage = useCallback(() => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        } else if (apiMessages) {
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        } else if (offlineError) {
            return `${t(NETWORK_OFFLINE_ERROR_MSG)}`;
        }
        return null;
    }, [apiErrors, apiMessages, offlineError]);

    const closeErrorMessage = _ => {
        setOfflineError(null);
        setOpenMessage(false);
    };

    const onEditClick = _ => {
        setOpenEditInvoice(true);
    };

    const onDelete = useCallback(async () => {
        setConfirmDeleteInvoiceOpen(true);
    }, [invoiceMetaData]);

    const continueDeleteInvoice = useCallback(async () => {
        setConfirmDeleteInvoiceOpen(false);
        await deleteInvoice(invoiceMetaData);
        navigation.pop();
    }, [invoiceMetaData]);

    const onBuyCreditsPress = async () => {
        navigation.navigate('Packages');
    };

    const deleteButton = (
        <View style={[global.flexCenter]}>
            <Pressable onPress={onDelete} style={{marginHorizontal: 15}}>
                <Icon name="trash-outline" type="ionicon" size={22} color={theme.colors.primary} />
            </Pressable>
        </View>
    );

    useFocusEffect(
        useCallback(() => {
            return () => {
                setCheckin(null);
                setInvoice(null);
                setInvoiceMetaData(null);
            };
        }, [])
    );

    useEffect(() => {
        if (isFocused && evaccounts) {
            clearErrors();
            if (checkinId || invoiceId) {
                const initInvoice = async () => {
                    if (checkinId && !invoiceId) {
                        const foundCheckin = await getCheckin(checkinId);
                        if (foundCheckin) {
                            const generatedInvoiceNumber = await getLastInvoiceNumber(foundCheckin, getLastInvoice);
                            const {userInfoData, operatorData, footerData} = await getInvoiceUserData();

                            const preparedInvoice = await prepareDefaultInvoice(
                                foundCheckin,
                                userInfoData,
                                generatedInvoiceNumber,
                                evaccounts,
                                operatorData,
                                footerData
                            );
                            setCheckin(foundCheckin);
                            setInvoice(preparedInvoice);
                        } else {
                            console.log('Could not find checkin');
                        }
                    } else if (invoiceId) {
                        const foundInvoice = await getInvoice(invoiceId);
                        const invoiceContent = foundInvoice?.[content];
                        const foundCheckin = await getCheckin(foundInvoice[CHECKIN_ID]);
                        if (foundCheckin) {
                            setCheckin(foundCheckin);
                        }
                        if (invoiceContent) {
                            setInvoice(invoiceContent);
                            setInvoiceMetaData(foundInvoice);
                        }
                    } else {
                        console.log('No checkinId nor invoiceId!');
                    }
                };
                initInvoice().catch(console.error);
            }
        }
    }, [isFocused, evaccounts]);

    useEffect(() => {
        if (invoice) {
            updateInvoiceHtml(setHtmlContent, invoice, t, setOpenProgress).catch(console.error);
        }
    }, [JSON.stringify(invoice)]);

    useEffect(() => {
        if ((invoiceMetaData || savedInvoiceId || invoiceId) && !isExistingInvoice) {
            navigation.setOptions({
                headerRight: () => <View style={[global.flexCenter]}>{deleteButton}</View>,
            });
            setIsExistingInvoice(true);
        }
    }, [invoiceMetaData, savedInvoiceId, isExistingInvoice]);

    return (
        <SafeAreaView style={global.containerBgDarker}>
            {openProgress && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}

            {/*Invoice preview*/}
            {isNativeWebView ? (
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <View style={styles.invoiceBoxWrapper}>
                        <View style={styles.invoiceBox}>{htmlContent && <WebView source={{html: htmlContent}} />}</View>
                    </View>
                </ScrollView>
            ) : (
                <ScrollView contentContainerStyle={{flexGrow: 1, alignItems: 'center'}}>
                    <View style={[styles.invoiceBoxWrapper, isMobile && {justifyContent: 'flex-start'}]}>
                        <View style={styles.invoiceBox}>
                            {htmlContent && (
                                <div
                                    ref={componentRef}
                                    dangerouslySetInnerHTML={{__html: htmlContent}}
                                    style={{width: '100%'}}
                                />
                            )}
                        </View>
                    </View>
                </ScrollView>
            )}

            {/*<BottomButtonGroup childButtons={bottomActionButtons(isExistingInvoice)} />*/}
            <FooterActionButtons
                t={t}
                theme={theme}
                global={global}
                isMobile={isMobile}
                onButtonPrint={onButtonPrint}
                onButtonShare={onButtonShare}
                onEditClick={onEditClick}
                isExistingInvoice={isExistingInvoice}
            />
            <EditInvoiceDialog
                open={openEditInvoice}
                setOpen={setOpenEditInvoice}
                onConfirm={onEditInvoiceConfirm}
                invoice={invoice}
                evaccounts={evaccounts}
                isExistingInvoice={isExistingInvoice}
                checkin={checkin}
            />
            <MessageDialog
                message={generateMessage()}
                isError={apiErrors}
                open={openMessage}
                handleOpen={closeErrorMessage}
            />
            <ConfirmDialog
                title={t(CONFIRM_DELETE_INVOICE_TITLE)}
                open={confirmDeleteInvoiceOpen}
                setOpen={setConfirmDeleteInvoiceOpen}
                onConfirm={continueDeleteInvoice}
                content={t(INVOICE_DELETE_WARNING)}
            />
            <Confirm2For1Dialog
                title={t(CREDITS_EXPIRED_TITLE)}
                content={t(CREDITS_EXPIRED_MSG)}
                onConfirm={useCreditsAfterExpireConfirm}
                onBuy={onBuyCreditsPress}
                useSharedDialogs={useSharedDialogs}
            />
            <CreditsDepletedDialog
                title={t(CREDITS_DEPLETED_TITLE)}
                content={t(CREDITS_DEPLETED_MSG)}
                onBuy={onBuyCreditsPress}
                useSharedDialogs={useSharedDialogs}
            />
        </SafeAreaView>
    );
};

export default InvoiceScreen;
