import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Text, Button, Icon, useTheme, Input, LinearProgress} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {
    CLOSE,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_DIALOG_CONTENT_1,
    DELETE_ACCOUNT_DIALOG_CONTENT_2,
    DELETE_ACCOUNT_EMAIL_INCORRECT,
    DELETE_ACCOUNT_ERROR,
    DELETE_ACCOUNT_INTRO,
    DELETE_ACCOUNT_SUCCESS,
    ABOUT_EMAIL_OFFICE,
    EMAIL,
    EMAIL_FIELD,
    MESSAGE,
    OPTIONAL,
    SEND,
} from '../../constants/stringsAndFields';
import Modal from 'react-native-modal';
import {View, Pressable, ScrollView, Platform, Linking} from 'react-native';
import DataContext from '../../context/dataContext';
import {sleep} from '../../utils/promiseHelpers';
import {getUserEmail} from '../../utils/userUtils';

const DeleteDialog = ({setVisible, visible}) => {
    const {deleteAccount} = useContext(DataContext);
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const [progressOngoing, setProgressOngoing] = useState(false);
    const [progressDetails, setProgressDetails] = useState(null);
    const [errors, setErrors] = useState(null);
    const [focusMessage, setMessageFocus] = useState(false);
    const [focusEmail, setEmailFocus] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const isWeb = Platform.OS === 'web';

    const toggleOverlay = () => {
        setVisible(!visible);
        setProgressDetails(null);
        setErrors(null);
    };

    const validate = async emailField => {
        const storedEmail = await getUserEmail();
        if (emailField === storedEmail) {
            setErrors(null);
            return true;
        } else {
            setErrors({
                [EMAIL_FIELD]: t(DELETE_ACCOUNT_EMAIL_INCORRECT),
            });
            return false;
        }
    };

    const openEmail = () => {
        Linking.openURL('mailto:office@echeckin.hr');
    };

    const doDelete = async () => {
        const isValid = await validate(email);
        if (isValid) {
            setProgressOngoing(true);
            try {
                setProgressDetails(null);
                const emailAndMessage = {email: email, message: message};
                await deleteAccount(emailAndMessage);
                setProgressDetails(t(DELETE_ACCOUNT_SUCCESS));
                await sleep(3000);
                toggleOverlay();
            } catch (e) {
                console.log(e);
                setProgressDetails(t(DELETE_ACCOUNT_ERROR));
            } finally {
                setProgressOngoing(false);
            }
        }
    };

    return (
        <Modal
            isVisible={visible}
            onBackdropPress={toggleOverlay}
            onBackButtonPress={toggleOverlay}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}
            avoidKeyboard={true}>
            <View style={[global.modalDialog, {maxHeight: '100%'}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(DELETE_ACCOUNT)}</Text>
                    <Pressable onPress={toggleOverlay} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <View style={[global.modalContent, {padding: 20}]}>
                        {progressOngoing && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
                        <Text
                            style={[
                                global.textMain,
                                {
                                    fontFamily: theme.fontFamilyMedium,
                                    fontSize: 16,
                                    textAlign: 'center',
                                    marginBottom: 20,
                                    marginTop: 5,
                                },
                            ]}>
                            {t(DELETE_ACCOUNT_INTRO)}
                        </Text>
                        <Text style={global.textMain}>
                            {t(DELETE_ACCOUNT_DIALOG_CONTENT_1)}
                            <Text style={[global.link, isWeb && {cursor: 'pointer'}]} onPress={openEmail}>
                                {t(ABOUT_EMAIL_OFFICE)}
                            </Text>
                        </Text>
                        <Text style={global.textMain}>{t(DELETE_ACCOUNT_DIALOG_CONTENT_2)}</Text>

                        <Input
                            placeholder={t(EMAIL)}
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                            style={[
                                global.inputRounded,
                                focusEmail && global.inputFocus,
                                {
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                },
                            ]}
                            containerStyle={{marginTop: 20}}
                            inputContainerStyle={{marginBottom: 15}}
                            onChangeText={text => setEmail(text)}
                            errorMessage={errors?.[EMAIL_FIELD] ?? null}
                        />
                        <Input
                            multiline={true}
                            numberOfLines={4}
                            textAlignVertical="top"
                            placeholder={t(MESSAGE) + t(OPTIONAL)}
                            onFocus={() => setMessageFocus(true)}
                            onBlur={() => setMessageFocus(false)}
                            style={[
                                global.inputRounded,
                                focusMessage && global.inputFocus,
                                {
                                    height: 90,
                                    borderWidth: 1,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    borderRadius: 10,
                                },
                            ]}
                            inputContainerStyle={{marginBottom: 0}}
                            onChangeText={text => setMessage(text)}
                        />
                        {progressDetails && <Text style={global.modalContentText}>{progressDetails}</Text>}
                    </View>
                </ScrollView>
                <View style={global.modalFooter}>
                    <Button
                        onPress={toggleOverlay}
                        title={t(CLOSE)}
                        containerStyle={global.ModalButton.Negative.containerStyle}
                        buttonStyle={global.ModalButton.Negative.buttonStyle}
                        titleStyle={global.ModalButton.Negative.titleStyle}
                    />
                    <Button
                        title={t(SEND)}
                        onPress={doDelete}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={[
                            global.ModalButton.Positive.buttonStyle,
                            {backgroundColor: '#f94747', borderColor: '#f94747'},
                        ]}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default DeleteDialog;
