import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ImageBackground, Linking, Platform, Pressable, View, useWindowDimensions} from 'react-native';
import {Button, Image, Text, useTheme} from 'react-native-elements';
import PdfViewer from '../../components/PdfViewer';
import GdprDialog from '../../components/self-checkin/GdprDialog';
import globalStyle from '../../theme/globalStyle';
import {generateExternalDocLink} from '../../utils/helpers';
import useStyles from './SelfCheckinFooter.styles';

import {
    CONTACT,
    DOWNLOAD_APP,
    ECHECKIN_GDPR_WEB_URL,
    GDPR,
    PARTNERS,
    PRIVACY_TITLE,
    SELF_CHECKIN_APP_DESCRIPTION,
    SELF_CHECKIN_GDPR_LINK,
    SELF_CHECKIN_NOTE_GDPR,
    TERMS_TITLE,
} from '../../constants/stringsAndFields';
import HtmlDialog, {PRIVACY_VIEW, TERMS_VIEW} from '../htmlviews/HtmlDialog';
import {logoSvg} from './SelfCheckinFooter.logo';

const IS_WEB = Platform.OS === 'web';
const FOOTER_BG_IMAGE = require('../../../assets/images/backgrounds/self-checkin-footer.jpg');
const GP_IMAGE = require('../../../assets/images/partners/going-places.png');

const SelfCheckinFooter = props => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const styles = useStyles(theme);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [openGDPRView, setOpenGDPRView] = useState(false);

    const width = useWindowDimensions().width;
    const isTablet = width < theme.desktopBP;
    const isDesktop = width >= theme.desktopBP;
    const isMobileSmall = width < 550;

    const termsPreview = () => {
        setShowTerms(true);
    };

    const privacyPreview = () => {
        setShowPrivacy(true);
    };

    const openGdpr = () => {
        setOpenGDPRView(true);
    };

    const openWeb = () => {
        Linking.openURL('https://echeckin.hr/');
    };

    const openMail = () => {
        Linking.openURL('mailto: office@echeckin.hr');
    };

    const openFb = () => {
        Linking.openURL('https://www.facebook.com/eCheckin/');
    };

    const openGP = () => {
        Linking.openURL('https://www.goingplaces.earth/');
    };

    const openAndroidApp = () => {
        Linking.openURL('https://play.google.com/store/apps/details?id=app.echeckin.hr');
    };

    const openiOsApp = () => {
        Linking.openURL('https://apps.apple.com/app/echeckin/id1636458005');
    };

    const openWebApp = () => {
        Linking.openURL('https://app.echeckin.hr/');
    };

    const documentUrl = generateExternalDocLink(t, ECHECKIN_GDPR_WEB_URL);

    return (
        <ImageBackground
            source={FOOTER_BG_IMAGE}
            style={[
                styles.footerWrapper,
                isTablet && {paddingHorizontal: 30},
                isMobileSmall && {paddingHorizontal: 10},
            ]}>
            <View>
                <View style={global.containerInner}>
                    <View
                        style={[
                            isDesktop && {flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'},
                        ]}>
                        <View style={[isTablet && {alignItems: 'center', textAlign: 'center'}, {paddingBottom: 20}]}>
                            {logoSvg}
                            <View style={{marginTop: 20}}>
                                <Text style={styles.footerText}>{t(SELF_CHECKIN_APP_DESCRIPTION)}</Text>
                            </View>
                        </View>

                        {/* Contact */}
                        <View style={isTablet && {alignItems: 'center'}}>
                            <Text style={[styles.footerSectionTitle, {marginBottom: 10}]}>{t(CONTACT)}</Text>
                            <Pressable onPress={openWeb}>
                                <Text style={[styles.footerLink, styles.echeckinLink, IS_WEB && {cursor: 'pointer'}]}>
                                    echeckin.hr
                                </Text>
                            </Pressable>
                            <Pressable onPress={openMail}>
                                <Text style={[styles.footerLink, styles.echeckinLink, IS_WEB && {cursor: 'pointer'}]}>
                                    office@echeckin.hr
                                </Text>
                            </Pressable>
                            <Pressable onPress={openFb}>
                                <Text style={[styles.footerLink, styles.echeckinLink, IS_WEB && {cursor: 'pointer'}]}>
                                    facebook.com/eCheckin
                                </Text>
                            </Pressable>
                        </View>

                        {/* Download */}
                        <View style={[isTablet && styles.sectionBorder]}>
                            <Text style={[styles.footerSectionTitle, isTablet && {textAlign: 'center'}]}>
                                {t(DOWNLOAD_APP)}
                            </Text>
                            <View style={[global.flexCenter, isTablet && {justifyContent: 'center', flexWrap: 'wrap'}]}>
                                <Button
                                    onPress={openAndroidApp}
                                    title={'Google Play'}
                                    icon={{
                                        type: 'ionicon',
                                        name: 'logo-google-playstore',
                                        size: 16,
                                        color: theme.colors.white,
                                        containerStyle: {marginLeft: 0, marginRight: 5},
                                    }}
                                    containerStyle={[global.Button.containerStyle, {marginRight: 5, marginBottom: 10}]}
                                    buttonStyle={styles.storeBtn}
                                    titleStyle={styles.storeBtnTitle}
                                />
                                <Button
                                    onPress={openiOsApp}
                                    title={'APPLE STORE'}
                                    icon={{
                                        type: 'ionicon',
                                        name: 'logo-apple',
                                        size: 16,
                                        color: theme.colors.white,
                                        containerStyle: {marginLeft: 0, marginRight: 5},
                                    }}
                                    containerStyle={[
                                        global.Button.containerStyle,
                                        {marginHorizontal: 5, marginBottom: 10},
                                    ]}
                                    buttonStyle={styles.storeBtn}
                                    titleStyle={styles.storeBtnTitle}
                                />
                                <Button
                                    onPress={openWebApp}
                                    title={'WEB APP'}
                                    icon={{
                                        type: 'ionicon',
                                        name: 'desktop-outline',
                                        size: 16,
                                        color: theme.colors.white,
                                        containerStyle: {marginLeft: 0, marginRight: 5},
                                    }}
                                    containerStyle={[global.Button.containerStyle, {marginLeft: 5, marginBottom: 10}]}
                                    buttonStyle={styles.storeBtn}
                                    titleStyle={styles.storeBtnTitle}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            {/* Partners */}
            <View>
                <View style={global.containerInner}>
                    <View style={[styles.sectionBorder, isTablet && {marginTop: 10}]}>
                        <Text style={[styles.footerSectionTitle, {textAlign: 'center'}]}>{t(PARTNERS)}</Text>
                        <View style={styles.footerPartnersImages}>
                            <Pressable onPress={openGP} style={{marginHorizontal: isTablet ? 10 : 20}}>
                                <Image source={GP_IMAGE} style={{width: 56, height: 25}}></Image>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </View>

            {/* Legal */}
            <View>
                <View style={global.containerInner}>
                    <View style={[styles.sectionBorder]}>
                        <View
                            style={[
                                global.flexCenter,
                                {flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center'},
                            ]}>
                            <Text style={[styles.footerLink, IS_WEB && {cursor: 'pointer'}]} onPress={termsPreview}>
                                {t(TERMS_TITLE)}
                            </Text>
                            <Text style={[styles.footerText, {opacity: 0.6, marginHorizontal: 5}]}>{' | '}</Text>
                            <Text style={[styles.footerLink, IS_WEB && {cursor: 'pointer'}]} onPress={privacyPreview}>
                                {t(PRIVACY_TITLE)}
                            </Text>
                            <Text style={[styles.footerText, {opacity: 0.6, marginHorizontal: 5}]}>{' | '}</Text>
                            <Text style={styles.footerText}>
                                {t(SELF_CHECKIN_NOTE_GDPR)}
                                <Text onPress={openGdpr} style={styles.footerLink}>
                                    {t(SELF_CHECKIN_GDPR_LINK)}
                                </Text>
                            </Text>
                        </View>
                        <View style={{alignItems: 'center', paddingTop: 15}}>
                            <Text style={styles.footerText}>© {new Date().getFullYear()} eCheckin</Text>
                        </View>
                    </View>
                </View>
            </View>

            {showTerms && <HtmlDialog isOpen={showTerms} setOpen={setShowTerms} view={TERMS_VIEW} />}
            {showPrivacy && <HtmlDialog isOpen={showPrivacy} setOpen={setShowPrivacy} view={PRIVACY_VIEW} />}
            <GdprDialog title={GDPR} visible={openGDPRView} setVisible={setOpenGDPRView}>
                <PdfViewer documentUrl={documentUrl} />
            </GdprDialog>
        </ImageBackground>
    );
};

export default SelfCheckinFooter;
