import React from 'react';
import {FlatList, RefreshControl, View, Text, useWindowDimensions} from 'react-native';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {useTranslation} from 'react-i18next';
import {NO_EVACCOUNT, PIN} from '../../constants/stringsAndFields';
import EVAccountItem from './EVAccountItem';
import useStyles from './EVAccountsList.styles';

const EVAccountsList = ({evaccounts, onDelete, refreshing, setOpenProgress, onRefresh, refetchEvisitor}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const width = useWindowDimensions().width;
    const isDesktop = width > 1500;

    const render = ({item}) => (
        <EVAccountItem
            evaccount={item}
            onDelete={onDelete}
            setOpenProgress={setOpenProgress}
            refetchEvisitor={refetchEvisitor}
        />
    );
    const listEmpty = () => (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_EVACCOUNT)}</Text>
        </View>
    );

    return (
        <FlatList
            data={evaccounts}
            renderItem={render}
            keyExtractor={item => item[PIN]}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            contentContainerStyle={[global.flatList, global.hasFAB, global.containerInner, {flexGrow: 1}]}
            ListEmptyComponent={listEmpty}
        />
    );
};

export default EVAccountsList;
