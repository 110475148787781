import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    contentWrapper: {
        width: '100%',
        maxWidth: 280,
        marginHorizontal: 'auto',
        paddingTop: 40,
        paddingBottom: 30,
        paddingHorizontal: 30,
        borderWidth: 1,
        borderColor: theme.colors.greyExtraLight,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
    },

    title: {
        color: theme.colors.primary,
        fontSize: 14,
        fontFamily: theme.fontFamilySemibold,
        textTransform: 'uppercase',
        marginBottom: 15,
        marginTop: 0,
        textAlign: 'center',
    },

    qrcode: {
        marginBottom: 30,
    },

    note: {
        fontSize: 12,
    },

    poweredNote: {
        color: theme.colors.textColorMedium,
        fontSize: 10,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 5,
    },
}));

export default useStyles;
