import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, View} from 'react-native';
import {Button, CheckBox, Icon, Input, ListItem, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    ACCEPT,
    CANCEL_TEXT,
    CANCEL_TOURIST,
    CHECKIN_ACTIONS,
    CHECKIN_TOURIST,
    CHECKOUT_TOURIST,
    CHECKOUT_WITH_CUSTOM_DATE,
    CHECKOUT_WITH_PREDEFINED_DATE,
    DECLINE,
    FORESEEN_STAY_UNTIL_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {
    getDateFromDateString,
    getDateFromTime,
    getFormattedDateFromDateString,
    getStringDate,
    getStringTime,
} from '../../utils/dateHelper';
import FormDateTimePicker from '../datetime/FormDateTimePicker';
import useStyles from './CheckinActionsDialog.styles';

const IS_WEB = Platform.OS === 'web';

const CheckinActionsDialog = ({onClose, selectedCheckinActions, actionName, guestName, guest, open = false}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const [predefinedCheckoutDate, setPredefinedCheckoutDate] = useState(true);
    const [customCheckoutDate, setCustomCheckoutDate] = useState(false);
    const [datePickerData, setDatePickerData] = useState(null);
    const [closeDateTimePicker, setCloseDateTimePicker] = useState(true);
    const [checkoutDateData, setCheckoutDateData] = useState({});

    const toDayRef = useRef(null);
    const toTimeRef = useRef(null);

    const onPredefinedCheckoutDate = _ => {
        setPredefinedCheckoutDate(true);
        setCustomCheckoutDate(false);
    };

    const onCustomCheckoutDate = _ => {
        setCustomCheckoutDate(true);
        setPredefinedCheckoutDate(false);
    };

    const onChangeDate = async (date, field) => {
        setCheckoutDateData({...checkoutDateData, [field]: getStringDate(date)});
    };

    const onChangeTime = async (date, field) => {
        setCheckoutDateData({...checkoutDateData, [field]: getStringTime(date)});
    };

    const forseenStayUntil = checkoutDateData?.[FORESEEN_STAY_UNTIL_FIELD] ?? guest?.[FORESEEN_STAY_UNTIL_FIELD] ?? '';
    const timeEstimatedUntil =
        checkoutDateData?.[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? guest?.[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? '';

    const openDateTimePicker = (mode, field, value) => {
        setDatePickerData({
            datePickerMode: mode,
            datePickerDate: mode === 'date' ? getDateFromDateString(value) : getDateFromTime(value),
            currentPickerField: field,
        });
        setCloseDateTimePicker(false);
    };

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = async _ => {
        onClose();
        if (actionName === CHECKOUT_TOURIST && customCheckoutDate) {
            const checkoutData = {forseenStayUntil, timeEstimatedUntil};
            await selectedCheckinActions(checkoutData);
        } else {
            await selectedCheckinActions();
        }
    };

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(CHECKIN_ACTIONS)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={global.modalContent}>
                    {actionName === CHECKIN_TOURIST && (
                        <ListItem onPress={handleListItemClick} key={CHECKIN_TOURIST}>
                            <ListItem.Content>
                                <ListItem.Title style={global.modalContentText}>{`${t(
                                    CHECKIN_TOURIST
                                )}: ${guestName}`}</ListItem.Title>
                            </ListItem.Content>
                        </ListItem>
                    )}
                    {actionName === CHECKOUT_TOURIST && (
                        <View>
                            <Text style={[global.link, {marginBottom: 10}]}>
                                {`${t(CHECKOUT_TOURIST)}: ${guestName}`}{' '}
                            </Text>
                            <CheckBox
                                title={t(CHECKOUT_WITH_PREDEFINED_DATE)}
                                checked={predefinedCheckoutDate}
                                onPress={onPredefinedCheckoutDate}
                                containerStyle={global.CheckBox}
                                textStyle={global.CheckBoxText}
                                size={21}
                            />
                            <CheckBox
                                title={t(CHECKOUT_WITH_CUSTOM_DATE)}
                                checked={customCheckoutDate}
                                onPress={onCustomCheckoutDate}
                                containerStyle={global.CheckBox}
                                textStyle={global.CheckBoxText}
                                size={21}
                            />
                            {customCheckoutDate && (
                                <View
                                    style={[
                                        global.timeBox,
                                        {
                                            marginLeft: 40,
                                            marginTop: 10,
                                            marginBottom: 5,
                                            paddingTop: 3,
                                            paddingBottom: 0,
                                            paddingLeft: 10,
                                            maxWidth: 160,
                                        },
                                    ]}>
                                    <View style={global.timeInputWrapper}>
                                        <Input
                                            leftIcon={
                                                <Pressable
                                                    onPress={_ => {
                                                        toDayRef.current.blur();
                                                        openDateTimePicker(
                                                            'date',
                                                            FORESEEN_STAY_UNTIL_FIELD,
                                                            forseenStayUntil
                                                        );
                                                    }}
                                                    style={({pressed}) => ({
                                                        opacity: pressed ? 0.5 : 1,
                                                    })}>
                                                    <Icon
                                                        type="ionicon"
                                                        name={'calendar-outline'}
                                                        size={16}
                                                        color={theme.colors.iconColor}
                                                    />
                                                </Pressable>
                                            }
                                            leftIconContainerStyle={global.inputIconContainer}
                                            ref={toDayRef}
                                            onFocus={_ => {
                                                toDayRef.current.blur();
                                                openDateTimePicker('date', FORESEEN_STAY_UNTIL_FIELD, forseenStayUntil);
                                            }}
                                            showSoftInputOnFocus={false}
                                            selectTextOnFocus={false}
                                            value={getFormattedDateFromDateString(forseenStayUntil)}
                                            inputContainerStyle={global.timeInputContainer}
                                            inputStyle={[global.timeInput, IS_WEB && {cursor: 'pointer'}]}
                                        />
                                    </View>
                                    <View style={global.timeInputWrapper}>
                                        <Input
                                            leftIcon={
                                                <Pressable
                                                    onPress={_ => {
                                                        toTimeRef.current.blur();
                                                        openDateTimePicker(
                                                            'time',
                                                            TIME_ESTIMATED_STAY_UNTIL_FIELD,
                                                            timeEstimatedUntil
                                                        );
                                                    }}
                                                    style={({pressed}) => ({
                                                        opacity: pressed ? 0.5 : 1,
                                                    })}>
                                                    <Icon
                                                        type="ionicon"
                                                        name={'time-outline'}
                                                        size={16}
                                                        color={theme.colors.iconColor}
                                                    />
                                                </Pressable>
                                            }
                                            leftIconContainerStyle={global.inputIconContainer}
                                            ref={toTimeRef}
                                            onFocus={_ => {
                                                toTimeRef.current.blur();
                                                openDateTimePicker(
                                                    'time',
                                                    TIME_ESTIMATED_STAY_UNTIL_FIELD,
                                                    timeEstimatedUntil
                                                );
                                            }}
                                            showSoftInputOnFocus={false}
                                            selectTextOnFocus={false}
                                            value={timeEstimatedUntil}
                                            inputContainerStyle={global.timeInputContainer}
                                            inputStyle={[global.timeInput, IS_WEB && {cursor: 'pointer'}]}
                                        />
                                    </View>
                                </View>
                            )}
                        </View>
                    )}

                    {actionName === CANCEL_TOURIST && (
                        <ListItem onPress={handleListItemClick} key={CANCEL_TOURIST}>
                            <ListItem.Content>
                                <ListItem.Title style={global.modalContentText}>
                                    {`${t(CANCEL_TOURIST)}: ${guestName}`}{' '}
                                </ListItem.Title>
                                <ListItem.Subtitle>{t(CANCEL_TEXT)} </ListItem.Subtitle>
                            </ListItem.Content>
                        </ListItem>
                    )}
                </View>
                {actionName === CHECKOUT_TOURIST && (
                    <View style={global.modalFooter}>
                        <Button
                            onPress={handleClose}
                            title={t(DECLINE)}
                            containerStyle={global.ModalButton.Negative.containerStyle}
                            buttonStyle={global.ModalButton.Negative.buttonStyle}
                            titleStyle={global.ModalButton.Negative.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                        <Button
                            onPress={handleListItemClick}
                            title={t(ACCEPT)}
                            containerStyle={global.ModalButton.Positive.containerStyle}
                            buttonStyle={global.ModalButton.Positive.buttonStyle}
                            titleStyle={global.ModalButton.Positive.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                    </View>
                )}
                <FormDateTimePicker
                    closed={closeDateTimePicker}
                    setClosed={setCloseDateTimePicker}
                    onChangeDate={onChangeDate}
                    onChangeTime={onChangeTime}
                    datePickerData={datePickerData}
                />
            </View>
        </Modal>
    );
};

export default CheckinActionsDialog;
